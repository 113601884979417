import React, { FunctionComponent, PropsWithChildren } from "react";
import { Svg, Path, Rect } from "react-native-svg";
import { IconProps } from "./types";

export const AccountIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 22 23"
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path d="M17.6667 20.5V18.8333C17.6667 17.9493 17.3155 17.1014 16.6904 16.4763C16.0652 15.8512 15.2174 15.5 14.3333 15.5H7.66668C6.78262 15.5 5.93478 15.8512 5.30965 16.4763C4.68453 17.1014 4.33334 17.9493 4.33334 18.8333V20.5M14.3333 8.83333C14.3333 10.6743 12.841 12.1667 11 12.1667C9.15906 12.1667 7.66668 10.6743 7.66668 8.83333C7.66668 6.99238 9.15906 5.5 11 5.5C12.841 5.5 14.3333 6.99238 14.3333 8.83333Z" />
      <Rect x="0.75" y="0.75" width="20.5" height="20.5" rx="3.25" />
    </Svg>
  );
};
