import create from "zustand";
import { SubjectOptionsEnum } from "./MessageProps";

export const useMessagesState = create<MessagesState>(() => ({
  showModal: false,
}));

interface MessagesState {
  showModal: boolean;
  initialSubject?: SubjectOptionsEnum;
}
