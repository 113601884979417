import React, { FunctionComponent } from "react";
import Svg, { Path, Circle, Defs } from "react-native-svg";
import Group from "react-native-svg";
import ClipPath from "react-native-svg";

import { IconProps } from "./types";

export const TwitterCircleIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg width={35} height={34} fill="none" testID={testID} color={color}>
      <Circle cx={17.5} cy={17} r={16} stroke="#fff" strokeWidth={2} />
      <Group clipPath="url(#a)">
        <Path
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M24.833 11a7.267 7.267 0 0 1-2.093 1.02 2.986 2.986 0 0 0-5.24 2v.667a7.106 7.106 0 0 1-6-3.02s-2.667 6 3.333 8.666a7.76 7.76 0 0 1-4.666 1.334c6 3.333 13.333 0 13.333-7.667 0-.186-.018-.371-.053-.553A5.147 5.147 0 0 0 24.833 11Z"
        />
      </Group>
      <Defs>
        <ClipPath id="a">
          <Path fill="#fff" d="M9.5 9h16v16h-16z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
