import { ApiError, AsyncStatus } from "../../../store/types";
import {
  CreateRefillDto,
  FulfillmentMethod,
  IngressMethod,
  PrescriptionDto,
  RefillDto,
} from "@digitalpharmacist/prescription-service-client-axios";
import create from "zustand";
import {
  LocationPatientRecordDto,
  PatientRecordDto,
} from "@digitalpharmacist/patient-service-client-axios";
import { OrderItemDto } from "@digitalpharmacist/order-service-client-axios";
import { Platform } from "react-native";
import refillService from "../../refill/refill-service";
import { PatientOrder } from "./order-service";

interface OrderRefillState {
  status?: AsyncStatus;
  error?: ApiError;
  patientOrders?: PatientOrder[];
  orderRefill?: RefillDto;
  selectedPatient?: LocationPatientRecordDto;
  selectedPatientRecord?: PatientRecordDto;
  updateOrderRefillData?: (data: OrderRefillState) => void;
  submitRefill?: (
    pharmacy_id: string,
    location_id: string,
    patient_id: string,
    fulfillmentMethod: string,
    refillItems: OrderItemDto[],
    submittedBy: string
  ) => Promise<void>;
}

export const useOrderRefillStore = create<OrderRefillState>((set) => ({
  status: "idle",
  patientOrders: [],
  orderRefill: undefined,
  selectedPatient: undefined,
  selectedPatientRecord: undefined,
  updateOrderRefillData: (data: OrderRefillState) => {
    set({ ...data });
  },
  submitRefill: async (
    pharmacy_id: string,
    location_id: string,
    patient_id: string,
    fulfillmentMethod: string,
    refillItems: OrderItemDto[],
    submittedBy: string
  ) => {
    const refill: CreateRefillDto = {
      pharmacy_id: pharmacy_id,
      location_id: location_id,
      patient_id: patient_id,
      ingress_method:
        Platform.OS === "web" ? IngressMethod.Web : IngressMethod.Mobile,
      fulfillment_method: fulfillmentMethod as FulfillmentMethod,
      refill_items: refillItems.map((it) => ({
        rx_number: it.item_name,
        rx_name: it.item_description,
      })),
      submitted_by: submittedBy,
    };

    await refillService.submitPatientRefill(location_id, patient_id, refill);
  },
}));
