import "react-native-gesture-handler";
import AsyncStorage from "@react-native-async-storage/async-storage";
import React from "react";
import {
  ThemeProvider,
  useThemeContext,
  createBrandedColors,
} from "assets/theme";
import { NavigationContainer } from "./modules/common/NavigationContainer";
import { RootNavigation } from "./modules/navigation/RootNavigation";
import { initLogger } from "assets/logging/logging";
import {
  DeepLinkingProvider,
  DefaultDeepLinkingConfig,
  initSmartBanner,
} from "assets/core/deep-linking";
import { initPushNotificationToken } from "./modules/common/push-notifications";
import { AppLoader } from "assets/common/AppLoader";
import { BottomSheetModalProvider } from "@gorhom/bottom-sheet";
import { useLoginState } from "./modules/screens/login/login-store";
import { isUserAuthorized, useUserState } from "./modules/store/user-store";
import {
  PHARMACY_ID,
  RETURN_URL_KEY,
  SENTRY_ENVIRONMENT,
  MAPBOX_ACCESS_TOKEN,
} from "./modules/common/constants";
import { StorageKeys } from "./enums/storage-keys";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { ApiConfig } from "assets/core/api";
import { logout } from "./modules/screens/login/login-actions";
import PharmacyServiceInstance from "./modules/api/pharmacy-service";
import {
  BrandingDto,
  PharmacyDto,
} from "@digitalpharmacist/pharmacy-service-client-axios";
import { SafeAreaProvider } from "react-native-safe-area-context";
import UsersServiceInstance from "./modules/api/users-service";
import { PatientUserDto } from "@digitalpharmacist/users-service-client-axios";
import { Platform } from "react-native";
import { useAppStateStore } from "./modules/store/app-store";
import { isMobileOrTabletBrowser } from "./modules/utils";
import {
  useNavigation,
  NavigationProp,
  ParamListBase,
} from "@react-navigation/native";
import { MapProvider } from "assets/components/map/context";

initLogger(SENTRY_ENVIRONMENT);
initPushNotificationToken();

ApiConfig.setBaseApiConfig({
  getAccessToken: async () =>
    await AsyncStorage.getItem(StorageKeys.AccessToken),
  setAccessToken: async (token: string) =>
    await AsyncStorage.setItem(StorageKeys.AccessToken, token),
  signOut: async () => {
    await logout();
  },
  onError: async (error: any) => {
    // TODO
    console.error("error from ApiConfig.onError", error);
  },
  retryRefreshToken: async () => {
    return Promise.resolve("");
  },
});

Platform.OS === "web" &&
  DefaultDeepLinkingConfig.enableSmartBanner &&
  initSmartBanner();

export default function App() {
  return (
    <SafeAreaProvider>
      <ThemeProvider>
        <NavigationContainer>
          <BottomSheetModalProvider>
            <ActionSheetProvider>
              <AppProvider />
            </ActionSheetProvider>
          </BottomSheetModalProvider>
        </NavigationContainer>
      </ThemeProvider>
    </SafeAreaProvider>
  );
}

const AppProvider: React.FC = () => {
  const { theme, setTheme } = useThemeContext();
  const { getStores } = useAppStateStore();

  const initialize = async () => {
    const checkUser = async () => {
      try {
        const value = await AsyncStorage.getItem(StorageKeys.AccessToken);
        const userId = await AsyncStorage.getItem(StorageKeys.UserId);

        if (value && userId) {
          useLoginState.setState({ status: "success" });
          const user: PatientUserDto = await UsersServiceInstance.getUser(
            userId
          );

          useUserState.setState({
            user: user,
          });
        }
      } catch (e) {}
    };

    const findPharmacyBranding = async () => {
      try {
        const brandingDto: BrandingDto =
          await PharmacyServiceInstance.findPharmacyBranding(PHARMACY_ID);

        const newColors = createBrandedColors({
          ...theme.colors,
          brandedText:
            brandingDto.branded_text_color ?? theme.colors.brandedText,
          gradientStart:
            brandingDto.gradient_bkg_1_color ?? theme.colors.gradientStart,
          gradientEnd:
            brandingDto.gradient_bkg_2_color ?? theme.colors.gradientEnd,
          brandedPrimary:
            brandingDto.button_primary_color ?? theme.colors.brandedPrimary,
        });

        setTheme({ colors: newColors });

        const pharmacy: PharmacyDto = await PharmacyServiceInstance.findOne(
          PHARMACY_ID
        );
        useAppStateStore.setState({ pharmacyName: pharmacy.name });
      } catch (e) {}
    };

    const checkIsMobileOrTabletBrowser = async () => {
      const mobileOrTabletChecker = await isMobileOrTabletBrowser();
      useAppStateStore.setState({ isMobileOrTablet: mobileOrTabletChecker });
    };

    await checkUser();
    await findPharmacyBranding();
    await checkIsMobileOrTabletBrowser();
    await getStores();
  };

  return (
    <AppLoader onBeforeLoad={initialize}>
      <MapProvider accessToken={MAPBOX_ACCESS_TOKEN}>
        <AppDeepLinkingProvider />
      </MapProvider>
    </AppLoader>
  );
};

const AppDeepLinkingProvider: React.FC = () => {
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const isAuthorized = useUserState((x) => isUserAuthorized(x.user));

  return (
    <DeepLinkingProvider
      navigation={navigation}
      isAuthorized={isAuthorized}
      returnUrlKey={RETURN_URL_KEY}
    >
      <RootNavigation />
    </DeepLinkingProvider>
  );
};
