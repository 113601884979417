import React, { useState, useEffect } from "react";
import { DeepLinkingContext, DeepLinkingContextType } from "../context";
import { DeepLinkingConfig } from "../types";
import * as Linking from "expo-linking";

export function useDeepLinkingContext(): DeepLinkingContextType {
  return React.useContext(DeepLinkingContext);
}

export function useDeepLinking(): DeepLinkingConfig {
  const { deepLinkingConfig } = useDeepLinkingContext();
  return deepLinkingConfig;
}

/**
 * This hook will return always the changed url on `web` platform but on the `mobile` platform it will return the last changed url from outside the inner navigation.
 * This means that on web it will show the changed url, on mobile will show the last changed url from outside (only when the user has clicked on a link, because all the other navigation done by the user inside the app with buttons or other elements are not caught on this level, they are state changes on the navigation container)
 * @returns the actual url as `string`
 */
export const useCurrentUrl = () => {
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    (async () => {
      const initialUrl = await Linking.getInitialURL();
      if (initialUrl) setUrl(initialUrl);
    })();

    const urlEventSubscription = Linking.addEventListener("url", (event) => {
      const { url: eventUrl } = event;
      setUrl(eventUrl);
    });

    return () => urlEventSubscription.remove();
  }, []);

  return { url, setUrl };
};
