/* tslint:disable */
/* eslint-disable */
/**
 * user
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserUpdate
 */
export interface UserUpdate {
    /**
     * User's first name
     * @type {string}
     * @memberof UserUpdate
     */
    firstName: string;
    /**
     * User's last name
     * @type {string}
     * @memberof UserUpdate
     */
    lastName: string;
    /**
     * User's date of birth
     * @type {Date}
     * @memberof UserUpdate
     */
    dateOfBirth: Date;
    /**
     * User's mobile cell phone number
     * @type {string}
     * @memberof UserUpdate
     */
    phoneNumberMobile: string;
}

export function UserUpdateFromJSON(json: any): UserUpdate {
    return UserUpdateFromJSONTyped(json, false);
}

export function UserUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'dateOfBirth': (new Date(json['dateOfBirth'])),
        'phoneNumberMobile': json['phoneNumberMobile'],
    };
}

export function UserUpdateToJSON(value?: UserUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'firstName': value.firstName,
        'lastName': value.lastName,
        'dateOfBirth': (value.dateOfBirth.toISOString().substr(0,10)),
        'phoneNumberMobile': value.phoneNumberMobile,
    };
}

