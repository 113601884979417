import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path, Rect } from "react-native-svg";
import { IconProps } from "./types";

export const PlusSquareIcon: FunctionComponent<PropsWithChildren<IconProps>> =
  ({ color, size, testID }) => {
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        color={color}
        testID={testID}
      >
        <Rect width="24" height="24" rx="4" fill="#748995" />
        <Path
          d="M12 5V19M5 12H19"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Svg>
    );
  };
