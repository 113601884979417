import React, { FunctionComponent, useEffect } from "react";
import { Button } from "assets/components/button";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { KeyboardAvoidingView, Platform, View } from "react-native";
import {
  AuthStackNavigationProp,
  AuthStackParamList,
} from "../../navigation/AuthNavigation";
import { makeStyles, useTheme } from "assets/theme";
import { getText } from "assets/localization/localization";
import { TextField } from "assets/components/text-field";
import { checkUserStatus, LoginForm } from "./login-actions";
import { useForm } from "assets/form";
import { LoginTestIDs } from "./LoginTestIDs";
import { useLoginState } from "./login-store";
import { ScreenContainer, Form } from "assets/layout";
import { GoogleSignInButton } from "../../components/google-sign-in-button/GoogleSignInButton";
import { AppleSignInButton } from "../../components/apple-sign-in-button/AppleSignInButton";
import { Logo } from "../../components/logo/Logo";
import isEmail from "validator/lib/isEmail";
import { useNavigation } from "@react-navigation/native";
import { useDeepLinkingContext } from "assets/core/deep-linking";
import * as Linking from "expo-linking";

export const LoginEnterEmail: FunctionComponent<LoginEnterEmailProps> =
  ({}) => {
    const { currentUrl } = useDeepLinkingContext();

    const theme = useTheme();
    const styles = useStyles();
    const methods = useForm<LoginForm>({
      defaultValues: {
        email: "",
      },
    });

    const navigation = useNavigation<AuthStackNavigationProp>();

    const handleSignUpPress = () => {
      navigation.goBack();
    };

    const { status, error } = useLoginState();

    const handleSubmit = async () => {
      if (currentUrl && currentUrl !== "") {
        const { queryParams } = Linking.parse(currentUrl);
        if (queryParams) {
          checkUserStatus(methods.getValues().email, navigation, queryParams);
        } else {
          checkUserStatus(methods.getValues().email, navigation);
        }
      } else {
        checkUserStatus(methods.getValues().email, navigation);
      }
    };

    useEffect(() => {
      return () => {
        useLoginState.setState({ error: undefined, status: "idle" });
      };
    }, []);

    return (
      <>
        <View style={styles.container}>
          <ScreenContainer backgroundColor={theme.colors.brandedPrimary}>
            <Logo />
            <KeyboardAvoidingView
              behavior="padding"
              enabled={Platform.OS === "ios"}
            >
              <Form methods={methods}>
                <Form.Alert
                  title={error?.message}
                  intent="error"
                  visible={!!error}
                />
                <Form.Row>
                  <Form.Column>
                    <TextField
                      label={getText("email")}
                      placeholderColor={theme.palette.white}
                      textColor={theme.palette.white}
                      activeBorderColor={theme.palette.white}
                      inactiveBorderColor={theme.palette.white}
                      backgroundColor={theme.colors.brandedPrimary}
                      labelColor={theme.palette.white}
                      name="email"
                      rules={{
                        required: getText("email-is-required"),
                        validate: {
                          value: () => {
                            return isEmail(methods.getValues().email)
                              ? true
                              : getText("email-is-not-valid");
                          },
                        },
                      }}
                      onSubmit={methods.handleSubmit(handleSubmit)}
                      disabled={status === "loading"}
                      testID={LoginTestIDs.usernameInput}
                    />
                  </Form.Column>
                </Form.Row>
                <Form.Actions>
                  <Button
                    testID={LoginTestIDs.loginButton}
                    onPress={methods.handleSubmit(handleSubmit)}
                    hierarchy="secondary"
                    loading={status === "loading"}
                    logger={{ id: "login-button" }}
                    style={styles.loginButton}
                  >
                    {getText("next")}
                  </Button>

                  <Button
                    hierarchy="primary"
                    testID={LoginTestIDs.goBackButton}
                    onPress={handleSignUpPress}
                    logger={{ id: "go-back-button" }}
                  >
                    {getText("go-back")}
                  </Button>
                </Form.Actions>
              </Form>
            </KeyboardAvoidingView>
          </ScreenContainer>
          <View style={styles.footer}>
            <AppleSignInButton
              text={getText("apple-login")}
              style={styles.appleButton}
            />
            <GoogleSignInButton text={getText("google-login")} />
          </View>
        </View>
      </>
    );
  };

interface LoginEnterEmailProps
  extends NativeStackScreenProps<AuthStackParamList, "login"> {}

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.brandedPrimary,
  },
  loginButton: {
    padding: theme.getSpacing(1),
    marginBottom: theme.getSpacing(2),
  },
  footer: {
    backgroundColor: theme.palette.white,
    borderRadius: theme.roundness,
    padding: theme.getSpacing(2),
  },
  appleButton: {
    marginBottom: theme.getSpacing(2),
  },
}));
