export const FindPrescriptionFlowTestIDs = {
  rxNumberTextField: "rx-number-text-field",
  drugNameTextField: "drug-name-text-field",
  lastNameTextField: "last-name-text-field",
  dobNameTextField: "date-of-birth-text-field",
  doThisLaterButton: "do-this-later-button",
  trashIconButton: "trash-icon-button",
  addPrescriptionButton: "add-new-prescription-button",
  manualPrescriptionFlowButton: "manual-prescription-flow-button",
  automaticPrescriptionFlowButton: "automatic-prescription-flow-button",
  nextButton: "next-button",
  doneButton: "done-button",
};
