import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
} from "react";
import { View, Platform } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { Text } from "assets/components/text/Text";
import { CheckboxInput } from "assets/components/checkbox";
import { getText } from "assets/localization/localization";
import { Icon } from "assets/components/icon/Icon";
import {
  AlertCircleIcon,
  AlertTriangleIcon,
  CalendarIcon,
  InfoIcon,
  RefreshSquareIcon,
} from "assets/icons";
import { BottomSheet } from "assets/components/bottom-sheet";
import { MedicationDetails } from "../medication-details/MedicationDetails";
import { IconButton } from "assets/components/icon-button";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import { Modal } from "assets/components/modal";
import { RefillTestIDs } from "../../screens/refill/RefillTestIDs";
import { Button } from "assets/components/button";
import {
  IntegrationType,
  PrescriptionDto,
  RefillStatus,
} from "@digitalpharmacist/prescription-service-client-axios";
import moment from "moment";
import { LocationPatientRecordDto } from "@digitalpharmacist/patient-service-client-axios";
import { PrescriptionCardTestIDs } from "./PrescriptionCardTestID";
import { getRefillErrorStatus } from "../../utils";
import { formatDateToMMMD } from "../../common/datetime-utils";

export const PrescriptionCard: FunctionComponent<
  PropsWithChildren<PrescriptionCardProps>
> = ({
  prescription,
  patient,
  selectable = false,
  showRxNumber = true,
  showPatientInfo = true,
  showStatus = true,
  showDetailsIcon = true,
  check = false,
  removable = false,
  onPress = () => {},
  onRemove = () => {},
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const [checked, setChecked] = useState<boolean>(check);

  const handleOnPress = () => {
    setChecked(!checked);
    onPress(prescription, !checked);
  };

  useEffect(() => {
    setChecked(check);
  }, [check]);

  const sheetRef = React.useRef<BottomSheetModal>(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);

  const handleOpenMedDetails = () => {
    Platform.OS === "web" ? setModalIsOpen(true) : sheetRef.current?.present();
  };

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
  };

  const isValidRefillStatus = (refillStatus: RefillStatus): boolean => {
    return (
      refillStatus === RefillStatus.Fillable ||
      refillStatus === RefillStatus.Success
    );
  };

  const getNextRefillObject = () => {
    if (!prescription.next_refill_date) return undefined;

    const nextRefillDate = moment(prescription.next_refill_date);
    const dateText = formatDateToMMMD(prescription.next_refill_date);

    if (prescription.auto_fill_enrolled) {
      return {
        label: getText("auto-refill"),
        color: theme.palette.success[500],
        iconColor: theme.palette.success[500],
        icon: RefreshSquareIcon,
        dateText,
      };
    }

    if (nextRefillDate.isBefore()) {
      return {
        label: getText("refill-past-due"),
        color: theme.palette.warning[500],
        iconColor: theme.palette.warning[500],
        icon: AlertCircleIcon,
        dateText,
      };
    }

    return {
      label: getText("refill-due"),
      color: theme.palette.gray[700],
      iconColor: theme.palette.gray[500],
      icon: RefreshSquareIcon,
      dateText,
    };
  };

  const getLastRefillObject = () => {
    if (prescription.last_refill_date) {
      const dateText = formatDateToMMMD(prescription.last_refill_date);

      return {
        label: getText("prescription-card-last-filled"),
        color: theme.palette.gray[500],
        iconColor: theme.palette.gray[400],
        icon: CalendarIcon,
        dateText,
      };
    }

    if (prescription.last_order_date) {
      const dateText = formatDateToMMMD(prescription.last_order_date);

      return {
        label: getText("prescription-card-last-ordered"),
        color: theme.palette.gray[500],
        iconColor: theme.palette.gray[400],
        icon: CalendarIcon,
        dateText,
      };
    }

    return undefined;
  };

  const nextRefillObject = getNextRefillObject();
  const lastRefillObject = getLastRefillObject();

  const dateOfBirth = moment(
    prescription.patient_date_of_birth,
    "YYYY-MM-DD"
  ).format("MM/DD/YYYY");

  return (
    <View style={{ marginBottom: theme.getSpacing(1) }}>
      <View style={styles.rowSpaceBetween}>
        <View style={{ flexDirection: "row" }}>
          <View>
            {selectable && (
              <View style={styles.checkbox}>
                <CheckboxInput
                  checked={checked}
                  onPress={handleOnPress}
                  testID={PrescriptionCardTestIDs.checkbox}
                />
              </View>
            )}
          </View>
          <View>
            <View style={styles.rowView}>
              <Text style={styles.textTitle}>{prescription.drug_name}</Text>
              {showDetailsIcon && (
                <IconButton
                  icon={InfoIcon}
                  color={theme.palette.gray[500]}
                  onPress={handleOpenMedDetails}
                  logger={{ id: "med-details-icon-button" }}
                  size={20}
                  style={{ margin: theme.getSpacing(0.5) }}
                />
              )}
            </View>
            {showRxNumber && (
              <Text style={styles.textDetails}>
                Rx: {prescription.rx_number}
              </Text>
            )}
            {selectable && (
              <>
                {nextRefillObject && (
                  <View style={styles.rowView}>
                    <Icon
                      icon={nextRefillObject.icon}
                      color={nextRefillObject.iconColor}
                      size={14}
                    />
                    <Text
                      style={{
                        ...styles.textDetails,
                        color: nextRefillObject.color,
                      }}
                    >
                      {nextRefillObject.label} {nextRefillObject.dateText}
                    </Text>
                  </View>
                )}
                {lastRefillObject && (
                  <View style={styles.rowView}>
                    <Icon
                      icon={lastRefillObject.icon}
                      color={lastRefillObject.iconColor}
                      size={14}
                    />
                    <Text style={styles.textDetails}>
                      {lastRefillObject.label} {lastRefillObject.dateText}
                    </Text>
                  </View>
                )}
              </>
            )}
            {showPatientInfo && (
              <Text style={styles.textDetails}>
                {prescription.patient_first_name}{" "}
                {prescription.patient_last_name} ({dateOfBirth})
              </Text>
            )}
            {showStatus &&
              prescription.refill_status &&
              !isValidRefillStatus(prescription.refill_status) && (
                <View style={styles.rowView}>
                  <Icon
                    icon={AlertTriangleIcon}
                    color={theme.palette.error[600]}
                    size={14}
                  />
                  <Text style={styles.textDetailsError}>
                    {getRefillErrorStatus(prescription.refill_status)}
                  </Text>
                </View>
              )}
            {prescription.integration_type === IntegrationType.Manual && (
              <Text style={styles.textDetails}>
                {getText("entered-prescription-manually")}
              </Text>
            )}
          </View>
        </View>
        <View>
          {removable && (
            <Button
              hierarchy="tertiary"
              onPress={onRemove}
              testID={RefillTestIDs.unableToSubmitButton}
              logger={{ id: RefillTestIDs.unableToSubmitButton }}
            >
              {getText("remove")}
            </Button>
          )}
        </View>
      </View>
      {Platform.OS === "web" ? (
        <Modal
          title={getText("personal-info")}
          show={modalIsOpen}
          okButtonProps={{
            onPress: () => setModalIsOpen(false),
            logger: { id: "med-details-ok-button-modal" },
          }}
          dismissButtonProps={{
            onPress: () => setModalIsOpen(false),
            logger: { id: "med-details-dismiss-button-modal" },
          }}
          isScrollable={true}
          height={800}
        >
          {patient && (
            <MedicationDetails patient={patient} prescription={prescription} />
          )}
        </Modal>
      ) : (
        patient && (
          <BottomSheet
            title={getText("personal-info")}
            bottomSheetRef={sheetRef}
            height={"80%"}
            onDismiss={handleBottomSheetDismiss}
            children={
              <MedicationDetails
                patient={patient}
                prescription={prescription}
              />
            }
          />
        )
      )}
    </View>
  );
};

export interface PrescriptionCardProps {
  prescription: PrescriptionDto;
  patient?: LocationPatientRecordDto;
  selectable?: boolean;
  showStatus?: boolean;
  showDetailsIcon?: boolean;
  showRxNumber?: boolean;
  showPatientInfo?: boolean;
  check?: boolean;
  removable?: boolean;
  onPress?: (medication: PrescriptionDto, checked: boolean) => void;
  onRemove?: () => void;
}

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    fontWeight: "600",
    fontSize: 18,
    color: theme.palette.gray[900],
  },
  textDetails: {
    marginTop: 2,
    marginLeft: 2,
    ...theme.fonts.regular,
    fontWeight: "400",
    fontSize: 14,
    height: 20,
    color: theme.palette.gray[700],
  },
  textDetailsError: {
    marginTop: 2,
    ...theme.fonts.regular,
    fontWeight: "400",
    fontSize: 14,
    height: 20,
    color: theme.palette.error[600],
  },
  rowView: {
    marginTop: 2,
    flexDirection: "row",
    alignItems: "center",
  },
  rowSpaceBetween: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  checkbox: {
    alignItems: "center",
    justifyContent: "flex-start",
    marginVertical: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
  },
}));
