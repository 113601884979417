import React, { FunctionComponent, PropsWithChildren, useEffect } from "react";
import {
  createStackNavigator,
  StackHeaderProps,
} from "@react-navigation/stack";
import { GestureResponderEvent, Platform } from "react-native";
import { NativeHeader } from "assets/components/native-header";
import type { StackNavigationProp } from "@react-navigation/stack";
import { getText } from "assets/localization/localization";
import { PatientUnderCareList } from "./PeopleUnderCareList";
import { AddPatientUnderCare } from "./AddPatientUnderCare";
import { AddPatientUnderCareEmail } from "./AddPatientUnderCareEmail";
import { AddPatientUnderCareInfo } from "./AddPatientUnderCareInfo";
import { AddPatientUnderCareRequest } from "./AddPatientUnderCareRequest";
import { PatientUnderCare } from "./PatientUnderCare";
import { PlusIcon } from "assets/icons";
import { AddMinorOrPetPatient } from "./AddMinorOrPetPatient";
import { AddMinorOrPatientUnderCareRequestSubmitted } from "./AddMinorOrPetPatientRequestSubmitted";

const Stack = createStackNavigator<PatientUnderCareStackParamList>();

export const PatientUnderCareNavigation: FunctionComponent<
  PropsWithChildren<PatientUnderCareProps>
> = ({}) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        header: PatientUnderCareNavigationHeader,
      }}
    >
      <Stack.Group>
        <Stack.Screen name="patient-under-care" component={PatientUnderCare} />
        <Stack.Screen
          name="patient-under-care-list"
          component={PatientUnderCareList}
          options={{
            title: getText("patient-under-care-list"),
            header: (props) => (
              <PatientUnderCareNavigationHeader showAddIcon={true} {...props} />
            ),
          }}
        />
        <Stack.Screen
          name="add-patient-under-care-info"
          component={AddPatientUnderCareInfo}
          options={{ title: getText("add-patient-under-care-info") }}
        />
        <Stack.Screen
          name="add-patient-under-care-email"
          component={AddPatientUnderCareEmail}
          options={{ title: getText("add-patient-under-care-email") }}
        />
        <Stack.Screen
          name="add-patient-under-care-confirmation"
          component={AddPatientUnderCareRequest}
          options={{ title: getText("add-patient-under-care-confirmation") }}
        />
        <Stack.Screen
          name="add-minor-or-pet-patient"
          component={AddMinorOrPetPatient}
          options={{ title: getText("add-minor-or-pet-patient") }}
        />
        <Stack.Screen
          name="add-minor-or-pet-request-submitted"
          component={AddMinorOrPatientUnderCareRequestSubmitted}
          options={{ title: getText("add-minor-or-pet-patient") }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

interface PatientUnderCareProps extends StackHeaderProps {
  showAddIcon?: boolean;
}

export type PatientUnderCareStackParamList = {
  "patient-under-care": undefined;
  "add-patient-under-care": undefined;
  "add-patient-under-care-info": undefined;
  "add-patient-under-care-email": undefined;
  "add-patient-under-care-confirmation": undefined;
  "patient-under-care-list": undefined;
  "add-minor-or-pet-patient": undefined;
  "add-minor-or-pet-request-submitted": undefined;
};

export type PatientUnderCareStackNavigationProp =
  StackNavigationProp<PatientUnderCareStackParamList>;

export const PatientUnderCareNavigationHeader: FunctionComponent<
  PropsWithChildren<PatientUnderCareProps>
> = (props) => {
  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  const handleActionPress = () => {
    props.navigation.navigate("add-patient-under-care-info");
  };
  // TODO: follow up cards will make this dynamic based on the route as we add other routes
  return (
    <NativeHeader
      actionLabel={getText("add")}
      actionIcon={PlusIcon}
      onActionPress={handleActionPress}
      onBack={handleBack}
      showAction={props.showAddIcon}
      showBack={true}
      title={getText("people-under-your-care")}
    />
  );
};
