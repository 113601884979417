import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import { Text } from "assets/components/text";
import { TextField } from "assets/components/text-field";
import { useForm } from "assets/form";
import { useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { RegisterTestIDs } from "./RegisterTestIDs";
import { useRegisterState } from "./register-store";
import { registerDetails, RegisterDetailsForm } from "./register-actions";
import { getText } from "assets/localization/localization";
import { Form, ScreenContainer } from "assets/layout";
import { useNavigation } from "@react-navigation/native";
import { AuthStackNavigationProp } from "../../navigation/AuthNavigation";
import {
  Dimensions,
  KeyboardAvoidingView,
  Platform,
  ScrollView,
  View,
} from "react-native";
import * as validate from "@digitalpharmacist/validation-dp";
import { formatDate } from "../../common/form-utils";

export const RegisterDetails: FunctionComponent<
  PropsWithChildren<RegisterDetailsProps>
> = (props) => {
  const theme = useTheme();
  const methods = useForm<RegisterDetailsForm>({
    defaultValues: {
      dateOfBirth: "",
      firstName: "",
      lastName: "",
      phoneNumberMobile: "",
    },
  });
  const [keyboardContainerHeight, setKeyboardContainerHeight] =
    useState<number | string>();

  const { status, error } = useRegisterState();
  const navigation = useNavigation<AuthStackNavigationProp>();

  const handleSubmit = async () => {
    registerDetails(methods.getValues(), navigation);
  };

  const handleOnFocusEvent = () => {
    setKeyboardContainerHeight((prev) => Dimensions.get("window").height * 0.8);
  };

  const handleOnBlurEvent = () => {
    setKeyboardContainerHeight((prev) => undefined);
  };

  return (
    <ScreenContainer>
      <ScrollView style={{ flex: 1 }}>
        <KeyboardAvoidingView
          behavior="position"
          enabled={Platform.OS === "ios"}
          contentContainerStyle={{
            flex: 1,
            height: keyboardContainerHeight,
          }}
        >
          <View
            style={{
              alignItems: "center",
              width: "50%",
              alignSelf: "center",
            }}
          >
            <Text
              style={{
                fontSize: 30,
                marginBottom: theme.getSpacing(2),
                marginTop: theme.getSpacing(4),
                textAlign: "center",
              }}
            ></Text>
          </View>
          <Text
            style={{
              fontSize: 30,
              marginBottom: theme.getSpacing(1),
              marginTop: theme.getSpacing(2),
              textAlign: "center",
            }}
          >
            {getText("welcome")}
          </Text>
          <Text
            style={{
              color: theme.palette.gray[900],
              fontSize: 14,
              paddingBottom: theme.getSpacing(2),
              textAlign: "center",
            }}
          >
            {getText("please-enter-your-information")}
          </Text>

          <Form methods={methods}>
            <Form.Alert
              title={error?.message}
              intent="error"
              visible={!!error}
            />
            <Form.Row>
              <Form.Column>
                <TextField
                  label={getText("first-name")}
                  name="firstName"
                  rules={{
                    required: getText("first-name-is-required"),
                    validate: {
                      value: () => {
                        return validate.isName(methods.getValues().firstName)
                          ? true
                          : getText("first-name-is-not-valid");
                      },
                    },
                  }}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={status === "loading"}
                  testID={RegisterTestIDs.firstNameInput}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  label={getText("last-name")}
                  name="lastName"
                  rules={{
                    required: getText("last-name-is-required"),
                    validate: {
                      value: () => {
                        return validate.isName(methods.getValues().lastName)
                          ? true
                          : getText("last-name-is-not-valid");
                      },
                    },
                  }}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={status === "loading"}
                  testID={RegisterTestIDs.lastNameInput}
                  onFocus={handleOnFocusEvent}
                  onBlur={handleOnBlurEvent}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  label={getText("birth-date")}
                  name="dateOfBirth"
                  rules={{
                    required: getText("birth-date-is-required"),
                    validate: {
                      value: () => {
                        return validate.isLegalAge(
                          formatDate(methods.getValues().dateOfBirth)
                        )
                          ? true
                          : getText("date-is-not-valid");
                      },
                    },
                  }}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={status === "loading"}
                  type="date"
                  testID={RegisterTestIDs.dateOfBirthInput}
                  onFocus={handleOnFocusEvent}
                  onBlur={handleOnBlurEvent}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row>
              <Form.Column>
                <TextField
                  label={getText("mobile-number")}
                  name="phoneNumberMobile"
                  rules={{
                    required: getText("mobile-number-is-required"),
                    validate: {
                      value: (value) => {
                        return validate.isPhoneNumber(
                          // Backend is expecting 1 digit country code in front of the phone number
                          value ? "+1" + value : ""
                        )
                          ? true
                          : getText("phone-is-not-valid");
                      },
                    },
                  }}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  disabled={status === "loading"}
                  type="telephoneNumber"
                  testID={RegisterTestIDs.phoneNumberMobileInput}
                  onFocus={handleOnFocusEvent}
                  onBlur={handleOnBlurEvent}
                />
              </Form.Column>
            </Form.Row>
            <Form.Actions>
              <Button
                testID={RegisterTestIDs.registerButton}
                onPress={methods.handleSubmit(handleSubmit)}
                hierarchy="primary"
                loading={status === "loading"}
                logger={{ id: "register-details-submit-button" }}
              >
                {getText("next")}
              </Button>
            </Form.Actions>
          </Form>
        </KeyboardAvoidingView>
      </ScrollView>
    </ScreenContainer>
  );
};

interface RegisterDetailsProps {}
