import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import { BaseApiConfiguration, RequestHeaders } from "./types";
import { ApiConfig } from "./config";
import { AxiosError } from "axios";
import { HttpErrorUtil } from "@digitalpharmacist/http-error-util";
import { getLocale } from "../../localization/localization";
import { PHARMACY_ID } from "../../../../apps/mobile/modules/common/constants";

/**
 * A class that handles the common functionalities for an api consumer
 */
export class BaseApiService {
  /**
   * Private property that get the local baseConfig or the default one configured on the app level
   */
  private get baseConfig(): BaseApiConfiguration | undefined {
    return this._baseConfig ?? ApiConfig.getBaseApiConfig();
  }
  /**
   * Api consumer instance
   */
  protected axiosInstance: AxiosInstance;

  /**
   * Creates a new of BaseApiService class.
   * @param baseUrl A string.
   * @param axiosRequestConfig An AxiosRequestConfig instance, default is empty object.
   * @param enableAuth A boolean, default true.
   * @param baseConfig A BaseApiConfiguration instance, default value taken from ApiConfig.getBaseApiConfig() or undefined
   * @returns A a new of BaseApiService class.
   */
  constructor(
    /**
     * Value that should take the base path of the consumer
     */
    private baseUrl: string,
    /**
     * Value that should take the default request configuration of the consumer
     */
    private axiosRequestConfig: AxiosRequestConfig = {},
    /**
     * Enable / Disable authentication flow
     */
    private enableAuth: boolean = true,
    /**
     * Some basic configurations for common flows
     */
    private _baseConfig?: BaseApiConfiguration
  ) {
    this.axiosInstance = axios.create({
      baseURL: this.baseUrl,
      headers: {
        "Content-Type": "application/json",
        "x-pharmacy-id": PHARMACY_ID,
      },
      ...this.axiosRequestConfig,
    });

    if (this.enableAuth) {
      // injecting authorization header
      this.axiosInstance.interceptors.request.use(
        async (currentRequestConfig: AxiosRequestConfig) => {
          if (currentRequestConfig.disableAuth) {
            // disable auth for single request
            return Promise.resolve(currentRequestConfig);
          }
          const token = await this.baseConfig?.getAccessToken?.();
          if (token) {
            (currentRequestConfig.headers as RequestHeaders)[
              "Authorization"
            ] = `Bearer ${token}`;
          }
          return currentRequestConfig;
        },
        async (error) => {
          return Promise.reject(error);
        }
      );

      // refresh token logic
      this.axiosInstance.interceptors.response.use(
        (res) => {
          return res;
        },
        async (err: AxiosError) => {
          const originalConfig = err.config as AxiosRequestConfig;
          if (err.response && !originalConfig.disableAuth) {
            // Access Token was expired
            // _retry is added to allow only one time retry
            if (err.response.status === 401 && !originalConfig._retry) {
              originalConfig._retry = true;
              try {
                const accessToken =
                  await this.baseConfig?.retryRefreshToken?.();
                // save new token to localStorage
                this.baseConfig?.setAccessToken?.(accessToken);
                // retry the request
                (originalConfig.headers as RequestHeaders)[
                  "Authorization"
                ] = `Bearer ${accessToken}`;
                return this.axiosInstance(originalConfig);
              } catch (_error) {
                this.baseConfig?.signOut?.();
                return Promise.reject(err);
              }
            }
          }
          return Promise.reject(err);
        }
      );
    }

    // handling default responses and errors
    this.axiosInstance.interceptors.response.use(
      (value: AxiosResponse<any>) => {
        return Promise.resolve(value);
      },
      (error: AxiosError) => {
        this.baseConfig?.onError?.(error);
        return Promise.reject(ApiError(error));
      }
    );
  }
}

function ApiError(axiosError: AxiosError): any {
  const systemLang = getLocale() || "en";

  return HttpErrorUtil.parse(axiosError).errorMessages.getMessage(systemLang);
}

export default BaseApiService;
