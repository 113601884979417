import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "assets/components/text";
import { View, StyleSheet } from "react-native";
import theme, { getSpacing } from "assets/theme";
import { SendOptions } from "./reset-password-actions";
import { TouchableOpacity } from "@gorhom/bottom-sheet";
import BaseRadioButton from "assets/components/radio-button-group/BaseRadioButton";

export const ResetLinkRadioButton: FunctionComponent<
  PropsWithChildren<ResetLinkRadioButtonProps>
> = ({ selected, disabled, text, value, description, onPress }) => {
  return (
    <TouchableOpacity
      style={radioButtonStyles.container}
      onPress={() => onPress(value)}
    >
      <View style={radioButtonStyles.radioButtonContainer}>
        <BaseRadioButton selected={selected} disabled={disabled} />
      </View>
      <View style={{ flex: 1 }}>
        <Text style={radioButtonStyles.label}>{text}</Text>
        <Text style={radioButtonStyles.description}>{description}</Text>
      </View>
    </TouchableOpacity>
  );
};

const radioButtonStyles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    marginBottom: getSpacing(2),
  },
  radioButtonContainer: {
    marginRight: getSpacing(2),
  },
  label: {
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  description: {
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[500],
  },
});

interface ResetLinkRadioButtonProps {
  selected: boolean;
  disabled: boolean;
  text: string;
  value: SendOptions;
  description: string | undefined;
  onPress: (value: SendOptions) => void;
}
