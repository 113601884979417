const phoneMask = "   -   -    ";
const dateMask = "  /  /    ";

export function applyPhoneMask(value: string, isBackspace: boolean) {
  const lastIndex = value.length - 1;

  if (value.length === 10 && !value.includes("-")) {
    return value
      .replace(/[^0-9]/g, "")
      .replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
  }

  if (isBackspace) {
    if (phoneMask[lastIndex + 1] === "-") {
      value = value.substring(0, value.length - 1);
    }
  } else {
    if (phoneMask[lastIndex + 1] === "-") {
      return value + "-";
    }
  }

  if (value.length > 12) {
    value = value.substring(0, 12);
  }

  return value;
}

export function applySecondPhoneMask(value: string, isBackspace: boolean) {
  if (isBackspace) {
    return value.substring(0, value.length);
  } else {
    const numbers = value.replace(/[^0-9]/g, "").split("");

    const code = numbers.slice(0, 3).join("");
    const beforeDash = numbers.slice(3, 6).join("");
    const lastNumbers = numbers.slice(6, 10).join("");

    if (numbers.length < 3) {
      return `(${code})`;
    } else if (numbers.length < 6) {
      return `(${code}) ${beforeDash}`;
    } else {
      return `(${code}) ${beforeDash}-${lastNumbers}`;
    }
  }
}

export function applyDateMask(value: string, isBackspace: boolean) {
  const lastIndex = value.length - 1;

  if (value.length === 8 && !value.includes("/")) {
    return value
      .replace(/[^0-9]/g, "")
      .replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
  }

  if (isBackspace) {
    if (dateMask[lastIndex + 1] === "/") {
      value = value.substring(0, value.length - 1);
    }
  } else {
    if (dateMask[lastIndex + 1] === "/") {
      return value + "/";
    }
  }

  if (value.length > 10) {
    value = value.substring(0, 10);
  }

  return value;
}
