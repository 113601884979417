import React, { FC } from "react";
import Svg, { Path } from "react-native-svg";
import { IconProps } from "./types";

export const ArrowsUp: FC<IconProps> = ({
  color,
  size,
  testID,
  ...restProps
}) => {
  return (
    <Svg
      width={size}
      height={size}
      fill="none"
      {...restProps}
      color={color}
      testID={testID}
    >
      <Path
        stroke="#374D58"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={1.5}
        d="M11.333 7.333 8 4 4.667 7.333M11.332 12 8 8.667 4.667 12"
      />
    </Svg>
  );
};
