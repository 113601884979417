import React, { FunctionComponent, PropsWithChildren } from "react";
import { StyleProp, ViewStyle, View } from "react-native";
import { useTheme } from "../../theme";

export const FormColumn: FunctionComponent<PropsWithChildren<FormColumnProps>> =
  ({ children, style, last }) => {
    const theme = useTheme();
    return (
      <View
        style={[
          { flex: 1 },
          style,
          !last && { marginRight: theme.getSpacing(2) },
        ]}
      >
        {children}
      </View>
    );
  };

export interface FormColumnProps {
  style?: StyleProp<ViewStyle>;
  /**
   * Auto assigned (see FormRow), never set explicitly
   */
  last?: boolean;
}
