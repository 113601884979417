/* tslint:disable */
/* eslint-disable */
/**
 * user
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * Unique identifier for the given user.
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    firstName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    lastName: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    email: string;
    /**
     * 
     * @type {Date}
     * @memberof User
     */
    dateOfBirth?: Date;
    /**
     * Set to true if the user's email has been verified.
     * @type {boolean}
     * @memberof User
     */
    emailVerified: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    phoneNumberMobile: string;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    phoneNumberMobileVerified: boolean;
    /**
     * The date that the user was created.
     * @type {Date}
     * @memberof User
     */
    createDate?: Date;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    privacyPolicyPrompt: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof User
     */
    termsOfServicePrompt: boolean;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    preferredPharmacyLocationId: string;
}

export function UserFromJSON(json: any): User {
    return UserFromJSONTyped(json, false);
}

export function UserFromJSONTyped(json: any, ignoreDiscriminator: boolean): User {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'firstName': json['firstName'],
        'lastName': json['lastName'],
        'email': json['email'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (new Date(json['dateOfBirth'])),
        'emailVerified': json['emailVerified'],
        'phoneNumberMobile': json['phoneNumberMobile'],
        'phoneNumberMobileVerified': json['phoneNumberMobileVerified'],
        'createDate': !exists(json, 'createDate') ? undefined : (new Date(json['createDate'])),
        'privacyPolicyPrompt': json['privacyPolicyPrompt'],
        'termsOfServicePrompt': json['termsOfServicePrompt'],
        'preferredPharmacyLocationId': json['preferred_pharmacy_location_id'],
    };
}

export function UserToJSON(value?: User | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth.toISOString().substr(0,10)),
        'emailVerified': value.emailVerified,
        'phoneNumberMobile': value.phoneNumberMobile,
        'phoneNumberMobileVerified': value.phoneNumberMobileVerified,
        'createDate': value.createDate === undefined ? undefined : (value.createDate.toISOString().substr(0,10)),
        'privacyPolicyPrompt': value.privacyPolicyPrompt,
        'termsOfServicePrompt': value.termsOfServicePrompt,
        'preferred_pharmacy_location_id': value.preferredPharmacyLocationId,
    };
}

