import React, { FunctionComponent } from "react";
import { Text } from "assets/components/text";
import { getText } from "assets/localization/localization";
import { makeStyles, useTheme } from "assets/theme";
import { View } from "react-native";
import { Icon } from "assets/components/icon";
import { CheckCircleIcon, BellIcon } from "assets/icons";

export const AppointmentChecklistBadge: FunctionComponent<AppointmentDetailsBadgeProps> =
  ({ complete }) => {
    const styles = useStyles();
    const theme = useTheme();

    const color = complete
      ? theme.palette.success[500]
      : theme.palette.warning[500];

    const text = complete ? "checklist-complete" : "checklist-incomplete";
    const icon = complete ? CheckCircleIcon : BellIcon;

    return (
      <View style={styles.container}>
        <Icon icon={icon} size={16} color={color} />
        <Text style={[styles.text, { color: color }]}>{getText(text)}</Text>
      </View>
    );
  };

export interface AppointmentDetailsBadgeProps {
  complete: boolean;
}

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.getSpacing(1),
  },
  text: {
    marginLeft: theme.getSpacing(0.5) + theme.getSpacing(0.5) / 2,
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 20,
    paddingBottom: theme.getSpacing(0.5) / 2,
  },
}));
