import React, { FunctionComponent } from "react";
import { View } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import {
  FacebookCircleIcon,
  GoogleCircleIcon,
  TwitterCircleIcon,
} from "assets/icons";
import { Text } from "assets/components/text";
import { IconButton } from "assets/components/icon-button";
import { Image } from "react-native";
import { PHARMACY_ID, STATIC_DEV_LUMISTRY } from "../../common/constants";
import { getText } from "assets/localization/localization";

export const WebFooter: FunctionComponent<WebFooterProps> = ({
  phone,
  fax,
  email,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const uri = STATIC_DEV_LUMISTRY + PHARMACY_ID + "/images/logo_on_dark.png";

  return (
    <>
      <View>
        <View style={styles.headerContainer}>
          <View style={styles.iconContent}>
            <IconButton
              icon={FacebookCircleIcon}
              onPress={function (): void {
                alert("Function not implemented.");
              }}
              logger={{
                id: "",
                data: undefined,
              }}
            />
            <IconButton
              icon={TwitterCircleIcon}
              onPress={function (): void {
                alert("Function not implemented.");
              }}
              logger={{
                id: "",
                data: undefined,
              }}
            />
            <IconButton
              icon={GoogleCircleIcon}
              onPress={function (): void {
                alert("Function not implemented.");
              }}
              logger={{
                id: "",
                data: undefined,
              }}
            />
          </View>
          <View style={styles.textStyle}>
            <Text style={{ color: theme.palette.white }}>
              {getText("phone-number-footer", { phone: phone })}
            </Text>
            <Text style={{ color: theme.palette.white }}>
              {getText("fax", { fax: fax })}
            </Text>
            <Text style={{ color: theme.palette.white }}>{email}</Text>
          </View>
          <View style={styles.imageContent}>
            <Image
              source={{
                uri: uri,
              }}
              style={styles.imageStyle}
              resizeMode={"contain"}
            />
          </View>
          <View style={styles.textStyle}>
            <Text style={{ color: theme.palette.white }}>
              {getText("pharmacy-website")}
            </Text>
            <Text style={{ color: theme.palette.white }}>
              {getText("digital-pharmacist")}
            </Text>
            <Text style={{ color: theme.palette.white }}>
              {getText("privacy-policy")} {"| "}
              {getText("terms-of-service")}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    backgroundColor: theme.palette.gray[700],
    width: "100%",
    alignItems: "stretch",
    justifyContent: "center",
    flexDirection: "row",
    borderTopWidth: 9,
    gap: 10,
    bottom: 0,
    borderColor: theme.palette.gray[300],
    flexWrap: "wrap",
  },
  imageContent: {
    justifyContent: "center",
    marginLeft: theme.getSpacing(4),
    marginRight: theme.getSpacing(4),
    marginBottom: theme.getSpacing(2),
  },
  iconContent: {
    justifyContent: "center",
    position: "relative",
    flexDirection: "row",
    alignContent: "center",
    alignSelf: "center",
    marginRight: theme.getSpacing(4),
    marginLeft: theme.getSpacing(4),
  },
  textStyle: {
    justifyContent: "center",
    alignItems: "center",
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 14,
    flexDirection: "column",
    marginLeft: theme.getSpacing(4),
    marginRight: theme.getSpacing(4),
  },
  imageStyle: {
    width: 222,
    height: 100,
    marginTop: theme.getSpacing(2),
  },
}));

export interface WebFooterProps {
  phone?: string;
  fax?: string;
  email?: string;
}

export const NativeHeaderTestIDs = {
  title: "native-header-title",
  actionButton: "native-header-action-button",
  backButton: "native-header-back-button",
};
