import React, { FunctionComponent, PropsWithChildren } from "react";
import { Platform, GestureResponderEvent } from "react-native";
import { IconButton } from "react-native-paper";
import { getText } from "../../localization/localization";
import { ArrowLeftIcon, ChevronLeftIcon } from "../../icons";
import { PlatformType } from "../types";
import { useTheme } from "../../theme";

/**
 * Display a back button styled for native header. Displays LeftChevron for iOS and LeftArrow for Android/Web
 */
export const NativeHeaderBackButton: FunctionComponent<
  PropsWithChildren<NativeHeaderBackButtonProps>
> = ({ onPress, platform = Platform.OS, size = 24, testID }) => {
  const theme = useTheme();

  if (platform === "ios") {
    return (
      <IconButton
        accessibilityLabel={getText("back")}
        icon={ChevronLeftIcon}
        color={theme.palette.white}
        size={size}
        onPress={onPress}
        testID={testID}
      />
    );
  }
  return (
    <IconButton
      accessibilityLabel={getText("back")}
      icon={ArrowLeftIcon}
      color={theme.palette.white}
      size={size}
      onPress={onPress}
      testID={testID}
    />
  );
};

export interface NativeHeaderBackButtonProps {
  platform?: PlatformType;
  size?: number;
  onPress?: (event: GestureResponderEvent) => void;
  testID?: string;
}
