import React, { FunctionComponent, PropsWithChildren } from "react";
import { Svg, Path } from "react-native-svg";
import { IconProps } from "./types";
export const AddMedicationsIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ size, testID }) => {
  return (
    <Svg width={size} height={size} fill="none" testID={testID}>
      <Path fill="#fff" fillOpacity={0.01} d="M0 0h70v80H0z" />
      <Path
        fill="#374D58"
        fillRule="evenodd"
        d="m28.202 53.886-12.61-.661L11 46.556l5.264-6.15 11.938.625v12.855ZM37.58 34.59v31.46a.99.99 0 0 0 .987.987h1.92a2.78 2.78 0 0 1 2.772 2.771v2.157H24.993v-2.157a2.78 2.78 0 0 1 2.772-2.771h1.918a.989.989 0 0 0 .987-.988V34.952l6.91-.363Zm-6.91-22.35a3.456 3.456 0 0 1 6.91 0v4.541l-6.91.36v-4.9Zm26.353 43.158-16.975-.891V41.653l17.646.925-5.267 6.15 4.596 6.669Z"
        clipRule="evenodd"
      />
      <Path
        fill="#118F7E"
        fillRule="evenodd"
        d="m11.787 20.603 20.716-1.086 20.714-1.086 5.264 6.158-4.593 6.673-20.713 1.086-20.714 1.086 4.593-6.673-5.267-6.158Z"
        clipRule="evenodd"
      />
    </Svg>
  );
};
