import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import {
  View,
  ImageBackground,
  useWindowDimensions,
  Platform,
} from "react-native";
import { useTheme } from "assets/theme";
import { StyleSheet } from "react-native";
import { Text } from "assets/components/text";
import { useForm } from "assets/form";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  PatientInsuranceForm,
  updatePatientIntake,
  uploadInsuranceCard,
} from "./patient-actions";
import { AccountStackParamList } from "../AccountNavigation";
import { Camera } from "../../../camera/Camera";
import { CameraIcon } from "assets/icons";
import type { CameraCapturedPicture } from "expo-camera";
import { Surface } from "react-native-paper";
import { TouchableOpacity } from "react-native-gesture-handler";
import { getText } from "assets/localization/localization";
import { Form, ScreenContainer } from "assets/layout";
import { Button } from "assets/components/button";
import { usePatientIntakeState } from "./patient-store";
import { useActionSheet } from "@expo/react-native-action-sheet";
import * as ImagePicker from "expo-image-picker";
import { AccountStackNavigationProp } from "../AccountNavigation";

export const PatientInsurance: FunctionComponent<PatientInsuranceProps> = ({
  navigation,
}) => {
  const theme = useTheme();
  const [
    { isCameraVisible: isVisible, insuranceCardType },
    setIsCameraVisible,
  ] = useState({ isCameraVisible: false } as {
    isCameraVisible: boolean;
    insuranceCardType?: "front" | "back";
  });

  const [frontPhoto, setFrontPhoto] =
    useState<CameraCapturedPicture | undefined>(undefined);
  const [backPhoto, setBackPhoto] =
    useState<CameraCapturedPicture | undefined>(undefined);

  const methods = useForm<PatientInsuranceForm>({
    defaultValues: {
      frontPhoto: undefined,
      backPhoto: undefined,
    },
  });

  const handleCameraClose = () => {
    setIsCameraVisible({ isCameraVisible: false });
  };

  const handleSavePhoto = (photo: CameraCapturedPicture) => {
    if (insuranceCardType === "back") {
      setBackPhoto(photo);
    } else if (insuranceCardType === "front") {
      setFrontPhoto(photo);
    }
    setIsCameraVisible({ isCameraVisible: false });
  };

  const { status, error } = usePatientIntakeState();
  const AccountNavigation = navigation as AccountStackNavigationProp;

  const handleSubmit = async () => {
    const frontBlob = new Blob([frontPhoto!.uri], { type: "image/jpeg" });
    const backBlob = new Blob([backPhoto!.uri], { type: "image/jpeg" });
    methods.setValue("frontPhoto", frontBlob);
    methods.setValue("backPhoto", backBlob);
    updatePatientIntake(methods.getValues());
    uploadInsuranceCard(methods.getValues(), AccountNavigation);
  };

  const { showActionSheetWithOptions } = useActionSheet();

  const handleOpenActionSheet = (side: any) => {
    showActionSheetWithOptions(
      {
        options: [
          getText("take-photo"),
          getText("choose-photo"),
          getText("cancel"),
        ],
        cancelButtonIndex: 2,
      },
      async (buttonIndex) => {
        if (buttonIndex === 0) {
          setIsCameraVisible({
            isCameraVisible: true,
            insuranceCardType: side,
          });
        } else if (buttonIndex === 1) {
          choosePhoto(side);
        }
      }
    );
  };

  const choosePhoto = async (side: any) => {
    let photo = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
    });
    if (!photo.cancelled) {
      side === "front" ? setFrontPhoto(photo) : setBackPhoto(photo);
    }
  };

  const { width, height } = useWindowDimensions();
  const buttonWidth =
    Platform.OS === "web"
      ? theme.webMaxWidth - theme.getSpacing(2)
      : width - theme.getSpacing(2);
  const buttonHeight = height * 0.25;

  return (
    <ScreenContainer>
      <View
        style={{
          marginTop: theme.getSpacing(2),
          marginBottom: theme.getSpacing(1),
          borderBottomColor: theme.palette.gray[300],
          borderBottomWidth: 1,
        }}
      >
        <Text
          style={{
            marginBottom: theme.getSpacing(1),
            fontSize: 16,
          }}
        >
          {getText("insurance-add-your-information")}
        </Text>
      </View>
      <Text style={{ color: theme.palette.gray[600] }}>
        {getText("insurance-take-card-picture")}
      </Text>
      <View
        style={{
          marginTop: theme.getSpacing(2),
          alignItems: "center",
        }}
      >
        <Form methods={methods}>
          <Form.Row>
            <PhotoButton
              uri={frontPhoto?.uri}
              width={buttonWidth}
              height={buttonHeight}
              onPress={() => {
                handleOpenActionSheet("front");
              }}
            >
              <View
                style={{
                  padding: theme.getSpacing(1),
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View>
                  <CameraIcon size={24} color={theme.palette.gray[500]} />
                </View>
                <View>
                  <Text style={{ color: theme.palette.gray[500] }}>
                    {getText("front-of-card")}
                  </Text>
                </View>
              </View>
            </PhotoButton>
          </Form.Row>
          <Form.Row>
            <PhotoButton
              uri={backPhoto?.uri}
              width={buttonWidth}
              height={buttonHeight}
              onPress={() => {
                handleOpenActionSheet("back");
              }}
            >
              <View
                style={{
                  padding: theme.getSpacing(1),
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <View>
                  <CameraIcon size={24} color={theme.palette.gray[500]} />
                </View>
                <View>
                  <Text style={{ color: theme.palette.gray[500] }}>
                    {getText("back-of-card")}
                  </Text>
                </View>
              </View>
            </PhotoButton>
          </Form.Row>
        </Form>
        <Camera
          title={
            insuranceCardType === "front"
              ? getText("front-of-card")
              : getText("back-of-card")
          }
          onSave={handleSavePhoto}
          isVisible={isVisible}
          onClose={handleCameraClose}
          mask={"card"}
        />
      </View>
      <Form.Actions>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate("home");
          }}
        >
          <Text
            style={{
              color: theme.palette.gray[700],
              fontSize: 16,
              marginTop: theme.getSpacing(1),
              marginBottom: theme.getSpacing(3),
              alignSelf: "center",
            }}
          >
            {getText("insurance-bring-to-store")}
          </Text>
        </TouchableOpacity>
        <Button
          onPress={handleSubmit}
          hierarchy="primary"
          loading={status === "loading"}
          logger={{ id: "patient-insurance-submit-button" }}
        >
          {getText("next")}
        </Button>
      </Form.Actions>
    </ScreenContainer>
  );
};

interface PatientInsuranceProps
  extends NativeStackScreenProps<AccountStackParamList, "patient-insurance"> {}

const PhotoButton: FunctionComponent<
  PropsWithChildren<{
    uri?: string;
    width?: string | number;
    height?: string | number;
    onPress: () => void;
  }>
> = ({ uri, width = 100, height = 100, onPress, children }) => {
  const theme = useTheme();
  const innerContent = !!uri ? (
    <ImageBackground
      source={{ uri: uri }}
      resizeMode="cover"
      style={{ height: height }}
    />
  ) : (
    children
  );

  return (
    <Surface
      style={[
        stylesButton.button,
        {
          elevation: 0,
          borderRadius: theme.roundness,
          height: height,
          justifyContent: "center",
          overflow: "hidden",
          backgroundColor: theme.palette.gray[100],
          width: width,
          minHeight: height,
        },
      ]}
    >
      <TouchableOpacity
        style={{
          justifyContent: "center",
          height: height,
        }}
        onPress={onPress}
      >
        {innerContent}
      </TouchableOpacity>
    </Surface>
  );
};

const stylesButton = StyleSheet.create({
  button: {
    minWidth: 64,
    borderStyle: "solid",
  },
});
