import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import { AxiosRequestConfig } from "axios";
import {
  AddPrescriptionDto,
  CreateRecordUnderCareRequestDto,
  DefaultApi as PatientServiceClient,
  LocationPatientRecordDto,
  PatientRecordDto,
  RecordUnderCareDto,
  RecordUnderCareRequestDto,
  UpdatePatientRecordDto,
  InitLocationPatientRecordDto,
  UpdateRecordUnderCareRequestDto,
  RecordUnderCareRequestStatus,
  CreatePatientRecordDto,
  UpdateRecordUnderCareDto,
} from "@digitalpharmacist/patient-service-client-axios";

import { PATIENT_API_BASE_URL } from "../common/constants";
import { useUserState } from "../store/user-store";

export interface IPatientService {
  findAll(): Promise<PatientRecordDto[]>;

  createRecordUnderCareRequest(
    userId: string,
    createRecordUnderCareRequestDto: CreateRecordUnderCareRequestDto
  ): Promise<RecordUnderCareRequestDto>;

  patientRecordAddPrescription(
    patientRecordId: string,
    createPrescription: AddPrescriptionDto
  ): Promise<PatientRecordDto>;

  findLocationPatientRecords(
    locationId: string
  ): Promise<LocationPatientRecordDto[]>;

  findLocationPatientRecord(
    locationId: string,
    locationPatientRecordId: string
  ): Promise<LocationPatientRecordDto | undefined>;

  findLocationPatientRecordByPR(
    locationId: string,
    patientRecord: PatientRecordDto
  ): Promise<LocationPatientRecordDto | undefined>;

  updatePatientRecord(
    patientRecordId: string,
    patient: UpdatePatientRecordDto
  ): Promise<PatientRecordDto>;

  findPatientRecord(patientRecordId: string): Promise<PatientRecordDto>;

  recordUnderCareFindByPatientUser(
    patient_record_id: string
  ): Promise<RecordUnderCareDto[]>;

  patientRecordInitLocationPatientRecord(
    patientRecordId: string,

    initLocationPatientRecordDto: InitLocationPatientRecordDto
  ): Promise<PatientRecordDto>;

  findRecordUnderCareByPatientUser(
    patientUserId: string
  ): Promise<RecordUnderCareDto[]>;

  findRecordUnderCareByRecordUnderCare(
    recordId: string
  ): Promise<RecordUnderCareDto[]>;

  revokeByPatientUnderCare(
    recordId: string,
    id: string
  ): Promise<RecordUnderCareDto>;

  removeRecordUnderCare(
    patientUserId: string,
    id: string
  ): Promise<RecordUnderCareDto>;

  patientRecordCreate(
    createPatientRecordDto: CreatePatientRecordDto
  ): Promise<PatientRecordDto>;

  patientRecordUpdate(
    id: string,
    updatePatientRecordDto: UpdatePatientRecordDto
  ): Promise<PatientRecordDto>;

  recordUnderCareUpdate(
    patientUserId: string,
    id: string,
    updateRecordUnderCareDto: UpdateRecordUnderCareDto
  ): Promise<RecordUnderCareDto>;
}

export class PatientService extends BaseApiService implements IPatientService {
  private patientServiceClient: PatientServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.patientServiceClient = new PatientServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );
  }
  patientRecordUpdate(
    id: string,
    updatePatientRecordDto: UpdatePatientRecordDto
  ): Promise<PatientRecordDto> {
    throw new Error("Method not implemented.");
  }

  async createRecordUnderCareRequest(
    userId: string,
    createRecordUnderCareRequestDto: CreateRecordUnderCareRequestDto
  ): Promise<RecordUnderCareRequestDto> {
    const { data } =
      await this.patientServiceClient.recordUnderCareRequestCreate(
        userId,
        createRecordUnderCareRequestDto
      );
    return data;
  }

  async findAll(): Promise<PatientRecordDto[]> {
    const { data } = await this.patientServiceClient.patientRecordFindAll();
    return data;
  }

  async findLocationPatientRecords(
    locationId: string
  ): Promise<LocationPatientRecordDto[]> {
    const { data } = await this.patientServiceClient.locationPatientRecordFind(
      locationId
    );
    return data;
  }

  async findLocationPatientRecordByPR(
    locationId: string,
    patientRecord: PatientRecordDto
  ): Promise<LocationPatientRecordDto | undefined> {
    const minLocationPatientRecord =
      patientRecord.location_patient_records.find(
        (lpr) => lpr.location_id === locationId
      );

    if (!minLocationPatientRecord) return;

    const locationPatientRecord = await this.findLocationPatientRecord(
      locationId,
      minLocationPatientRecord.id
    );

    return locationPatientRecord;
  }

  async findLocationPatientRecord(
    locationId: string,
    locationPatientRecordId: string
  ): Promise<LocationPatientRecordDto | undefined> {
    try {
      const { data } =
        await this.patientServiceClient.locationPatientRecordFindOne(
          locationId,
          locationPatientRecordId
        );
      return data;
    } catch (e) {
      return undefined;
    }
  }

  async updatePatientRecord(
    patientRecordId: string,
    patient: UpdatePatientRecordDto
  ): Promise<PatientRecordDto> {
    const { data } = await this.patientServiceClient.patientRecordUpdate(
      patientRecordId,
      patient
    );

    return data;
  }

  async findPatientRecord(patientRecordId: string): Promise<PatientRecordDto> {
    const { data } = await this.patientServiceClient.patientRecordFindOne(
      patientRecordId
    );

    return data;
  }

  async patientRecordAddPrescription(
    patient_record_id: string,
    createPrescription: AddPrescriptionDto
  ): Promise<PatientRecordDto> {
    const { data } =
      await this.patientServiceClient.patientRecordAddPrescription(
        patient_record_id,
        createPrescription
      );

    return data;
  }

  async recordUnderCareFindByPatientUser(
    patient_user_id: string
  ): Promise<RecordUnderCareDto[]> {
    const { data } =
      await this.patientServiceClient.recordUnderCareFindByPatientUser(
        patient_user_id
      );
    return data;
  }

  async patientRecordInitLocationPatientRecord(
    patientRecordId: string,
    initLocationPatientRecordDto: InitLocationPatientRecordDto
  ): Promise<PatientRecordDto> {
    const { data } =
      await this.patientServiceClient.patientRecordInitLocationPatientRecord(
        patientRecordId,
        initLocationPatientRecordDto
      );
    return data;
  }

  async findPendingRequestsByCaregiver(
    // Pending list on Caregiver side
    userId: string
  ): Promise<RecordUnderCareRequestDto[]> {
    const { data } =
      await this.patientServiceClient.recordUnderCareRequestFindRequestsByCaregiver(
        userId,
        RecordUnderCareRequestStatus.Pending
      );
    return data;
  }

  async findPendingRequestsByRecordUnderCare(
    // Medical info
    userId: string
  ): Promise<RecordUnderCareRequestDto[]> {
    const status: RecordUnderCareRequestStatus =
      RecordUnderCareRequestStatus.Pending;
    const { data } =
      await this.patientServiceClient.recordUnderCareRequestFindRequestsByRecordUnderCare(
        userId,
        status
      );
    return data;
  }

  async findRecordUnderCareByPatientUser(
    //Established one By caregiver
    patientUserId: string
  ): Promise<RecordUnderCareDto[]> {
    const { data } =
      await this.patientServiceClient.recordUnderCareFindByPatientUser(
        patientUserId
      );
    return data;
  }

  async findRecordUnderCareByRecordUnderCare(
    //Established one by record under care - Medical info page
    recordId: string
  ): Promise<RecordUnderCareDto[]> {
    const { data } =
      await this.patientServiceClient.recordUnderCareFindByRecordUnderCare(
        recordId
      );
    return data;
  }

  async patchRequestsRecordUnderCare(
    userId: string,
    requestId: string,
    updateRecordUnderCareRequestDto: UpdateRecordUnderCareRequestDto
  ): Promise<RecordUnderCareRequestDto> {
    const { data } =
      await this.patientServiceClient.recordUnderCareRequestUpdate(
        userId,
        requestId,
        updateRecordUnderCareRequestDto
      );
    return data;
  }

  async revokeByPatientUnderCare(
    recordId: string,
    id: string
  ): Promise<RecordUnderCareDto> {
    const { data } =
      await this.patientServiceClient.recordUnderCareRevokeByPatientUnderCare(
        recordId,
        id
      );
    return data;
  }

  async removeRecordUnderCare(
    patientUserId: string,
    id: string
  ): Promise<RecordUnderCareDto> {
    const { data } = await this.patientServiceClient.recordUnderCareRemove(
      patientUserId,
      id
    );
    return data;
  }

  async patientRecordCreate(
    createPatientRecordDto: CreatePatientRecordDto
  ): Promise<PatientRecordDto> {
    const { data } = await this.patientServiceClient.patientRecordCreate(
      createPatientRecordDto
    );
    return data;
  }

  async recordUnderCareUpdate(
    patientUserId: string,
    id: string,
    updateRecordUnderCareDto: UpdateRecordUnderCareDto
  ): Promise<RecordUnderCareDto> {
    const { data } = await this.patientServiceClient.recordUnderCareUpdate(
      patientUserId,
      id,
      updateRecordUnderCareDto
    );
    return data;
  }
}

export default new PatientService(PATIENT_API_BASE_URL, undefined, false);
