import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTheme } from "assets/theme";
import { ScreenSafeContainer } from "assets/layout";
import { getText } from "assets/localization/localization";
import { Text } from "assets/components/text";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { useNavigation } from "@react-navigation/native";
import { StoreSelectorList } from "../../components/store-selector/list";
import {
  AuthStackNavigationProp,
  AuthStackParamList,
} from "../../navigation/AuthNavigation";
import { useAppStateStore } from "../../store/app-store";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { StoreSelectorInput } from "../../components/store-selector/input/StoreSelectorInput";
import { ScrollView, View } from "react-native";
import { registerStore } from "./register-actions";
import { Button } from "assets/components/button";

export const RegisterStore: FunctionComponent<
  PropsWithChildren<RegisterStoreProps>
> = ({}) => {
  const theme = useTheme();
  const { pharmacyId, stores, getStores } = useAppStateStore();
  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();
  const [inputSearch, setInputSearch] = useState<string>("");
  const navigation = useNavigation<AuthStackNavigationProp>();
  const scrollViewRef = useRef<ScrollView>(null);

  useEffect(() => {
    getStores();
    return () => setInputSearch("");
  }, []);

  const handleOnChange = (value: PharmacyLocationDto) => {
    setSelectedStore(value);
  };

  const handleSubmit = () => {
    if (selectedStore) registerStore(selectedStore?.id, navigation);
  };

  const handleOnInfoButtonPress = (value: PharmacyLocationDto) => {
    navigation.navigate("patient-pharmacy-stores", {
      storeId: value.id,
    } as any);
  };

  return (
    //TODO fix the scroll view with flex...
    <ScreenSafeContainer>
      <View
        style={{
          borderBottomColor: theme.palette.gray[300],
          borderBottomWidth: 1,
          marginBottom: theme.getSpacing(1),
        }}
      >
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 16,
            fontWeight: "600",
            lineHeight: 24,
            marginBottom: theme.getSpacing(1),
          }}
        >
          {getText("find-your-store")}
        </Text>
      </View>
      <View
        style={{
          marginBottom: theme.getSpacing(1),
        }}
      >
        <StoreSelectorInput onInputChange={(value) => setInputSearch(value)} />
      </View>
      <View
        style={{
          flex: 1,
        }}
      >
        <ScrollView style={{ marginBottom: theme.getSpacing(4) }}>
          <StoreSelectorList
            options={stores}
            selectedOption={selectedStore}
            onChange={handleOnChange}
            searchCriteria={inputSearch}
            onInfoButtonPress={handleOnInfoButtonPress}
          />
        </ScrollView>
      </View>
      <View>
        <Button
          hierarchy="primary"
          logger={{ id: "patient-pharmacy-submit-button" }}
          onPress={handleSubmit}
        >
          {getText("set-my-store")}
        </Button>
      </View>
    </ScreenSafeContainer>
  );
};

interface RegisterStoreProps
  extends NativeStackScreenProps<AuthStackParamList, "patient-stores"> {}
