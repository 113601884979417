import React, { FunctionComponent } from "react";
import { View } from "react-native";
import { ScreenContainer } from "assets/layout";
import { useTheme } from "assets/theme";
import { useAppointmentsState } from "./appointments-store";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { Appointment } from "./Appointment";
import { useAppStateStore } from "../../store/app-store";
import { useUserState } from "../../store/user-store";
import { getAppointments, setOffsetPast } from "./appointments-actions";
import { formatDateTimeApi } from "../../common/datetime-utils";
import moment from "moment";
import { Pagination } from "../../components/pagination/Pagination";
import { SortOrder } from "@digitalpharmacist/appointment-service-client-axios/dist";

export const PastAppointments: FunctionComponent<PastAppointmentsProps> = (
  props
) => {
  const theme = useTheme();
  const { pastAppointments, status, offsetPast, limit } =
    useAppointmentsState();
  const { pharmacyId } = useAppStateStore();
  const { user } = useUserState();

  const handleNext = () => {
    setOffsetPast(offsetPast + limit);
    getAppointmentsData();
  };

  const handlePrev = () => {
    setOffsetPast(offsetPast - limit);
    getAppointmentsData();
  };

  const getAppointmentsData = () => {
    if (user && user.preferredPharmacyLocationId) {
      getAppointments(
        pharmacyId,
        user.preferredPharmacyLocationId,
        SortOrder.Desc,
        "past",
        {
          patientId: user.id,
          maxStartDate: formatDateTimeApi(moment()),
          withAllCanceled: true,
        }
      );
    }
  };

  return (
    <ScreenContainer>
      <View
        style={{
          marginTop: theme.getSpacing(4),
        }}
      >
        {status === "loading" && !pastAppointments ? (
          <LoadingIndicator />
        ) : (
          pastAppointments?.results.map((appointment) => (
            <Appointment
              appointment={appointment}
              type="past"
              key={appointment.id}
            />
          ))
        )}

        {pastAppointments && pastAppointments.total > limit ? (
          <Pagination
            currentPage={(offsetPast + limit) / limit}
            totalPages={Math.ceil(pastAppointments.total / limit)}
            onPrevPress={handlePrev}
            onNextPress={handleNext}
          />
        ) : null}
      </View>
    </ScreenContainer>
  );
};

interface PastAppointmentsProps {}
