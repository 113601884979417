import { AddPrescriptionDto } from "@digitalpharmacist/patient-service-client-axios";
import { getText } from "assets/localization/localization";

import patientService from "../../api/patient-service";
import {
  defaultAddPrescriptionState,
  useAddPrescriptionStore,
} from "./add-prescription-store";

export const patientRecordAddPrescription = async (
  addPrescription: AddPrescriptionDto
) => {
  const patientRecordId = useAddPrescriptionStore.getState().patientRecord?.id;
  if (!patientRecordId) return;

  useAddPrescriptionStore.setState({ status: "loading" });

  try {
    const patientResponse = await patientService.patientRecordAddPrescription(
      patientRecordId,
      {
        location_id: addPrescription.location_id,
        rx_number: addPrescription.rx_number,
        last_name: addPrescription.last_name,
        date_of_birth: addPrescription.date_of_birth,
      }
    );

    const createdLocationPatientRecordId =
      patientResponse.location_patient_records.find(
        (lpr) => lpr.location_id === addPrescription.location_id
      )?.id;

    if (!createdLocationPatientRecordId) {
      useAddPrescriptionStore.setState({
        status: "error",
        error: {
          message: getText("prescription-find-server-error"),
        },
      });
      return;
    }

    useAddPrescriptionStore.setState({
      status: "idle",
      error: undefined,
      patientRecord: patientResponse,
      locationPatientRecordId: createdLocationPatientRecordId,
    });
  } catch (error) {
    // 404 show a not found prescription to user
    // this is a big hack as the HttpErrorUtil is preprocessing the request
    if (error === "The requested resource was not found") {
      useAddPrescriptionStore.setState({
        status: "error",
        error: {
          message: getText(
            "unable-to-locate-your-prescription-alert-description"
          ),
        },
      });
    } else {
      useAddPrescriptionStore.setState({
        status: "error",
        error: {
          message: getText("prescription-find-server-error"),
        },
      });
    }
  }
};

export const getPatientRecord = async (patientRecordId: string) => {
  useAddPrescriptionStore.setState({ status: "loading" });

  try {
    const patientRecord = await patientService.findPatientRecord(
      patientRecordId
    );
    useAddPrescriptionStore.setState({
      status: "idle",
      patientRecord: patientRecord,
    });
  } catch (e) {
    useAddPrescriptionStore.setState({
      status: "error",
      error: {
        message: "Error loading patient records",
      },
    });
  }
};

export const resetAddPrescriptionState = () => {
  useAddPrescriptionStore.setState(defaultAddPrescriptionState);
};
