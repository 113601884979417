import { AxiosRequestConfig } from "axios";
import { UNIFIED_COMMS_SERVICE_BASE_URL } from "../common/constants";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import {
  DefaultApi as UnifiedCommsClient,
  ConversationDto,
  DirectMessageDto,
  UpdateUserViewedStatusDto,
  CreateConversationDto,
  DirectMessagePatientDto,
  AddDirectMessageDto,
  DirectMessageExtendedResponseDto,
} from "@digitalpharmacist/unified-communications-service-client-axios";

export interface IUnifiedCommsService {}

export class UnifiedCommsService
  extends BaseApiService
  implements IUnifiedCommsService
{
  private unifiedCommsServiceClient: UnifiedCommsClient;
  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.unifiedCommsServiceClient = new UnifiedCommsClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );
  }

  async getAllConversationsByPatientAndLocation(
    locationId: string,
    locationPatientId: string
  ): Promise<DirectMessagePatientDto[]> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetAllConversationsByPatientAndLocation(
        locationId,
        locationPatientId
      );
    return data ?? [];
  }

  async getConversationsByPatientId(
    locationId: string,
    locationPatientId: string
  ): Promise<ConversationDto[]> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetAllConversationsByPatient(
        locationId,
        locationPatientId
      );
    return data;
  }

  async getAllMessagesByConversation(
    locationId: string,
    locationPatientId: string,
    conversationId: string
  ): Promise<DirectMessageExtendedResponseDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetAllMessagesByConversation(
        locationId,
        locationPatientId,
        conversationId
      );
    return data;
  }

  async updateUserViewedStatus(
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    updateUserViewedStatusDto: UpdateUserViewedStatusDto
  ): Promise<ConversationDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageUpdateUserViewedStatus(
        locationId,
        locationPatientId,
        conversationId,
        updateUserViewedStatusDto
      );
    return data;
  }

  async createConversation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    createConversationDto: CreateConversationDto
  ): Promise<ConversationDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageCreateConversation(
        xPharmacyId,
        locationId,
        locationPatientId,
        createConversationDto
      );
    return data;
  }

  async createMessage(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string,
    addDirectMessageDto: AddDirectMessageDto
  ): Promise<DirectMessageDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageAddMessage(
        xPharmacyId,
        locationId,
        locationPatientId,
        conversationId,
        addDirectMessageDto
      );
    return data;
  }

  async getConversation(
    xPharmacyId: string,
    locationId: string,
    locationPatientId: string,
    conversationId: string
  ): Promise<DirectMessagePatientDto> {
    const { data } =
      await this.unifiedCommsServiceClient.directMessageGetConversation(
        xPharmacyId,
        locationId,
        locationPatientId,
        conversationId
      );
    return data;
  }
}

export default new UnifiedCommsService(
  UNIFIED_COMMS_SERVICE_BASE_URL,
  undefined,
  true
);
