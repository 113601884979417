import { BottomSheetModal } from "@gorhom/bottom-sheet";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Platform, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { BottomSheet } from "assets/components/bottom-sheet";
import { IconButton } from "assets/components/icon-button";
import { Modal } from "assets/components/modal";
import { Text } from "assets/components/text";
import { CloseIcon } from "assets/icons";
import { makeStyles, useTheme } from "assets/theme";
import { getText } from "assets/localization/localization";
import { Button } from "assets/components/button";
import { BookingStep, useBookAppointmentState } from "./book-appointment-store";
import {
  createBooking,
  disableNextButton,
  resetBookAppointment,
  setProcessError,
  setShowBookAppointment,
  setStep,
  setLocation,
  updateBooking,
  clearLocation,
  setStepsMap,
  setFormSubmissions,
  setCurrentForm,
} from "./book-appointment-actions";
import { BookAppointmentSteps } from "./BookAppointmentSteps";
import {
  CreateBookingDto,
  UpdateBookingDto,
} from "@digitalpharmacist/appointment-service-client-axios";
import { useUserState } from "../../../store/user-store";
import { formatDateTimeApi } from "../../../common/datetime-utils";
import moment from "moment";
import { useAppStateStore } from "../../../store/app-store";
import { refreshAppointmentsList } from "../appointments-actions";
import { refreshAppointmentDetails } from "../../appointment/appointment-details-actions";
import { AppointmentsScreenRouteProp } from "../../../navigation/RootNavigation";
import { RecordUnderCareDto } from "@digitalpharmacist/patient-service-client-axios";
import { AddToCalendar, AddToCalendarHandler } from "./AddToCalendar";
import { getMinutesBetweenDates } from "./book-appointment-utils";
import { BookAppointmentPatientRecord } from "./BookAppointmentPatientRecord";
import { BookAppointmentDate } from "./BookAppointmentDate";
import { BookAppointmentTime } from "./BookAppointmentTime";
import { BookAppointmentConfirmation } from "./BookAppointmentConfirmation";
import { BookAppointmentForms } from "./BookAppointmentForms";
import { setStatus } from "../../fill-in-form/fill-in-form-actions";
import {
  CloseConfirmation,
  CloseConfirmationHandler,
} from "./CloseConfirmation";

export const BookAppointment: FunctionComponent<BookAppointmentProps> = ({
  onDismiss,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const sheetRef = useRef<BottomSheetModal>(null);
  const addToCalendarRef = useRef<AddToCalendarHandler>(null);
  const closeConfirmationRef = useRef<CloseConfirmationHandler>(null);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const {
    step,
    stepName,
    stepsMap,
    nextButtonStatus,
    selectedSlot,
    bookingStatus,
    showBookAppointment,
    appointmentType,
    isReschedule,
    booking,
    location,
    processError,
    selectedPatient,
    selectedLocation,
    locationAppointmentTypeId,
    formSubmissions,
    currentForm,
  } = useBookAppointmentState((state) => ({
    ...state,
    locationAppointmentTypeId: state.availableAppointmentLocations.find(
      (item) => item.location_id === state.selectedLocation?.id
    )?.appointment_type_ids[0],
  }));
  const { user } = useUserState();
  const { pharmacyId, stores } = useAppStateStore();
  const navigation = useNavigation<AppointmentsScreenRouteProp>();
  const [bookingSteps, setBookingSteps] = useState<JSX.Element[]>([]);

  const handleOnAddToCalendar = () => {
    addToCalendarRef.current?.show();
  };

  const handleCloseConfirmation = () => {
    closeConfirmationRef.current?.show();
  };

  useEffect(() => {
    const steps = [
      <BookAppointmentPatientRecord navigation={navigation} />,
      <BookAppointmentDate />,
      <BookAppointmentTime />,
      <BookAppointmentConfirmation
        onAddToCalendarPress={handleOnAddToCalendar}
      />,
    ];

    if (isReschedule) {
      steps.shift();
      setStepsMap([
        BookingStep.Date,
        BookingStep.Time,
        BookingStep.Confirmation,
      ]);
    }

    if (!isReschedule && appointmentType?.forms.length) {
      steps.splice(3, 0, <BookAppointmentForms />);
      setStepsMap([
        BookingStep.Patient,
        BookingStep.Date,
        BookingStep.Time,
        BookingStep.Forms,
        BookingStep.Confirmation,
      ]);
    }

    setBookingSteps(steps);
  }, [isReschedule, appointmentType]);

  useEffect(() => {
    if (bookingStatus === "success" || bookingStatus === "error") {
      setStep(step + 1);
    }
  }, [bookingStatus]);

  useEffect(() => {
    if (showBookAppointment) {
      Platform.OS === "web"
        ? setModalIsOpen(true)
        : sheetRef.current?.present();
    } else {
      Platform.OS === "web"
        ? setModalIsOpen(false)
        : sheetRef.current?.dismiss();
    }

    // check if there is a selected location for the book appointment screen
    if (showBookAppointment && !location && user?.preferredPharmacyLocationId) {
      // use preferred location as a fallback
      const findPreferredLocation = stores.find(
        (store) => store.id === user.preferredPharmacyLocationId
      );
      setLocation(findPreferredLocation!);
    }

    if (!showBookAppointment && location) {
      // clear configured location on modal close
      clearLocation();
    }
  }, [showBookAppointment]);

  const handleDismiss = () => {
    if (stepName === BookingStep.Forms) {
      handleCloseConfirmation();
    } else {
      dismissModal();
    }
  };

  const handleConfirmClose = () => {
    dismissModal();
  };

  const dismissModal = () => {
    if (bookingStatus === "success") {
      refreshAppointmentsList();

      if (isReschedule) {
        refreshAppointmentDetails();
      }
    }

    resetBookAppointment();
    onDismiss();
  };

  const handleNextPress = () => {
    if (step < stepsMap.indexOf(BookingStep.Confirmation) - 1) {
      setStep(step + 1);
    }

    if (step < stepsMap.indexOf(BookingStep.Forms)) {
      disableNextButton();
    }

    if (step === stepsMap.indexOf(BookingStep.Confirmation) - 1) {
      handleBooking();
    }

    if (stepName === BookingStep.Confirmation) {
      setShowBookAppointment(false);
      handleDismiss();
    }

    if (processError) {
      setProcessError(false);
    }
  };

  const handleBackPress = () => {
    setStep(step - 1);
    setFormSubmissions([]);
    setCurrentForm(0);

    if (processError) {
      setStatus("idle");
      setProcessError(false);
    }
  };

  const handleBooking = () => {
    if (isReschedule) {
      handleRescheduleBooking();
    } else {
      handleCreateBooking();
    }
  };

  const handleCreateBooking = () => {
    if (!selectedLocation || !selectedPatient || !locationAppointmentTypeId)
      return;

    const isRecordUnderCare = "record_under_care" in selectedPatient;

    const locationAppointmentTypeOverride =
      location!.id !== user!.preferredPharmacyLocationId
        ? locationAppointmentTypeId
        : appointmentType!.id;

    const bookingData: CreateBookingDto = {
      appointment_type_id: locationAppointmentTypeOverride,
      patient_user_id: user!.id,
      patient_record_id: isRecordUnderCare
        ? (selectedPatient as RecordUnderCareDto).record_under_care.id
        : selectedPatient.id,
      title: appointmentType!.title,
      description: appointmentType!.description,
      startTime: selectedSlot!.time,
      endTime: formatDateTimeApi(
        moment(selectedSlot!.time).add(appointmentType!.length, "minutes")
      ),
      timeZone: "US/Central",
      submissions: formSubmissions,
    };

    createBooking(pharmacyId, selectedLocation.id, bookingData);
  };

  const handleRescheduleBooking = () => {
    if (!booking || !selectedLocation) return;

    const bookingData: UpdateBookingDto = {
      ...booking,
      startTime: selectedSlot!.time,
      endTime: formatDateTimeApi(
        moment(selectedSlot!.time).add(appointmentType!.length, "minutes")
      ),
      timeZone: undefined,
    };

    updateBooking(pharmacyId, selectedLocation.id, booking.id, bookingData);
  };

  const getOkButtonText = () => {
    if (step === stepsMap.indexOf(BookingStep.Confirmation) - 1) {
      return getText("confirm");
    }

    if (stepName === BookingStep.Confirmation) {
      return getText("done");
    }

    return getText("next");
  };

  const getTitle = () => {
    if (isReschedule) {
      return getText("reschedule-appointment");
    }

    if (stepName === BookingStep.Forms) {
      return `${appointmentType!.title} (${currentForm + 1} ${getText("of")} ${
        appointmentType!.forms.length
      })`;
    }

    return getText("book-appointment");
  };

  return (
    <>
      {Platform.OS === "web" ? (
        <Modal
          show={modalIsOpen}
          title={getTitle()}
          okButtonProps={{
            onPress: handleNextPress,
            logger: { id: "book-appointment-ok-button-modal" },
            text: getOkButtonText(),
            disabled: nextButtonStatus === "disabled",
            loading: bookingStatus === "loading",
          }}
          dismissButtonProps={{
            onPress: handleDismiss,
            logger: { id: "book-appointment-dismiss-button-modal" },
          }}
          cancelButtonProps={
            step > 0 && step < bookingSteps.length - 1
              ? {
                  onPress: handleBackPress,
                  text: getText("back"),
                  logger: { id: "book-appointment-back-button-modal" },
                }
              : undefined
          }
          isScrollable={true}
          height={800}
          scrollViewStyle={{ flex: 1 }}
          contentContainerStyle={{ flex: 1 }}
        >
          <BookAppointmentSteps
            navigation={navigation}
            bookingSteps={bookingSteps}
          />
        </Modal>
      ) : (
        <BottomSheet
          bottomSheetRef={sheetRef}
          height={"100%"}
          onDismiss={handleDismiss}
          hideHandle={true}
          contentContainerStyle={{ flexGrow: 1 }}
          headerContent={
            <View
              style={{
                position: "relative",
                marginBottom: theme.getSpacing(1),
              }}
            >
              <View style={styles.sheetTitleContainer}>
                <Text style={styles.sheetTitle}>{getTitle()}</Text>
              </View>
              <View style={styles.sheetIconContainer}>
                <IconButton
                  icon={CloseIcon}
                  onPress={handleDismiss}
                  size={24}
                  color={theme.palette.gray[500]}
                  logger={{ id: "book-appointment-bottom-sheet-close" }}
                />
              </View>
            </View>
          }
          footerContent={
            <>
              {!processError && (
                <View style={styles.sheetButtons}>
                  {step > 0 && step < bookingSteps.length - 1 ? (
                    <Button
                      hierarchy="tertiary-gray"
                      size="medium"
                      onPress={handleBackPress}
                      logger={{ id: `book-appointment-back` }}
                      style={styles.sheetButton}
                    >
                      {getText("back")}
                    </Button>
                  ) : null}
                  {step < bookingSteps.length - 1 ? (
                    <Button
                      hierarchy="primary"
                      size="medium"
                      onPress={handleNextPress}
                      logger={{ id: `book-appointment-next` }}
                      style={[
                        styles.sheetButton,
                        step === 0 && styles.sheetButtonFull,
                      ]}
                      disabled={nextButtonStatus === "disabled"}
                      loading={bookingStatus === "loading"}
                    >
                      {step === bookingSteps.length - 2
                        ? getText("confirm")
                        : getText("next")}
                    </Button>
                  ) : (
                    <Button
                      hierarchy="primary"
                      size="medium"
                      onPress={() => handleDismiss()}
                      logger={{ id: `book-appointment-done` }}
                      style={[styles.sheetButton, styles.sheetButtonFull]}
                    >
                      {getText("done")}
                    </Button>
                  )}
                </View>
              )}
            </>
          }
        >
          <BookAppointmentSteps
            navigation={navigation}
            bookingSteps={bookingSteps}
          />
        </BottomSheet>
      )}

      {booking && (
        <AddToCalendar
          ref={addToCalendarRef}
          title={`${booking.title} with ${selectedLocation?.name ?? ""}`}
          notes={booking.description}
          startDate={new Date(booking.startTime)}
          endDate={new Date(booking.endTime)}
          location={`${
            selectedLocation?.address?.address1
              ? selectedLocation.address.address1 + ","
              : ""
          } ${
            selectedLocation?.address?.city
              ? selectedLocation.address.city + ","
              : ""
          } ${
            selectedLocation?.address?.state
              ? selectedLocation.address.state + ","
              : ""
          } ${
            selectedLocation?.address?.postal_code
              ? selectedLocation.address.postal_code + ","
              : ""
          }`}
          durationInMinutes={getMinutesBetweenDates(
            booking.startTime,
            booking.endTime
          )}
        />
      )}

      <CloseConfirmation
        ref={closeConfirmationRef}
        onConfirm={handleConfirmClose}
      />
    </>
  );
};

export interface BookAppointmentProps {
  onDismiss: () => void;
}

const useStyles = makeStyles((theme) => ({
  sheetIconContainer: {
    position: "absolute",
    right: -12,
    top: -5,
    zIndex: 1,
  },
  sheetTitleContainer: {
    paddingHorizontal: theme.getSpacing(3),
    paddingVertical: theme.getSpacing(1),
  },
  sheetTitle: {
    ...theme.fonts.medium,
    textAlign: "center",
    fontWeight: "600",
    fontSize: 18,
  },
  sheetButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginVertical: theme.getSpacing(2),
  },
  sheetButton: {
    marginBottom: theme.getSpacing(2),
    marginTop: "auto",
    width: "45%",
  },
  sheetButtonFull: {
    width: "100%",
  },
}));
