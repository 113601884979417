import * as React from "react";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { FunctionComponent, PropsWithChildren } from "react";
import { useTheme } from "assets/theme";
import { Platform } from "react-native";

const Tab = createMaterialTopTabNavigator();
export const NavigationTabBar: FunctionComponent<
  PropsWithChildren<NavigationTabsProps>
> = ({ screens }) => {
  const theme = useTheme();
  return (
    <Tab.Navigator
      screenOptions={{
        tabBarIndicatorStyle: { backgroundColor: theme.colors.brandedPrimary },
        tabBarLabelStyle: {
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: 16,
          lineHeight: 24,
          textTransform: Platform.OS === "android" ? "uppercase" : "capitalize",
        },
        tabBarStyle: {
          backgroundColor: theme.palette.gray[50],
          borderBottomColor: theme.palette.gray[200],
        },
        tabBarActiveTintColor: theme.colors.brandedPrimary,
        tabBarInactiveTintColor: theme.palette.gray[500],
      }}
    >
      {screens &&
        screens.map((item, index) => (
          <Tab.Screen
            name={item.name}
            component={item.component}
            key={index}
            options={{ title: item.title ?? item.name }}
          />
        ))}
    </Tab.Navigator>
  );
};

export interface NavigationTabsProps {
  screens: Screen[];
}

type Screen = {
  name: string;
  title?: string;
  component?: any;
  testID?: string;
};
