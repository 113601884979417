import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  FunctionComponent,
  useImperativeHandle,
  useState,
} from "react";
import { Linking, View, Platform } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import * as Calendar from "expo-calendar";
import { BottomSheet } from "assets/components/bottom-sheet";
import {
  AppleIcon,
  CloseIcon,
  GoogleIcon,
  OutlookIcon,
} from "../../../../../../packages/assets/icons";
import { getText } from "../../../../../../packages/assets/localization/localization";
import {
  generateIcsCalendarFile,
  generateGoogleCalendarUrl,
  generateOutlook,
  calculateSecondsSinceDate,
} from "./book-appointment-utils";
import { Button } from "../../../../../../packages/assets/components/button";
import { Text } from "../../../../../../packages/assets/components/text";
import { IconButton } from "../../../../../../packages/assets/components/icon-button";
import { Modal } from "../../../../../../packages/assets/components/modal";

const CalendarProviders: FunctionComponent<CalendarEvent> = (calendarEvent) => {
  const styles = useStyles();

  const addNativeCalendarEvent = async () => {
    if (Platform.OS === "web") {
      await Linking.openURL(generateIcsCalendarFile(calendarEvent));
      return;
    }

    const { status } = await Calendar.requestCalendarPermissionsAsync();

    if (status === "granted") {
      const defaultCalendar = await Calendar.getDefaultCalendarAsync();

      const eventIdInCalendar = await Calendar.createEventAsync(
        defaultCalendar.id,
        calendarEvent
      );

      if (Platform.OS === "android") {
        Calendar.openEventInCalendar(eventIdInCalendar);
      } else if (Platform.OS === "ios") {
        const seconds = calculateSecondsSinceDate(calendarEvent.startDate);
        await Linking.openURL(`calshow:${seconds}`);
      }
    }
  };

  const addGoogleCalendarEvent = () =>
    void Linking.openURL(generateGoogleCalendarUrl(calendarEvent));

  const addOutlookCalendarEvent = () => {
    const isMobile = Platform.OS === "android" || Platform.OS === "ios";
    void Linking.openURL(generateOutlook(calendarEvent, isMobile));
  };

  return (
    <View style={styles.container}>
      <Button
        icon={GoogleIcon}
        hierarchy={"tertiary-gray"}
        logger={{ id: "google-calendar-button" }}
        size="extra-extra-large"
        onPress={addGoogleCalendarEvent}
      >
        {getText("google")}
      </Button>

      <Button
        icon={AppleIcon}
        hierarchy={"tertiary-gray"}
        logger={{ id: "apple-calendar-button" }}
        size="extra-extra-large"
        onPress={addNativeCalendarEvent}
      >
        {getText("apple")}
      </Button>

      <Button
        icon={OutlookIcon}
        hierarchy={"tertiary-gray"}
        logger={{ id: "outlook-login-button" }}
        size="extra-extra-large"
        onPress={addOutlookCalendarEvent}
      >
        {getText("outlook")}
      </Button>
    </View>
  );
};

export const AddToCalendarButtonTooltip: ForwardRefRenderFunction<
  AddToCalendarHandler,
  CalendarEvent
> = (calendarEvent: CalendarEvent, ref: ForwardedRef<AddToCalendarHandler>) => {
  const styles = useStyles();
  const theme = useTheme();
  const sheetRef = React.useRef<BottomSheetModal>(null);

  useImperativeHandle(ref, () => ({
    show: () => sheetRef.current?.present(),
    hide: () => sheetRef.current?.dismiss(),
  }));

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
  };

  return (
    <View>
      <BottomSheet
        bottomSheetRef={sheetRef}
        height={"70%"}
        onDismiss={handleBottomSheetDismiss}
        headerContent={
          <View style={styles.spacing}>
            <View style={styles.headerFlex}>
              <View style={styles.bottomSheetViewTitle}>
                <Text style={styles.textTitle}>
                  {getText("add-calendar-event")}
                </Text>
              </View>
              <View style={styles.flexEnd}>
                <IconButton
                  logger={{ id: "dismiss-calendar-modal-button" }}
                  icon={CloseIcon}
                  onPress={handleBottomSheetDismiss}
                  size={20}
                  color={theme.palette.gray[500]}
                />
              </View>
            </View>
          </View>
        }
      >
        <View style={styles.containerFlex}>
          <CalendarProviders {...calendarEvent} />
        </View>
      </BottomSheet>
    </View>
  );
};

export const AddToCalendarButtonBottomSheet = forwardRef<
  AddToCalendarHandler,
  CalendarEvent
>(AddToCalendarButtonTooltip);

export const AddToCalendarButtonWeb: ForwardRefRenderFunction<
  AddToCalendarHandler,
  CalendarEvent
> = (calendarEvent: CalendarEvent, ref: ForwardedRef<AddToCalendarHandler>) => {
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleDismissModal = () => {
    setShowModal(false);
  };

  useImperativeHandle(ref, () => ({
    show: handleShowModal,
    hide: handleDismissModal,
  }));

  return (
    <Modal
      size="sm"
      title={getText("add-calendar-event")}
      show={showModal}
      cancelButtonProps={{
        onPress: handleDismissModal,
        logger: { id: "cancel-button-add-to-calendar" },
      }}
      okButtonProps={{
        onPress: handleDismissModal,
        logger: { id: "ok-button-modal-add-to-calendar" },
        hierarchy: "pharmacy-primary",
      }}
    >
      <View style={styles.containerModal}>
        <CalendarProviders {...calendarEvent} />
      </View>
    </Modal>
  );
};

export const AddToCalendarModal: ForwardRefRenderFunction<
  AddToCalendarHandler,
  CalendarEvent
> = (calendarEvent: CalendarEvent, ref: ForwardedRef<AddToCalendarHandler>) => {
  const nativeRef = React.useRef<AddToCalendarHandler>(null);

  useImperativeHandle(ref, () => ({
    show: () => nativeRef.current?.show(),
    hide: () => nativeRef.current?.hide(),
  }));

  return (
    <>
      {Platform.OS === "web" ? ( // based on this flag we have always one rendering, so will be on ref
        <AddToCalendarButtonModal ref={nativeRef} {...calendarEvent} />
      ) : (
        <AddToCalendarButtonBottomSheet ref={nativeRef} {...calendarEvent} />
      )}
    </>
  );
};

export const AddToCalendar =
  forwardRef<AddToCalendarHandler, CalendarEvent>(AddToCalendarModal);

export const AddToCalendarButtonModal = forwardRef<
  AddToCalendarHandler,
  CalendarEvent
>(AddToCalendarButtonWeb);

export interface CalendarEvent {
  title: string;
  startDate: Date;
  endDate?: Date;
  location: string;
  notes?: string;
  durationInMinutes: number;
}

export interface AddToCalendarHandler {
  show: () => void;
  hide: () => void;
}

const useStyles = makeStyles((theme) => ({
  bottomSheetViewTitle: {
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  title: {
    flexDirection: "row",
    alignItems: "center",
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginVertical: theme.getSpacing(1),
  },

  container: {
    flexDirection: "column",
    alignItems: "flex-start",
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  spacing: {
    marginLeft: theme.getSpacing(1),
    marginRight: theme.getSpacing(1),
  },
  headerFlex: {
    flexDirection: "row",
    margin: theme.getSpacing(1),
  },
  containerFlex: {
    flexGrow: 1,
    margin: theme.getSpacing(1),
    justifyContent: "center",
  },
  containerModal: {
    justifyContent: "center",
    alignItems: "center",
  },
  flexEnd: {
    justifyContent: "flex-end",
  },
}));
