import React, { FunctionComponent, PropsWithChildren } from "react";
import { View } from "react-native";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { useTheme, makeStyles } from "../../theme";
import { Text } from "../text";
import {
  RadioButtonGroupInput,
  RadioButtonGroupInputProps,
} from "./RadioButtonGroupInput";

export const RadioButtonGroupField: FunctionComponent<
  PropsWithChildren<RadioButtonGroupFieldProps>
> = ({ name, rules, hintMessage, ...rest }) => {
  const theme = useTheme();
  const styles = useStyles();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error("RadioGroup form control must have a parent form context");
  }

  const { control, formState } = formContext;
  const error = formState.errors[name];

  return (
    <View>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <RadioButtonGroupInput
            {...rest}
            value={value}
            onValueChange={onChange}
            hintMessage={hintMessage}
          />
        )}
        name={name}
        rules={rules}
      />

      {!!error && (
        <Text
          testID={RadioButtonGroupFieldTestIDs.error}
          style={styles.errorMessage}
        >
          {error.message}
        </Text>
      )}
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
}));

export interface RadioButtonGroupFieldProps
  extends Omit<RadioButtonGroupInputProps, "onValueChange"> {
  name: string;
  rules?: RegisterOptions;
  hintMessage?: string;
}

export const RadioButtonGroupFieldTestIDs = {
  error: "rdb-error",
};
