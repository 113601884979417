import React, {
  ForwardedRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { View } from "react-native";
import { StoreSelectorHandler, StoreSelectorProps } from "./types";
import { Modal } from "assets/components/modal";
import { StoreSelectorList } from "./list";
import { Distance } from "../../types";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { StoreSelectorInput } from "./input/StoreSelectorInput";
import { useTheme } from "assets/theme";

export const StoreSelectorModal = forwardRef<
  StoreSelectorHandler,
  StoreSelectorProps
>(
  (
    {
      options = [],
      defaultOption,
      onChange = (value: PharmacyLocationDto) => {},
      disabled = false,
      onInfoButtonPress,
    }: StoreSelectorProps,
    ref: ForwardedRef<StoreSelectorHandler>
  ) => {
    const theme = useTheme();
    const [showModal, setShowModal] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [selectedValue, setSelectedValue] = React.useState(
      defaultOption || options[0]
    );

    const handleShowModal = () => {
      setShowModal(true);
    };

    const handleDismissModal = () => {
      setShowModal(false);
      setInputValue("");
    };

    useImperativeHandle(ref, () => ({
      show: handleShowModal,
      hide: handleDismissModal,
    }));

    const handleOnSave = () => {
      onChange(selectedValue);
      handleDismissModal();
    };

    const handleStoreItemChange = (item: Distance<PharmacyLocationDto>) => {
      setSelectedValue(item as PharmacyLocationDto);
    };

    const handleInputChange = (value: string) => {
      setInputValue(value);
    };

    return (
      <Modal
        size="sm"
        title="Find your store"
        show={showModal}
        cancelButtonProps={{
          onPress: handleDismissModal,
          logger: { id: "cancel-button-modal-store-selector" },
        }}
        okButtonProps={{
          onPress: handleOnSave,
          logger: { id: "ok-button-modal-store-selector" },
        }}
        headerComponent={
          <View
            style={{
              flexDirection: "row",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <View
              style={{
                justifyContent: "center",
                width: 380,
                marginBottom: theme.getSpacing(1),
              }}
            >
              <StoreSelectorInput onInputChange={handleInputChange} />
            </View>
          </View>
        }
        isScrollable
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "center",
            height: "100%",
            width: "100%",
          }}
        >
          <View
            style={{
              justifyContent: "center",
              height: "100%",
              width: 380,
            }}
          >
            <StoreSelectorList
              options={options}
              selectedOption={selectedValue}
              defaultOption={defaultOption}
              disabled={disabled}
              onChange={handleStoreItemChange}
              searchCriteria={inputValue}
              onInfoButtonPress={onInfoButtonPress}
            />
          </View>
        </View>
      </Modal>
    );
  }
);
