import { Button } from "assets/components/button";
import { makeStyles, useTheme } from "assets/theme";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { View, StyleSheet } from "react-native";
import { Text } from "assets/components/text";
import { getText } from "assets/localization/localization";
import { AccountStackParamList } from "../AccountNavigation";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { PatientFeedbackSuccessTestIDs } from "./PatientIntakeTestIDs";
import { Alert } from "assets/components/alert";
import { ScreenContainer } from "assets/layout";

export const FeedbackSuccessMessage: FunctionComponent<
  PropsWithChildren<FeedbackSuccessMessageProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  // TODO: add patient address call to mock server, to discus w/ Oliver
  const patientAddress = {
    email: "johndoe@gmail.com",
    phone: "(566) 354-4523",
  };

  return (
    <ScreenContainer>
      <View style={{ marginTop: theme.getSpacing(2), flexGrow: 1 }}>
        <Alert
          intent="success"
          title={getText("feedback-thanks-message")}
          style={styles.alert}
        />

        <Text style={styles.message}>
          {getText("feedback-reach-you-message")}
        </Text>

        <View style={styles.contactInfoContainer}>
          <Text style={styles.contactInfoTitleText}>
            {getText("feedback-contact-info").toLocaleUpperCase()}
          </Text>
          <Button
            testID={PatientFeedbackSuccessTestIDs.submit}
            onPress={() => {
              navigation.navigate("patient-info");
            }}
            hierarchy="tertiary"
            logger={{ id: "feedback-edit-button" }}
          >
            {getText("feedback-edit")}
          </Button>
        </View>

        <View style={styles.infoContainer}>
          <Text style={styles.infoContainerLabel}>
            {getText("feedback-email-address")}
          </Text>
          <Text style={styles.infoContainerValue}>{patientAddress.email}</Text>
        </View>

        <View
          style={[styles.infoContainer, { marginBottom: theme.getSpacing(4) }]}
        >
          <Text style={styles.infoContainerLabel}>
            {getText("feedback-phone-number")}
          </Text>
          <Text style={styles.infoContainerValue}>{patientAddress.phone}</Text>
        </View>

        <View style={styles.buttonContainer}>
          <Button
            testID={PatientFeedbackSuccessTestIDs.submit}
            onPress={() => {
              navigation.navigate("home");
            }}
            hierarchy="secondary"
            logger={{ id: "feedback-back-to-profile-button" }}
          >
            {getText("feedback-back-to-profile")}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  alert: {
    marginBottom: theme.getSpacing(2),
  },
  message: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: "600",
    marginBottom: theme.getSpacing(4),
  },
  contactInfoContainer: {
    flexDirection: "row",
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    justifyContent: "space-between",
    marginBottom: theme.getSpacing(2),
  },
  contactInfoTitleText: {
    ...theme.fonts.medium,
    color: theme.palette.gray["500"],
    alignSelf: "center",
  },
  infoContainer: { marginBottom: theme.getSpacing(2) },
  infoContainerLabel: {
    ...theme.fonts.medium,
    color: theme.palette.gray["500"],
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 16,
  },
  infoContainerValue: {
    ...theme.fonts.medium,
    color: theme.palette.gray["900"],
    fontSize: 18,
    fontWeight: "600",
    lineHeight: 20,
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "flex-end",
    width: "100%",
  },
}));

interface FeedbackSuccessMessageProps
  extends NativeStackScreenProps<AccountStackParamList, "feedback-success"> {}
