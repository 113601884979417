import { ScreenContainer } from "assets/layout";
import { makeStyles } from "assets/theme";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { Button } from "assets/components/button";
import { View, StyleSheet } from "react-native";
import { getText } from "assets/localization/localization";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text } from "assets/components/text/Text";
import { RefillGuestStackParamList } from "./RefillGuestNavigation";
import { RefillGuestTestIDs } from "./RefillGuestTestIDs";

export const RefillGuestFindStore: FunctionComponent<
  PropsWithChildren<RefillGuestFindStoreProps>
> = ({ navigation }) => {
  const styles = useStyles();
  const handleOnPressDone = () => {
    navigation.goBack();
  };

  return (
    <ScreenContainer>
      <Text style={styles.textTitle}>{RefillGuestFindStore.name}</Text>
      <View>
        <Button
          hierarchy="primary"
          onPress={handleOnPressDone}
          testID={RefillGuestTestIDs.refillGuestFindStoreNext}
          logger={{ id: RefillGuestTestIDs.refillGuestFindStoreNext }}
        >
          {getText("next")}
        </Button>
      </View>
    </ScreenContainer>
  );
};

export interface RefillGuestFindStoreProps
  extends NativeStackScreenProps<
    RefillGuestStackParamList,
    "refill-guest-find-store"
  > {}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
}));
