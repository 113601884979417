import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
} from "react";
import { TextInput as RNPTextInput } from "react-native-paper";
import {
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
  View,
  TextInputChangeEventData,
  Platform,
  TextStyle,
} from "react-native";
import { EyeIcon } from "../../icons/EyeIcon";
import { EyeOffIcon } from "../../icons/EyeOffIcon";
import { makeStyles, useTheme } from "../../theme";
import { Text } from "../text";
import {
  applyDateMask,
  applyPhoneMask,
  applySecondPhoneMask,
} from "./InputMask";
import { PlatformType } from "../types";
import { BarcodeIcon } from "../../icons";
import { getText } from "../../localization/localization";

export const TextInput: FunctionComponent<PropsWithChildren<TextInputProps>> =
  ({
    value,
    hintMessage,
    multiline = false,
    numberOfLines = 1,
    label,
    type = "text",
    disabled = false,
    testID,
    platform = Platform.OS,
    onChange = () => {},
    onBlur,
    onFocus,
    onKeyPress = (
      event: NativeSyntheticEvent<TextInputKeyPressEventData>
    ) => {},
    accessibilityLabel = getText("text-field"),
    handleOpenScanner,
    placeholder,
    placeholderColor,
    labelColor,
    textColor,
    iconColor,
    inactiveBorderColor,
    activeBorderColor,
    disabledBorderColor,
    backgroundColor = "transparent",
    minHeight = numberOfLines * 44,
    style,
  }) => {
    const theme = useTheme();
    const styles = useStyles();

    const lastKey = React.useRef("");
    const phoneMask = "000-000-0000";
    const dateMask = "MM/DD/YYYY";

    const [currentValue, setCurrentValue] = useState(value);
    const [secureEntryVisible, setSecureEntryVisible] = useState(true);
    const [isFocused, setIsFocused] = useState(false);

    useEffect(() => {
      if (currentValue !== value) {
        setCurrentValue(value);
      }
    }, [value]);

    const handleInputKeyPress = (
      event: NativeSyntheticEvent<TextInputKeyPressEventData>
    ) => {
      lastKey.current = event.nativeEvent.key;
      onKeyPress(event);
    };

    const handleOnFocus = (arg: any) => {
      setIsFocused(true);
      onFocus?.(arg);
    };

    const handleOnBlur = (arg: any) => {
      setIsFocused(false);
      onBlur?.(arg);
    };

    const handleChange = (
      event: NativeSyntheticEvent<TextInputChangeEventData>
    ) => {
      let value = event.nativeEvent.text;
      switch (type) {
        case "telephoneNumber":
          value = applyPhoneMask(value, lastKey.current === "Backspace");
          break;
        case "telephoneNumber2":
          value = applySecondPhoneMask(value, lastKey.current === "Backspace");
          break;
        case "date":
          value = applyDateMask(value, lastKey.current === "Backspace");
          break;
        default:
          break;
      }
      setCurrentValue(value);
      onChange(value);
    };

    const textInputIcon =
      (type === "password" && (
        <RNPTextInput.Icon
          accessibilityLabel={getText("show-hide-password")}
          name={secureEntryVisible ? EyeIcon : EyeOffIcon}
          color={iconColor || theme.palette.gray[500]}
          size={22}
          onPress={() => setSecureEntryVisible(!secureEntryVisible)}
          forceTextInputFocus={false}
          style={{ top: 4 }}
        />
      )) ||
      (type === "barcode" && (
        <RNPTextInput.Icon
          accessibilityLabel={getText("scan-barcode")}
          name={BarcodeIcon}
          color={theme.palette.gray[500]}
          size={40}
          onPress={handleOpenScanner}
          forceTextInputFocus={false}
          style={{ right: 10 }}
        />
      )) ||
      null;

    const Label = () => (
      <Text style={{ color: labelColor ?? theme.palette.gray[500] }}>
        {label}
      </Text>
    );

    if (platform === "android") {
      return (
        <View>
          <>
            <RNPTextInput
              accessibilityLabel={accessibilityLabel}
              placeholder={
                type === "telephoneNumber"
                  ? phoneMask
                  : "" || type === "date"
                  ? dateMask
                  : placeholder ?? ""
              }
              label={label}
              autoComplete="off"
              autoCapitalize="none"
              onChange={handleChange}
              multiline={multiline}
              secureTextEntry={type === "password" && secureEntryVisible}
              value={currentValue}
              style={{
                backgroundColor:
                  backgroundColor === "transparent"
                    ? theme.palette.white
                    : backgroundColor,
              }}
              mode="outlined"
              onKeyPress={handleInputKeyPress}
              numberOfLines={multiline ? numberOfLines : undefined}
              testID={testID ?? TextInputTestIDs.input}
              right={textInputIcon}
              disabled={disabled}
              children={undefined}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
              underlineColor="transparent"
              activeUnderlineColor="transparent"
              activeOutlineColor={activeBorderColor ?? theme.palette.gray[500]}
              outlineColor={inactiveBorderColor ?? theme.palette.gray[300]}
              theme={{
                colors: {
                  primary: textColor,
                  background: backgroundColor || "transparent",
                  disabled: disabledBorderColor ?? theme.colors.disabled,
                  text: textColor ?? theme.palette.gray[900],
                  placeholder: placeholderColor ?? theme.palette.gray[500],
                },
              }}
            />
          </>
          {!!hintMessage && (
            <Text
              style={styles.hintMessage}
              testID={TextInputTestIDs.hintMessage}
            >
              {hintMessage}
            </Text>
          )}
        </View>
      );
    }

    return (
      <View>
        <>
          <RNPTextInput
            accessibilityLabel={accessibilityLabel}
            placeholder={
              type === "telephoneNumber"
                ? phoneMask
                : "" || type === "date"
                ? dateMask
                : placeholder ?? ""
            }
            label={label && <Label />}
            autoComplete="off"
            autoCapitalize="none"
            onChange={handleChange}
            multiline={multiline}
            secureTextEntry={type === "password" && secureEntryVisible}
            value={currentValue}
            style={{
              ...styles.textInput,
              minHeight: minHeight,
              borderColor: isFocused
                ? activeBorderColor ?? theme.palette.primary[500]
                : inactiveBorderColor ?? theme.palette.gray[300],
              borderWidth: 1,
              borderRadius: theme.roundness,
              ...style,
            }}
            mode="flat"
            onKeyPress={handleInputKeyPress}
            numberOfLines={multiline ? numberOfLines : undefined}
            testID={testID ?? TextInputTestIDs.input}
            right={textInputIcon}
            disabled={disabled}
            children={undefined}
            onFocus={handleOnFocus}
            onBlur={handleOnBlur}
            underlineColor="transparent"
            selectionColor={theme.palette.primary[500]}
            activeUnderlineColor="transparent"
            theme={{
              colors: {
                primary: theme.palette.black[500],
                background: backgroundColor || "transparent",
                disabled: disabledBorderColor ?? theme.colors.disabled,
                text: textColor ?? theme.palette.gray[900],
                placeholder: placeholderColor ?? theme.palette.gray[500],
              },
            }}
          />
        </>
        {!!hintMessage && (
          <Text
            testID={TextInputTestIDs.hintMessage}
            style={styles.hintMessage}
          >
            {hintMessage}
          </Text>
        )}
      </View>
    );
  };

const useStyles = makeStyles((theme) => ({
  textInput: {
    borderColor: theme.palette.gray[500],
    fontSize: 16,
  },
  label: {
    color: theme.palette.gray[700],
    fontSize: 14,
  },
  hintMessage: {
    color: theme.palette.gray[500],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
}));

export interface TextInputProps {
  value?: string;
  title?: string;
  label?: string;
  intent?: "primary";
  type?:
    | "text"
    | "password"
    | "telephoneNumber"
    | "telephoneNumber2"
    | "date"
    | "emailAddress"
    | "barcode";
  hintMessage?: string;
  rightIcon?: string;
  disabled?: boolean;
  multiline?: boolean;
  numberOfLines?: number;
  testID?: string;
  platform?: PlatformType;
  onChange?: (value: any) => void;
  onBlur?: (args: any) => void;
  onFocus?: (args: any) => void;
  onKeyPress?: (
    event: NativeSyntheticEvent<TextInputKeyPressEventData>
  ) => void;
  accessibilityLabel?: string;
  handleOpenScanner?: () => void;
  placeholder?: string;
  placeholderColor?: string;
  labelColor?: string;
  textColor?: string;
  iconColor?: string;
  inactiveBorderColor?: string;
  activeBorderColor?: string;
  disabledBorderColor?: string;
  backgroundColor?: string;
  minHeight?: number | string;
  style?: TextStyle;
}

export const TextInputTestIDs = {
  hintMessage: "text-field-hint-message",
  input: "text-field-input",
  label: "text-field-label",
};
