import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "assets/components/text";
import { TextField } from "assets/components/text-field";
import { useForm } from "assets/form";
import { useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { getText } from "assets/localization/localization";
import { Form, ScreenContainer } from "assets/layout";
import { View } from "react-native";
import {
  PatientUnderCareForm,
  submitPatientUnderCareRequest,
} from "./patient-actions";
import { usePatientUnderCareState } from "./patient-store";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import * as validate from "@digitalpharmacist/validation-dp";
import { useAppStateStore } from "../../../store/app-store";
import { useUserState } from "../../../store/user-store";
import { PatientUnderCareStackParamList } from "./PatientUnderCareNavigation";

export const AddPatientUnderCareEmail: FunctionComponent<
  PropsWithChildren<AddPatientUnderCareEmailProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const { status, error, data } = usePatientUnderCareState();
  const { user } = useUserState();
  const { pharmacyName } = useAppStateStore();

  const methods = useForm<PatientUnderCareForm>({
    defaultValues: {
      first_name: data?.first_name,
      last_name: data?.last_name,
      date_of_birth: data?.date_of_birth,
      relationship: data?.relationship,
      email: "",
    },
  });

  const handleSubmit = async () => {
    await submitPatientUnderCareRequest(methods.getValues());
    navigation.navigate("add-patient-under-care-confirmation");
  };

  return (
    <ScreenContainer>
      <View
        style={{
          marginTop: theme.getSpacing(2),
          marginBottom: theme.getSpacing(1),
          borderBottomColor: theme.palette.gray[300],
          borderBottomWidth: 1,
        }}
      >
        <Text
          style={{
            marginBottom: theme.getSpacing(1),
            fontSize: 16,
            lineHeight: 24,
            fontWeight: "600",
          }}
        >
          {getText("enter-patient-email")}
        </Text>
      </View>
      <View
        style={{
          marginTop: theme.getSpacing(2),
        }}
      >
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column>
              <Text
                style={{
                  color: theme.palette.gray[900],
                  fontSize: 14,
                  paddingTop: theme.getSpacing(2),
                  paddingBottom: theme.getSpacing(2),
                  fontWeight: "400",
                }}
              >
                {getText("provide-an-email", {
                  firstName: data?.first_name,
                  lastName: data?.last_name,
                  pharmacyName: pharmacyName,
                })}
              </Text>
              <TextField
                label={getText("email")}
                name="email"
                rules={{
                  required: getText("email-is-required"),
                  validate: {
                    value: () => {
                      return validate.isEmail(methods.getValues().email)
                        ? true
                        : getText("email-is-not-valid");
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === "loading"}
              />
            </Form.Column>
          </Form.Row>
          <Form.Actions>
            <Button
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === "loading"}
              logger={{ id: "register-details-submit-button" }}
            >
              {getText("submit")}
            </Button>
          </Form.Actions>
        </Form>
      </View>
    </ScreenContainer>
  );
};

type AddPatientUnderCareEmailProps = NativeStackScreenProps<
  PatientUnderCareStackParamList,
  "add-patient-under-care-email"
>;
