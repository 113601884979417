import { RecordUnderCareDto } from "@digitalpharmacist/patient-service-client-axios";
import React, { FunctionComponent, useEffect } from "react";
import { Platform, View } from "react-native";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { useFillInFormStore } from "../../fill-in-form/fill-in-form-store";
import { FillInFormContent } from "../../fill-in-form/FillInFormContent";
import {
  enableNextButton,
  initLocationPatientRecord,
  setCurrentForm,
  setFormSubmissions,
  setProcessError,
  setSelectedLocationPatientRecordId,
} from "./book-appointment-actions";
import { useBookAppointmentState } from "./book-appointment-store";

export const BookAppointmentForms: FunctionComponent<BookAppointmentFormsProps> =
  (props) => {
    const {
      selectedLocation,
      selectedPatient,
      appointmentType,
      selectedLocationPatientRecordId,
      currentForm,
      formSubmissions,
    } = useBookAppointmentState();
    const { status } = useFillInFormStore()

    useEffect(() => {
      if (
        !selectedLocationPatientRecordId &&
        selectedLocation &&
        selectedPatient
      ) {
        const locationPatientRecord =
          selectedPatient?.location_patient_records.find(
            (record) => record.location_id === selectedLocation?.id
          );

        if (locationPatientRecord) {
          setSelectedLocationPatientRecordId(locationPatientRecord.id);
        } else {
          initLocationPatientRecord(
            selectedPatient.hasOwnProperty("record_under_care")
              ? (selectedPatient as RecordUnderCareDto).record_under_care.id
              : selectedPatient?.id,
            selectedLocation?.id
          );
        }
      }
    }, []);

    useEffect(() => {
      if (status === 'error') {
        setProcessError(true);
      }
    }, [status])

    useEffect(() => {
      Platform.OS === "web" &&
        window.addEventListener("message", handleMessage);

      return () => {
        Platform.OS === "web" &&
          window.removeEventListener("message", handleMessage);
      };
    }, [currentForm])

    const handleMessage = (event: MessageEvent) => {
      if (event.data.action !== "form-submitted") return;

      handleSubmit(event.data.submissionId);
    };

    const handleSubmit = (submissionId: string) => {
      if (submissionId) {
        setFormSubmissions([
          ...formSubmissions,
          {
            submission_id: submissionId,
            form_id: appointmentType!.forms[currentForm].form_id,
          },
        ]);

        if (appointmentType!.forms.length > currentForm + 1) {
          setCurrentForm(currentForm + 1);
        } else {
          enableNextButton();
        }
      }
    };

    return (
      <View style={{ flex: 1 }}>
        {!appointmentType ||
        !selectedLocation ||
        !selectedLocationPatientRecordId ? (
          <LoadingIndicator />
        ) : (
          <FillInFormContent
            formId={appointmentType.forms[currentForm].form_id}
            locationId={selectedLocation.id}
            locationPatientRecordId={selectedLocationPatientRecordId}
            onFormSubmit={handleSubmit}
          />
        )}
      </View>
    );
  };

export interface BookAppointmentFormsProps {}
