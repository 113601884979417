import React, { FunctionComponent, PropsWithChildren } from "react";
import { VerificationCode } from "assets/components/verification-code";
import {
  useVerificationState,
  useResendVerificationState,
} from "./verification-store";
import { Text } from "assets/components/text";
import { TouchableOpacity, View, StyleSheet } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { Alert } from "assets/components/alert";
import { Button } from "assets/components/button";
import { resendPhoneConfirmationCode, verify } from "./verification-actions";
import { useNavigation } from "@react-navigation/native";
import { AuthStackNavigationProp } from "../../navigation/AuthNavigation";
import { useUserState } from "../../store/user-store";
import { getText } from "assets/localization/localization";
import { ScreenContainer } from "assets/layout";
import { Portal, Provider } from "react-native-paper";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { formatPhoneNumber } from "../../utils";

export const PhoneVerification: FunctionComponent<
  PropsWithChildren<PhoneVerificationProps>
> = (props) => {
  const theme = useTheme();
  const styles = useStyles();
  const verificationState = useVerificationState();
  const resendVerificationState = useResendVerificationState();
  const navigation = useNavigation<AuthStackNavigationProp>();

  let phone = useUserState((x) => {
    return x.user?.phoneNumber;
  });
  if (phone) {
    phone = formatPhoneNumber(phone);
  }

  const handleSubmit = (confirmCode: string) => {
    verify({ confirmCode }, "phone", navigation);
  };

  const handleResendCode = () => {
    resendPhoneConfirmationCode();
  };

  return (
    <ScreenContainer style={{ alignItems: "center" }}>
      {!!verificationState.error && (
        <Alert
          title={verificationState.error.message}
          intent="error"
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      )}
      {!!resendVerificationState.error && (
        <Alert
          title={resendVerificationState.error.message}
          intent="error"
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      )}
      {resendVerificationState.status === "success" && (
        <Alert
          title={getText("verification-code-resent")}
          intent="success"
          style={{ marginBottom: theme.getSpacing(2) }}
        />
      )}
      <View
        style={{
          alignItems: "center",
          marginBottom: theme.getSpacing(2),
          width: "50%",
          alignSelf: "center",
        }}
      >
        <Text
          style={{
            fontSize: 30,
            marginBottom: theme.getSpacing(2),
            marginTop: theme.getSpacing(3),
            textAlign: "center",
          }}
        >
          {getText("verify-your-phone")}
        </Text>
      </View>
      <View style={{ alignItems: "center", marginBottom: theme.getSpacing(2) }}>
        <Text
          style={{
            color: theme.palette.gray[600],
          }}
        >
          {getText("please-enter-code")}
        </Text>
        <Text style={{ marginTop: theme.getSpacing(1) }}>{phone}</Text>
      </View>
      <View style={{ marginBottom: theme.getSpacing(2), alignItems: "center" }}>
        <VerificationCode
          onSubmit={handleSubmit}
          disabled={verificationState.status === "loading"}
        />
        {verificationState.status === "loading" && (
          <Provider>
            <Portal>
              <LoadingIndicator color="#428EC6" />
            </Portal>
          </Provider>
        )}
      </View>
      <View
        style={{
          alignItems: "center",
        }}
      >
        <TouchableOpacity onPress={handleResendCode}>
          <Text style={styles.pressableText}>{getText("resend-code")}</Text>
        </TouchableOpacity>
        <View style={{ marginTop: theme.getSpacing(1) }}>
          <Text style={styles.text}>
            {getText("didnt-receive-text-message")}
          </Text>
          <Text style={styles.text}>
            {getText("phone-number-is-sms-capable")}
          </Text>
        </View>
      </View>
      <View style={{ marginTop: theme.getSpacing(4) }}>
        <Text style={styles.bottomText}>
          {getText("standard-text-messages-apply")}
        </Text>
      </View>
    </ScreenContainer>
  );
};

interface PhoneVerificationProps {}

const useStyles = makeStyles((theme) => ({
  pressableText: {
    ...theme.fonts.medium,
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.primary[500],
    fontStyle: "normal",
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
  text: {
    ...theme.fonts.regular,
    fontSize: 16,
    textAlign: "center",
    color: theme.palette.gray[600],
    fontWeight: "400",
    lineHeight: 24,
  },
  bottomText: {
    ...theme.fonts.regular,
    fontSize: 12,
    lineHeight: 18,
    fontWeight: "400",
    color: theme.palette.gray[600],
    marginTop: theme.getSpacing(4),
    textAlign: "center",
  },
}));
