import React, { FunctionComponent, PropsWithChildren } from "react";
import { IconButton as RNPIconButton } from "react-native-paper";
import { StyleProp, ViewStyle, GestureResponderEvent } from "react-native";
import type { IconSource } from "react-native-paper/lib/typescript/components/Icon";
import { logEvent } from "../../logging/logger";
import { useTheme } from "../../theme";

export const IconButton: FunctionComponent<PropsWithChildren<IconButtonProps>> =
  ({
    icon,
    size = 24,
    color,
    disabled,
    onPress,
    testID = IconButtonTestIDs.icon,
    logger,
    style,
  }) => {
    const theme = useTheme();

    const handleOnPress = (event: GestureResponderEvent): void => {
      if (logger) {
        logEvent(logger.id, {
          data: typeof logger.data === "function" ? logger.data() : logger.data,
        });
      }
      if (onPress) {
        onPress(event);
      }
    };

    return (
      <RNPIconButton
        icon={icon}
        testID={testID}
        size={size}
        color={color ?? theme.palette.black}
        disabled={disabled}
        onPress={handleOnPress}
        style={style}
      />
    );
  };

export interface IconButtonProps {
  icon: IconSource;
  onPress: (e: GestureResponderEvent) => void;
  size?: number;
  color?: string;
  testID?: string;
  disabled?: boolean;
  logger: { id: string; data?: Object | (() => Object) };
  style?: StyleProp<ViewStyle>;
}

export const IconButtonTestIDs = {
  icon: "iconButton",
};
