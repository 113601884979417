import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path } from "react-native-svg";
import { IconProps } from "./types";

export const EmailIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 21 20"
      fill="none"
      color={color}
      testID={testID}
    >
      <Path
        d="M18.8333 5.00001C18.8333 4.08334 18.0833 3.33334 17.1666 3.33334H3.83329C2.91663 3.33334 2.16663 4.08334 2.16663 5.00001M18.8333 5.00001V15C18.8333 15.9167 18.0833 16.6667 17.1666 16.6667H3.83329C2.91663 16.6667 2.16663 15.9167 2.16663 15V5.00001M18.8333 5.00001L10.5 10.8333L2.16663 5.00001"
        stroke="#748995"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
