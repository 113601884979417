import { AxiosRequestConfig } from "axios";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import { FORMS_API_BASE_URL } from "../common/constants";
import {
  FormFullDataDto,
  FormsApi as FormsServiceClient,
} from "@digitalpharmacist/forms-service-client-axios";

export interface IFormsService {}

export class FormsService extends BaseApiService implements IFormsService {
  private formsServiceClient: FormsServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.formsServiceClient = new FormsServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );
  }

  async getForm(locationId: string, formId: string): Promise<FormFullDataDto> {
    const { data } = await this.formsServiceClient.formsGetForm(
      locationId,
      formId
    );

    return data;
  }

  async getPrivateForm(
    locationId: string,
    formId: string,
    patientRecordId: string
  ): Promise<string> {
    // need to cast from `unknown` as the end-point doesn't return a JSON, but rather HTML content that we want to treat as a string
    const { data } = (await this.formsServiceClient.formsGetPrivateFormContent(
      locationId,
      formId,
      patientRecordId
    )) as unknown as { data: string };

    return data;
  }
}

export default new FormsService(FORMS_API_BASE_URL, undefined, false);
