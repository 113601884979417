import React, { FunctionComponent, PropsWithChildren } from "react";
import { Controller, RegisterOptions, useFormContext } from "react-hook-form";
import { makeStyles, useTheme } from "../../theme";
import { Text } from "../text";
import { TypeaheadWithTags, TypeaheadWithTagsProps } from "./TypeaheadWithTags";

export const TypeaheadWithTagsField: FunctionComponent<
  PropsWithChildren<TypeaheadWithTagsFieldProps>
> = ({ name, rules, typeaheadWithTagsProps: typeaheadProps }) => {
  const theme = useTheme();
  const styles = useStyles();
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error("AllergiesInputField must have a parent form context");
  }

  const { control, formState } = formContext;
  const error = formState.errors[name];

  return (
    <>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <TypeaheadWithTags
            {...typeaheadProps}
            onChange={(e) => {
              onChange(e);
            }}
            defaultValue={value}
          />
        )}
        name={name}
        rules={rules}
      />
      {!!error && (
        <Text
          testID={TypeaheadWithTagsFieldTestIDs.error}
          style={styles.errorMessage}
        >
          {error.message}
        </Text>
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  errorMessage: {
    color: theme.palette.error[600],
    fontSize: 14,
    marginTop: theme.getSpacing(1),
  },
}));

export interface TypeaheadWithTagsFieldProps {
  name: string;
  rules?: RegisterOptions;
  typeaheadWithTagsProps: TypeaheadWithTagsProps;
}

export const TypeaheadWithTagsFieldTestIDs = {
  error: "typeahead-with-tags-formControl-error",
};
