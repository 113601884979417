import {
  PatientUserDto,
  UpdateUserRequest,
} from "@digitalpharmacist/users-service-client-axios";
import create from "zustand";
import UsersServiceInstance from "../api/users-service";

export interface UserState {
  user?: PatientUserDto;
  updateUser: (data: Partial<PatientUserDto>) => void;
  logout: () => void;
}
export const useUserState = create<UserState>((set, get) => ({
  user: undefined,
  updateUser: async (partialUser: Partial<PatientUserDto>) => {
    const currentUserInfo = get().user;
    const user: PatientUserDto = await UsersServiceInstance.updatePatient(
      currentUserInfo?.id!,
      { ...currentUserInfo, ...partialUser } as UpdateUserRequest
    );
    set({ user: { ...user, ...partialUser } });
  },
  logout: () => {
    set({ user: undefined });
  },
}));

export const isUserAuthorized = (user: PatientUserDto | undefined) => {
  return user?.id &&
    user?.firstName &&
    user?.lastName &&
    user?.dateOfBirth &&
    user?.phoneNumber &&
    user?.phoneNumberVerified &&
    user?.email &&
    user?.emailVerified &&
    user?.hasSeenFindMedsScreen &&
    user?.preferredPharmacyLocationId
    ? true
    : false;
};

export const isUserNeedEmailVerification = (
  user: PatientUserDto | undefined
) => {
  return user?.id && user?.email && !user?.emailVerified;
};

export const isUserNeedPhoneVerification = (
  user: PatientUserDto | undefined
) => {
  return user?.id && user?.phoneNumber && !user?.phoneNumberVerified;
};

export const isEmailVerified = (user: PatientUserDto | undefined) => {
  return user?.emailVerified;
};

export const userHasPreferredPharmacy = (user: PatientUserDto | undefined) => {
  return user?.id && !user.preferredPharmacyLocationId;
};

export const userHasDetails = (user: PatientUserDto | undefined) => {
  return user?.id && user?.firstName && user?.lastName && user?.dateOfBirth
    ? true
    : false;
};

export const hasSeenFindMedsScreen = (user: PatientUserDto | undefined) => {
  return user?.hasSeenFindMedsScreen;
};
