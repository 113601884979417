import { SortOrder } from "@digitalpharmacist/appointment-service-client-axios";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { getText } from "assets/localization/localization";
import { StorageKeys } from "../../../enums/storage-keys";
import appointmentService, {
  FindBookingsFilters,
} from "../../api/appointment-service";
import { useAppointmentsState } from "./appointments-store";

export const getAppointments = async (
  pharmacyId: string,
  locationId: string,
  orderBy: SortOrder,
  type: "upcoming" | "past",
  filters?: FindBookingsFilters
) => {
  useAppointmentsState.setState({
    status: "loading",
  });

  try {
    const { limit, offsetUpcoming, offsetPast } =
      useAppointmentsState.getState();
    const response = await appointmentService.findBookings(
      pharmacyId,
      type === "upcoming" ? offsetUpcoming : offsetPast,
      limit,
      orderBy,
      filters
    );

    if (type === "upcoming") {
      useAppointmentsState.setState({ upcomingAppointments: response });
    } else {
      useAppointmentsState.setState({ pastAppointments: response });
    }

    useAppointmentsState.setState({
      status: "success",
    });
  } catch (error) {
    useAppointmentsState.setState({
      error: {
        message: getText("error-loading-appointments"),
      },
      status: "error",
    });
  }
};

export const setLocation = (location: PharmacyLocationDto) => {
  useAppointmentsState.setState({ location });
};

export const setRefresh = (refresh: boolean) => {
  useAppointmentsState.setState({ refresh });
};

export const refreshAppointmentsList = () => {
  setRefresh(true);
};

export const setOffsetUpcoming = (offsetUpcoming: number) => {
  useAppointmentsState.setState({ offsetUpcoming });
};

export const setOffsetPast = (offsetPast: number) => {
  useAppointmentsState.setState({ offsetPast });
};

export const setAppointmentsLocation = (locationId: string) => {
  useAppointmentsState.setState({
    appointmentsLocationId: locationId,
  });
};
