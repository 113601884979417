import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "assets/components/text";
import { View } from "react-native";
import { useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { useNavigation } from "@react-navigation/native";
import { AuthStackNavigationProp } from "../../navigation/AuthNavigation";
import { getText } from "assets/localization/localization";
import { ScreenContainer } from "assets/layout";
import { resendResetLink } from "./reset-password-actions";
import { useResetPasswordState } from "./reset-password-store";

export const ResetUnsuccessful: FunctionComponent<
  PropsWithChildren<ResetUnsuccessfulProps>
> = (props) => {
  const theme = useTheme();
  const navigation = useNavigation<AuthStackNavigationProp>();
  const { email } = useResetPasswordState();
  const onPress = async () => {
    resendResetLink(email || "", navigation);
  };

  return (
    <ScreenContainer>
      <Text
        style={{
          color: theme.palette.gray[900],
          fontSize: 25,
          paddingTop: theme.getSpacing(2),
          paddingBottom: theme.getSpacing(2),
          textAlign: "center",
        }}
      >
        {getText("reset-unsuccessful")}
      </Text>
      <Text
        style={{
          color: theme.palette.gray[900],
          fontSize: 14,
          paddingTop: theme.getSpacing(2),
          paddingBottom: theme.getSpacing(2),
          textAlign: "center",
        }}
      >
        {getText("link-expired")}
      </Text>
      <View style={{ margin: theme.getSpacing(1) }}>
        <View style={{ marginBottom: theme.getSpacing(2) }}>
          <Button
            onPress={onPress}
            hierarchy="primary"
            logger={{ id: "reset-password-link-sent-back-to-login-button" }}
          >
            {getText("send-reset-link")}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

interface ResetUnsuccessfulProps {}
