import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from "react";
import { View } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import {
  OrderMedicationsHandler,
  OrderMedicationsProps,
} from "./OrderMedicationsAlertProps";
import { Alert } from "assets/components/alert";
import { BottomSheet } from "assets/components/bottom-sheet";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import { getText } from "assets/localization/localization";
import { Text } from "assets/components/text";
import { IconButton } from "react-native-paper";
import { CloseIcon } from "assets/icons";

const OrderMedicationsAlertBottomSheet: ForwardRefRenderFunction<
  OrderMedicationsHandler,
  OrderMedicationsProps
> = (
  { disabled = false }: OrderMedicationsProps,
  ref: ForwardedRef<OrderMedicationsHandler>
) => {
  const theme = useTheme();
  const styles = useStyles();
  const sheetRef = React.useRef<BottomSheetModal>(null);

  useImperativeHandle(ref, () => ({
    show: () => sheetRef.current?.present(),
    hide: () => sheetRef.current?.dismiss(),
  }));

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.dismiss();
  };

  return (
    <BottomSheet
      headerContent={
        <View>
          <View style={{ flexDirection: "row" }}>
            <View style={styles.bottomSheetViewTitle}>
              <Text style={styles.title}>{getText("select-one-item")}</Text>
            </View>
            <View style={{ justifyContent: "flex-end" }}>
              <IconButton
                accessibilityLabel={getText("dismiss")}
                icon={CloseIcon}
                onPress={handleBottomSheetDismiss}
                size={20}
                color={theme.palette.gray[500]}
              />
            </View>
          </View>
        </View>
      }
      bottomSheetRef={sheetRef}
      height={"25%"}
      onDismiss={handleBottomSheetDismiss}
    >
      <View>
        <Alert title={getText("choose-from-the-list")} intent="warning" />
      </View>
    </BottomSheet>
  );
};
const useStyles = makeStyles((theme) => ({
  bottomSheetViewTitle: {
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  title: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginLeft: theme.getSpacing(4),
  },
}));
export const OrderMedicationsBottomSheet = forwardRef<
  OrderMedicationsHandler,
  OrderMedicationsProps
>(OrderMedicationsAlertBottomSheet);
