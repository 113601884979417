import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { useFocusEffect } from "@react-navigation/native";
import shallow from "zustand/shallow";
import {
  NavigationTabBar,
  NavigationTabsProps,
} from "../../components/navigation-tab-bar/NavigationTabBar";
import { LandingHeader } from "../../components/landing-header";
import { useAppStateStore } from "../../store/app-store";
import { UpcomingAppointments } from "./UpcomingAppointments";
import { Platform } from "react-native";
import { PastAppointments } from "./PastAppointments";
import { getText } from "assets/localization/localization";
import { useUserState } from "../../store/user-store";
import {
  getAppointments,
  setLocation,
  setRefresh,
} from "./appointments-actions";
import { getAppointmentTypes } from "./appointment-types/appointment-types-actions";
import { formatDateTimeApi } from "../../common/datetime-utils";
import moment from "moment";
import { useAppointmentsState } from "./appointments-store";
import { SortOrder } from "@digitalpharmacist/appointment-service-client-axios";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { setLocation as setBookingLocation } from "./book-appointment/book-appointment-actions";

const hideLandingHeaderOnWeb = Platform.OS !== "web";

export const AppointmentsNavigation: FunctionComponent = () => {
  const { pharmacyId, pharmacyName, stores, getStores } = useAppStateStore(
    (state) => ({
      pharmacyId: state.pharmacyId,
      pharmacyName: state.pharmacyName,
      stores: state.stores,
      getStores: state.getStores,
    }),
    shallow
  );

  const preferredPharmacyLocationId = useUserState(
    (state) => state.user?.preferredPharmacyLocationId
  );

  const userId = useUserState((state) => state.user?.id);

  const { refresh, pastAppointmentsTotal, appointmentsLocationId } =
    useAppointmentsState(
      (state) => ({
        refresh: state.refresh,
        pastAppointmentsTotal: state.pastAppointments
          ? state.pastAppointments.total
          : null,
        appointmentsLocationId: state.appointmentsLocationId,
      }),
      shallow
    );

  useEffect(() => {
    if (!stores.length) getStores();
  }, []);

  useFocusEffect(
    useCallback(() => {
      if (preferredPharmacyLocationId) {
        useAppointmentsState.setState({
          appointmentsLocationId: preferredPharmacyLocationId,
        });
      }
    }, [preferredPharmacyLocationId])
  );

  useFocusEffect(
    useCallback(() => {
      if (appointmentsLocationId || (appointmentsLocationId && refresh)) {
        if (refresh) {
          setRefresh(false);
        }

        if (stores.length) {
          const location = stores.find(
            (store) => store.id === appointmentsLocationId
          ) as PharmacyLocationDto;
          setLocation(location);
          setBookingLocation(location);
        }

        getAppointmentTypes(pharmacyId, appointmentsLocationId);

        getAppointments(
          pharmacyId,
          appointmentsLocationId,
          SortOrder.Asc,
          "upcoming",
          {
            patientId: userId,
            minEndDate: formatDateTimeApi(moment()),
          }
        );

        getAppointments(
          pharmacyId,
          appointmentsLocationId,
          SortOrder.Desc,
          "past",
          {
            patientId: userId,
            maxEndDate: formatDateTimeApi(moment()),
            withAllCanceled: true,
          }
        );
      }
    }, [appointmentsLocationId, refresh, stores.length])
  );

  const screens: NavigationTabsProps["screens"] = useMemo(() => {
    const screensProps = [
      {
        name: "upcoming",
        component: UpcomingAppointments,
        testID: "upcoming-appointments-tab-test-id",
        title: getText("upcoming"),
      },
    ];

    if (pastAppointmentsTotal) {
      screensProps.push({
        name: "past",
        component: PastAppointments,
        testID: "past-appointments-tab-test-id",
        title: getText("past"),
      });
    }

    return screensProps;
  }, [pastAppointmentsTotal]);

  return (
    <>
      {hideLandingHeaderOnWeb && (
        <LandingHeader pharmacyName={pharmacyName} greetings="Appointments" />
      )}
      <NavigationTabBar screens={screens} />
    </>
  );
};
