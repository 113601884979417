import React, { FunctionComponent, PropsWithChildren } from "react";
import { View } from "react-native";
import { TouchableRipple } from "react-native-paper";
import { ChevronRightIcon } from "../../icons";
import { makeStyles, useTheme } from "../../theme";
import { Icon } from "../icon";

export const ListItemLink: FunctionComponent<PropsWithChildren<ListItemProps>> =
  ({ onPress, children, showDivider = true }) => {
    const theme = useTheme();
    const styles = useStyles();
    return (
      <>
        <TouchableRipple
          onPress={onPress}
          disabled={!onPress}
          style={styles.menuItemLinkContainer}
          testID={ListItemLinkTestIDs.button}
        >
          <View style={styles.menuItemLink}>
            <View style={{ flex: 1 }} testID={ListItemLinkTestIDs.children}>
              {children}
            </View>
            <View>
              <Icon icon={ChevronRightIcon} color={theme.palette.gray[500]} />
            </View>
          </View>
        </TouchableRipple>
        {showDivider && <View style={styles.borderBottom}></View>}
      </>
    );
  };

const useStyles = makeStyles((theme) => ({
  menuItemLinkContainer: { backgroundColor: theme.palette.transparent },
  menuItemLink: {
    flexDirection: "row",
    minHeight: 32,
    marginRight: theme.getSpacing(1),
    alignItems: "center",
    marginTop: theme.getSpacing(1),
    marginBottom: theme.getSpacing(1),
  },
  borderBottom: {
    height: 1,
    backgroundColor: theme.palette.gray[300],
  },
}));

interface ListItemProps {
  onPress?: () => void;
  showDivider?: boolean;
}

export const ListItemLinkTestIDs = {
  button: "list-item-button",
  children: "list-item-children",
};
