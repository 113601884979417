import Constants from "expo-constants";

const defaultBrandedColors: Partial<ReactNativePaper.ThemeColors> = {
  primary: "#2970FF",
  brandedPrimary: "#118F7E",
  brandedSecondary: "#51B6AC",
  brandedLight: "#EAF3F2",
  brandedText: "#009D87",
  gradientStart: "#017363",
  gradientEnd: "#009D87",
  pharmacyPrimary: "#0063B2",
  pharmacySecondary: "#00A6ED",
};

export function createBrandedColors(
  overrides?: Partial<ReactNativePaper.ThemeColors>
): ReactNativePaper.ThemeColors {
  // Getting info from app constants
  // TODO: review this part with colleagues if we need this part or not anymore since we are getting the colors from the pharmacy service
  // perhaps we need some static info for the deployed app. To discuss ...
  if (Constants && Constants.manifest && Constants.manifest.extra) {
    const extra = Constants.manifest.extra;
    if (extra.COLOR_PRIMARY) {
      defaultBrandedColors.brandedPrimary = extra.COLOR_PRIMARY;
    }
    if (extra.COLOR_SECONDARY) {
      defaultBrandedColors.brandedSecondary = extra.COLOR_SECONDARY;
    }
    if (extra.COLOR_TEXT) {
      defaultBrandedColors.brandedText = extra.COLOR_TEXT;
    }
    if (extra.COLOR_LIGHT) {
      defaultBrandedColors.brandedLight = extra.COLOR_LIGHT;
    }
    if (extra.COLOR_GRADIENT_START) {
      defaultBrandedColors.gradientStart = extra.COLOR_GRADIENT_START;
    }
    if (extra.COLOR_GRADIENT_END) {
      defaultBrandedColors.gradientEnd = extra.COLOR_GRADIENT_END;
    }
  }

  // overriding default colors
  const brandedColors: Partial<ReactNativePaper.ThemeColors> = {
    ...defaultBrandedColors,
    ...overrides,
  };

  // creating secondaries and disabled
  brandedColors.brandedPrimaryDisabled = brandedColors.brandedPrimary + "30";
  brandedColors.brandedSecondaryDisabled =
    brandedColors.brandedSecondary + "30";
  brandedColors.brandedTextDisabled = brandedColors.brandedText + "30";
  brandedColors.pharmacyPrimaryDisabled = brandedColors.pharmacyPrimary + "30";
  brandedColors.pharmacySecondaryDisabled =
    brandedColors.pharmacySecondary + "30";

  return brandedColors as ReactNativePaper.ThemeColors;
}
