import React, {
  ForwardedRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useRef,
} from "react";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import { View } from "react-native";
import { BottomSheet } from "assets/components/bottom-sheet";
import { Button } from "assets/components/button";
import { Text } from "assets/components/text";
import { getText } from "assets/localization/localization";
import { makeStyles, useTheme } from "assets/theme";
import {
  CloseConfirmationHandler,
  CloseConfirmationProps,
} from "./CloseConfirmation";

export const CloseConfirmationBottomSheet: ForwardRefRenderFunction<
  CloseConfirmationHandler,
  CloseConfirmationProps
> = ({ onConfirm }, ref: ForwardedRef<CloseConfirmationHandler>) => {
  const styles = useStyles();
  const theme = useTheme();
  const sheetRef = useRef<BottomSheetModal>(null);

  useImperativeHandle(ref, () => ({
    show: () => sheetRef.current?.present(),
    hide: () => sheetRef.current?.close(),
  }));

  const handleBottomSheetDismiss = () => {
    sheetRef.current?.close();
  };

  const handleConfirm = () => {
    handleBottomSheetDismiss();
    onConfirm();
  };

  return (
    <View>
      <BottomSheet
        bottomSheetRef={sheetRef}
        height={"30%"}
        onDismiss={handleBottomSheetDismiss}
        footerContent={
          <View style={styles.sheetButtonsContainer}>
            <View style={styles.sheetButtonContainer}>
              <Button
                onPress={handleBottomSheetDismiss}
                hierarchy="secondary"
                logger={{ id: "close-confirmation-bottom-sheet-cancel-button" }}
                style={{
                  marginRight: theme.getSpacing(1),
                }}
                size="medium"
              >
                {getText("cancel")}
              </Button>
            </View>
            <View style={styles.sheetButtonContainer}>
              <Button
                onPress={handleConfirm}
                hierarchy="primary"
                logger={{
                  id: "close-confirmation-bottom-sheet-confirm-button",
                }}
                style={{
                  marginLeft: theme.getSpacing(1),
                }}
                size="medium"
              >
                {getText("confirm")}
              </Button>
            </View>
          </View>
        }
      >
        <View style={styles.textContainer}>
          <Text style={styles.text}>
            {getText("close-confirmation-question")}
          </Text>
          <Text style={styles.text}>{getText("close-confirmation-text")}</Text>
        </View>
      </BottomSheet>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  sheetButtonsContainer: {
    flex: 1,
    flexDirection: "row",
    marginBottom: theme.getSpacing(2),
  },
  sheetButtonContainer: {
    flex: 1,
  },
  textContainer: {
    paddingVertical: theme.getSpacing(2),
  },
  text: {
    marginBottom: theme.getSpacing(2),
  },
}));
