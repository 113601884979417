import React from "react";
import { ThemeContext, ThemeContextType } from "../context";

export function useThemeContext(): ThemeContextType {
  return React.useContext(ThemeContext);
}

export function useTheme(): ReactNativePaper.Theme {
  const { theme } = useThemeContext();
  return theme;
}
