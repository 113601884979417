import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "../text";
import {
  Platform,
  TouchableOpacity,
  TouchableOpacityProps,
  TextProps,
  StyleProp,
  ViewStyle,
} from "react-native";
import { makeStyles, useTheme } from "../../theme";
import { Icon, IconProps } from "../icon";

export const Tag: FunctionComponent<PropsWithChildren<TagProps>> = ({
  label,
  icon,
  selected,
  disabled,
  onPress,
  onLongPress,
  delayLongPress,
  textProps,
  style,
  iconProps,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <TouchableOpacity
      testID={tagTestIDs.root}
      style={[
        styles.root,
        selected && { backgroundColor: theme.palette.primary[600] },
        !selected && { backgroundColor: theme.palette.gray[100] },
        style,
      ]}
      onPress={onPress}
      onLongPress={onLongPress}
      delayLongPress={delayLongPress}
      disabled={!onPress || disabled}
    >
      {icon && (
        <Icon
          icon={icon}
          size={14}
          color={selected ? theme.palette.white : theme.palette.gray[500]}
          {...iconProps}
        />
      )}

      <Text
        {...textProps}
        testID={tagTestIDs.label}
        style={[
          styles.text,
          { textAlign: "center" },
          selected && { color: theme.palette.white },
          !selected && { color: theme.palette.gray[700] },
          Platform.OS === "web" &&
            ({
              userSelect: "none",
            } as any),
          textProps?.style,
        ]}
      >
        {label}
      </Text>
    </TouchableOpacity>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    height: 22,
    alignItems: "center",
    borderRadius: theme.roundness * 4,
    flexDirection: "row",
    paddingLeft: theme.getSpacing(1),
    paddingRight: theme.getSpacing(1),
    alignSelf: "flex-start",
  },
  text: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "500",
    marginLeft: theme.getSpacing(0.5),
  },
}));

export interface TagProps
  extends Pick<
    TouchableOpacityProps,
    "onPress" | "onLongPress" | "disabled" | "delayLongPress"
  > {
  label: string;
  icon?: FunctionComponent;
  iconProps?: Omit<IconProps, "icon">;
  selected?: boolean;
  textProps?: TextProps;
  style?: StyleProp<ViewStyle>;
}

export const tagTestIDs = {
  root: "tag",
  label: "tag-label",
};
