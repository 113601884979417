import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path, Rect } from "react-native-svg";
import { IconProps } from "./types";

export const MedicationCircleIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 37 36"
      fill="none"
      color={color}
      testID={testID}
    >
      <Rect x="0.5" width="36" height="36" rx="18" fill={color} />
      <Path
        d="M21.833 11.3337H23.4997C23.9417 11.3337 24.3656 11.5093 24.6782 11.8218C24.9907 12.1344 25.1663 12.5583 25.1663 13.0003V24.667C25.1663 25.109 24.9907 25.5329 24.6782 25.8455C24.3656 26.1581 23.9417 26.3337 23.4997 26.3337H13.4997C13.0576 26.3337 12.6337 26.1581 12.3212 25.8455C12.0086 25.5329 11.833 25.109 11.833 24.667V13.0003C11.833 12.5583 12.0086 12.1344 12.3212 11.8218C12.6337 11.5093 13.0576 11.3337 13.4997 11.3337H15.1663M15.9997 9.66699H20.9997C21.4599 9.66699 21.833 10.0401 21.833 10.5003V12.167C21.833 12.6272 21.4599 13.0003 20.9997 13.0003H15.9997C15.5394 13.0003 15.1663 12.6272 15.1663 12.167V10.5003C15.1663 10.0401 15.5394 9.66699 15.9997 9.66699Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};
