import { AppointmentTypeSlotDto } from "@digitalpharmacist/appointment-service-client-axios";
import moment from "moment";
import { MarkedDates } from "react-native-calendars/src/types";
import { CalendarEvent } from "./AddToCalendar";

export const APPOINTMENT_DATE_TIME_FORMAT = "hh:mma, dddd, MMMM D, YYYY";
export const APPOINTMENT_TIME_FORMAT = "hh:mma";

// Chicago timezone
export const DEFAULT_UTC_OFFSET = "-0500";

export const parseSlots = (
  slots: AppointmentTypeSlotDto[]
): AppointmentSlots => {
  const appointmentSlots: AppointmentSlots = {};

  slots.map((slot) => {
    const date = moment(slot.time).format("YYYY-MM-DD");
    const existingSlot = appointmentSlots[date];

    if (existingSlot) {
      appointmentSlots[date].push(slot);
    } else {
      appointmentSlots[date] = [slot];
    }
  });

  return appointmentSlots;
};

export const getMarkedDates = (slots: AppointmentSlots): MarkedDates => {
  const dates = Object.keys(slots);
  const markedDates: MarkedDates = {};
  const today = moment().format("YYYY-MM-DD");

  dates.map((date) => {
    markedDates[date] = {
      disabled: false,
      disableTouchEvent: false,
    };
  });

  const todayMarkedDate = markedDates[today];

  if (todayMarkedDate) {
    markedDates[today] = {
      ...todayMarkedDate,
      marked: true,
    };
  } else {
    markedDates[today] = {
      marked: true,
      disabled: true,
      disableTouchEvent: true,
    };
  }

  return markedDates;
};

export type AppointmentSlots = Record<string, AppointmentTypeSlotDto[]>;

const MINUTE_IN_MS = 60 * 1000;

export function getEndTime(calendarEvent: CalendarEvent) {
  return (
    calendarEvent.endDate ??
    addMinutesToDate(calendarEvent.startDate, calendarEvent.durationInMinutes)
  );
}

export function getMinutesBetweenDates(startDate: string, endDate: string) {
  const diff = new Date(endDate).getTime() - new Date(startDate).getTime();

  return diff / 60000;
}

export function formatDateForCalendarUrl(date: Date) {
  return date.toISOString().replace(/-|:|\.\d+/g, "");
}
function addMinutesToDate(date: Date, minutes: number) {
  return new Date(date.getTime() + minutes * MINUTE_IN_MS);
}

export function generateGoogleCalendarUrl(calendarEvent: CalendarEvent) {
  const startDate = formatDateForCalendarUrl(calendarEvent.startDate);
  const endDate = formatDateForCalendarUrl(getEndTime(calendarEvent));

  const encodedUrl = encodeURI(
    [
      "https://www.google.com/calendar/render",
      "?action=TEMPLATE",
      `&text=${calendarEvent.title || ""}`,
      `&dates=${startDate || ""}`,
      `/${endDate || ""}`,
      `&details=${`${calendarEvent.notes}\n` || ""}`,
      `&location=${calendarEvent.location || ""}`,
      "&sprop=&sprop=name:",
    ].join("")
  );

  return encodedUrl;
}

export function generateIcsCalendarFile(calendarEvent: CalendarEvent) {
  const startDate = formatDateForCalendarUrl(calendarEvent.startDate);
  const endDate = formatDateForCalendarUrl(getEndTime(calendarEvent));

  const encodedUrl = encodeURI(
    `data:text/calendar;charset=utf8,${[
      "BEGIN:VCALENDAR",
      "VERSION:2.0",
      "BEGIN:VEVENT",
      `DTSTART:${startDate || ""}`,
      `DTEND:${endDate || ""}`,
      `SUMMARY:${calendarEvent.title || ""}`,
      `DESCRIPTION:${calendarEvent.notes || ""}`,
      `LOCATION:${calendarEvent.location || ""}`,
      "END:VEVENT",
      "END:VCALENDAR",
    ].join("\n")}`
  );

  return encodedUrl;
}

export function generateOutlook(
  calendarEvent: CalendarEvent,
  isMobile: boolean
) {
  const urlParts = [];
  const basePath = isMobile
    ? "/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent"
    : "/calendar/action/compose?rru=addevent";

  const baseUrl = "https://outlook.live.com" + basePath;

  urlParts.push(baseUrl);
  urlParts.push("startdt=" + calendarEvent.startDate);
  urlParts.push("enddt=" + calendarEvent.endDate);

  if (calendarEvent.title) {
    urlParts.push("subject=" + encodeURIComponent(calendarEvent.title));
  }
  if (calendarEvent.location) {
    urlParts.push("location=" + encodeURIComponent(calendarEvent.location));
  }
  if (calendarEvent.notes) {
    urlParts.push("body=" + encodeURIComponent(calendarEvent.notes));
  }
  return urlParts.join("&");
}

export const calculateSecondsSinceDate = (startDate: Date) => {
  const formatDate = Math.floor(Number(startDate) / 1000);
  // 978307200 is the reference date in seconds for Apple Cocoa Core time, that is used by Apple
  // Generating the number was causing some issue which is the reason for the hard-coded value
  const cocoaCoreReference = 978307200;

  return formatDate - cocoaCoreReference;
};

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
}
