export const RefillTestIDs = {
  refillMedicationsNext: "refill-medications-next",
  refillMedicalInfosSubmit: "refill-medical-infos-submit",
  refillOrderDetailsSubmit: "refill-order-details-submit",
  refillReviewSubmit: "refill-review-submit",
  refillConfirmationDone: "refill-confirmation-done",
  allergiesCheckbox: "refill-allergies",
  allergyInput: "medical-info-allergy-input",
  medicalConditionsInput: "medical-info-medical-conditions-input",
  unableToSubmitButton: "unable-to-submit-bottom-sheet",
  refillSubmitButton: "refill-submit-button",
  refillNotesButton: "refill-notes-button",
};
