import React, { FunctionComponent } from "react";
import { AppointmentsScreenRouteProp } from "../../../navigation/RootNavigation";
import { useBookAppointmentState } from "./book-appointment-store";
import { BookAppointmentError } from "./BookAppointmentError";

export const BookAppointmentSteps: FunctionComponent<BookAppointmentStepsProps> =
  ({ navigation, bookingSteps }) => {
    const { step, processError } = useBookAppointmentState();

    const getStep = () => {
      const currentStep = bookingSteps[step];

      if (!currentStep || processError) {
        return <BookAppointmentError navigation={navigation} />;
      }

      return currentStep;
    };

    return <>{getStep()}</>;
  };

export interface BookAppointmentStepsProps {
  navigation: AppointmentsScreenRouteProp;
  bookingSteps: JSX.Element[];
}
