import { Alert } from "assets/components/alert";
import { getText } from "assets/localization/localization";
import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from "react";
import { Platform } from "react-native";
import { StoreSelectorBottomSheet } from "./StoreSelectorBottomSheet";
import { StoreInformation } from "./StoreInformation";
import { StoreSelectorHandler, StoreSelectorProps } from "./types";
import { StoreSelectorModal } from "./StoreSelectorModal";

const StoreSelectorComponent: ForwardRefRenderFunction<
  StoreSelectorHandler,
  StoreSelectorProps
> = (
  {
    platform = Platform.OS,
    mapProps,
    options,
    defaultOption,
    storeInformationShown = true,
    changeButtonShown = true,
    ...rest
  }: StoreSelectorProps,
  ref: ForwardedRef<StoreSelectorHandler>
) => {
  const nativeRef = React.useRef<StoreSelectorHandler>(null);
  const webRef = React.useRef<StoreSelectorHandler>(null);

  const handleShow = () => {
    if (platform === "web") webRef.current?.show();
    else nativeRef.current?.show();
  };

  const handleHide = () => {
    if (platform === "web") webRef.current?.hide();
    else nativeRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const handleOnChangePress = () => {
    handleShow();
  };

  if (options.length === 0) {
    return (
      <Alert intent="error" title={getText("store-selector-no-stores-error")} />
    );
  }

  return (
    <>
      {storeInformationShown && (
        <StoreInformation
          onChangePress={handleOnChangePress}
          // TODO: discuss with team, it should not be possible to not have a default option
          // (the preselected store, it is chosen from the onboarding flow)
          // fallback on first option if it is not supplied correctly because there is no ui
          // for the look and feel of "no default" option state
          item={defaultOption ?? options[0]}
          mapProps={mapProps}
          showChange={options && options.length > 1 && changeButtonShown}
        />
      )}
      {platform === "web" ? (
        <StoreSelectorModal
          {...rest}
          ref={webRef}
          options={options}
          defaultOption={defaultOption}
        />
      ) : (
        <StoreSelectorBottomSheet
          {...rest}
          ref={nativeRef}
          options={options}
          defaultOption={defaultOption}
        />
      )}
    </>
  );
};

export const StoreSelector = forwardRef<
  StoreSelectorHandler,
  StoreSelectorProps
>(StoreSelectorComponent);
