import { FunctionComponent } from "react";
import { TouchableOpacity, View } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { Text } from "assets/components/text";
import { DirectMessagePatientDto } from "@digitalpharmacist/unified-communications-service-client-axios";
import { formatDate } from "../../common/datetime-utils";
import { CircleBulletIcon, GeneralMessageCircleIcon } from "assets/icons";
import {
  useNavigation,
  NavigationProp,
  ParamListBase,
} from "@react-navigation/native";
import { useUserState } from "../../store/user-store";
import { Icon } from "assets/components/icon";
import { SubjectOptions } from "./MessageProps";

export const ConversationItem: FunctionComponent<ConversationItemProps> = ({
  conversation,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const navigation = useNavigation<NavigationProp<ParamListBase>>();
  const { user } = useUserState(); // TODO: ask about this

  const textColor = () => {
    return conversation.patient_viewed_all_messages
      ? styles.readMessage
      : styles.unreadMessage;
  };

  const getIcon = () => {
    // eslint is wrong about this. It is possible for this to be undefined
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (SubjectOptions[conversation.subject]) {
      return SubjectOptions[conversation.subject];
    } else {
      return GeneralMessageCircleIcon;
    }
  };

  return (
    <>
      <TouchableOpacity
        style={[styles.container]}
        onPress={() => {
          navigation.navigate("conversation", {
            screen: "conversation-box",
            params: {
              conversationId: conversation.conversation_id,
              locationPatientRecordId: conversation.location_patient_id,
              locationId: user?.preferredPharmacyLocationId,
              patientViewedAllMessages:
                conversation.patient_viewed_all_messages,
              pharmacyViewedAllMessages:
                conversation.pharmacy_viewed_all_messages,
              title:
                conversation.subject +
                " - " +
                conversation.first_name +
                " " +
                conversation.last_name,
            },
          });
        }}
      >
        <View
          style={[
            styles.circles,
            conversation.patient_viewed_all_messages && styles.hideColumn,
          ]}
        >
          <CircleBulletIcon size={15} color={theme.palette.primary[600]} />
        </View>
        <View style={styles.circles}>
          <Icon icon={getIcon()} size={32} color={theme.palette.gray[400]} />
        </View>
        <View style={styles.rightBlock}>
          <View style={styles.topRow}>
            <Text style={[styles.subject, textColor()]}>
              {conversation.subject}
            </Text>
            <Text style={[styles.date, textColor()]}>
              {formatDate(conversation.most_recent_qualifying_message_date)}
            </Text>
          </View>
          <Text numberOfLines={1} style={[textColor(), styles.message]}>
            {conversation.most_recent_qualifying_message}
          </Text>
        </View>
      </TouchableOpacity>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: "row",
    marginTop: theme.getSpacing(1),
    paddingBottom: theme.getSpacing(1),
    width: "82%",
  },

  hideColumn: {
    opacity: 0,
  },

  topRow: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
  },

  unreadMessage: {
    color: theme.palette.gray[900],
  },

  readMessage: {
    color: theme.palette.gray[700],
  },

  message: {
    fontSize: 12,
  },

  circles: {
    alignSelf: "center",
    paddingRight: theme.getSpacing(1),
  },

  date: {
    width: "50%",
    textAlign: "right",
    fontSize: 12,
  },

  subject: {
    width: "50%",
    fontSize: 14,
    paddingBottom: theme.getSpacing(1),
  },

  rightBlock: {
    width: "100%",
  },
}));

interface ConversationItemProps {
  conversation: DirectMessagePatientDto;
  isViewed: boolean;
  isSelected?: boolean;
}
