import React, { FunctionComponent } from "react";
import Svg, { Path, Circle } from "react-native-svg";
import { IconProps } from "./types";

export const GoogleCircleIcon: FunctionComponent<IconProps> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg width={35} height={34} fill="none" testID={testID} color={color}>
      <Circle cx={17.5} cy={17} r={16} stroke="#fff" strokeWidth={2} />
      <Path
        fill="#fff"
        d="M24.5 17.156c0-.576-.047-.996-.15-1.431h-6.706v2.597h3.936c-.08.646-.508 1.618-1.46 2.271l-.014.087 2.12 1.61.148.015c1.349-1.221 2.127-3.018 2.127-5.15Z"
      />
      <Path
        fill="#fff"
        d="M17.643 24c1.929 0 3.548-.622 4.73-1.696l-2.254-1.71c-.603.411-1.412.7-2.476.7a4.292 4.292 0 0 1-4.063-2.91l-.084.007-2.205 1.673-.029.078A7.154 7.154 0 0 0 17.643 24Z"
      />
      <Path
        fill="#fff"
        d="M13.58 18.384A4.232 4.232 0 0 1 13.342 17c0-.482.087-.949.23-1.384l-.004-.093-2.232-1.7-.073.035A6.893 6.893 0 0 0 10.5 17c0 1.128.277 2.193.762 3.142l2.317-1.758Z"
      />
      <Path
        fill="#fff"
        d="M17.643 12.707c1.341 0 2.246.567 2.762 1.042l2.016-1.929C21.183 10.692 19.57 10 17.643 10a7.154 7.154 0 0 0-6.38 3.858l2.309 1.758a4.31 4.31 0 0 1 4.071-2.91Z"
      />
    </Svg>
  );
};
