import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Rect, Path, Defs } from "react-native-svg";
import { IconProps } from "./types";
import Group from "react-native-svg";
import ClipPath from "react-native-svg";

export const MicIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      testID={testID}
    >
      <Group clipPath="url(#clip0_10501_191965)">
        <Path
          d="M15.8337 8.3335V10.0002C15.8337 11.5473 15.2191 13.031 14.1251 14.125C13.0312 15.2189 11.5474 15.8335 10.0003 15.8335M10.0003 15.8335C8.45323 15.8335 6.9695 15.2189 5.87554 14.125C4.78157 13.031 4.16699 11.5473 4.16699 10.0002V8.3335M10.0003 15.8335V19.1668M6.66699 19.1668H13.3337M10.0003 0.833496C9.33728 0.833496 8.7014 1.09689 8.23256 1.56573C7.76372 2.03457 7.50033 2.67045 7.50033 3.3335V10.0002C7.50033 10.6632 7.76372 11.2991 8.23256 11.7679C8.7014 12.2368 9.33728 12.5002 10.0003 12.5002C10.6634 12.5002 11.2993 12.2368 11.7681 11.7679C12.2369 11.2991 12.5003 10.6632 12.5003 10.0002V3.3335C12.5003 2.67045 12.2369 2.03457 11.7681 1.56573C11.2993 1.09689 10.6634 0.833496 10.0003 0.833496Z"
          stroke="#748995"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </Group>
      <Defs>
        <ClipPath id="clip0_10501_191965">
          <Rect width="20" height="20" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
};
