import React, { FunctionComponent, useEffect } from "react";
import { Platform, View } from "react-native";
import WebView from "react-native-webview";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { Text } from "assets/components/text";
import { makeStyles, useTheme } from "assets/theme";
import { getFormContent } from "./fill-in-form-actions";
import { useFillInFormStore } from "./fill-in-form-store";

const FormIFrame: FunctionComponent<{ htmlSrc: string }> = ({ htmlSrc }) => {
  const theme = useTheme();

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        flex: 1,
      }}
    >
      <iframe
        style={{
          borderWidth: 1,
          borderStyle: "solid",
          borderColor: theme.palette.gray[500],
          borderRadius: 8,
          outline: "none",
          boxSizing: "border-box",
          boxShadow: "none",
        }}
        height="100%"
        width="100%"
        srcDoc={htmlSrc}
        id="jotformIframe"
      ></iframe>
    </View>
  );
};

const FormWebView: FunctionComponent<{
  htmlSrc: string;
  onFormSubmit?: (submissionId: string) => void;
}> = ({ htmlSrc, onFormSubmit }) => {
  const theme = useTheme();

  return (
    <View
      style={{
        width: "100%",
        height: "100%",
        paddingBottom: theme.getSpacing(1),
      }}
    >
      <WebView
        style={{
          outline: "none",
          boxSizing: "border-box",
          boxShadow: "none",
        }}
        height="100%"
        width="100%"
        source={{ html: htmlSrc }}
        // Adding a "load" event listener to the WebView that checks
        // if the loaded page is a "Thank You" page. We don't have
        // any other way of detecting form submission in WebView, so
        // it looks for the title or scripts specific to the "Thank You" page.
        injectedJavaScriptBeforeContentLoaded={`
          window.addEventListener("load", function (event) {
            if (window.document.title === "Thank you" || document.documentElement.innerHTML.includes("thankYouPageScripts.js") || documentElement.innerHTML.includes("thankyou.css")) {
              window.ReactNativeWebView.postMessage(JSON.stringify({ action: 'form-submitted', submissionId: window.submissionId }));
            }
          });
        `}
        onMessage={(event) => {
          const eventData = JSON.parse(event.nativeEvent.data);

          if (eventData.action === "form-submitted") {
            onFormSubmit && onFormSubmit(eventData.submissionId);
          }
        }}
      ></WebView>
    </View>
  );
};

export const FillInFormContent: FunctionComponent<FillInFormContentProps> = ({
  formId,
  locationId,
  locationPatientRecordId,
  helperText,
  onFormSubmit,
}) => {
  const { status, formHtmlContent } = useFillInFormStore();
  const theme = useTheme();
  const styles = useStyles();

  useEffect(() => {
    getFormContent(formId, locationId, locationPatientRecordId);
  }, [formId]);

  if (status !== "success" || !formHtmlContent) {
    return (
      <View style={styles.loadingIndicator}>
        <LoadingIndicator></LoadingIndicator>
      </View>
    );
  }

  return (
    <>
      {helperText ? (
        <Text
          style={{
            color: theme.palette.gray[700],
            marginBottom: theme.getSpacing(1),
          }}
        >
          {helperText}
        </Text>
      ) : null}

      {Platform.OS === "web" ? (
        <FormIFrame htmlSrc={formHtmlContent} />
      ) : (
        <FormWebView htmlSrc={formHtmlContent} onFormSubmit={onFormSubmit} />
      )}
    </>
  );
};

export interface FillInFormContentProps {
  formId: string;
  locationId: string;
  locationPatientRecordId: string;
  onFormSubmit: (submissionId: string) => void;
  helperText?: string;
}

const useStyles = makeStyles((theme) => ({
  loadingIndicator: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: -50,
  },
}));
