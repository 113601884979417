import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "assets/components/text";
import { View } from "react-native";
import { useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { useNavigation } from "@react-navigation/native";
import {
  AuthStackNavigationProp,
  AuthStackParamList,
} from "../../navigation/AuthNavigation";
import { getText } from "assets/localization/localization";
import { ScreenContainer } from "assets/layout";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useDeepLinkingContext } from "assets/core/deep-linking";
import * as Linking from "expo-linking";

export const RegisterConfirmation: FunctionComponent<
  PropsWithChildren<RegisterConfirmationProps>
> = () => {
  const { currentUrl } = useDeepLinkingContext();

  const theme = useTheme();
  const navigation = useNavigation<AuthStackNavigationProp>();

  const onPress = async () => {
    if (currentUrl && currentUrl !== "") {
      const { queryParams } = Linking.parse(currentUrl);
      if (queryParams) {
        navigation.navigate("intro", queryParams as never);
      } else {
        navigation.navigate("intro");
      }
    } else {
      navigation.navigate("intro");
    }
  };

  return (
    <ScreenContainer>
      <Text
        style={{
          color: theme.palette.gray[900],
          fontSize: 25,
          paddingTop: theme.getSpacing(2),
          paddingBottom: theme.getSpacing(2),
          textAlign: "center",
        }}
      >
        {getText("register-confirmation-heading")}
      </Text>
      <Text
        style={{
          color: theme.palette.gray[900],
          fontSize: 14,
          paddingTop: theme.getSpacing(2),
          paddingBottom: theme.getSpacing(2),
          textAlign: "center",
        }}
      >
        {getText("register-confirmation-body")}
      </Text>
      <View style={{ margin: theme.getSpacing(1) }}>
        <View style={{ marginBottom: theme.getSpacing(2) }}>
          <Button
            onPress={onPress}
            hierarchy="tertiary"
            logger={{ id: "register-confirmation-back-to-login-button" }}
          >
            {getText("return-to-login")}
          </Button>
        </View>
      </View>
    </ScreenContainer>
  );
};

interface RegisterConfirmationProps
  extends NativeStackScreenProps<AuthStackParamList, "register-confirmation"> {}
