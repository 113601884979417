import {
  AppointmentTypeDto,
  BookingDto,
  SubmissionDto,
} from "@digitalpharmacist/appointment-service-client-axios";
import create from "zustand";
import { ApiError, AsyncStatus } from "../../store/types";

export const useAppointmentDetailsState = create<AppointmentDetailsState>(
  () => ({
    status: "idle",
    appointmentTypeStatus: "idle",
    cancelAppointmentStatus: "idle",
    checkListStatus: "idle",
    saveSubmissionsStatus: "idle",
    refresh: false,
    showCancelAppointment: false,
    currentForm: 0,
    formSubmissions: [],
  })
);

interface AppointmentDetailsState {
  status: AsyncStatus;
  appointmentTypeStatus: AsyncStatus;
  cancelAppointmentStatus: AsyncStatus;
  checkListStatus: AsyncStatus;
  saveSubmissionsStatus: AsyncStatus;
  refresh: boolean;
  showCancelAppointment: boolean;
  currentForm: number;
  formSubmissions: SubmissionDto[];
  error?: ApiError;
  booking?: BookingDto;
  appointmentType?: AppointmentTypeDto;
  type?: "upcoming" | "past";
}
