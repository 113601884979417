import React, { FunctionComponent, PropsWithChildren } from "react";
import { GestureResponderEvent } from "react-native";
import {
  createStackNavigator,
  StackHeaderProps,
} from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";
import { NativeHeader } from "assets/components/native-header";
import { getText } from "assets/localization/localization";
import { IntegratedAddPrescription } from "./integratedAddPrescription/IntegratedAddPrescription";
import { IntegratedAllPrescriptions } from "./integratedAddPrescription/IntegratedAllPrescriptions";
import {
  hasSeenFindMedsScreen,
  isUserAuthorized,
  useUserState,
} from "../../store/user-store";
import { AddMedications } from "../medications/add-meds/AddMedications";
import { ManuallyAddPrescription } from "./manuallyEnterPrescription/ManuallyEnterPrescription";

const Stack = createStackNavigator<PrescriptionStackParamList>();

export const FindPrescriptionNavigation: FunctionComponent<
  PropsWithChildren<RefillNavigationProps>
> = ({}) => {
  const isAuthorized = useUserState((x) => isUserAuthorized(x.user));
  const userHasSeenFindMedsScreen = useUserState((x) => {
    return hasSeenFindMedsScreen(x.user);
  });
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: isAuthorized,
        header: FindPrescriptionHeader,
      }}
    >
      <Stack.Group>
        <Stack.Screen name="add-meds" component={AddMedications} />
        <Stack.Screen
          name="automatic-prescription-flow"
          component={IntegratedAddPrescription}
          options={{
            cardStyle: { flex: 1 },
            title: getText("automatic-prescription-flow"),
          }}
        />
        <Stack.Screen
          name="success-automatic-prescription-flow"
          component={IntegratedAllPrescriptions}
          options={{ title: getText("success-automatic-prescription-flow") }}
        />
        <Stack.Screen
          name="manual-prescription-flow"
          component={ManuallyAddPrescription}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

interface RefillNavigationProps {}

export type PrescriptionStackParamList = {
  "choose-prescription-flow": undefined;
  "manual-prescription-flow": undefined;
  "automatic-prescription-flow": undefined;
  "success-automatic-prescription-flow": undefined;
  "add-meds": undefined;
};

export type PrescriptionStackNavigationProp =
  StackNavigationProp<PrescriptionStackParamList>;

export const FindPrescriptionHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const routeName = props.route.name as keyof PrescriptionStackParamList;

  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  return (
    <NativeHeader
      title={getText("add-meds")}
      onBack={handleBack}
      showAction={false}
      showBack={true}
    />
  );
};
