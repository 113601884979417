import React, { FunctionComponent } from "react";
import { Image, Linking, Platform, View } from "react-native";
import { TouchableOpacity } from "react-native-gesture-handler";
import { getText } from "../../localization/localization";
import { useTheme } from "../../theme";
import { Alert } from "../alert";
import { useMapContext } from "./hooks";

export const Map: FunctionComponent<MapProps> = ({
  longitude,
  latitude,
  height = 150,
  width = 150,
  accessToken,
  zoom = 15,
  missingCoordinatesErrorMessage,
}) => {
  const theme = useTheme();
  const mapContext = useMapContext();

  const render = () => {
    const { accessToken: _contextAccessToken } = mapContext;

    if (!accessToken && !_contextAccessToken)
      return <Alert intent="error" title="Missing access token" />;

    if (!longitude || !latitude)
      <Alert
        intent="warning"
        title={missingCoordinatesErrorMessage ?? "Missing coordinates"}
      />;

    return (
      <View style={{ height, width }}>
        <TouchableOpacity
          testID={MapTestIDs.map}
          onPress={() => openMap(longitude!, latitude!)}
          accessibilityRole="button"
          accessibilityLabel={getText("open-map")}
        >
          <Image
            style={{
              height,
              width,
              borderRadius: theme.roundness,
              borderWidth: 2,
              borderColor: theme.palette.gray[200],
            }}
            source={{
              uri:
                "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+cc3333(" +
                longitude +
                "," +
                latitude +
                ")/" +
                longitude +
                "," +
                latitude +
                "," +
                zoom +
                ",0/" +
                height +
                "x" +
                width +
                "@2x?access_token=" +
                (accessToken ?? _contextAccessToken),
            }}
          />
        </TouchableOpacity>
      </View>
    );
  };

  return render();
};

const openMap = (longitude: number, latitude: number) => {
  const scheme = Platform.select({
    ios: "maps:0,0?q=",
    android: "geo:0,0?q=",
  });
  const url = Platform.select({
    ios: scheme + encodeURIComponent(latitude + "," + longitude),
    android: scheme + encodeURIComponent(latitude + "," + longitude),
    default:
      "https://www.google.com/maps/search/?api=1&query=" +
      encodeURIComponent(latitude + "," + longitude),
  });
  Linking.openURL(url);
};

export interface MapProps {
  longitude?: number;
  latitude?: number;
  height?: number;
  width?: number;
  accessToken?: string;
  zoom?: number;
  missingCoordinatesErrorMessage?: string;
}

export const MapTestIDs = {
  map: "map",
};
