import type { StackNavigationProp } from "@react-navigation/stack";
import {
  StackHeaderProps,
  createStackNavigator,
} from "@react-navigation/stack";
import { Account } from "./Account";
import { NativeHeader } from "assets/components/native-header";
import { CloseIcon } from "assets/icons";
import { getText } from "assets/localization/localization";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { Platform } from "react-native";
import { Medications } from "../medications/Medications";
import { FeedbackSuccessMessage } from "./patient/FeedbackSuccessMessage";
import { GiveFeedback } from "./patient/GiveFeedback";
import { MedicalInfo } from "./patient/MedicalInfo";
import { MedicationScanner } from "./patient/MedicationScanner";
import { PatientInfo } from "./patient/PatientInfo";
import { PatientInsurance } from "./patient/PatientInsurance";
import { PatientPreferences } from "./patient/PatientPreferences";

const Stack = createStackNavigator<AccountStackParamList>();

export const AccountNavigation: FunctionComponent<
  PropsWithChildren<AccountNavigationProps>
> = ({}) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: !(Platform.OS === "web"),
        header: AccountNavigationHeader,
      }}
    >
      <Stack.Group>
        <Stack.Screen
          name="home"
          component={Account}
          options={{ title: getText("home") }}
        />
        <Stack.Screen
          name="patient-info"
          component={PatientInfo}
          options={{ title: getText("patient-info") }}
        />
        <Stack.Screen
          name="patient-insurance"
          component={PatientInsurance}
          options={{ title: getText("patient-insurance") }}
        />
        <Stack.Screen
          name="barcode"
          component={MedicationScanner}
          options={{ title: getText("barcode") }}
        />
        <Stack.Screen
          name="medical-info"
          component={MedicalInfo}
          options={{ title: getText("medical-info") }}
        />
        <Stack.Screen
          name="patient-preferences"
          component={PatientPreferences}
          options={{ title: getText("patient-preferences") }}
        />
        <Stack.Screen
          name="feedback"
          component={GiveFeedback}
          options={{ title: getText("feedback") }}
        />

        <Stack.Screen
          name="feedback-success"
          component={FeedbackSuccessMessage}
          options={{ title: getText("feedback-success") }}
        />

        <Stack.Screen
          name="medications"
          component={Medications}
          options={{ title: getText("medications") }}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

interface AccountNavigationProps {}

export type AccountStackParamList = {
  home: undefined;
  "personal-information": undefined;
  medical: undefined;
  settings: undefined;
  "patient-info": undefined;
  "patient-insurance": undefined;
  barcode: undefined;
  feedback: undefined;
  "feedback-success": undefined;
  "medical-info": undefined;
  "patient-preferences": undefined;
  medications: undefined;
  "people-under-care": undefined;
};

export type AccountStackNavigationProp =
  StackNavigationProp<AccountStackParamList>;

export const AccountNavigationHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const routeName = props.route.name as keyof AccountStackParamList;

  const handleBack = () => {
    props.navigation.goBack();
  };

  // TODO: follow up cards will make this dynamic based on the route as we add other routes
  return (
    <NativeHeader
      actionIcon={CloseIcon}
      actionLabel={getText("cancel")}
      onActionPress={handleBack}
      onBack={handleBack}
      showAction={true}
      showBack={false}
      title={getText("account")}
    />
  );
};
