import { Button } from "assets/components/button";
import { useTheme } from "assets/theme";
import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import { Barcode } from "../../../camera/barcode/Barcode";
import { View } from "react-native";
import { Text } from "assets/components/text";
import { getText } from "assets/localization/localization";

export const MedicationScanner: FunctionComponent<
  PropsWithChildren<MedicationScannerProps>
> = (props) => {
  const theme = useTheme();
  const [isScannerVisible, setIsScannerVisible] = useState(false);
  const [scannedValue, setScannedValue] = useState("none");

  const handleScan = (value: string) => {
    setScannedValue(value);
    setIsScannerVisible(false);
  };

  const handleOpenScanner = () => {
    setIsScannerVisible(true);
  };

  const handleScannerClose = () => {
    setIsScannerVisible(false);
  };

  return (
    <View style={{ margin: theme.getSpacing(2) }}>
      <Text>
        {getText("scanned")}: {scannedValue}
      </Text>
      <Button
        hierarchy="primary"
        onPress={handleOpenScanner}
        logger={{ id: "medication-scan-barcode-button" }}
      >
        {getText("scan-barcode")}
      </Button>
      <Barcode
        onScan={handleScan}
        isVisible={isScannerVisible}
        onClose={handleScannerClose}
      />
    </View>
  );
};

interface MedicationScannerProps {}
