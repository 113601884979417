import React, { FunctionComponent, useEffect } from "react";
import { Text } from "assets/components/text";
import { TextField } from "assets/components/text-field";
import { useForm } from "assets/form";
import { View } from "react-native";
import { useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { useResetPasswordState } from "./reset-password-store";
import { ResetPasswordForm } from "./reset-password-actions";
import { useNavigation } from "@react-navigation/native";
import {
  AuthStackNavigationProp,
  ResetPasswordProps,
} from "../../navigation/AuthNavigation";
import { resetPassword } from "./reset-password-actions";
import { ResetPasswordTestIDs } from "./ResetPasswordTestIDs";
import { PasswordRules } from "../../common/PasswordRules";
import { getText } from "assets/localization/localization";
import { ScreenContainer, Form } from "assets/layout";
import * as Linking from "expo-linking";
import { isPassword } from "@digitalpharmacist/validation-dp";

export const ResetPassword: FunctionComponent<ResetPasswordProps> = ({
  route,
}) => {
  const theme = useTheme();
  const methods = useForm<ResetPasswordForm>({
    defaultValues: {
      password: "",
      passwordConfirm: "",
      email: "",
    },
  });

  const passwordValue = methods.watch("password");
  const { status, error, updateData, data } = useResetPasswordState();
  const navigation = useNavigation<AuthStackNavigationProp>();
  const { email, confirmationCode } = route.params;

  useEffect(() => {
    methods.setValue("email", email);
    methods.setValue("confirmationCode", confirmationCode);
  }, [email, confirmationCode]);

  const handleSubmit = async () => {
    updateData({ ...data, email: methods.getValues().email });
    resetPassword(methods.getValues(), navigation);
  };

  const handleGoToLogin = async () => {
    navigation.navigate("intro");
  };

  return (
    <ScreenContainer>
      <View>
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 30,
            paddingTop: theme.getSpacing(2),
            paddingBottom: theme.getSpacing(1),
            textAlign: "center",
          }}
        >
          {getText("create-new-password")}
        </Text>
        <Text
          style={{
            color: theme.palette.gray[600],
            fontSize: 16,
            paddingBottom: theme.getSpacing(2),
            textAlign: "center",
          }}
        >
          {getText("please-enter-your-new-password")}
        </Text>
      </View>
      <Form methods={methods}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              testID={ResetPasswordTestIDs.passwordInput}
              label={getText("new-password")}
              name="password"
              rules={{
                required: getText("password-is-required"),
                validate: {
                  value: () => {
                    return isPassword(methods.getValues().password)
                      ? true
                      : getText("password-entry-does-not-meet-criteria");
                  },
                },
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              testID={ResetPasswordTestIDs.passwordConfirmInput}
              label={getText("confirm-new-password")}
              name="passwordConfirm"
              rules={{
                required: getText("password-is-required"),
                validate: {
                  value: () => {
                    return methods.getValues().password ===
                      methods.getValues().passwordConfirm
                      ? true
                      : getText("passwords-must-match");
                  },
                },
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Content>
          <PasswordRules passwordValue={passwordValue}></PasswordRules>
        </Form.Content>
        <Form.Actions>
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <Button
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === "loading"}
              logger={{ id: "reset-password-submit-button" }}
            >
              {getText("change-password")}
            </Button>
          </View>
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <Button
              onPress={handleGoToLogin}
              hierarchy="tertiary"
              logger={{ id: "reset-password-back-to-login-button" }}
            >
              {getText("login-with-email")}
            </Button>
          </View>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};
