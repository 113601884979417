import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path } from "react-native-svg";
import { IconProps } from "./types";

export const BarcodeIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={0.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M2.6 0h1.9v1.2H2.6c-.9 0-1.6 1-1.6 2.1v2.5H0V3.3C0 1.5 1.2 0 2.6 0zM23 3.3v2.5h1V3.3C24 1.5 22.8 0 21.4 0h-1.9v1.2h1.9c.9 0 1.6 1 1.6 2.1zM19.5 22.8h1.9c.9 0 1.6-.9 1.6-2.1V19h1v1.7c0 1.8-1.2 3.3-2.6 3.3h-1.9v-1.2zM1 20.7V19H0v1.7C0 22.5 1.2 24 2.6 24h1.9v-1.2H2.6c-.9 0-1.6-1-1.6-2.1zM3.9 4.1h-.7v5.8h.6V4.1zM19.5 4.1h.6v5.8h-.6V4.1zM11 4.1h-.6v5.8h.6V4.1zM17.5 4.1h.6v5.8h-.6V4.1zM9.7 4.1H8.4v5.8h1.3V4.1zM15.6 4.1h1.3v5.8h-1.3V4.1zM7.1 4.1H5.2v5.8h1.9V4.1zM12.3 4.1h1.9v5.8h-1.9V4.1zM3.9 14.1h-.7v5.8h.6v-5.8zM19.5 14.1h.6v5.8h-.6v-5.8zM11 14.1h-.6v5.8h.6v-5.8zM17.5 14.1h.6v5.8h-.6v-5.8zM9.7 14.1H8.4v5.8h1.3v-5.8zM15.6 14.1h1.3v5.8h-1.3v-5.8zM7.1 14.1H5.2v5.8h1.9v-5.8zM12.3 14.1h1.9v5.8h-1.9v-5.8zM1.9 11.2c-.4 0-.6.4-.6.8s.3.8.6.8H22c.4 0 .6-.4.6-.8s-.3-.8-.6-.8H1.9z"
        fill="#475467"
      />
    </Svg>
  );
};
