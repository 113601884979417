import { ScreenContainer } from "assets/layout";
import { makeStyles } from "assets/theme";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { Button } from "assets/components/button";
import { View, StyleSheet } from "react-native";
import { getText } from "assets/localization/localization";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Text } from "assets/components/text/Text";
import { RefillStackParamList } from "./RefillNavigation";
import { RefillTestIDs } from "./RefillTestIDs";

export const RefillOrderDetails: FunctionComponent<
  PropsWithChildren<RefillOrderDetailsProps>
> = ({ navigation }) => {
  const styles = useStyles();
  const handleOnPressDone = () => {
    navigation.navigate("refill-confirmation");
  };

  return (
    <ScreenContainer>
      <Text style={styles.textTitle}>{RefillOrderDetails.name}</Text>
      <View>
        <Button
          hierarchy="primary"
          onPress={handleOnPressDone}
          testID={RefillTestIDs.refillOrderDetailsSubmit}
          logger={{ id: RefillTestIDs.refillOrderDetailsSubmit }}
        >
          {getText("next")}
        </Button>
      </View>
    </ScreenContainer>
  );
};

export interface RefillOrderDetailsProps
  extends NativeStackScreenProps<
    RefillStackParamList,
    "refill-order-details"
  > {}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginTop: theme.getSpacing(3),
  },
}));
