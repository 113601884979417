import { LandingHeader } from "../../components/landing-header";
import { ScreenContainer } from "assets/layout";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from "react";
import { Platform, View, Text } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { useUserState } from "../../store/user-store";
import { useAppStateStore } from "../../store/app-store";
import { PreferredStoreInformation } from "../../components/store-selector/PreferredHomeLocationInformation";
import pharmacyService from "../../api/pharmacy-service";
import {
  PharmacyLocationDto,
  TimeRangeDto,
} from "@digitalpharmacist/pharmacy-service-client-axios";
import { StoreSelectorHandler } from "../../components/store-selector/types";
import { StoreSelector } from "../../components/store-selector";
import { ListItemLink, ListMenu } from "assets/components/list-menu";
import { getText } from "assets/localization/localization";
import {
  useNavigation,
  ParamListBase,
  NavigationProp,
} from "@react-navigation/native";
import { Icon } from "assets/components/icon";
import {
  HomeMessagesIcon,
  HomeMedicationsIcon,
  HomeAppointmentsIcon,
} from "assets/icons";
import { NewMessage } from "../messages/NewMessage";
import { NewMessageHandler } from "../messages/MessageProps";
import { Image } from "react-native";
import { usePatientUnderCareState } from "../account/patient/patient-store";
import { buildMessageList } from "../messages/messages-actions";
import { DirectMessagePatientDto } from "@digitalpharmacist/unified-communications-service-client-axios";

export const Dashboard: FunctionComponent<PropsWithChildren<DashboardProps>> =
  () => {
    const theme = useTheme();
    const styles = useStyles();
    const { user, updateUser } = useUserState();
    const [preferredLocation, setPreferredLocation] =
      useState<PharmacyLocationDto>();
    const [locationHours, setLocationHours] = useState<TimeRangeDto>();
    const storeSelectorRef = useRef<StoreSelectorHandler>(null);
    const { pharmacyName, isMobileOrTablet, stores, getStores } =
      useAppStateStore();

    const navigation = useNavigation<NavigationProp<ParamListBase>>();

    if (!stores) {
      getStores();
    }

    const handleOnChangePress = () => {
      storeSelectorRef.current?.show();
    };

    const handleOnLocationChanged = (value: PharmacyLocationDto) => {
      updateUser({ preferredPharmacyLocationId: value.id });
    };

    useEffect(() => {
      void (async () => {
        if (!user?.preferredPharmacyLocationId || !user.patientRecordId) return;

        const location = stores.find(
          (location) => location.id === user.preferredPharmacyLocationId!
        );
        setPreferredLocation(location);
        const hours = await pharmacyService.findLocationHours(
          user.preferredPharmacyLocationId
        );
        const date = new Date();
        const selectedPreferredHours = hours.find(
          (x) => x.type === "store" && x.day! - 1 === date.getDay()
        );
        setLocationHours(selectedPreferredHours);
      })();
    }, [user?.preferredPharmacyLocationId, stores]);

    const newMessageRef = React.useRef<NewMessageHandler>(null);
    const { recordsUnderCare } = usePatientUnderCareState();
    const [conversations, setConversations] =
      useState<DirectMessagePatientDto[]>();

    const onMessageCreate = async () => {
      await buildMessageList(setConversations);
    };

    return (
      <View style={{ flex: 1 }}>
        {(Platform.OS !== "web" || isMobileOrTablet) && (
          <LandingHeader
            pharmacyName={pharmacyName}
            patientName={user?.firstName}
          />
        )}
        <ScreenContainer style={styles.screenContainer}>
          <ListMenu style={styles.container}>
            <ListItemLink
              onPress={() => {
                navigation.navigate("medications");
              }}
            >
              <>
                <View style={styles.listItemContainer}>
                  <View style={styles.iconContainer}>
                    <Icon icon={HomeMedicationsIcon} size={53} />
                  </View>
                  <View>
                    <View style={styles.container}>
                      <Text style={styles.heading}>
                        {getText("medications")}
                      </Text>
                    </View>
                    <Text style={styles.subHeading}>
                      {getText("home-medications-subheading")}
                    </Text>
                  </View>
                </View>
              </>
            </ListItemLink>
            <ListItemLink
              onPress={() => {
                navigation.navigate("messages");
              }}
            >
              <>
                <View style={styles.listItemContainer}>
                  <View style={styles.iconContainer}>
                    <Icon icon={HomeMessagesIcon} size={53} />
                  </View>
                  <View>
                    <View style={styles.container}>
                      <Text style={styles.heading}>{getText("messages")}</Text>
                    </View>
                    <Text style={styles.subHeading}>
                      {getText("home-messages-subheading")}
                    </Text>
                  </View>
                </View>
              </>
            </ListItemLink>
            <ListItemLink
              onPress={() => {
                navigation.navigate("appointments");
              }}
            >
              <>
                <View style={styles.listItemContainer}>
                  <View style={styles.iconContainer}>
                    <Icon icon={HomeAppointmentsIcon} size={53} />
                  </View>
                  <View>
                    <View style={styles.container}>
                      <Text style={styles.heading}>
                        {getText("appointments")}
                      </Text>
                    </View>
                    <Text style={styles.subHeading}>
                      {getText("home-appointments-subheading")}
                    </Text>
                  </View>
                </View>
              </>
            </ListItemLink>
            <ListItemLink
              onPress={() => {
                navigation.navigate("people-under-care");
              }}
            >
              <>
                <View style={styles.listItemContainer}>
                  <View style={styles.iconContainer}>
                    {/*
                      Using Image instead of Icon because of some issues with the Mask component with our current version of React Native SVG.
                      We will be updating Expo soon, and will likely be updating this package when we do. Will fix this issue then.
                      */}
                    <Image
                      source={require("../../../assets/home-puc.png")}
                      style={{ width: 53, height: 53 }}
                    />
                  </View>
                  <View>
                    <View style={styles.container}>
                      <Text style={styles.heading}>
                        {getText("people-under-your-care")}
                      </Text>
                    </View>
                    {recordsUnderCare && recordsUnderCare.length > 0 ? (
                      <Text
                        style={{
                          color: theme.palette.gray[700],
                        }}
                      >
                        {recordsUnderCare
                          .slice(0, Math.min(3, recordsUnderCare.length))
                          .map(
                            (record: {
                              record_under_care: { first_name: any };
                            }) => record.record_under_care.first_name
                          )
                          .join(", ")}
                        {recordsUnderCare.length > 3 &&
                          `, +${recordsUnderCare.length - 3} ${getText(
                            "more"
                          )}`}
                      </Text>
                    ) : (
                      <Text style={styles.subHeading}>
                        {getText("home-puc-subheading")}
                      </Text>
                    )}
                  </View>
                </View>
              </>
            </ListItemLink>
            <ListItemLink
              onPress={() => {
                navigation.navigate("account");
              }}
              showDivider={false}
            >
              <>
                <View style={styles.listItemContainer}>
                  <View style={styles.iconContainer}>
                    {/*
                      Using Image instead of Icon because of some issues with the Mask component with our current version of React Native SVG.
                      We will be updating Expo soon, and will likely be updating this package when we do. Will fix this issue then.
                      */}
                    <Image
                      source={require("../../../assets/home-account.png")}
                      style={{ width: 53, height: 53 }}
                    />
                  </View>
                  <View>
                    <View style={styles.container}>
                      <Text style={styles.heading}>{getText("account")}</Text>
                    </View>
                    <Text style={styles.subHeading}>
                      {getText("home-account-subheading")}
                    </Text>
                  </View>
                </View>
              </>
            </ListItemLink>
          </ListMenu>
          {stores && preferredLocation && Platform.OS !== "web" && (
            <>
              <View style={styles.locationContainer}>
                <PreferredStoreInformation
                  item={preferredLocation}
                  onChangePress={handleOnChangePress}
                  openingHours={locationHours}
                  showChangeButton={stores.length > 1}
                  onSendMessagePress={() => {
                    newMessageRef.current?.show();
                  }}
                />
                <NewMessage
                  ref={newMessageRef}
                  onMessageCreate={onMessageCreate}
                />
                <StoreSelector
                  ref={storeSelectorRef}
                  options={stores}
                  defaultOption={stores.find(
                    (x) => x.id === user?.preferredPharmacyLocationId
                  )}
                  onChange={handleOnLocationChanged}
                  storeInformationShown={false}
                />
              </View>
            </>
          )}
        </ScreenContainer>
      </View>
    );
  };

interface DashboardProps {}

const useStyles = makeStyles((theme) => ({
  screenContainer: {
    marginTop: theme.getSpacing(0.5),
  },
  container: {
    marginBottom: theme.getSpacing(1),
  },
  iconContainer: {
    marginRight: theme.getSpacing(2),
  },
  locationContainer: {
    backgroundColor: theme.palette.gray[50],
    paddingTop: theme.getSpacing(3),
  },
  listItemContainer: {
    flexDirection: "row",
  },
  subHeading: {
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(0.5),
  },
  heading: {
    color: theme.palette.black,
    fontSize: 18,
    fontWeight: "600",
  },
}));
