import patientService from "../../api/patient-service";
import { useUserState } from "../../store/user-store";
import { SubjectOptionsEnum } from "./MessageProps";
import { useMessagesState } from "./messages-store";
import UnifiedCommsService from "../../api/unified-comms-service";
import { SetStateAction } from "react";
import { DirectMessagePatientDto } from "@digitalpharmacist/unified-communications-service-client-axios";

export const setInitialSubject = (
  initialSubject: SubjectOptionsEnum | undefined
) => {
  useMessagesState.setState({ initialSubject });
};

export const setShowMessageModal = (showModal: boolean) => {
  useMessagesState.setState({ showModal });
};

export const buildMessageList = async (setConversations: {
  (value: SetStateAction<DirectMessagePatientDto[] | undefined>): void;
}) => {
  const user = useUserState.getState().user;
  if (user?.patientRecordId) {
    const patientRecord = await patientService.findPatientRecord(
      user.patientRecordId
    );

    const locationPatientRecord = patientRecord.location_patient_records.find(
      (record) => record.location_id === user.preferredPharmacyLocationId
    );

    if (locationPatientRecord) {
      const conversationList =
        await UnifiedCommsService.getAllConversationsByPatientAndLocation(
          locationPatientRecord.location_id,
          locationPatientRecord.id
        );

      setConversations(conversationList);
    }
  }
};
