//TODO decide if we are going to implement UI for meds flow prior to this view
import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "assets/components/text";
import { FlatList, View } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { getText } from "assets/localization/localization";
import { Form, ScreenContainer } from "assets/layout";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { usePatientUnderCareState } from "./patient-store";
import { useAppStateStore } from "../../../store/app-store";
import { PatientUnderCareStackParamList } from "./PatientUnderCareNavigation";
import {
  useNavigation,
  NavigationProp,
  ParamListBase,
} from "@react-navigation/native";

export const AddMinorOrPatientUnderCareRequestSubmitted: FunctionComponent<
  PropsWithChildren<AddMinorOrPatientUnderCareRequestSubmittedProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const profileNav = useNavigation<NavigationProp<ParamListBase>>();
  const { status, error, data } = usePatientUnderCareState();
  const { pharmacyName } = useAppStateStore();
  const onPress = () => {
    navigation.navigate("add-patient-under-care-info");
  };

  const handleDone = () => {
    navigation.navigate("patient-under-care-list");
  };

  return (
    <ScreenContainer>
      <View style={styles.headerContainer}>
        <Text style={styles.mainTitle}>
          {getText("add-patient-request-submit")}
        </Text>
      </View>
      <View style={styles.marginTop}>
        <Form.Alert
          title={getText("patient-record-created", {
            firstName: data?.first_name,
            lastName: data?.last_name,
          })}
          intent="success"
          visible={true}
        />
      </View>
      <View style={styles.headerContainer}>
        <Text style={styles.mainTitle}>
          {getText("as-an-authorized-caregiver")}
        </Text>
      </View>
      <View style={styles.marginTop}>
        <FlatList
          data={[
            {
              key: getText("manage-prescriptions-digitally", {
                firstName: data?.first_name,
              }),
            },
            { key: getText("order-refills", { firstName: data?.first_name }) },
            {
              key: getText("pick-up-prescriptions-for", {
                firstName: data?.first_name,
              }),
            },
            {
              key: getText("discuss-care-with", {
                firstName: data?.first_name,
                pharmacyName: pharmacyName,
              }),
            },
            {
              key: getText("manage-health-and-clinic", {
                firstName: data?.first_name,
              }),
            },
          ]}
          renderItem={({ item }) => {
            return (
              <View style={{ marginBottom: 10 }}>
                <Text style={styles.modalText}>{`\u2022 ${item.key}`}</Text>
              </View>
            );
          }}
        />
      </View>
      <View>
        <Text style={[styles.mainTitle, styles.marginTop]}>
          {getText("access-validity-puc", { firstName: data?.first_name })}
        </Text>
      </View>
      <View>
        <Text style={styles.modalText}>{getText("update-puc-list")}</Text>
      </View>
      <View
        style={{
          marginTop: theme.getSpacing(2),
        }}
      >
        <View style={{ marginTop: theme.getSpacing(1) }}>
          <View style={styles.marginBottom}>
            <Button
              onPress={onPress}
              hierarchy="primary"
              logger={{
                id: "add-patient-under-care-add-another-person-button",
              }}
              loading={status === "loading"}
            >
              {getText("add-another-person")}
            </Button>
          </View>
          <View style={styles.marginBottom}>
            <Button
              onPress={handleDone}
              hierarchy="secondary"
              logger={{ id: "add-patient-under-care-done-button" }}
              loading={status === "loading"}
            >
              {getText("done")}
            </Button>
          </View>
        </View>
      </View>
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  mainTitle: {
    marginBottom: theme.getSpacing(1),
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "600",
  },
  marginBottom: {
    marginBottom: theme.getSpacing(2),
  },
  modalText: {
    lineHeight: 24,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: "400",
  },
  marginTop: {
    marginTop: theme.getSpacing(2),
  },
}));

type AddMinorOrPatientUnderCareRequestSubmittedProps = NativeStackScreenProps<
  PatientUnderCareStackParamList,
  "add-patient-under-care-confirmation"
>;
