import React, { FunctionComponent, PropsWithChildren } from "react";
import { useTheme } from "assets/theme";
import { Text } from "assets/components/text";
import { useForm } from "assets/form";
import { Button } from "assets/components/button";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { PatientPreferencesForm, updatePatientIntake } from "./patient-actions";
import { AccountStackParamList } from "../AccountNavigation";
import { getText } from "assets/localization/localization";
import { CheckboxField } from "assets/components/checkbox";
import { usePatientIntakeState } from "./patient-store";
import { Form, ScreenContainer } from "assets/layout";
import { View } from "react-native";

export const PatientPreferences: FunctionComponent<
  PropsWithChildren<PatientPreferencesProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const methods = useForm<PatientPreferencesForm>({
    defaultValues: {
      appNotifications: true,
      automatedVoiceCall: false,
      email: false,
      textMessage: false,
    },
  });

  const { status, error } = usePatientIntakeState();

  const handleSubmit = async () => {
    updatePatientIntake(methods.getValues());
  };

  return (
    <ScreenContainer>
      <View
        style={{
          borderBottomColor: theme.palette.gray[300],
          borderBottomWidth: 1,
          marginBottom: theme.getSpacing(4),
          marginTop: theme.getSpacing(2),
        }}
      >
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 14,
            paddingTop: theme.getSpacing(2),
            paddingBottom: theme.getSpacing(2),
          }}
        >
          {getText("set-your-preferences", { currentStep: 1, totalSteps: 3 })}
        </Text>
      </View>
      <Form methods={methods}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Content>
          <Text>{getText("prescriptions-communications")}</Text>
        </Form.Content>
        <Form.Row compact={true}>
          <Form.Column>
            <CheckboxField name="prescriptionEmail" label="Email" />
          </Form.Column>
        </Form.Row>
        <Form.Row compact={true}>
          <Form.Column>
            <CheckboxField
              name="prescriptionAutomatedVoiceCall"
              label={getText("automated-voice-call")}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row compact={true}>
          <Form.Column>
            <CheckboxField
              name="prescriptionTextMessage"
              label={getText("text-message")}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <CheckboxField
              name="prescriptionAppNotifications"
              label={getText("app-notifications")}
            />
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <Button
            onPress={handleSubmit}
            hierarchy="primary"
            loading={status === "loading"}
            logger={{ id: "patient-preferences-submit-button" }}
          >
            {getText("next")}
          </Button>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

interface PatientPreferencesProps
  extends NativeStackScreenProps<
    AccountStackParamList,
    "patient-preferences"
  > {}
