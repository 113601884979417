import React, { PropsWithChildren } from "react";
import defaultTheme from "../theme";
import { Provider } from "react-native-paper";

export type ThemeContextType = {
  theme: ReactNativePaper.Theme;
  setTheme: (override: Partial<ReactNativePaper.Theme>) => void;
};

export type ThemeProviderProp = {
  theme?: ReactNativePaper.Theme;
};

export const ThemeContext = React.createContext<ThemeContextType>({
  theme: defaultTheme as ReactNativePaper.Theme,
  setTheme: () => {},
});

export const ThemeConsumer = ThemeContext.Consumer;

export const ThemeProvider: React.FunctionComponent<
  PropsWithChildren<ThemeProviderProp>
> = ({ children, theme }) => {
  const [currentTheme, setCurrentTheme] =
    React.useState<ReactNativePaper.Theme>(theme ?? defaultTheme);

  const setTheme = (override: Partial<ReactNativePaper.Theme>) => {
    setCurrentTheme((curr) => ({
      ...curr,
      ...override,
    }));
  };

  return (
    <ThemeContext.Provider value={{ theme: currentTheme, setTheme: setTheme }}>
      <Provider theme={currentTheme}>{children}</Provider>
    </ThemeContext.Provider>
  );
};
