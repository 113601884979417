import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { View, ScrollView } from "react-native";
import { Divider } from "react-native-paper";
import { useForm } from "react-hook-form";
import moment from "moment";
import { TextField } from "assets/components/text-field";
import { Text } from "assets/components/text/Text";
import { makeStyles, useTheme } from "assets/theme";
import { Form, ScreenContainer } from "assets/layout";
import { Alert } from "assets/components/alert";
import { PrescriptionStackParamList } from "../FindPrescriptionNavigation";
import { Barcode } from "../../../camera/barcode/Barcode";
import { useAppStateStore } from "../../../store/app-store";
import { StoreSelector } from "../../../components/store-selector";
import { Button } from "assets/components/button";
import { FindPrescriptionFlowTestIDs } from "../FindPrescriptionFlowTestIDs";
import { getText } from "assets/localization/localization";
import { MapSize } from "../../../components/store-selector/types";
import { useUserState } from "../../../store/user-store";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { useAddPrescriptionStore } from "../add-prescription-store";
import patientService from "../../../api/patient-service";
import { InitLocationPatientRecordDto } from "@digitalpharmacist/patient-service-client-axios";
import {
  getPatientRecord,
  patientRecordAddPrescription,
  resetAddPrescriptionState,
} from "../add-prescription-actions";

// TODO: KD: don't like this function to live here
const formatDateOfBirthToUI = (dateOfBirth: string): string => {
  return moment(dateOfBirth).format("MM/DD/YYYY");
};

const formatDateOfBirthToSystem = (dateOfBirth: string): string => {
  return moment(dateOfBirth, "MM/DD/YYYY").format("YYYY-MM-DD");
};

export const IntegratedAddPrescription: FunctionComponent<
  PropsWithChildren<IntegratedAddPrescriptionProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { user, updateUser } = useUserState();
  const { stores, getStores } = useAppStateStore();
  const { status, patientRecord, locationPatientRecordId, error } =
    useAddPrescriptionStore();

  const [isScannerVisible, setIsScannerVisible] = useState(false);
  const [scannedValue, setScannedValue] = useState("");
  const [selectedStore, setSelectedStore] = useState<PharmacyLocationDto>();

  const hasError = status === "error";

  const methods = useForm<FindPrescriptionForm>({
    defaultValues: {
      rxNumber: "",
      lastName: "",
      dateOfBirth: "",
    },
  });

  useEffect(() => {
    if (!patientRecord) return;

    methods.setValue("lastName", patientRecord.last_name);
    methods.setValue(
      "dateOfBirth",
      formatDateOfBirthToUI(patientRecord.date_of_birth)
    );
  }, [patientRecord]);

  useEffect(() => {
    if (!stores.length) void getStores(); // Load stores if not loaded
    if (!user?.preferredPharmacyLocationId) return;

    const selectedStore = stores.find(
      (store) => store.id === user.preferredPharmacyLocationId
    );

    setSelectedStore(selectedStore);
  }, [stores, user?.preferredPharmacyLocationId]);

  useEffect(() => {
    if (!selectedStore?.id) return;

    updateUser({ preferredPharmacyLocationId: selectedStore.id });
  }, [selectedStore]);

  useEffect(() => {
    if (!user?.patientRecordId) return;

    void getPatientRecord(user.patientRecordId);
  }, [user?.patientRecordId]);

  useEffect(() => {
    if (!locationPatientRecordId) return;

    navigation.navigate("success-automatic-prescription-flow");
  }, [locationPatientRecordId]);

  const handleScan = (value: string) => {
    setScannedValue(value);
    setIsScannerVisible(false);
  };

  const handleOpenScanner = () => {
    setIsScannerVisible(true);
  };

  const handleScannerClose = () => {
    setIsScannerVisible(false);
  };

  const setFindMedsScreenAsSeen = () => {
    updateUser({ hasSeenFindMedsScreen: true });
  };

  const handleSubmit = () => {
    if (!selectedStore?.id) return;

    void patientRecordAddPrescription({
      location_id: selectedStore.id,
      rx_number: methods.getValues().rxNumber,
      last_name: methods.getValues().lastName,
      date_of_birth: formatDateOfBirthToSystem(
        methods.getValues().dateOfBirth || ""
      ),
    });
  };

  const handleSkip = async () => {
    if (!user?.patientRecordId) return;
    if (!user.preferredPharmacyLocationId) return;

    const initLocationPatientRecordDto: InitLocationPatientRecordDto = {
      location_id: user.preferredPharmacyLocationId,
    };

    if (!user.hasSeenFindMedsScreen)
      await patientService.patientRecordInitLocationPatientRecord(
        user.patientRecordId,
        initLocationPatientRecordDto
      );

    setFindMedsScreenAsSeen();
    resetAddPrescriptionState();
  };

  return (
    // TODO: replace ScreenContainer with SafeScreenContainer
    <ScreenContainer style={styles.root}>
      <ScrollView style={{ flex: 1 }}>
        {hasError && (
          <Alert
            intent={"error"}
            useTriangleIcon
            title={getText("unable-to-locate-your-prescription-alert-title")}
            description={error?.message}
          />
        )}
        <Text style={styles.textTitle}>
          {getText("retrieve-your-prescriptions")}
        </Text>
        <Divider />
        <Text style={styles.descriptionText}>
          {getText("integrated-prescription-flow-description")}
        </Text>
        <Text style={styles.title}>
          {getText("prescription-information")} *
        </Text>
        <Form methods={methods}>
          <Form.Row>
            <Form.Column>
              <View style={{ marginBottom: theme.getSpacing(1) }}>
                <TextField
                  type="barcode"
                  label={getText("rx-number")}
                  name="rxNumber"
                  handleOpenScanner={handleOpenScanner}
                  onSubmit={methods.handleSubmit(handleSubmit)}
                  rules={{
                    required: getText("rx-number-is-required"),
                  }}
                  value={scannedValue}
                  testID={FindPrescriptionFlowTestIDs.rxNumberTextField}
                />
              </View>
              {hasError && (
                <>
                  <View style={{ marginBottom: theme.getSpacing(1) }}>
                    <TextField
                      type="text"
                      label={getText("patient-last-name")}
                      name="lastName"
                      onSubmit={methods.handleSubmit(handleSubmit)}
                      rules={{
                        required: getText("patient-last-name-is-required"),
                      }}
                      testID={FindPrescriptionFlowTestIDs.lastNameTextField}
                    />
                  </View>
                  <View style={{ marginBottom: theme.getSpacing(1) }}>
                    <TextField
                      type="date"
                      label={getText("patient-dob")}
                      name="dateOfBirth"
                      onSubmit={methods.handleSubmit(handleSubmit)}
                      rules={{
                        required: getText("patient-dob-is-required"),
                      }}
                      testID={FindPrescriptionFlowTestIDs.dobNameTextField}
                    />
                  </View>
                </>
              )}
            </Form.Column>
          </Form.Row>
          <View>
            <Text style={styles.textTitle}>{getText("location")}</Text>
          </View>
          <View style={{ marginBottom: theme.getSpacing(2) }}>
            <Divider />
          </View>
          <Form.Row>
            <Form.Column>
              <StoreSelector
                options={stores}
                defaultOption={selectedStore}
                onChange={setSelectedStore}
                mapProps={{ size: MapSize.md }}
              />
            </Form.Column>
          </Form.Row>
        </Form>
      </ScrollView>

      <View>
        <Form.Actions>
          {!hasError && (
            <Button
              hierarchy={"tertiary"}
              testID={FindPrescriptionFlowTestIDs.doThisLaterButton}
              onPress={handleSkip}
              logger={{ id: FindPrescriptionFlowTestIDs.doThisLaterButton }}
            >
              {getText("do-this-later")}
            </Button>
          )}

          <Button
            hierarchy={"primary"}
            onPress={methods.handleSubmit(handleSubmit)}
            testID={FindPrescriptionFlowTestIDs.nextButton}
            logger={{ id: FindPrescriptionFlowTestIDs.nextButton }}
          >
            {hasError ? getText("try-again") : getText("next")}
          </Button>

          {hasError && (
            <Button
              hierarchy={"tertiary"}
              testID={FindPrescriptionFlowTestIDs.doThisLaterButton}
              onPress={handleSkip}
              logger={{ id: FindPrescriptionFlowTestIDs.doThisLaterButton }}
            >
              {getText("do-this-later")}
            </Button>
          )}
        </Form.Actions>
      </View>

      <View style={{ margin: theme.getSpacing(1) }}>
        <Barcode
          onScan={handleScan}
          isVisible={isScannerVisible}
          onClose={handleScannerClose}
        />
      </View>
    </ScreenContainer>
  );
};

interface FindPrescriptionForm {
  rxNumber: string;
  lastName: string;
  dateOfBirth: string;
}

export type IntegratedAddPrescriptionProps = NativeStackScreenProps<
  PrescriptionStackParamList,
  "automatic-prescription-flow"
>;

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginTop: theme.getSpacing(3),
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginTop: theme.getSpacing(3),
    marginBottom: theme.getSpacing(2),
  },
  title: {
    color: theme.palette.gray[900],
    fontWeight: "400",
    fontSize: 16,
    marginTop: theme.getSpacing(3),
    marginBottom: theme.getSpacing(1),
  },
  nameText: {
    color: theme.palette.gray[900],
    fontWeight: "700",
    fontSize: 16,
    marginTop: theme.getSpacing(2),
  },
  addressText: {
    color: theme.palette.gray[900],
    fontSize: 16,
  },
  descriptionText: {
    ...theme.fonts.medium,
    color: theme.palette.gray[500],
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    marginVertical: theme.getSpacing(1),
  },
  blueText: {
    ...theme.fonts.medium,
    fontSize: 16,
    color: theme.palette.primary[400],
  },
  row: {
    flexDirection: "row",
    marginBottom: theme.getSpacing(1),
    fontWeight: "400",
    justifyContent: "space-between",
  },
  container: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(4),
  },
  textTitleCard: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: 14,
    lineHeight: 20,
  },
  textSubtitleCard: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
  },
}));
