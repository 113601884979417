import React, { FunctionComponent, PropsWithChildren, useRef } from "react";
import { GradientView } from "../gradient-view";
import { View, GestureResponderEvent, Platform } from "react-native";
import { useTheme } from "../../theme";
import { NativeHeaderBackButton } from "./NativeHeaderBackButton";
import { NativeHeaderActionButton } from "./NativeHeaderActionButton";
import { SaveIcon } from "../../icons";
import { Text } from "../text";
import { PlatformType } from "../types";
import { uuid } from "../../utils/uuid";
import { getText } from "../../localization/localization";

export const NativeHeader: FunctionComponent<
  PropsWithChildren<NativeHeaderProps>
> = ({
  onBack,
  title,
  showBack,
  showAction,
  actionIcon = SaveIcon,
  actionLabel = getText("save"),
  onActionPress = () => {},
  platform = Platform.OS,
  gradientId = uuid(),
}) => {
  const theme = useTheme();
  const gradientIdRef = useRef(gradientId);

  return (
    <>
      <GradientView
        contentHeight={88}
        fillGradientStops={[
          { stopColor: theme.colors.gradientStart, offset: 0 },
          { stopColor: theme.colors.gradientEnd, offset: 100 },
        ]}
        gradientId={gradientIdRef.current}
      >
        <View
          style={{
            position: "relative",
            height: "100%",
            alignItems: "flex-end",
            flexDirection: "row",
          }}
        >
          <View
            style={{
              alignItems: "center",
              width: "100%",
              marginBottom: theme.getSpacing(1),
            }}
          >
            <Text
              style={{
                color: theme.palette.white,
                fontSize: 18,
                fontWeight: "500",
              }}
              testID={NativeHeaderTestIDs.title}
            >
              {title}
            </Text>
          </View>
          <View
            style={{
              flexDirection: "row",
              flex: 1,
              position: "absolute",
              left: 0,
              right: 0,
              justifyContent: "space-between",
            }}
          >
            <View>
              {showBack && (
                <NativeHeaderBackButton
                  onPress={onBack}
                  platform={platform}
                  testID={NativeHeaderTestIDs.backButton}
                />
              )}
            </View>
            <View>
              {showAction && (
                <NativeHeaderActionButton
                  onPress={onActionPress}
                  label={actionLabel}
                  icon={actionIcon}
                  platform={platform}
                  testID={NativeHeaderTestIDs.actionButton}
                />
              )}
            </View>
          </View>
        </View>
      </GradientView>
    </>
  );
};

export interface NativeHeaderProps {
  /**
   * Title to display in center of header
   */
  title?: string;
  /**
   * Callback for user clicking back button
   */
  onBack?: (event: GestureResponderEvent) => void;
  /**
   * Whether the back button is shown or not
   */
  showBack?: boolean;
  /**
   * Label displayed in text button for iOS
   */
  actionLabel?: string;
  /**
   * Icon displayed for Android
   */
  actionIcon?: FunctionComponent;
  /**
   * Whether the action button should be shown
   */
  showAction?: boolean;
  /**
   * Whether the action button fires onPress
   */
  onActionPress?: (event: GestureResponderEvent) => void;
  /**
   * Platform, should *only* be necessary for storybook
   */
  platform?: PlatformType;
  /**
   * GradientId, should *only* be necessary for snapshot tests
   */
  gradientId?: string;
}

export const NativeHeaderTestIDs = {
  title: "native-header-title",
  actionButton: "native-header-action-button",
  backButton: "native-header-back-button",
};
