import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path } from "react-native-svg";
import { IconProps } from "./types";

export const MessageSquareIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z" />
    </Svg>
  );
};
