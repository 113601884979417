import create from "zustand";
import { Platform } from "react-native";
import { ApiError, AsyncStatus } from "../../store/types";
import {
  LocationPatientRecordDto,
  PatientRecordDto,
} from "@digitalpharmacist/patient-service-client-axios";
import {
  CreateRefillDto,
  FulfillmentMethod,
  IngressMethod,
  PrescriptionDto,
  RefillDto,
} from "@digitalpharmacist/prescription-service-client-axios";
import refillService from "./refill-service";

export const useRefillMedicationsStore = create<RefillMedicationsState>(
  (set) => ({
    status: "idle",
    selectedMedications: [],
    allMedications: [],
    selectedPatient: undefined,
    showRefillMedications: true,
    isFirstTimeRegister: true,
    selectedPatientRecord: undefined,
    selectedLocationId: undefined,
    unableToSubmitMedications: [],
    updateMedicationsData: (data: RefillMedicationsState) => {
      set({ ...data });
    },
  })
);

interface RefillMedicationsState {
  status?: AsyncStatus;
  error?: ApiError;
  selectedMedications?: Array<PrescriptionDto & { checked?: boolean }>;
  allMedications?: Array<PrescriptionDto & { checked?: boolean }>;
  selectedPatient?: LocationPatientRecordDto;
  showRefillMedications?: boolean;
  isFirstTimeRegister?: boolean;
  selectedPatientRecord?: PatientRecordDto;
  selectedLocationId?: string;
  unableToSubmitMedications?: PrescriptionDto[];
  updateMedicationsData?: (data: RefillMedicationsState) => void;
}

export interface PatientMedicalInfo {
  gender: string;
  allergies: ResourceItem[];
  insuranceChange: string;
  medicalConditions: ResourceItem[];
}

export const useRefillMedicalInformationStore =
  create<RefillMedicalInformationState>((set) => ({
    status: "idle",
    patientMedicalInfos: {
      id: "",
      gender: "",
      allergies: [],
      insuranceChange: "",
      medicalConditions: [],
    },
    updateMedicalInformationData: (medicalInfos: PatientMedicalInfo) => {
      set({ patientMedicalInfos: medicalInfos });
    },
  }));

export interface ResourceItem {
  value: string;
  text: string;
}

interface RefillMedicalInformationState {
  status?: AsyncStatus;
  error?: ApiError;
  patientMedicalInfos: PatientMedicalInfo;
  updateMedicalInformationData: (data: PatientMedicalInfo) => void;
}

export const useRefillReviewMethodStore = create<RefillReviewMethodState>(
  (set, get) => ({
    status: "idle",
    methodValue: "Not selected",
    noteValue: "",
    submittedRefill: undefined,
    updateReviewMethodData: (data: RefillReviewMethodState) => {
      set({ ...data });
    },
    submitRefill: async (
      pharmacy_id: string,
      location_id: string,
      patient_id: string,
      selectedMedications: PrescriptionDto[],
      submittedBy: string,
      isToday?: boolean,
      isUrgent?: boolean
    ) => {
      const state = get();

      set({ ...state, status: "loading" });

      const refill: CreateRefillDto = {
        pharmacy_id: pharmacy_id,
        location_id: location_id,
        patient_id: patient_id,
        is_today: isToday,
        is_urgent: isUrgent,
        ingress_method:
          Platform.OS === "web" ? IngressMethod.Web : IngressMethod.Mobile,
        fulfillment_method: state.methodValue as FulfillmentMethod,
        notes: state.noteValue,
        refill_items: selectedMedications.map((med) => ({
          rx_number: med.rx_number,
          rx_name: med.drug_name,
        })),
        submitted_by: submittedBy,
      };

      try {
        const data = await refillService.submitPatientRefill(
          location_id,
          patient_id,
          refill
        );

        set({
          ...state,
          status: "idle",
          submittedRefill: data,
        });
      } catch (error) {
        console.error(error);
        set({ ...state, status: "error" });
      }
    },
  })
);

interface RefillReviewMethodState {
  status?: AsyncStatus;
  error?: ApiError;
  methodValue: string;
  noteValue?: string;
  submittedRefill?: RefillDto;
  updateReviewMethodData?: (data: RefillReviewMethodState) => void;
  isToday?: boolean;
  isUrgent?: boolean;
  submitRefill?: (
    pharmacy_id: string,
    location_id: string,
    patient_id: string,
    selectedMedications: PrescriptionDto[],
    submittedBy: string,
    isToday?: boolean,
    isUrgent?: boolean
  ) => Promise<void>;
}

export const useRefillReviewInsuranceStore = create<RefillReviewInsuranceState>(
  (set) => ({
    status: "idle",
    insuranceValue: "Not selected",
    updateReviewInsuranceData: (data: RefillReviewInsuranceState) => {
      set({ ...data });
    },
  })
);

interface RefillReviewInsuranceState {
  status?: AsyncStatus;
  error?: ApiError;
  insuranceValue: string;
  updateReviewInsuranceData?: (data: RefillReviewInsuranceState) => void;
}

export const useRefillDetailsStore = create<RefillDetailsState>((set) => ({
  status: "idle",
  prescriptionsTogether: "",
  easyOpenBottleCaps: "",
  updateRefillDetailsData: (data: RefillDetailsState) => {
    set({ ...data });
  },
}));

interface RefillDetailsState {
  status?: AsyncStatus;
  error?: ApiError;
  prescriptionsTogether: string;
  easyOpenBottleCaps: string;
  updateRefillDetailsData?: (data: RefillDetailsState) => void;
}
