import { AppointmentTypeSlotDto } from "@digitalpharmacist/appointment-service-client-axios";
import moment from "moment";
import React, { FunctionComponent, useEffect } from "react";
import { View } from "react-native";
import { Button } from "assets/components/button";
import { Text } from "assets/components/text";
import { CalendarIcon } from "assets/icons";
import { makeStyles, useTheme } from "assets/theme";
import { enableNextButton, setSelectedSlot } from "./book-appointment-actions";
import { useBookAppointmentState } from "./book-appointment-store";
import { formatDateTimeWithTimezone } from "../../../common/datetime-utils";
import {
  APPOINTMENT_TIME_FORMAT,
  DEFAULT_UTC_OFFSET,
} from "./book-appointment-utils";

export const BookAppointmentTime: FunctionComponent<BookAppointmentTimeProps> =
  (props) => {
    const { timeSlots, selectedSlot, selectedDate } = useBookAppointmentState();
    const styles = useStyles();
    const theme = useTheme();

    useEffect(() => {
      selectedSlot && enableNextButton();
    }, []);

    const handleTimePress = (slot: AppointmentTypeSlotDto) => {
      enableNextButton();
      setSelectedSlot(slot);
    };

    return (
      <View style={{ flex: 1 }}>
        <View style={styles.textContainer}>
          <CalendarIcon size={16} color={theme.palette.gray[500]} />
          <Text style={styles.text}>
            {moment(selectedDate).format("dddd MMMM DD")}
          </Text>
        </View>
        {timeSlots?.map((slot) => (
          <Button
            hierarchy={
              selectedSlot?.time === slot.time ? "primary" : "secondary-gray"
            }
            size="medium"
            onPress={() => handleTimePress(slot)}
            logger={{ id: `button-appointment-slot-${slot.time}` }}
            key={slot.time}
            style={styles.button}
          >
            {formatDateTimeWithTimezone(
              slot.time,
              DEFAULT_UTC_OFFSET,
              APPOINTMENT_TIME_FORMAT
            )}
          </Button>
        ))}
      </View>
    );
  };

export interface BookAppointmentTimeProps {}

const useStyles = makeStyles((theme) => ({
  textContainer: {
    flexDirection: "row",
    justifyContent: "center",
    marginBottom: theme.getSpacing(2),
  },
  text: {
    ...theme.fonts.medium,
    color: theme.palette.gray[700],
    marginLeft: theme.getSpacing(1),
  },
  button: {
    marginBottom: theme.getSpacing(1),
  },
}));
