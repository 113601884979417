import moment from "moment";

/**
 * Perform a deep removal of all properties that are undefined. Does not mutate object
 */
export const removeUndefinedProperties = <T>(obj: T): T => {
  // general function is based on the deep clone from: https://www.npmjs.com/package/just-clone
  if (typeof obj == "function") {
    return obj;
  }
  const result: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    const value = obj[key];
    if (value === null || value === undefined) {
      continue;
    }
    const type = {}.toString.call(value).slice(8, -1);
    if (type == "Array" || type == "Object") {
      result[key] = removeUndefinedProperties(value);
    } else if (type == "Date") {
      result[key] = new Date((value as unknown as Date).getTime());
    } else {
      result[key] = value;
    }
  }
  return result;
};

/**
 * Perform a deep trim of all properties that are strings. Does not mutate object
 */
export const trimAllStringProperties = <T>(obj: T): T => {
  // general function is based on the deep clone from: https://www.npmjs.com/package/just-clone
  if (typeof obj == "function") {
    return obj;
  }
  const result: any = Array.isArray(obj) ? [] : {};
  for (const key in obj) {
    const value = obj[key];
    const type = {}.toString.call(value).slice(8, -1);
    if (type == "Array" || type == "Object") {
      result[key] = trimAllStringProperties(value);
    } else if (type == "Date") {
      result[key] = new Date((value as unknown as Date).getTime());
    } else if (type === "String") {
      result[key] = (value as unknown as string).trim();
    } else {
      result[key] = value;
    }
  }
  return result;
};

export enum DateTimeFormat {
  ISODate = "YYYY-MM-DD",
  USDateFormat = "MM/DD/YYYY",
}

const DEFAULT_DATE_FORMAT = DateTimeFormat.ISODate;

export const formatDate = (
  valueToFormat: string,
  format: string = DEFAULT_DATE_FORMAT
) => {
  return new Date(moment(valueToFormat).format(format));
};

export const formatDateAsString = (
  valueToFormat: string,
  format: string = DEFAULT_DATE_FORMAT
) => {
  return moment(valueToFormat).format(format);
};

export const emailRegex =
  /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

//regex for MM-DD-YYYY - for month will accept 1-12, for days 1-31, for years will accept 4-digits starting with 19-29
export const dateRegex =
  /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|29)\d\d$/;
