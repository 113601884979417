import { logError, logEvent } from "assets/logging/logger";
import { useUserState } from "../../store/user-store";
import { useAppStateStore } from "../../store/app-store";
import { useLoginState } from "./login-store";
import { getText } from "assets/localization/localization";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StorageKeys } from "../../../enums/storage-keys";
import { api } from "../../api";
import { User } from "@sentry/react-native";
import UsersServiceInstance from "../../api/users-service";
import {
  PatientUserDto,
  UserLogin,
  UserLoginResponse,
} from "@digitalpharmacist/users-service-client-axios";
import { AuthStackNavigationProp } from "../../navigation/AuthNavigation";

import { ampli } from "../../common/ampliPatient";
import { resetAddPrescriptionState } from "../find-prescription-flow/add-prescription-actions";

export const login = async (
  values: UserLogin,
  navigation: AuthStackNavigationProp
): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useLoginState.setState({ error: undefined, status: "loading" });
  useLoginState.setState({ email: values.email });

  try {
    values.pharmacyId = pharmacyId;

    const userLoginResponse: UserLoginResponse =
      await UsersServiceInstance.logIn(values);
    if (!userLoginResponse.accessToken) {
      throw new Error(getText("email-or-password-incorrect"));
    }
    ampli.userLogin({
      pharmacy_id: pharmacyId,
      status: "Logged In",
      method: "lumistry",
    });

    await AsyncStorage.setItem(
      StorageKeys.AccessToken,
      userLoginResponse.accessToken
    );
    useLoginState.setState({ status: "success" });
    if (!userLoginResponse.patient_user) {
      throw new Error(getText("email-or-password-incorrect"));
    }
    const user: PatientUserDto = userLoginResponse.patient_user;
    if (user.id) {
      await AsyncStorage.setItem(StorageKeys.UserId, user.id);
      useUserState.setState({
        user: user,
      });
    } else {
      throw new Error(getText("email-or-password-incorrect"));
    }
  } catch (e) {
    ampli.userLogin({
      pharmacy_id: pharmacyId,
      status: "Login Failed",
      method: "lumistry",
    });
    if (e === "The requested resource was not found") {
      useLoginState.setState({
        error: {
          message: getText("email-or-password-incorrect"),
        },
        status: "error",
      });
    } else if (e === "Forbidden") {
      navigation.navigate("login-attempts-exceeded");
    } else {
      useLoginState.setState({
        error: {
          message: e as string,
        },
        status: "error",
      });
    }
  }
};

export const checkUserStatus = async (
  email: string,
  navigation: AuthStackNavigationProp,
  queryParams?: any
): Promise<void> => {
  try {
    const pharmacyId = useAppStateStore.getState().pharmacyId;
    const data = await UsersServiceInstance.checkUser(email, pharmacyId);
    // TODO we need to handle one more case:
    //the case where user exists in  dp1
    if (!data.dp1 && !data.dp2) {
      navigation.navigate("register-confirmation", queryParams);
    } else if (!data.dp1 && data.dp2) {
      useLoginState.setState({ email: email });
      navigation.navigate("login", queryParams);
    } else if (data.dp1 && !data.dp2) {
      navigation.navigate("register-confirmation", queryParams);
    }
  } catch (e) {
    useLoginState.setState({
      error: {
        message: e as string,
      },
      status: "error",
    });
  }
};

export const logout = async (): Promise<void> => {
  try {
    //TODO sign out method here
    void AsyncStorage.clear();
    useUserState.setState({
      user: undefined,
    });
    resetAddPrescriptionState();

    ampli.userLogout();
  } catch (error: any) {
    logError(error);
  }
};

export const googleLogin = async (values: {
  accessToken: string;
  pharmacyId?: string;
}): Promise<void> => {
  const pharmacyId = useAppStateStore.getState().pharmacyId;
  useLoginState.setState({ error: undefined, status: "loading" });
  const userId = useUserState.getState().user?.id!;
  try {
    values.pharmacyId = pharmacyId;
    const response = await api.googleSignIn({
      userAccessToken: { accessToken: values.accessToken },
    });
    await AsyncStorage.setItem(StorageKeys.AccessToken, response.accessToken);
    ampli.userLogin({
      pharmacy_id: pharmacyId,
      status: "Logged In",
      method: "google",
    });
    useLoginState.setState({ status: "success" });
    const user = await UsersServiceInstance.getUser(userId);

    useUserState.setState({ user: user });
  } catch (e) {
    logEvent("google_login_failed", { accessToken: values.accessToken });
    ampli.userLogin({
      pharmacy_id: pharmacyId,
      status: "Login Failed",
      method: "google",
    });
    useLoginState.setState({
      error: { message: getText("google-login-failed") },
      status: "error",
    });
  }
};

export interface LoginForm {
  email: string;
  password: string;
  pharmacyId: string;
}

export interface UserToken {
  accessToken: string;
  idToken: string;
  userInfo: User;
}
