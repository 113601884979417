import { DefaultTheme } from "@react-navigation/native";
import theme from "assets/theme";

export const navigationTheme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    background: theme.palette.white,
  },
};
