import { RecordUnderCareDto } from "@digitalpharmacist/patient-service-client-axios";
import { getText } from "assets/localization/localization";
import { FunctionComponent, PropsWithChildren } from "react";
import { View } from "react-native";
import { Modal } from "assets/components/modal";
import { Text } from "assets/components/text";
import { useTheme } from "react-native-paper";
import { makeStyles } from "assets/theme";

export const RevokeAccessWebComponent: FunctionComponent<
  PropsWithChildren<{
    record: RecordUnderCareDto;
    showModal: boolean;
    handleRevokeOnPress: (recordId: string, caregiverId: string) => void;
    handleCancelOnPress: (val: boolean) => void;
  }>
> = ({ record, showModal, handleRevokeOnPress, handleCancelOnPress }) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <Modal
      size="sm"
      title={getText("revoke-access") + "?"}
      show={showModal}
      cancelButtonProps={{
        onPress: () => handleCancelOnPress(false),
        logger: { id: "revoke-access-modal-cancel-button" },
      }}
      okButtonProps={{
        text: getText("revoke"),
        hierarchy: "destructive-secondary",
        onPress: () => handleRevokeOnPress(record.id, record.caregiver_id),
        logger: { id: "revoke-access-modal-send-button" },
      }}
    >
      <View style={{ alignContent: "center" }}>
        <Text style={styles.modalText}>
          {getText("revoke-access-for-authorized-caregiver", {
            firstName: record.caregiver_first_name,
            lastName: record.caregiver_last_name,
          })}
        </Text>
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  modalText: {
    lineHeight: 24,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: "400",
    padding: theme.getSpacing(1),
    alignSelf: "center",
  },
}));
