import React, { FunctionComponent, PropsWithChildren } from "react";
import { View, SafeAreaView, StyleProp, ViewStyle } from "react-native";
import { NumberProp } from "react-native-svg";
import { useTheme } from "../../theme";
import { uuid } from "../../utils/uuid";
import { LinearGradient } from "expo-linear-gradient";

export const GradientView: FunctionComponent<
  PropsWithChildren<GradientViewProps>
> = ({
  contentHeight = "100%",
  contentWidth = "100%",
  fillGradientStops,
  children,
  style,
  contentStyle,
  gradientId = uuid(),
}) => {
  const theme = useTheme();
  const fillGradientStopsValues = fillGradientStops ?? [
    { stopColor: theme.palette.white, offset: 0 },
    { stopColor: theme.palette.gray[100], offset: 100 },
  ];
  return (
    <View style={style}>
      <LinearGradient
        colors={fillGradientStopsValues.map((x) => x.stopColor)}
        style={{ position: "absolute", height: contentHeight, width: "100%" }}
      />
      <SafeAreaView style={[{ height: contentHeight }, contentStyle]}>
        {children}
      </SafeAreaView>
    </View>
  );
};

interface GradientViewProps {
  /**
   * GradientId must be unique to ensure it doesn't conflict with other gradient defs
   */
  gradientId?: string;
  contentHeight?: NumberProp;
  contentWidth?: NumberProp;
  fillGradientStops?: Array<{ stopColor: string; offset: number }>;
  contentStyle?: StyleProp<ViewStyle>;
  style?: StyleProp<ViewStyle>;
}
