import React, { FunctionComponent, PropsWithChildren, useEffect } from "react";
import { Button } from "assets/components/button";
import { Text } from "assets/components/text/Text";
import { ScreenContainer } from "assets/layout";
import { makeStyles } from "assets/theme";
import { getText } from "assets/localization/localization";
import { StyleSheet, View } from "react-native";
import { Divider } from "react-native-paper";

import { PrescriptionDto } from "@digitalpharmacist/prescription-service-client-axios";

import { PrescriptionCard } from "../../components/prescription-card";
import refillService from "./refill-service";
import { useRefillMedicationsStore } from "./refill-store";
import { RefillStackParamList } from "./RefillNavigation";
import { RefillTestIDs } from "./RefillTestIDs";
import { UnableToSubmit } from "./unable-to-submit/UnableToSubmit";
import { UnableToSubmitHandler } from "./unable-to-submit/UnableToSubmitProps";

import type { NativeStackScreenProps } from "@react-navigation/native-stack";

export const RefillMedications: FunctionComponent<
  PropsWithChildren<RefillMedicationsProps>
> = ({ navigation }) => {
  const styles = useStyles();

  const {
    selectedLocationId,
    selectedMedications,
    allMedications,
    selectedPatient,
    updateMedicationsData,
  } = useRefillMedicationsStore();

  const unableToSubmitRef = React.useRef<UnableToSubmitHandler>(null);

  useEffect(() => {
    (async () => {
      if (!updateMedicationsData || !selectedPatient || !selectedLocationId)
        return;

      const prescriptions = await refillService.getPatientPrescriptions(
        selectedLocationId,
        selectedPatient.id
      );

      const isFirstTimeRegister =
        selectedPatient &&
        !(await refillService.getLastRefill(
          selectedLocationId,
          selectedPatient?.id
        ));

      updateMedicationsData({
        allMedications: prescriptions.map((x) => ({
          ...x,
          checked: false,
        })),
        showRefillMedications: true,
        isFirstTimeRegister: isFirstTimeRegister,
        selectedPatient: selectedPatient,
      });
    })();
  }, []);

  const handleOnPressMedication = (
    medication: PrescriptionDto,
    checked: boolean
  ) => {
    if (!updateMedicationsData || !allMedications) return;

    updateMedicationsData({
      allMedications: allMedications.map((x) => ({
        ...x,
        checked: x.rx_number === medication.rx_number ? checked : x.checked,
      })),
    });
  };

  const handleShowUnableToSubmit = () => {
    unableToSubmitRef.current?.show();
  };

  const handleOnPressNext = async () => {
    if (
      !selectedPatient?.id ||
      !updateMedicationsData ||
      !selectedLocationId ||
      !selectedMedications
    )
      return;

    const failedMedications = await refillService.checkPatientPrescriptions(
      selectedMedications,
      selectedLocationId,
      selectedPatient.id
    );

    if (failedMedications.length > 0 && updateMedicationsData) {
      updateMedicationsData({
        unableToSubmitMedications: failedMedications,
      });
      return handleShowUnableToSubmit();
    }

    const isFirstTimeRegister = !(await refillService.getLastRefill(
      selectedLocationId,
      selectedPatient.id
    ));

    if (isFirstTimeRegister) {
      return navigation.navigate("refill-medical-infos");
    }

    navigation.navigate("refill-review");
  };

  const handleUnableToSubmit = async (value: string) => {
    if (value === "continue") {
      // check if is first time
      const isFirstTimeRegister = !(
        selectedPatient &&
        selectedLocationId &&
        (await refillService.getLastRefill(
          selectedLocationId,
          selectedPatient.id
        ))
      );

      if (isFirstTimeRegister) {
        navigation.navigate("refill-medical-infos");
      } else {
        navigation.navigate("refill-review");
      }
    }
    if (value === "back") {
      navigation.navigate("refill-medications");
    }
  };

  return (
    <ScreenContainer>
      <Text style={styles.textTitle}>
        {getText("medications-ready-to-be-filled")}
      </Text>
      <Divider style={styles.divider} />
      <View>
        {selectedPatient &&
          allMedications?.map((medication) => (
            <PrescriptionCard
              key={medication.rx_number}
              patient={selectedPatient}
              prescription={medication}
              selectable={true}
              showStatus={false}
              check={medication.checked}
              onPress={handleOnPressMedication}
            />
          ))}
      </View>

      <View style={styles.buttonContainer}>
        <Button
          hierarchy="primary"
          onPress={handleOnPressNext}
          testID={RefillTestIDs.refillMedicationsNext}
          logger={{ id: RefillTestIDs.refillMedicationsNext }}
          disabled={selectedMedications?.length === 0}
        >
          {getText("next")}
        </Button>
      </View>
      <UnableToSubmit
        ref={unableToSubmitRef}
        handleUnableToSubmit={handleUnableToSubmit}
      />
    </ScreenContainer>
  );
};

export interface RefillMedicationsProps
  extends NativeStackScreenProps<RefillStackParamList, "refill-medications"> {}

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginTop: theme.getSpacing(3),
    marginLeft: theme.getSpacing(1),
  },
  divider: {
    margin: theme.getSpacing(1),
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "flex-end",
    width: "100%",
  },
}));
