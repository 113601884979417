import { useState, useEffect } from "react";
import {
  requestForegroundPermissionsAsync,
  getCurrentPositionAsync,
  PermissionStatus,
} from "expo-location";
import { LocationInfo } from "../types";

export const useLocation = (): LocationInfo => {
  const [location, setLocation] = useState<LocationInfo>({
    granted: false,
    status: PermissionStatus.UNDETERMINED,
  });

  useEffect(() => {
    (async () => {
      const { status, granted } = await requestForegroundPermissionsAsync();

      if (status !== PermissionStatus.GRANTED) setLocation({ granted, status });

      if (status === PermissionStatus.GRANTED) {
        const {
          coords: { latitude, longitude },
        } = await getCurrentPositionAsync({});
        setLocation({ status, granted, latitude, longitude });
      }
    })();
  }, []);

  return location;
};
