import { DeepLinkingConfig } from "../types";

export const DefaultDeepLinkingConfig: DeepLinkingConfig = {
  scheme: "lumistry",
  android: {
    fallbackUrl:
      "https://play.google.com/apps/internaltest/4701318999354359901",
    package: "com.dp.mobilepatient",
  },
  ios: {
    fallbackUrl:
      "https://apps.apple.com/us/app/pocketrx-refill-medications/id1281037910",
    appId: "1639140500",
    appArgument: "TODO", //This is a fallback url for the smart banner
  },
  enableSmartBanner: true,
};

// var custom =
// "exp://192.168.1.40:19000/--/http://localhost:19006/intro?returnUrl=http%3A%2F%2Flocalhost%3A19006%2Fapp%2Fprofile%2Fhome";
// var alt = "https://itunes.apple.com/it/app/id320279293?mt=8";
// var g_intent =
// "intent://192.168.1.40:19000/--/http://localhost:19006/intro?returnUrl=http%3A%2F%2Flocalhost%3A19006%2Fapp%2Fprofile%2Fhome/#Intent;scheme=exp;S.browser_fallback_url=http%3A%2F%2Fzxing.org;end";
