import React, { FunctionComponent } from "react";
import { View } from "react-native";
import { IconButton } from "assets/components/icon-button";
import { Text } from "assets/components/text";
import { ChevronLeftIcon, ChevronRightIcon } from "assets/icons";
import { getText } from "assets/localization/localization";
import { makeStyles } from "assets/theme";

export const Pagination: FunctionComponent<PaginationProps> = ({
  currentPage,
  totalPages,
  onPrevPress,
  onNextPress,
}) => {
  const styles = useStyles();
  const isFirst = currentPage === 1;
  const isLast = currentPage === totalPages;

  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <IconButton
          icon={ChevronLeftIcon}
          onPress={onPrevPress}
          logger={{ id: `pagination-prev-button` }}
          disabled={isFirst}
        />
      </View>
      <View style={styles.textContainer}>
        <Text>
          {currentPage} {getText("of")} {totalPages}
        </Text>
      </View>
      <View style={styles.buttonContainer}>
        <IconButton
          icon={ChevronRightIcon}
          onPress={onNextPress}
          logger={{ id: `pagination-next-button` }}
          disabled={isLast}
        />
      </View>
    </View>
  );
};

export interface PaginationProps {
  currentPage: number;
  totalPages: number;
  onPrevPress: () => void;
  onNextPress: () => void;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  buttonContainer: {
    justifyContent: "center",
  },
  textContainer: {
    marginHorizontal: theme.getSpacing(2),
    justifyContent: "center",
  },
}));
