import { Form, ScreenContainer } from "assets/layout";
import { makeStyles } from "assets/theme";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { View } from "react-native";
import { Text } from "assets/components/text/Text";
import { PrescriptionStackParamList } from "../FindPrescriptionNavigation";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Divider } from "react-native-paper";
import { Button } from "assets/components/button";
import { FindPrescriptionFlowTestIDs } from "../FindPrescriptionFlowTestIDs";
import { getText } from "assets/localization/localization";
import refillService from "../../refill/refill-service";
import { PrescriptionCard } from "../../../components/prescription-card";
import { PrescriptionDto } from "@digitalpharmacist/prescription-service-client-axios";
import { useAddPrescriptionStore } from "../add-prescription-store";
import { Alert } from "assets/components/alert";
import { useUserState } from "../../../store/user-store";
import { resetAddPrescriptionState } from "../add-prescription-actions";
import patientService from "../../../api/patient-service";
import { LocationPatientRecordDto } from "@digitalpharmacist/patient-service-client-axios";

export const IntegratedAllPrescriptions: FunctionComponent<
  PropsWithChildren<IntegratedAllPrescriptionsProps>
> = ({ navigation }) => {
  const styles = useStyles();
  const { user, updateUser } = useUserState();
  const { locationPatientRecordId, patientRecord } = useAddPrescriptionStore();
  const [prescriptions, setPrescriptions] = useState<PrescriptionDto[]>([]);
  const [locationPatientRecord, setLocationPatientRecord] =
    useState<LocationPatientRecordDto>();

  const selectedLocationId = user?.preferredPharmacyLocationId;

  const setFindMedsScreenAsSeen = () => {
    updateUser({ hasSeenFindMedsScreen: true });
  };

  useEffect(() => {
    if (!selectedLocationId || !locationPatientRecordId) return;

    void refillService
      .getPatientPrescriptions(selectedLocationId, locationPatientRecordId)
      .then((response) => {
        setPrescriptions(response);
      });

    void patientService
      .findLocationPatientRecord(selectedLocationId, locationPatientRecordId)
      .then((response) => {
        setLocationPatientRecord(response);
      });
  }, [selectedLocationId, locationPatientRecordId]);

  const handleOnPressDone = () => {
    setFindMedsScreenAsSeen();
    resetAddPrescriptionState();
  };

  return (
    <ScreenContainer>
      <Alert
        title={getText("we-found-your-medications-alert-title")}
        intent={"success"}
      />
      <Text style={styles.textTitle}>{getText("your-prescriptions")}</Text>
      <Divider />
      <>
        {prescriptions.map((item, index) => (
          <View key={index} style={styles.container}>
            <PrescriptionCard
              key={item.rx_number}
              patient={locationPatientRecord}
              prescription={item}
              selectable={false}
            />
          </View>
        ))}
      </>
      <View style={{ bottom: 0, left: 0, right: 0, position: "absolute" }}>
        <Form.Actions>
          <Button
            hierarchy="primary"
            onPress={handleOnPressDone}
            testID={FindPrescriptionFlowTestIDs.nextButton}
            logger={{ id: "add-prescription" }}
          >
            {getText("done")}
          </Button>
        </Form.Actions>
        <Button
          hierarchy="tertiary"
          onPress={() => navigation.navigate("manual-prescription-flow")}
          testID={FindPrescriptionFlowTestIDs.doThisLaterButton}
          logger={{ id: "add-prescription" }}
        >
          {getText("other-meds")}
        </Button>
      </View>
    </ScreenContainer>
  );
};

export type IntegratedAllPrescriptionsProps = NativeStackScreenProps<
  PrescriptionStackParamList,
  "success-automatic-prescription-flow"
>;

const useStyles = makeStyles((theme) => ({
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginTop: theme.getSpacing(3),
    marginBottom: theme.getSpacing(2),
  },
  container: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(4),
  },
}));
