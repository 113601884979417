import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path, Circle } from "react-native-svg";
import { IconProps } from "./types";

export const CameraIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z" />
      <Circle cx={12} cy={13} r={4} />
    </Svg>
  );
};
