import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path } from "react-native-svg";
import { IconProps } from "./types";

export const HomeMessagesIcon: FunctionComponent<PropsWithChildren<IconProps>> =
  ({ color, size, testID }) => {
    return (
      <Svg width={size} height={size} viewBox="0 0 53 53" testID={testID}>
        <Path
          fill="#B36472"
          d="M53 26.5C53 11.864 41.136 0 26.5 0S0 11.864 0 26.5 11.864 53 26.5 53 53 41.136 53 26.5Z"
        />
        <Path
          fill="#75414A"
          d="M27.751 52.968 11.478 36.697c.35.254.78.404 1.242.404H31.8l5.3 5.3v-5.3h3.18c1.166 0 2.12-.954 2.12-2.12V18.02c0-.484-.164-.931-.44-1.289l11.01 11.01c-.63 13.644-11.576 24.593-25.219 25.227Zm-17.15-17.97v-.001Zm0-.006v-.003.003Zm0-.005v-.007.007ZM40.295 15.9h-.001Zm-.007 0h-.002.002Z"
        />
        <Path
          fill="#F7E5C4"
          d="M40.28 15.9H12.72c-1.166 0-2.12.954-2.12 2.12v16.96c0 1.166.954 2.12 2.12 2.12H31.8l5.3 5.3v-5.3h3.18c1.166 0 2.12-.954 2.12-2.12V18.02c0-1.166-.954-2.12-2.12-2.12Z"
        />
        <Path
          fill="#DF8679"
          d="M18.55 27.56h15.9v-2.12h-15.9v2.12ZM18.55 22.26h15.9v-2.12h-15.9v2.12ZM18.55 32.86h10.6v-2.12h-10.6v2.12Z"
        />
        <Path
          fill="#E1D1B3"
          d="m37.1 42.4-5.3-5.3h-5.3v-4.24h2.65v-2.12H26.5v-3.18h7.95v-2.12H26.5v-3.18h7.95v-2.12H26.5V15.9h13.78c1.166 0 2.12.954 2.12 2.12v16.96c0 1.166-.954 2.12-2.12 2.12H37.1v5.3Z"
        />
        <Path
          fill="#CB7A6E"
          d="M26.5 27.56h7.95v-2.12H26.5v2.12ZM26.5 22.26h7.95v-2.12H26.5v2.12ZM26.5 32.86h2.65v-2.12H26.5v2.12Z"
        />
      </Svg>
    );
  };
