import React, { FunctionComponent, PropsWithChildren } from "react";
import { Modal as RNPModal, Portal, IconButton } from "react-native-paper";
import { View, Dimensions, ViewStyle } from "react-native";
import { Button, ButtonHierarchy, ButtonProps } from "../button";
import { Text } from "../text";
import { makeStyles, useTheme } from "../../theme";
import { CloseIcon } from "../../icons";
import { TrashIcon } from "../../icons";
import { ScrollView } from "react-native-gesture-handler";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    padding: theme.getSpacing(2),
    margin: theme.getSpacing(2),
    borderRadius: theme.roundness,
    justifyContent: "center",
    alignSelf: "center",
    maxHeight: Dimensions.get("window").height * 0.9,
  },
  modalSM: { width: 600 },
  modalLG: { width: 800 },
  title: {
    ...theme.fonts.regular,
    fontWeight: "800",
    color: theme.palette.gray[900],
  },
  titleSM: { fontSize: 16, lineHeight: 18 },
  titleLG: { fontSize: 18, lineHeight: 20 },
  closeIcon: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },

  dismissButton: {
    margin: 0,
    padding: 0,
    height: 20,
    width: 20,
    alignSelf: "flex-end",
    backgroundColor: "transparent",
  },
  content: {
    marginTop: theme.getSpacing(1),
    textAlign: "center",
  },
  header: {
    flexDirection: "row",
    paddingBottom: theme.getSpacing(2),
  },
  footer: { flexDirection: "row-reverse" },
  footerButton: {
    marginRight: theme.getSpacing(2),
    marginTop: theme.getSpacing(2),
    width: 120,
    height: 44,
  },
  okButton: {
    marginRight: 0,
  },
  pinLeft: {
    flex: 1,
    alignItems: "flex-start",
  },
}));

export const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({
  show,
  size = "sm",
  okButtonProps,
  cancelButtonProps,
  resetButtonProps,
  deleteButtonProps,
  dismissButtonProps,
  title,
  titleSize = "sm",
  children,
  isScrollable = false,
  height,
  headerComponent,
  scrollViewStyle,
  contentContainerStyle,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <Portal>
      <RNPModal
        visible={show}
        onDismiss={dismissButtonProps?.onPress ?? cancelButtonProps?.onPress}
        contentContainerStyle={[
          styles.root,
          size === "sm" ? styles.modalSM : styles.modalLG,
        ]}
      >
        <View testID={ModalTestIDs.header} style={styles.header}>
          {title && (
            <View>
              <Text
                testID={ModalTestIDs.title}
                style={[
                  styles.title,
                  titleSize === "sm" ? styles.titleSM : styles.titleLG,
                ]}
              >
                {title}
              </Text>
            </View>
          )}
          {(dismissButtonProps || cancelButtonProps) && (
            <View testID={ModalTestIDs.closeIcon} style={styles.closeIcon}>
              <IconButton
                accessibilityLabel={dismissButtonProps?.text ?? "dismiss"}
                icon={CloseIcon}
                style={styles.dismissButton}
                onPress={
                  dismissButtonProps?.onPress ?? cancelButtonProps?.onPress
                }
                size={20}
                color={theme.palette.gray[500]}
                {...dismissButtonProps}
              />
            </View>
          )}
        </View>
        {headerComponent}
        <ScrollView
          scrollEnabled={isScrollable}
          style={{ height: height ?? undefined }}
          contentContainerStyle={scrollViewStyle}
        >
          <View
            testID={ModalTestIDs.content}
            style={[styles.content, contentContainerStyle]}
          >
            {children}
          </View>
        </ScrollView>
        <View testID={ModalTestIDs.footer} style={styles.footer}>
          <View
            style={[styles.footerButton, styles.okButton]}
            testID={ModalTestIDs.okButton}
          >
            <Button
              hierarchy="primary"
              accessibilityLabel={okButtonProps.text ?? "OK"}
              {...okButtonProps}
            >
              {okButtonProps.text ?? "OK"}
            </Button>
          </View>
          {cancelButtonProps && (
            <View
              style={styles.footerButton}
              testID={ModalTestIDs.cancelButton}
            >
              <Button
                hierarchy="secondary-gray"
                accessibilityLabel={cancelButtonProps.text ?? "Cancel"}
                {...cancelButtonProps}
              >
                {cancelButtonProps.text ?? "Cancel"}
              </Button>
            </View>
          )}
          {resetButtonProps && (
            <View style={styles.footerButton} testID={ModalTestIDs.resetButton}>
              <Button
                hierarchy="tertiary-gray"
                accessibilityLabel={resetButtonProps.text ?? "Reset"}
                {...resetButtonProps}
              >
                {resetButtonProps.text ?? "Reset"}
              </Button>
            </View>
          )}

          {deleteButtonProps && (
            <View
              style={[
                styles.footerButton,
                deleteButtonProps.pinLeft && styles.pinLeft,
              ]}
              testID={ModalTestIDs.deleteButton}
            >
              <Button
                hierarchy="destructive"
                icon={TrashIcon}
                accessibilityLabel={deleteButtonProps.text ?? "Delete"}
                {...deleteButtonProps}
              >
                {deleteButtonProps.text ?? "Delete"}
              </Button>
            </View>
          )}
        </View>
      </RNPModal>
    </Portal>
  );
};

export interface ModalProps {
  show: boolean;
  size?: "sm" | "lg";
  title?: string;
  titleSize?: "sm" | "lg";
  okButtonProps: ModalButtonProps;
  cancelButtonProps?: ModalButtonProps;
  resetButtonProps?: ModalButtonProps;
  deleteButtonProps?: ModalButtonProps & {
    pinLeft?: boolean;
  };
  dismissButtonProps?: Pick<ButtonProps, "onPress" | "logger"> & {
    text?: string;
  };
  isScrollable?: boolean;
  height?: number;
  headerComponent?: React.ReactNode;
  scrollViewStyle?: ViewStyle;
  contentContainerStyle?: ViewStyle;
}

export interface ModalButtonProps extends Omit<ButtonProps, "hierarchy"> {
  text?: string;
  hierarchy?: ButtonHierarchy;
}

export const ModalTestIDs = {
  header: "confirmation-modal-header",
  footer: "confirmation-modal-header",
  title: "confirmation-modal-title",
  content: "confirmation-modal-content",
  okButton: "confirmation-modal-okButton",
  cancelButton: "confirmation-modal-cancelButton",
  closeIcon: "confirmation-modal-closeIcon",
  resetButton: "confirmation-modal-resetButton",
  deleteButton: "confirmation-modal-deleteButton",
};
