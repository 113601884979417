import create from "zustand";
import { ApiError, AsyncStatus } from "../../store/types";

export const useVerificationState = create<VerificationState>((set) => ({
  status: "idle",
  data: undefined,
}));

interface VerificationState {
  status: AsyncStatus;
  error?: ApiError;
}

export const useResendVerificationState = create<ResendVerificationState>(
  (set) => ({
    status: "idle",
    data: undefined,
  })
);

interface ResendVerificationState {
  status: AsyncStatus;
  error?: ApiError;
}

export const useUserAgreementState = create<UserAgreementState>((set) => ({
  status: "idle",
  data: undefined,
}));

interface UserAgreementState {
  status: AsyncStatus;
  error?: ApiError;
}
