import { StyleSheet, ViewStyle, TextStyle, ImageStyle } from "react-native";
import { useTheme } from "./use-theme-hook";

/**
 * @internal
 */
type NamedStyles<T> = { [P in keyof T]: ViewStyle | TextStyle | ImageStyle };
type StyleCallback<T> = (theme: ReactNativePaper.Theme) => T;

export const makeStyles =
  <T extends NamedStyles<T> | NamedStyles<any>>(styles: StyleCallback<T> | T) =>
  (): T => {
    if (typeof styles !== "function") return StyleSheet.create(styles);
    const theme = useTheme();
    return StyleSheet.create(styles(theme));
  };
