import React, { FunctionComponent, PropsWithChildren } from "react";
import { StyleProp, TextStyle, View, ViewStyle } from "react-native";
import { Text } from "assets/components/text";
import { makeStyles } from "assets/theme";

export const AppointmentDetailsInfo: FunctionComponent<
  PropsWithChildren<AppointmentDetailsInfoProps>
> = ({ label, containerStyle, labelStyle, children }) => {
  const styles = useStyles();

  return (
    <View style={[styles.container, containerStyle && containerStyle]}>
      <View style={[styles.labelContainer, labelStyle && labelStyle]}>
        <Text style={styles.label}>{label}</Text>
      </View>
      <View style={styles.content}>{children}</View>
    </View>
  );
};

export interface AppointmentDetailsInfoProps {
  label: string;
  containerStyle?: StyleProp<ViewStyle>;
  labelStyle?: StyleProp<TextStyle>;
}

const useStyles = makeStyles((theme) => ({
  container: {
    flexDirection: "row",
    marginBottom: theme.getSpacing(2),
  },
  labelContainer: {
    width: 100,
    marginRight: theme.getSpacing(1),
  },
  label: {
    ...theme.fonts.medium,
    fontSize: 16,
    color: theme.palette.gray[900],
  },
  content: {
    flex: 1,
  },
}));
