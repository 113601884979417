import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { makeStyles, useTheme } from "assets/theme";
import { View, Pressable, Text, Platform } from "react-native";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  AuthStackNavigationProp,
  AuthStackParamList,
} from "../../navigation/AuthNavigation";
import { Logo } from "../../components/logo/Logo";
import { ScreenContainer } from "assets/layout";
import { AppleSignInButton } from "../../components/apple-sign-in-button/AppleSignInButton";
import { GoogleSignInButton } from "../../components/google-sign-in-button/GoogleSignInButton";
import { getText } from "assets/localization/localization";
import { Button } from "assets/components/button";
import { EmailIcon, FaceIdIcon } from "assets/icons";
import { Icon } from "assets/components/icon";
import { useAppStateStore } from "../../store/app-store";
import { BottomSheet } from "assets/components/bottom-sheet";
import { BottomSheetModal, enableLogging } from "@gorhom/bottom-sheet";
import { login, LoginForm } from "../login/login-actions";
import { useForm } from "assets/form";
import { useDeepLinkingContext } from "assets/core/deep-linking";
import * as Linking from "expo-linking";
import { Modal } from "assets/components/modal";
import { notImplementedAlert } from "assets/utils/alert";

const randomText = (
  <Text>
    Velit reprehenderit in velit dolore magna. Aliquip mollit enim non culpa
    minim dolore. Adipisicing sunt consequat ex deserunt pariatur dolore.
    Exercitation nostrud ullamco nostrud proident velit tempor ipsum duis tempor
    magna enim consectetur magna. Minim et aute velit esse deserunt. Est id
    culpa excepteur eiusmod fugiat quis. Mollit officia voluptate voluptate
    Lorem reprehenderit duis. Tempor tempor ullamco labore culpa commodo
    reprehenderit cupidatat exercitation duis consectetur excepteur. Irure
    consequat incididunt irure do magna est aliqua et quis do. Consequat elit
    exercitation aliquip Lorem ea in adipisicing sunt est ea velit. Mollit magna
    sunt dolore veniam incididunt non. Anim duis aliquip adipisicing non dolor
    commodo aliquip pariatur qui magna esse ex incididunt mollit. Sit ut veniam
    enim culpa ut consectetur reprehenderit magna. Occaecat reprehenderit do
    laboris eu minim nostrud. Magna consequat adipisicing non ipsum. Commodo
    quis irure aliquip nulla qui dolor commodo consequat Lorem incididunt.
    Labore eiusmod dolor ex dolore incididunt fugiat dolore cupidatat qui. Ea
    veniam elit et exercitation exercitation occaecat ipsum id. Nisi officia
    veniam esse esse. Irure ipsum aliqua anim ea enim elit incididunt. Et
    excepteur anim reprehenderit nisi cillum ipsum ex commodo cillum. Deserunt
    laborum mollit ad sint pariatur. Consectetur fugiat sit aliquip ipsum
    excepteur sunt sit sunt. Ex est occaecat nulla ipsum enim. In officia enim
    anim excepteur dolor pariatur officia officia velit eu in. Excepteur
    consequat nisi irure excepteur laboris commodo reprehenderit duis cillum
    tempor deserunt irure. Ad culpa cupidatat fugiat non qui duis exercitation
    velit proident laborum velit voluptate. Minim veniam Lorem veniam est dolore
    cillum officia nostrud quis aliqua deserunt anim nulla qui. Proident id aute
    elit deserunt incididunt. Eu ex qui ex tempor aute amet pariatur. Laboris
    nulla ipsum ad proident sit occaecat cupidatat duis qui voluptate deserunt
    do consectetur. Nulla tempor laborum eu do dolore Lorem est aliqua non nisi.
    Non est veniam Lorem nostrud occaecat proident quis officia nisi. Sint elit
    sit labore voluptate enim quis laborum officia tempor Lorem veniam elit
    consequat. Dolor ut officia fugiat id velit. Dolor occaecat nostrud tempor
    excepteur.
  </Text>
);

export const Intro: FunctionComponent<PropsWithChildren<IntroProps>> = ({
  navigation,
}) => {
  const { currentUrl } = useDeepLinkingContext();
  const theme = useTheme();
  const styles = useStyles();
  const [isNewPatient, setIsNewPatient] = useState(false);
  const [isTermsOfServiceOpen, setIsTermsOfServiceOpen] = useState(false);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false);
  const termsOfServiceBottomSheetRef = React.useRef<BottomSheetModal>(null);
  const privacyPolicyBottomSheetRef = React.useRef<BottomSheetModal>(null);
  const methods = useForm<LoginForm>({
    defaultValues: {
      email: "",
      password: "",
    },
  });

  //TODO: This is a temporary fix for a bug, this needs to be resolved, I will write up a ticket
  useEffect(() => {
    enableLogging();
  }, []);

  const toggleIsNewPatient = () => {
    setIsNewPatient((prevValue) => !prevValue);
  };

  const continueWithEmail = async () => {
    if (currentUrl && currentUrl !== "") {
      const { queryParams } = Linking.parse(currentUrl);
      if (queryParams) {
        navigation.navigate("login-enter-email", queryParams as never);
      } else {
        navigation.navigate("login-enter-email");
      }
    } else {
      navigation.navigate("login-enter-email");
    }
  };

  const openTermsOfService = () => {
    if (Platform.OS === "web") {
      setIsTermsOfServiceOpen(true);
    } else {
      termsOfServiceBottomSheetRef.current?.present();
    }
  };

  const closeTermsOfService = () => {
    if (Platform.OS === "web") {
      setIsTermsOfServiceOpen(false);
    } else {
      termsOfServiceBottomSheetRef.current?.dismiss();
    }
  };

  const openPrivacyPolicy = () => {
    if (Platform.OS === "web") {
      setIsPrivacyPolicyOpen(true);
    } else {
      privacyPolicyBottomSheetRef.current?.present();
    }
  };

  const { launchApp } = useDeepLinkingContext();

  const handleSubmit = async () => {
    login(methods.getValues(), navigation as AuthStackNavigationProp);
  };

  const closePrivacyPolicy = () => {
    if (Platform.OS === "web") {
      setIsPrivacyPolicyOpen(false);
    } else {
      privacyPolicyBottomSheetRef.current?.dismiss();
    }
  };

  const { pharmacyName } = useAppStateStore();

  return (
    <View style={styles.container}>
      <ScreenContainer backgroundColor={theme.colors.brandedPrimary}>
        <View style={{ flex: 1 }}>
          <View style={{ flex: 0.5 }}>
            <Logo />
          </View>
          {isNewPatient && (
            <View>
              <Text style={[styles.text, styles.thankYouText]}>
                {getText("thank-you-for-choosing")} {"\n"} {pharmacyName}!
              </Text>
              <Text style={[styles.text, styles.getStartedText]}>
                {getText("to-get-started-choose")}
              </Text>
            </View>
          )}
          <View style={{ flex: 1 }}>
            <View>
              <AppleSignInButton
                text={getText("apple-continue")}
                style={[
                  styles.marginBottom2,
                  isNewPatient && { marginTop: theme.getSpacing(4) },
                ]}
              />
              <GoogleSignInButton
                text={getText("google-continue")}
                style={styles.marginBottom2}
              />
              <Button
                icon={EmailIcon}
                style={[styles.button]}
                hierarchy={"secondary-gray"}
                onPress={continueWithEmail}
                logger={{ id: "email-continue-button" }}
              >
                {getText("email-continue")}
              </Button>
            </View>

            <View>
              <Pressable onPress={toggleIsNewPatient}>
                <Text style={[styles.text, styles.marginTop3]}>
                  {isNewPatient
                    ? getText("go-back")
                    : getText("i-am-a-new-patient")}
                </Text>
              </Pressable>

              <Pressable onPress={notImplementedAlert}>
                <View style={[styles.container, styles.marginTop3]}>
                  <Icon icon={FaceIdIcon} size={32} />
                  <Text style={[styles.text, styles.marginTop1]}>
                    {getText("face-id")}
                  </Text>
                </View>
              </Pressable>
            </View>

            <View style={{ marginTop: 44, flex: 1 }}>
              <Text style={styles.text}>
                {getText("continuation-disclaimer")}{" "}
                <Text style={styles.link} onPress={openTermsOfService}>
                  {getText("terms-of-service")}
                </Text>{" "}
                {getText("and")}{" "}
                <Text style={styles.link} onPress={openPrivacyPolicy}>
                  {getText("privacy-policy")}
                </Text>
                .
              </Text>
            </View>

            {Platform.OS === "web" ? (
              <Modal
                title={getText("terms-of-service")}
                show={isTermsOfServiceOpen}
                okButtonProps={{
                  onPress: closeTermsOfService,
                  logger: { id: "terms-of-service-ok-button-modal" },
                  text: getText("close"),
                }}
                dismissButtonProps={{
                  onPress: closeTermsOfService,
                  logger: { id: "terms-of-service-dismiss-button-modal" },
                }}
                isScrollable
                height={800}
              >
                <Text style={styles.modalText}>{randomText}</Text>
              </Modal>
            ) : (
              <BottomSheet
                bottomSheetRef={termsOfServiceBottomSheetRef}
                title={getText("terms-of-service")}
                height={"90%"}
                onDismiss={closeTermsOfService}
              >
                <View>
                  <Text style={styles.modalText}>{randomText}</Text>
                </View>
              </BottomSheet>
            )}

            {Platform.OS === "web" ? (
              <Modal
                title={getText("pharmacy-privacy-policy")}
                show={isPrivacyPolicyOpen}
                okButtonProps={{
                  onPress: closePrivacyPolicy,
                  logger: { id: "privacy-policy-ok-button-modal" },
                  text: getText("close"),
                }}
                dismissButtonProps={{
                  onPress: closePrivacyPolicy,
                  logger: { id: "privacy-policy-dismiss-button-modal" },
                }}
                isScrollable
                height={800}
              >
                <Text style={styles.modalText}>{randomText}</Text>
              </Modal>
            ) : (
              <BottomSheet
                bottomSheetRef={privacyPolicyBottomSheetRef}
                title={getText("pharmacy-privacy-policy")}
                height={"90%"}
                onDismiss={closePrivacyPolicy}
              >
                <View>
                  <Text style={styles.modalText}>{randomText}</Text>
                </View>
              </BottomSheet>
            )}
          </View>
        </View>
      </ScreenContainer>
    </View>
  );
};

interface IntroProps
  extends NativeStackScreenProps<AuthStackParamList, "intro"> {}

const useStyles = makeStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    backgroundColor: theme.colors.brandedPrimary,
  },
  marginBottom2: {
    marginBottom: theme.getSpacing(2),
  },
  marginTop1: {
    marginTop: theme.getSpacing(1),
  },
  marginTop3: {
    marginTop: theme.getSpacing(3),
  },
  button: {
    borderColor: theme.palette.gray[300],
    paddingTop: theme.getSpacing(0.5),
    paddingBottom: theme.getSpacing(0.5),
  },
  text: {
    color: theme.palette.white,
    textAlign: "center",
  },
  link: {
    ...theme.fonts.medium,
    textDecorationLine: "underline",
    color: theme.palette.white,
  },
  thankYouText: {
    ...theme.fonts.medium,
    fontSize: 20,
    lineHeight: 30,
  },
  getStartedText: {
    fontSize: 18,
    lineHeight: 28,
    marginTop: theme.getSpacing(3),
  },
  modalText: {
    lineHeight: 20,
    padding: theme.getSpacing(1),
  },
}));
