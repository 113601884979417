import create from "zustand";
import { ApiError, AsyncStatus } from "../../store/types";

export const useResetPasswordState = create<ResetState>((set) => ({
  status: "idle",
  data: undefined,
  updateData: (data: ResetState) => {
    set({ ...data });
  },
}));

interface ResetState {
  status: AsyncStatus;
  error?: ApiError;
  email?: string;
  data: any;
  updateData: (data: ResetState) => void;
  resetPasswordLinkId?: string;
}
