import {
  PharmacyLocationDto,
  TimeRangeDto,
} from "@digitalpharmacist/pharmacy-service-client-axios";

export enum MapSize {
  md = "md",
  sm = "sm",
  xs = "xs",
}

export interface StoreInformationMapProps {
  size: MapSize;
  zoom?: number;
}

export interface StoreInformationProps {
  item: PharmacyLocationDto;
  onChangePress: (item: PharmacyLocationDto) => void;
  disabled?: boolean;
  mapProps?: StoreInformationMapProps;
  showChange?: boolean;
  showPhone?: boolean;
  openingHours?: TimeRangeDto;
}

export interface PreferredHomeLocationInformationProps {
  item: PharmacyLocationDto;
  onChangePress: (item?: PharmacyLocationDto) => void;
  openingHours?: TimeRangeDto;
  showChangeButton?: boolean;
  onSendMessagePress?: () => void;
}
