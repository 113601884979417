import React, { FunctionComponent, PropsWithChildren } from "react";
import { View, StyleProp, ViewStyle } from "react-native";
import { Icon } from "../icon";
import {
  InfoIcon,
  AlertCircleIcon,
  AlertTriangleIcon,
  CheckCircleIcon,
} from "../../icons";
import { makeStyles, useTheme } from "../../theme";
import { Text } from "../text";

export const Alert: FunctionComponent<PropsWithChildren<AlertProps>> = ({
  intent,
  title,
  description,
  style,
  testID,
  flat,
  useTriangleIcon,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const colors = useColors();

  const intentColors = colors[intent];

  return (
    <View
      style={[
        styles.root,
        !flat && {
          borderColor: intentColors.border,
          backgroundColor: intentColors.background,
          borderWidth: 1,
          borderRadius: theme.roundness,
          padding: theme.getSpacing(2),
        },
        style,
      ]}
      testID={testID}
    >
      <View>
        <Icon
          icon={getIcon(intent, useTriangleIcon)}
          color={intentColors.emphasis}
          size={16}
          testID={AlertTestIDs.icon}
        />
      </View>
      <View style={styles.textContainer}>
        <View>
          <Text
            style={{
              color: intentColors.emphasis,
              fontSize: 14,
              fontWeight: "500",
            }}
            testID={AlertTestIDs.titleText}
          >
            {title}
          </Text>
        </View>
        {description && (
          <View style={{ marginTop: theme.getSpacing(0.5) }}>
            <Text
              style={{ color: intentColors.description, fontSize: 14 }}
              testID={AlertTestIDs.descriptionText}
            >
              {description}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};

const useColors = () => {
  const theme = useTheme();
  return {
    error: {
      border: theme.palette.error[300],
      background: theme.palette.error[25],
      emphasis: theme.palette.error[700],
      description: theme.palette.error[600],
    },
    info: {
      border: theme.palette.primary[300],
      background: theme.palette.primary[25],
      emphasis: theme.palette.primary[700],
      description: theme.palette.primary[600],
    },
    warning: {
      border: theme.palette.warning[300],
      background: theme.palette.warning[25],
      emphasis: theme.palette.warning[700],
      description: theme.palette.warning[600],
    },
    success: {
      border: theme.palette.success[300],
      background: theme.palette.success[25],
      emphasis: theme.palette.success[700],
      description: theme.palette.success[600],
    },
  };
};

const getIcon = (intent: AlertIntent, useTriangleColor?: boolean) => {
  if (intent === "error") {
    if (useTriangleColor) {
      return AlertTriangleIcon;
    }
    return AlertCircleIcon;
  } else if (intent === "info") {
    return InfoIcon;
  } else if (intent === "success") {
    return CheckCircleIcon;
  } else if (intent === "warning") {
    return AlertTriangleIcon;
  } else {
    throw new Error(`Unknown intent of "${intent}"`);
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: "row",
  },
  textContainer: {
    flexDirection: "column",
    marginLeft: theme.getSpacing(1),
    flexWrap: "wrap",
    flex: 1,
    flexGrow: 1,
  },
}));

export interface AlertProps {
  intent: AlertIntent;
  title: string;
  description?: string;
  style?: StyleProp<ViewStyle>;
  testID?: string;
  flat?: boolean;
  useTriangleIcon?: boolean;
}

export const AlertTestIDs = {
  descriptionText: "description",
  titleText: "title",
  icon: "icon",
};

type AlertIntent = "info" | "warning" | "error" | "success";
