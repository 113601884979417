import React, { FunctionComponent, PropsWithChildren } from "react";
import { useFormContext } from "react-hook-form";
import { PlatformType } from "../types";
import { Platform } from "react-native";
import { DropdownSelectNative } from "./DropdownSelectNative";
import { DropdownSelectWeb } from "./DropdownSelectWeb";

export const DropdownSelect: FunctionComponent<
  PropsWithChildren<DropdownSelectProps>
> = ({
  platform = Platform.OS,
  label,
  disabled = false,
  options,
  fieldName,
  placeholder,
  isSearchable,
  navBarDropdown,
  fontColor,
  labelInlined = false,
}) => {
  const formContext = useFormContext();
  if (!formContext) {
    throw new Error("Dropdown/Select field must have a parent form context");
  }

  return platform === "web" ? (
    <DropdownSelectWeb
      label={label}
      disabled={disabled}
      options={options}
      fieldName={fieldName}
      formContext={formContext}
      placeholder={placeholder}
      isSearchable={isSearchable}
      navBarDropdown={navBarDropdown}
      fontColor={fontColor}
    />
  ) : (
    <DropdownSelectNative
      label={label}
      options={options}
      fieldName={fieldName}
      formContext={formContext}
      labelInlined={labelInlined}
    />
  );
};

export interface DropdownSelectProps {
  platform?: PlatformType;
  label?: string;
  // Disabled property is only supported for the web platform
  disabled?: boolean;
  options: Array<{ label: string; value: string }>;
  fieldName: string;
  placeholder?: string;
  isSearchable?: boolean;
  navBarDropdown?: boolean;
  fontColor?: string;
  labelInlined?: boolean;
}
