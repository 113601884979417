import React, { FunctionComponent, PropsWithChildren } from "react";
import { List } from "react-native-paper";
import { useTheme } from "../../theme";
import { StyleProp, ViewStyle } from "react-native";

export const ListAccordion: FunctionComponent<
  PropsWithChildren<ListAccordionProps>
> = ({
  title,
  expanded,
  onPress,
  children,
  backgroundColor,
  listStyle,
  titleStyle,
}) => {
  const theme = useTheme();
  return (
    <>
      <List.Accordion
        title={title}
        expanded={expanded}
        onPress={onPress}
        testID={ListAccordionTestIDs.accordion}
        style={[
          {
            margin: 0,
            padding: 0,
            height: 40,
            backgroundColor: backgroundColor ?? theme.palette.gray[50],
          },
          listStyle,
        ]}
        titleStyle={[
          {
            margin: 0,
            alignContent: "flex-start",
            textAlignVertical: "center",
            paddingLeft: theme.getSpacing(2),
            lineHeight: 20,
            fontSize: 14,
            color: theme.palette.gray[900],
          },
          titleStyle,
        ]}
      >
        {children}
      </List.Accordion>
    </>
  );
};

export interface ListAccordionProps {
  title: string;
  expanded?: boolean;
  onPress?: () => void;
  backgroundColor?: string;
  listStyle?: StyleProp<ViewStyle>;
  titleStyle?: StyleProp<ViewStyle>;
}

export const ListAccordionTestIDs = {
  accordion: "accordion",
};
