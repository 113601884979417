import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path } from "react-native-svg";
import { IconProps } from "./types";

export const FaceIdIcon: FunctionComponent<PropsWithChildren<IconProps>> = ({
  color,
  size,
  testID,
}) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      color={color}
      testID={testID}
    >
      <Path
        d="M16.8452 1.33739C17.8629 1.33739 18.6905 2.20339 18.6905 3.16109V5.59271C18.6905 5.96201 18.9836 6.2614 19.3452 6.2614C19.7069 6.2614 20 5.96201 20 5.59271V3.16109C20 1.44402 18.5657 0 16.8452 0H14.3452C13.9836 0 13.6905 0.299384 13.6905 0.668693C13.6905 1.038 13.9836 1.33739 14.3452 1.33739H16.8452Z"
        fill="white"
      />
      <Path
        d="M1.30952 16.8389C1.30952 17.7966 2.13708 18.6626 3.15476 18.6626H5.65476C6.01638 18.6626 6.30952 18.962 6.30952 19.3313C6.30952 19.7006 6.01638 20 5.65476 20H3.15476C1.43434 20 0 18.556 0 16.8389V14.4073C0 14.038 0.293147 13.7386 0.654762 13.7386C1.01638 13.7386 1.30952 14.038 1.30952 14.4073V16.8389Z"
        fill="white"
      />
      <Path
        d="M18.6905 16.8389C18.6905 17.7966 17.8629 18.6626 16.8452 18.6626H14.3452C13.9836 18.6626 13.6905 18.962 13.6905 19.3313C13.6905 19.7006 13.9836 20 14.3452 20H16.8452C18.5657 20 20 18.556 20 16.8389V14.4073C20 14.038 19.7069 13.7386 19.3452 13.7386C18.9836 13.7386 18.6905 14.038 18.6905 14.4073V16.8389Z"
        fill="white"
      />
      <Path
        d="M1.30952 3.16109C1.30952 2.20339 2.13708 1.33739 3.15476 1.33739H5.65476C6.01638 1.33739 6.30952 1.038 6.30952 0.668693C6.30952 0.299384 6.01638 0 5.65476 0H3.15476C1.43434 0 0 1.44402 0 3.16109V5.59271C0 5.96201 0.293147 6.2614 0.654762 6.2614C1.01638 6.2614 1.30952 5.96201 1.30952 5.59271V3.16109Z"
        fill="white"
      />
      <Path
        d="M6.75774 13.7449C7.0502 13.5277 7.45969 13.5937 7.67239 13.8924C7.89193 14.2007 8.72526 14.9544 10 14.9544C11.2928 14.9544 12.3467 14.1818 12.6811 13.824C12.9308 13.5568 13.3453 13.547 13.6069 13.802C13.8684 14.057 13.8781 14.4803 13.6284 14.7474C13.1295 15.2812 11.7548 16.2918 10 16.2918C8.22712 16.2918 7.0287 15.2623 6.61333 14.679C6.40063 14.3803 6.46529 13.9621 6.75774 13.7449Z"
        fill="white"
      />
      <Path
        d="M6.90476 7.53799C6.90476 7.10154 6.55832 6.74772 6.13095 6.74772C5.70359 6.74772 5.35714 7.10154 5.35714 7.53799V8.93617C5.35714 9.37263 5.70359 9.72644 6.13095 9.72644C6.55832 9.72644 6.90476 9.37263 6.90476 8.93617V7.53799Z"
        fill="white"
      />
      <Path
        d="M14.1071 6.74772C14.5345 6.74772 14.881 7.10154 14.881 7.53799V8.93617C14.881 9.37263 14.5345 9.72644 14.1071 9.72644C13.6798 9.72644 13.3333 9.37263 13.3333 8.93617V7.53799C13.3333 7.10154 13.6798 6.74772 14.1071 6.74772Z"
        fill="white"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.0119 7.41641C11.0119 7.0471 10.7188 6.74772 10.3571 6.74772C9.99553 6.74772 9.70238 7.0471 9.70238 7.41641V10.9422C9.70238 11.0838 9.69243 11.1789 9.67776 11.2429C9.66847 11.2835 9.65942 11.3021 9.65579 11.3085C9.63432 11.3232 9.54426 11.3678 9.28571 11.3678C8.9241 11.3678 8.63095 11.6672 8.63095 12.0365C8.63095 12.4058 8.9241 12.7052 9.28571 12.7052C9.81605 12.7052 10.3205 12.5801 10.6576 12.1702C10.9725 11.7874 11.0119 11.3023 11.0119 10.9422V7.41641ZM9.65697 11.3076C9.65951 11.3048 9.66128 11.3038 9.66128 11.3038C9.66128 11.3038 9.66017 11.3053 9.65697 11.3076Z"
        fill="white"
      />
    </Svg>
  );
};
