import React, { ForwardedRef, forwardRef, useImperativeHandle } from "react";
import { makeStyles, useTheme } from "assets/theme";
import { View } from "react-native";
import { Text } from "assets/components/text";
import {
  PharmacyLocationDto,
  TimeRangeDto,
} from "@digitalpharmacist/pharmacy-service-client-axios";
import { getText } from "assets/localization/localization";
import { ListAccordion } from "assets/components/list-accordion";
import { Form } from "assets/layout";
import { useAppStateStore } from "../../store/app-store";
import { StoreInformation } from "./StoreInformation";
import {
  MapSize,
  StoreSelectorItemHandler,
  StoreSelectorItemProps,
} from "./types";
import { Distance } from "../../types";
import { BottomSheet } from "assets/components/bottom-sheet";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import { IconButton } from "assets/components/icon-button";
import { CloseIcon } from "assets/icons";

export const StoreSelectorInformationBottomSheet = forwardRef<
  StoreSelectorItemHandler,
  StoreSelectorItemProps
>(
  (
    {
      currentDayOpenHours = (hoursAsStr: TimeRangeDto[]) => "",
      daysAsStr = (hours: TimeRangeDto) => "",
      item = {} as Distance<PharmacyLocationDto>,
    }: StoreSelectorItemProps,
    ref: ForwardedRef<StoreSelectorItemHandler>
  ) => {
    const theme = useTheme();
    const styles = useStyles();

    const {
      locationPharmacyHours,
      locationStoreHours,
      locationLanguages,
      locationServices,
    } = useAppStateStore();

    const sheetRef = React.useRef<BottomSheetModal>(null);

    useImperativeHandle(ref, () => ({
      show: () => sheetRef.current?.present(),
      hide: () => sheetRef.current?.dismiss(),
    }));

    const handleBottomSheetDismiss = () => {
      sheetRef.current?.dismiss();
    };

    return (
      <BottomSheet
        bottomSheetRef={sheetRef}
        height={"90%"}
        headerContent={
          <View style={styles.headerContent}>
            <View style={styles.bottomSheetViewTitle}>
              <Text>{getText("store-info")}</Text>
            </View>
            <View style={{ justifyContent: "flex-end" }}>
              <IconButton
                icon={CloseIcon}
                onPress={handleBottomSheetDismiss}
                size={20}
                color={theme.palette.gray[500]}
                logger={{
                  id: "store-info-close-icon",
                }}
              />
            </View>
          </View>
        }
      >
        <View>
          {item && (
            <StoreInformation
              item={item as PharmacyLocationDto}
              onChangePress={() => {}}
              mapProps={{
                size: MapSize.md,
              }}
            />
          )}
          {locationStoreHours.length > 0 && (
            <Form.Row>
              <Form.Column>
                <View style={styles.hoursTitle}>
                  <Text style={styles.hoursTitleText}>
                    {getText("store-hours")}
                  </Text>
                </View>
                <Form.Row>
                  <Form.Column>
                    <ListAccordion
                      title={getText("open-hours", {
                        hours: currentDayOpenHours(locationStoreHours),
                      })}
                      backgroundColor={theme.palette.white}
                      titleStyle={styles.titleStyle}
                      listStyle={styles.listStyle}
                    >
                      <Text style={styles.textColor}>
                        {getText("holiday-hours-vary")}
                      </Text>
                      {locationStoreHours.map((storeHours) => (
                        <View style={styles.hoursStyle} key={storeHours.id}>
                          <Text style={styles.textColor}>
                            {daysAsStr(storeHours)}
                          </Text>
                          <Text style={styles.textColor}>
                            {storeHours.start_hour} {":00-"}
                            {storeHours.end_hour} {":00"}
                          </Text>
                        </View>
                      ))}
                    </ListAccordion>
                  </Form.Column>
                </Form.Row>
              </Form.Column>
            </Form.Row>
          )}
          {locationPharmacyHours.length > 0 && (
            <Form.Row>
              <Form.Column>
                <View style={styles.hoursTitle}>
                  <Text style={styles.hoursTitleText}>{"Pharmacy Hours"}</Text>
                </View>
                <Form.Row>
                  <Form.Column>
                    <ListAccordion
                      title={getText("open-hours", {
                        hours: currentDayOpenHours(locationPharmacyHours),
                      })}
                      backgroundColor={theme.palette.white}
                      titleStyle={styles.titleStyle}
                      listStyle={styles.listStyle}
                    >
                      <Text style={styles.textColor}>
                        {getText("holiday-hours-vary")}
                      </Text>
                      {locationPharmacyHours.map((storeHours) => (
                        <View style={styles.hoursStyle} key={storeHours.id}>
                          <Text style={styles.textColor}>
                            {daysAsStr(storeHours)}
                          </Text>
                          <Text style={styles.textColor}>
                            {storeHours.start_hour} {":00-"}
                            {storeHours.end_hour} {":00"}
                          </Text>
                        </View>
                      ))}
                    </ListAccordion>
                  </Form.Column>
                </Form.Row>
              </Form.Column>
            </Form.Row>
          )}
          {locationLanguages.length > 0 && (
            <Form.Row>
              <Form.Column>
                <View style={styles.hoursTitle}>
                  <Text style={styles.hoursTitleText}>
                    {getText("languages-spoken")}
                  </Text>
                </View>
                <Text style={styles.textColor}>
                  {locationLanguages.map((language) => language).join(", ")}
                </Text>
              </Form.Column>
            </Form.Row>
          )}
          {locationServices.length > 0 && (
            <Form.Row>
              <Form.Column>
                <View style={styles.hoursTitle}>
                  <Text style={styles.hoursTitleText}>
                    {getText("services-offered")}
                  </Text>
                </View>
                <Text style={styles.textColor}>
                  {locationServices.map((service) => service).join(", ")}
                </Text>
              </Form.Column>
            </Form.Row>
          )}
        </View>
      </BottomSheet>
    );
  }
);

const useStyles = makeStyles((theme) => ({
  listStyle: {
    margin: 0,
    marginRight: -12,
  },
  titleStyle: {
    marginStart: -theme.getSpacing(1),
    marginEnd: -theme.getSpacing(1),
    paddingLeft: 0,
    color: theme.palette.gray[700],
  },
  hoursStyle: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: theme.getSpacing(1),
  },
  bottomSheetViewTitle: {
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  headerContent: {
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  hoursTitle: {
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
  },
  hoursTitleText: {
    fontSize: 14,
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
  textColor: {
    color: theme.palette.gray[700],
  },
}));
