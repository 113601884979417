import React, { FunctionComponent, PropsWithChildren } from "react";
import { Svg, Path, Rect } from "react-native-svg";
import { IconProps } from "./types";

export const AccountCircleIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
      color={color}
      testID={testID}
    >
      <Path
        d="M18.5 19.75V18.25C18.5 17.4544 18.1839 16.6913 17.6213 16.1287C17.0587 15.5661 16.2956 15.25 15.5 15.25H9.5C8.70435 15.25 7.94129 15.5661 7.37868 16.1287C6.81607 16.6913 6.5 17.4544 6.5 18.25V19.75M15.5 9.25C15.5 10.9069 14.1569 12.25 12.5 12.25C10.8431 12.25 9.5 10.9069 9.5 9.25C9.5 7.59315 10.8431 6.25 12.5 6.25C14.1569 6.25 15.5 7.59315 15.5 9.25Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Rect
        x="2.25"
        y="1.75"
        width="20.5"
        height="20.5"
        rx="10.25"
        strokeWidth="1.5"
      />
    </Svg>
  );
};
