import React, { FunctionComponent, PropsWithChildren } from "react";
import { StyleProp, ViewStyle, View } from "react-native";

/*
 * Wrapper for List Menu Items of varying types, each defined to maintain uniformity across similar List Menu types
 */
export const ListMenu: FunctionComponent<PropsWithChildren<ListMenuProps>> = ({
  style,
  children,
}) => {
  return <View style={style}>{children}</View>;
};

export interface ListMenuProps {
  style?: StyleProp<ViewStyle>;
}
