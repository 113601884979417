import { BaseApiConfiguration } from "../types";

/**
 * A class with static methods to store and share a static BaseApiConfiguration
 */
export default class ApiConfig {
  private static value?: BaseApiConfiguration;

  /**
   * Sets the configuration that will be used as default configuration
   * @param newValue new configuration value
   */
  static setBaseApiConfig(newValue: BaseApiConfiguration): void {
    this.value = newValue;
  }

  /**
   *
   * @returns the base api configuration instance or undefined if the instance is not settled before
   */
  static getBaseApiConfig(): BaseApiConfiguration | undefined {
    if (!this.value)
      console.error(
        "ApiConfig not configured. You should set the base configuration in order to use BaseApiConfiguration. Go to your entry point and add ApiConfig.setBaseApiConfig"
      );

    return this.value;
  }
}
