import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { EmptyStatePage } from "../../../components/empty-state-page/EmptyStatePage";
import { getText } from "assets/localization/localization";
import { ShoppingBagIcon } from "assets/icons";
import { View } from "react-native";
import { PastOrdersPrescriptionCard } from "../../../components/past-orders-prescription-card";
import orderService, { PatientOrder } from "./order-service";
import { useUserState } from "../../../store/user-store";
import { OrderDto } from "@digitalpharmacist/order-service-client-axios";
import { LocationPatientRecordDto } from "@digitalpharmacist/patient-service-client-axios";
import refillService from "../../refill/refill-service";
import { useOrderRefillStore } from "./order-store";
import patientService from "../../../api/patient-service";
import { useAppStateStore } from "../../../store/app-store";
import { StackHeaderProps } from "@react-navigation/stack";
import { useRefillMedicationsStore } from "../../refill/refill-store";
import { ScreenContainer } from "../../../../../../packages/assets/layout";

export const Orders: FunctionComponent<PropsWithChildren<OrderProps>> = ({
  navigation,
}) => {
  const { user } = useUserState();
  const { patientOrders, updateOrderRefillData, submitRefill } =
    useOrderRefillStore();
  const { pharmacyId } = useAppStateStore();
  const { updateMedicationsData } = useRefillMedicationsStore();

  useEffect(() => {
    (async () => {
      if (
        !user?.preferredPharmacyLocationId ||
        !user?.patientRecordId ||
        !user?.id ||
        !updateOrderRefillData
      )
        return;

      const allOrders = await orderService.getPatientOrders(
        user.preferredPharmacyLocationId,
        user.patientRecordId,
        user.id
      );

      updateOrderRefillData({
        patientOrders: allOrders.sort((po1, po2) =>
          po1.order.created_at < po2.order.created_at ? 1 : -1
        ),
      });
    })();
  }, []);

  const handleOpenOrderDetails = async (order: OrderDto) => {
    if (!updateOrderRefillData || !user?.patientRecordId) return;

    const refill = await refillService.getOrderRefill(
      order.location_id,
      order.patient_id,
      order.order_id
    );

    // TODO: add check here in case the order.patient_id === user.id
    const patientRecord = await patientService.findPatientRecord(
      user.patientRecordId
    );
    const patient = await patientService.findLocationPatientRecordByPR(
      order.location_id,
      patientRecord
    );

    updateOrderRefillData({
      orderRefill: refill,
      selectedPatient: patient,
      selectedPatientRecord: patientRecord,
    });

    navigation.navigate("app", { screen: "order-details" });
  };

  const handleOnPress = async (order: OrderDto) => {
    if (
      !user?.preferredPharmacyLocationId ||
      !user?.patientRecordId ||
      !user?.id ||
      !order.items ||
      !submitRefill ||
      !updateMedicationsData ||
      !updateOrderRefillData
    )
      return;

    await submitRefill(
      pharmacyId,
      user.preferredPharmacyLocationId,
      order.patient_id,
      order.fulfillment_method,
      order.items,
      user.id
    );

    updateMedicationsData({
      selectedLocationId: user.preferredPharmacyLocationId,
    });

    navigation.navigate("refill", { screen: "refill-confirmation" });

    const allOrders = await orderService.getPatientOrders(
      user.preferredPharmacyLocationId,
      user.patientRecordId,
      user.id
    );

    useOrderRefillStore.setState({
      patientOrders: allOrders.sort((po1, po2) =>
        po1.order.created_at < po2.order.created_at ? 1 : -1
      ),
    });
  };

  return (
    <ScreenContainer>
      {patientOrders?.length === 0 ? (
        <EmptyStatePage
          title={getText("orders-tab-empty-state-title")}
          description={getText("orders-tab-empty-state-description")}
          buttonText={getText("start-an-order")}
          icon={ShoppingBagIcon}
          onPress={() => {
            navigation.navigate("medications");
          }}
        />
      ) : (
        <>
          {patientOrders?.map((patientOrder, index) => (
            <View key={index}>
              <PastOrdersPrescriptionCard
                order={patientOrder.order}
                patient={
                  {
                    first_name: patientOrder.patient?.first_name,
                    last_name: patientOrder.patient?.last_name,
                    date_of_birth: patientOrder.patient?.date_of_birth,
                  } as LocationPatientRecordDto
                }
                onPress={() => handleOnPress(patientOrder.order)}
                handleOpenOrderDetails={() =>
                  handleOpenOrderDetails(patientOrder.order)
                }
              />
            </View>
          ))}
        </>
      )}
    </ScreenContainer>
  );
};

export interface OrderProps extends StackHeaderProps {}
