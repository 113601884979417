import React, { FunctionComponent, PropsWithChildren } from "react";
import { TouchableOpacity, View } from "react-native";
import { Text } from "assets/components/text";
import { useRoute, CommonActions } from "@react-navigation/native";
import { makeStyles } from "assets/theme";
import { useTheme } from "react-native-paper";
import {
  DashboardScreenRouteProp,
  RootNavigationParamList,
} from "../../navigation/RootNavigation";
import { Icon } from "assets/components/icon";
import { GradientView } from "assets/components/gradient-view";
import color from "color";

export const NavigationItems: FunctionComponent<
  PropsWithChildren<NavigationItemsProps>
> = ({ navigation, screens }) => {
  const theme = useTheme();
  const styles = useStyles();
  const route = useRoute();

  const gradientStart = theme.colors.gradientStart;
  const gradientEnd = theme.colors.gradientEnd;

  const lightenedGradientStart = color(gradientStart).lighten(0.1).hex();
  const lightenedGradientEnd = color(gradientEnd).lighten(0.18).hex();

  return (
    <>
      {screens.map((screen) => (
        <GradientView
          key={screen.name}
          style={[styles.middleContent]}
          fillGradientStops={
            route.name === screen.name
              ? [
                  {
                    stopColor: lightenedGradientStart,
                    offset: 0,
                  }, //Todo these needs to be coming from pharmacy service?! right now we don't have values for these
                  {
                    stopColor: lightenedGradientEnd,
                    offset: 100,
                  },
                ]
              : []
          }
        >
          <TouchableOpacity
            key={screen.name}
            style={[styles.middleContent]}
            onPress={() => {
              const resetAction = CommonActions.reset({
                index: 0,
                routes: [
                  { name: screen.name as keyof RootNavigationParamList },
                ],
              });
              navigation.dispatch(resetAction);
            }}
          >
            <View style={styles.tabContent}>
              <Icon icon={screen.icon} color={theme.palette.white} />
              <Text style={styles.textStyle}>{screen.label}</Text>
            </View>
          </TouchableOpacity>
        </GradientView>
      ))}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  navItem: {
    color: theme.palette.white,
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 24,
  },
  middleContent: {
    marginLeft: theme.getSpacing(2),
    alignItems: "stretch",
    flexDirection: "row",
    justifyContent: "flex-end",
    zIndex: 1,
    height: "100%",
  },
  tabContent: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: theme.getSpacing(2),
    paddingRight: theme.getSpacing(2),
  },
  textStyle: {
    color: theme.palette.white,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "500",
    marginLeft: theme.getSpacing(1),
  },
}));

export interface NavigationItemsProps {
  navigation: DashboardScreenRouteProp;
  screens: {
    name: string;
    label: string;
    icon: FunctionComponent;
  }[];
}
