import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { makeStyles, useTheme } from "assets/theme";
import { FlatList, Platform, TouchableOpacity, View } from "react-native";
import { Text } from "assets/components/text";
import { TextField } from "assets/components/text-field";
import { useForm } from "assets/form";
import { Button } from "assets/components/button";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  MedicalInfoForm,
  refreshRecordUnderCareRequestsState,
  respondToRequestUnderCare,
  updatePatientIntake,
} from "./patient-actions";
import { AccountStackParamList } from "../AccountNavigation";
import { Typeahead } from "assets/components/typeahead";
import {
  TypeaheadBaseItem,
  TypeaheadProps,
} from "assets/components/typeahead/types";
import { MedicalInfoTestIDs } from "./PatientIntakeTestIDs";
import {
  usePatientIntakeState,
  usePatientUnderCareState,
} from "./patient-store";
import { getText } from "assets/localization/localization";
import { ScreenContainer, Form } from "assets/layout";
import { Icon } from "assets/components/icon";
import { CalendarIcon } from "assets/icons";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import { BottomSheet } from "assets/components/bottom-sheet";
import { Modal } from "assets/components/modal";
import { useUserState } from "../../../store/user-store";
import {
  RecordUnderCareRequestDto,
  RecordUnderCareRequestStatus,
  UpdateRecordUnderCareRequestDto,
} from "@digitalpharmacist/patient-service-client-axios";
import { useAppStateStore } from "../../../store/app-store";
import moment from "moment";
import patientService from "../../../api/patient-service";
import { SafeAreaView } from "react-native-safe-area-context";
import { RevokeAccessMobileComponent } from "./components/revoke-access/RevokeAccessMobileComponent";
import { RevokeAccessWebComponent } from "./components/revoke-access/RevokeAccessWebComponent";

export const MedicalInfo: FunctionComponent<
  PropsWithChildren<MedicalInfoProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const methods = useForm<MedicalInfoForm>({
    defaultValues: {
      allergies: "",
      medicalConditions: "",
      otcMedications: "",
    },
  });
  const { recordUnderCareRequests, caregivers } = usePatientUnderCareState();
  const isWeb = Platform.OS === "web";
  const [isCaregiverApprovalOpen, setIsCaregiverApprovalOpen] = useState(false);
  const caregiverApprovalBottomSheetRef = React.useRef<BottomSheetModal>(null);
  const { pharmacyName } = useAppStateStore();
  const { user } = useUserState();
  const [isApproved, setIsApproved] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const revokeAccessBottomSheetRef = React.useRef<BottomSheetModal>(null);

  const { status, error } = usePatientIntakeState();

  const getOptionValue = (option: TypeaheadBaseItem) => {
    return option.value;
  };
  const getOptionText = (option: TypeaheadBaseItem) => {
    return option.text;
  };

  const allergiesTypeaheadProps = {
    testID: MedicalInfoTestIDs.allergyInput,
    label: "Allergies",
    multiple: true,
    asyncOptions: async (searchTerm) => {
      if (!searchTerm) {
        return Promise.resolve([]);
      }
      const url = `https://api-suggest.rxwiki.com/v1/meds/${searchTerm}`;
      const { data } = await (await fetch(url)).json();
      return data.map((x: any) => ({ text: x.STR, value: x.ID }));
    },
    getOptionValue,
    getOptionText,
    onChange: (value) => {
      methods.setValue("allergies", value.map((item) => item.text).join(", "));
    },
  } as TypeaheadProps<TypeaheadBaseItem>;

  const medicalConditionsTypeaheadProps = {
    testID: MedicalInfoTestIDs.medicalConditionsInput,
    label: "Medical Conditions",
    multiple: true,
    asyncOptions: async (searchTerm) => {
      if (!searchTerm) {
        return Promise.resolve([]);
      }
      const url = `https://api-suggest.rxwiki.com/v1/meds/${searchTerm}`;
      const { data } = await (await fetch(url)).json();
      return data.map((x: any) => ({ text: x.STR, value: x.ID }));
    },
    getOptionValue,
    getOptionText,
    onChange: (value) => {
      methods.setValue(
        "medicalConditions",
        value.map((item) => item.text).join(", ")
      );
    },
  } as TypeaheadProps<TypeaheadBaseItem>;

  useEffect(() => {
    refreshRecordUnderCareRequestsState();
  }, [isApproved, showModal]);

  const handleSubmit = async () => {
    updatePatientIntake(methods.getValues());
    //TODO: navigate to next screen when it is built
  };

  const handleShowModal = (show: boolean) => {
    if (Platform.OS === "web") {
      setShowModal(show);
    } else {
      if (!show) {
        revokeAccessBottomSheetRef.current?.dismiss();
      } else {
        revokeAccessBottomSheetRef.current?.present();
      }
    }
  };

  const handleOnPressRevoke = async (recordId: string, id: string) => {
    await patientService.revokeByPatientUnderCare(id, recordId);
    handleShowModal(false);
  };

  const openCaregiverApprovalAgreement = (show: boolean) => {
    setIsApproved(show);
    if (Platform.OS === "web") {
      setIsCaregiverApprovalOpen(show);
    } else {
      if (!show) {
        caregiverApprovalBottomSheetRef.current?.dismiss();
      } else {
        caregiverApprovalBottomSheetRef.current?.present();
      }
    }
  };

  const respondToCaregiverAgreement = async (
    requestId: string,
    isApproved: boolean
  ) => {
    const updateRecordUnderCareRequestDto: UpdateRecordUnderCareRequestDto = {
      status: isApproved
        ? RecordUnderCareRequestStatus.Approved
        : RecordUnderCareRequestStatus.Denied,
    };
    await respondToRequestUnderCare(requestId, updateRecordUnderCareRequestDto);
    openCaregiverApprovalAgreement(false);
  };

  return (
    <ScreenContainer>
      <Text style={styles.containerText}>{getText("medical-info-step-3")}</Text>
      <Form methods={methods}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row style={styles.typeaheadContainer1}>
          <Form.Column>
            <Typeahead {...allergiesTypeaheadProps} />
          </Form.Column>
        </Form.Row>
        <Form.Row style={styles.typeaheadContainer2}>
          <Form.Column>
            <Typeahead {...medicalConditionsTypeaheadProps} />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              testID={MedicalInfoTestIDs.otcMedicationsInput}
              label={getText("otc-medications")}
              name="otcMedications"
              disabled={status === "loading"}
              type="text"
              numberOfLines={3}
            />
          </Form.Column>
        </Form.Row>
        {(recordUnderCareRequests.length > 0 || caregivers.length > 0) && (
          <View style={styles.lineStyle}>
            <Text style={styles.requestHeaderStyle}>
              {getText("authorized-caregivers").toUpperCase()}
            </Text>
          </View>
        )}
        {caregivers.map((record) => (
          <View style={styles.requestContainer} key={record.id}>
            <View style={styles.requestTextContainer}>
              <Text style={styles.nameText}>
                {`${record.caregiver_first_name} ${record.caregiver_last_name}`.trim()}{" "}
              </Text>
              <TouchableOpacity
                style={styles.textContainer}
                onPress={() => handleShowModal(true)}
              >
                <Text
                  style={[
                    styles.pressableTextRevoke,
                    styles.leftSpacing,
                    isWeb && styles.pointer,
                  ]}
                >
                  {getText("revoke-access")}
                </Text>
              </TouchableOpacity>
            </View>
            {Platform.OS === "web" ? (
              <RevokeAccessWebComponent
                handleCancelOnPress={handleShowModal}
                handleRevokeOnPress={handleOnPressRevoke}
                showModal={showModal}
                record={record}
              ></RevokeAccessWebComponent>
            ) : (
              <RevokeAccessMobileComponent
                revokeAccessBottomSheetRef={revokeAccessBottomSheetRef}
                dismissBottomSheet={handleShowModal}
                handleRevokeOnPress={handleOnPressRevoke}
                record={record}
              ></RevokeAccessMobileComponent>
            )}
            <View style={styles.rowDirection}>
              <Text style={styles.emailTextStyle}>
                {record.caregiver_email}
              </Text>
            </View>

            <View style={styles.authorizationContainer}>
              <View style={styles.rightSpacing}>
                <Icon
                  icon={CalendarIcon}
                  color={theme.palette.gray[500]}
                  size={14}
                ></Icon>
              </View>
              <Text style={styles.requestApprovedText}>
                {getText("authorized-on", {
                  authorizedDate: `${new Date(
                    record.created_at
                  ).getDate()} ${moment(record.created_at).format(
                    "MMM"
                  )}`.trim(),
                })}
              </Text>
            </View>
          </View>
        ))}
        {recordUnderCareRequests.map((request) => (
          <View key={request.id}>
            <View style={styles.requestContainer}>
              <View style={styles.requestTextContainer}>
                <Text style={styles.nameText}>
                  {`${request.caregiver_first_name} ${request.caregiver_last_name}`.trim()}
                </Text>
                <TouchableOpacity
                  onPress={() => openCaregiverApprovalAgreement(true)}
                  style={styles.textContainer}
                >
                  <Text
                    style={[
                      styles.pressableText,
                      styles.leftSpacing,
                      isWeb && styles.pointer,
                    ]}
                  >
                    {getText("review-request")}
                  </Text>
                </TouchableOpacity>
              </View>
              <View style={styles.rowDirection}>
                <Text style={styles.emailTextStyle}>
                  {request.caregiver_email}
                </Text>
              </View>

              <View style={styles.authorizationContainer}>
                <View style={styles.rightSpacing}>
                  <Icon
                    icon={CalendarIcon}
                    color={theme.palette.warning[500]}
                    size={14}
                  ></Icon>
                </View>
                <Text style={styles.requestPendingStyle}>
                  {getText("request-status", {
                    submittedDate: `${new Date(
                      request.created_at
                    ).getDate()} ${moment(request.created_at).format(
                      "MMM"
                    )}`.trim(),
                  })}
                </Text>
              </View>
              {Platform.OS === "web" ? (
                <WebComponent
                  isCaregiverApprovalOpen={isCaregiverApprovalOpen}
                  pharmacyName={pharmacyName}
                  request={request}
                  openCaregiverApprovalAgreement={() =>
                    openCaregiverApprovalAgreement(false)
                  }
                  respondToCaregiverAgreement={respondToCaregiverAgreement}
                ></WebComponent>
              ) : (
                <MobileComponent
                  caregiverApprovalBottomSheetRef={
                    caregiverApprovalBottomSheetRef
                  }
                  request={request}
                  openCaregiverApprovalAgreement={() =>
                    openCaregiverApprovalAgreement(false)
                  }
                  pharmacyName={pharmacyName}
                  respondToCaregiverAgreement={respondToCaregiverAgreement}
                ></MobileComponent>
              )}
            </View>
          </View>
        ))}
        <Form.Actions>
          <Button
            onPress={handleSubmit}
            hierarchy="primary"
            loading={status === "loading"}
            logger={{ id: "medical-info-submit-button" }}
          >
            {getText("next")}
          </Button>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

const WebComponent: FunctionComponent<
  PropsWithChildren<{
    request: RecordUnderCareRequestDto;
    pharmacyName: string;
    isCaregiverApprovalOpen: boolean;
    openCaregiverApprovalAgreement: (val: boolean) => void;
    respondToCaregiverAgreement: (
      requestId: string,
      isApproved: boolean
    ) => void;
  }>
> = ({
  request,
  pharmacyName,
  isCaregiverApprovalOpen,
  openCaregiverApprovalAgreement,
  respondToCaregiverAgreement,
}) => {
  const theme = useTheme();
  const styles = useStyles();

  return (
    <Modal
      title={getText("caregiver-approval-agreement")}
      show={isCaregiverApprovalOpen}
      okButtonProps={{
        onPress: () => respondToCaregiverAgreement(request.id, true),
        logger: {
          id: "caregiver-agreement-authorize-button-modal",
        },
        text: getText("authorize"),
      }}
      dismissButtonProps={{
        onPress: () => openCaregiverApprovalAgreement(false),
        logger: { id: "caregiver-agreement-close-button-modal" },
      }}
      cancelButtonProps={{
        onPress: () => respondToCaregiverAgreement(request.id, false),
        logger: { id: "caregiver-agreement-decline-button-modal" },
        text: getText("decline"),
        hierarchy: "destructive-secondary",
      }}
      isScrollable
      height={800}
    >
      <View style={styles.contentAlignmentCenter}>
        <Text style={styles.modalText}>
          {getText("puc-permission-access-to-records", {
            firstName: request.caregiver_first_name,
            lastName: request.caregiver_last_name,
          })}
        </Text>
        <View style={styles.lineStyle}>
          <Text style={styles.modalHeader}>
            {getText("if-you-authorize-person")}
          </Text>
        </View>
        <View style={styles.leftSpacing}>
          <FlatList
            data={[
              { key: getText("manage-prescriptions") },
              { key: getText("pick-up-prescriptions") },
              {
                key: getText("discuss-care", {
                  pharmacyName: pharmacyName,
                }),
              },
              {
                key: getText("manage-health"),
              },
            ]}
            renderItem={({ item }) => {
              return (
                <View style={{ marginBottom: 10 }}>
                  <Text style={styles.modalText}>{`\u2022 ${item.key}`}</Text>
                </View>
              );
            }}
          />
        </View>
        <Text
          style={[
            styles.modalHeader,
            {
              marginTop: theme.getSpacing(2),
            },
          ]}
        >
          {getText("access-validity")}
        </Text>
        <Text style={[styles.modalText, { marginTop: theme.getSpacing(1) }]}>
          {getText("revoke-access-in-settings")}
        </Text>
      </View>
    </Modal>
  );
};

const MobileComponent: FunctionComponent<
  PropsWithChildren<{
    caregiverApprovalBottomSheetRef: React.RefObject<BottomSheetModal>;
    openCaregiverApprovalAgreement: (val: boolean) => void;
    request: RecordUnderCareRequestDto;
    pharmacyName: string;
    respondToCaregiverAgreement: (
      requestId: string,
      isApproved: boolean
    ) => void;
  }>
> = ({
  caregiverApprovalBottomSheetRef,
  openCaregiverApprovalAgreement,
  request,
  pharmacyName,
  respondToCaregiverAgreement,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <BottomSheet
      bottomSheetRef={caregiverApprovalBottomSheetRef}
      title={getText("caregiver-approval-agreement")}
      height={"90%"}
      onDismiss={() => openCaregiverApprovalAgreement(false)}
      footerContent={
        <SafeAreaView style={styles.buttonContainer}>
          <Button
            hierarchy="destructive-secondary"
            logger={{
              id: "decline-bottom-sheet-button",
            }}
            style={styles.declineButtonStyle}
            onPress={() => respondToCaregiverAgreement(request.id, false)}
          >
            {getText("decline")}
          </Button>
          <Button
            hierarchy="primary"
            logger={{
              id: "authorize-bottom-sheet-button",
            }}
            style={{ width: "50%" }}
            onPress={() => respondToCaregiverAgreement(request.id, true)}
          >
            {getText("authorize")}
          </Button>
        </SafeAreaView>
      }
    >
      <View style={styles.contentAlignmentCenter}>
        <Text style={styles.modalText}>
          {getText("puc-permission-access-to-records", {
            firstName: request.caregiver_first_name,
            lastName: request.caregiver_last_name,
          })}
        </Text>
        <View style={styles.lineStyle}>
          <Text style={styles.modalHeader}>
            {getText("if-you-authorize-person")}
          </Text>
        </View>
        <View style={styles.leftSpacing}>
          <FlatList
            data={[
              { key: getText("manage-prescriptions") },
              { key: getText("pick-up-prescriptions") },
              {
                key: getText("discuss-care", {
                  pharmacyName: pharmacyName,
                }),
              },
              {
                key: getText("manage-health"),
              },
            ]}
            renderItem={({ item }) => {
              return (
                <View style={{ marginBottom: 10 }}>
                  <Text style={styles.modalText}>{`\u2022 ${item.key}`}</Text>
                </View>
              );
            }}
          />
        </View>
        <Text
          style={[
            styles.modalHeader,
            {
              marginTop: theme.getSpacing(2),
            },
          ]}
        >
          {getText("access-validity")}
        </Text>
        <Text style={styles.modalText}>
          {getText("revoke-access-in-settings")}
        </Text>
      </View>
    </BottomSheet>
  );
};

const useStyles = makeStyles((theme) => ({
  typeaheadContainer1: {
    marginBottom: theme.getSpacing(2),
    zIndex: 2,
  },
  typeaheadContainer2: {
    marginBottom: theme.getSpacing(2),
    zIndex: 1,
  },
  nameText: {
    fontWeight: "400",
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[700],
  },
  pressableText: {
    ...theme.fonts.medium,
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.primary[600],
  },
  pressableTextRevoke: {
    ...theme.fonts.medium,
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.error[600],
  },
  textContainer: { flexDirection: "row", alignItems: "center" },
  leftSpacing: { marginLeft: theme.getSpacing(1) },
  pointer: {
    //@ts-ignore
    [Platform.OS === "web" ? "cursor" : undefined]: "pointer", // only for web purposes
  },
  requestTextContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  lineStyle: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[200],
    borderBottomWidth: 1,
  },
  requestHeaderStyle: {
    marginBottom: theme.getSpacing(1),
    fontSize: 12,
    lineHeight: 16,
    fontWeight: "800",
    color: theme.palette.gray[900],
  },
  emailTextStyle: {
    color: theme.palette.gray[900],
    fontSize: 12,
    lineHeight: 18,
    fontWeight: "400",
  },
  requestContainer: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(2),
  },
  modalText: {
    lineHeight: 24,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: "400",
  },
  modalHeader: {
    lineHeight: 28,
    fontWeight: "600",
    fontSize: 18,
    color: theme.palette.gray[900],
  },
  paddingModalHeader: {
    padding: theme.getSpacing(1),
  },
  buttonContainer: {
    flexDirection: "row",
    alignSelf: "center",
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
    flex: 1,
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
  },
  declineButtonStyle: { marginEnd: theme.getSpacing(2), width: "50%" },
  authorizationContainer: {
    flexDirection: "row",
    marginTop: theme.getSpacing(1),
  },
  rightSpacing: { marginRight: theme.getSpacing(0.5) },
  requestPendingStyle: {
    color: theme.palette.warning[500],
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 20,
  },
  containerText: {
    color: theme.palette.gray[900],
    fontSize: 14,
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
    textAlign: "center",
  },
  requestApprovedText: {
    color: theme.palette.gray[500],
    fontSize: 14,
    fontWeight: "400",
    lineHeight: 20,
  },
  contentAlignmentCenter: { alignContent: "center" },
  rowDirection: { flexDirection: "row" },
}));

type MedicalInfoProps = NativeStackScreenProps<
  AccountStackParamList,
  "medical-info"
>;
