import { DefaultTheme, configureFonts } from "react-native-paper";
import { createBrandedColors } from "./utils/colors";

let Lato = "Lato_400Regular";
let LatoBold = "Lato_700Bold";
let LatoLight = "Lato_300Light";
let LatoThin = "Lato_100Thin";

export const theme: ReactNativePaper.Theme = {
  ...DefaultTheme,
  roundness: 8,
  colors: {
    ...DefaultTheme.colors,
    ...createBrandedColors(),
  },
  // For spacing, aim for something like MUI: https://mui.com/system/spacing/#transformation
  spacing: 8,
  getSpacing: function (factor: 0.5 | 1 | 2 | 3 | 4) {
    return this.spacing * factor;
  },
  webMaxWidth: 425,
  palette: {
    black: "#000000",
    white: "#FFFFFF",
    transparent: "rgba(0, 0, 0, 0)",
    gray: {
      25: "#FCFCFD",
      50: "#F9FAFB",
      100: "#F2F4F7",
      200: "#EAECF0",
      300: "#D0D5DD",
      400: "#98A2B3",
      500: "#667085",
      600: "#475467",
      700: "#344054",
      800: "#1D2939",
      900: "#101828",
    },
    primary: {
      25: "#F3FAFF",
      50: "#E9F6FF",
      100: "#CEECFF",
      200: "#A1D9FA",
      300: "#77C7F6",
      400: "#4FB5F1",
      500: "#27A4ED",
      600: "#0093E8",
      700: "#0171C2",
      800: "#01529F",
      900: "#003078",
    },
    error: {
      25: "#FFFBFA",
      50: "#FEF3F2",
      100: "#FEE4E2",
      200: "#FECDCA",
      300: "#FDA29B",
      400: "#F97066",
      500: "#F04438",
      600: "#D92D20",
      700: "#B42318",
      800: "#912018",
      900: "#7A271A",
    },
    warning: {
      25: "#FFFCF5",
      50: "#FFFAEB",
      100: "#FEF0C7",
      200: "#FEDF89",
      300: "#FEC84B",
      400: "#FDB022",
      500: "#F79009",
      600: "#DC6803",
      700: "#B54708",
      800: "#93370D",
      900: "#7A2E0E",
    },
    success: {
      25: "#F6FEF9",
      50: "#ECFDF3",
      100: "#D1FADF",
      200: "#A6F4C5",
      300: "#6CE9A6",
      400: "#32D583",
      500: "#12B76A",
      600: "#039855",
      700: "#027A48",
      800: "#05603A",
      900: "#054F31",
    },
    purple: {
      25: "#FAFAFF",
      50: "#F4F3FF",
      100: "#EBE9FE",
      200: "#D9D6FE",
      300: "#BDB4FE",
      400: "#9B8AFB",
      500: "#7A5AF8",
      600: "#6938EF",
      700: "#5925DC",
      800: "#4A1FB8",
      900: "#3E1C96",
    },
    cyan: {
      25: "#F5FEFF",
      50: "#ECFDFF",
      100: "#CFF9FE",
      200: "#A5F0FC",
      300: "#67E3F9",
      400: "#22CCEE",
      500: "#06AED4",
      600: "#088AB2",
      700: "#0E7090",
      800: "#155B75",
      900: "#164C63",
    },
  },
  fonts: configureFonts({
    web: {
      regular: {
        fontFamily: Lato,
        fontWeight: "normal",
      },
      medium: {
        fontFamily: LatoBold,
        fontWeight: "bold",
      },
      light: {
        fontFamily: LatoLight,
        fontWeight: "normal",
      },
      thin: {
        fontFamily: LatoThin,
        fontWeight: "normal",
      },
    },
    ios: {
      regular: {
        fontFamily: Lato,
        fontWeight: "normal",
      },
      medium: {
        fontFamily: LatoBold,
        fontWeight: "bold",
      },
      light: {
        fontFamily: LatoLight,
        fontWeight: "normal",
      },
      thin: {
        fontFamily: LatoThin,
        fontWeight: "normal",
      },
    },
    android: {
      regular: {
        fontFamily: Lato,
        fontWeight: "normal",
      },
      medium: {
        fontFamily: LatoBold,
        fontWeight: "bold",
      },
      light: {
        fontFamily: LatoLight,
        fontWeight: "normal",
      },
      thin: {
        fontFamily: LatoThin,
        fontWeight: "normal",
      },
    },
  }),
};

export default theme;
