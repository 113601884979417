import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path, Polyline, Rect, Line, Circle } from "react-native-svg";
import { IconProps } from "./types";

export const MedicationIcon: FunctionComponent<PropsWithChildren<IconProps>> =
  ({ color, size, testID }) => {
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
        color={color}
        testID={testID}
      >
        <Path d="M20.5001 3V7" />
        <Path d="M22.5001 5L18.5001 5" />
        <Path d="M3.50012 12C3.50012 12.5523 3.05241 13 2.50012 13C1.94784 13 1.50012 12.5523 1.50012 12C1.50012 11.4477 1.94784 11 2.50012 11C3.05241 11 3.50012 11.4477 3.50012 12Z" />
        <Path d="M16.5001 7C16.5001 7.55228 16.0524 8 15.5001 8C14.9479 8 14.5001 7.55228 14.5001 7C14.5001 6.44772 14.9479 6 15.5001 6C16.0524 6 16.5001 6.44772 16.5001 7Z" />
        <Path d="M11.5 12.5C11.5 13.3284 10.8284 14 10 14C9.17159 14 8.50002 13.3284 8.50002 12.5C8.50002 11.6716 9.17159 11 10 11C10.8284 11 11.5 11.6716 11.5 12.5Z" />
        <Circle cx="5.00018" cy="17.5" r="2.5" />
        <Rect
          x="3.18604"
          y="9.22398"
          width="4.5"
          height="9.5"
          rx="2.25"
          transform="rotate(-101.186 3.18604 9.22398)"
        />
        <Circle cx="18.5001" cy="16" r="5" />
        <Path d="M22.2684 17.1392L15.0331 15.1644" />
      </Svg>
    );
  };
