import React, { FunctionComponent, PropsWithChildren, RefObject } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  GestureResponderEvent,
  SafeAreaView,
  Platform,
} from "react-native";
import { Camera as ExpoCamera, CameraType, FlashMode } from "expo-camera";
import { ZapIcon, ZapOffIcon } from "assets/icons";
import { useTheme } from "assets/theme";
import { CameraMask } from "./CameraMask";
import { getText } from "assets/localization/localization";

/*
 * Show the screen for taking a photo
 */
export const CameraScreen: FunctionComponent<
  PropsWithChildren<CameraScreenProps>
> = ({
  cameraType,
  flashMode,
  cameraRef,
  onTakePicturePress,
  onToggleFlashPress,
  onPressCancel,
  title,
  mask,
}) => {
  const theme = useTheme();
  return (
    <View
      style={{
        flex: 1,
        width: "100%",
      }}
    >
      <ExpoCamera
        type={cameraType}
        flashMode={flashMode}
        style={{ flex: 1 }}
        ref={cameraRef}
      >
        <CameraScreenHeader
          isFlashEnabled={flashMode !== FlashMode.off}
          title={title}
          onPressFlash={onToggleFlashPress}
          onPressCancel={onPressCancel}
        />
        {mask === "card" && (
          <CameraMask type={mask} label={getText("camera-mask-label")} />
        )}
        <View
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            padding: theme.getSpacing(2),
            alignItems: "center",
            flex: 1,
            backgroundColor: theme.palette.black,
          }}
        >
          <View style={{ padding: theme.getSpacing(1) }}>
            <TouchableOpacity onPress={onTakePicturePress}>
              <View
                style={{
                  borderRadius: 32,
                  width: 64,
                  height: 64,
                  borderColor: theme.palette.white,
                  borderWidth: 4,
                  position: "relative",
                }}
              >
                <View
                  style={{
                    position: "absolute",
                    top: 4,
                    left: 4,
                    width: 48,
                    height: 48,
                    borderRadius: 24,
                    backgroundColor: theme.palette.white,
                  }}
                ></View>
              </View>
            </TouchableOpacity>
          </View>
        </View>
      </ExpoCamera>
    </View>
  );
};

const CameraScreenHeader = ({
  title,
  isFlashEnabled,
  onPressCancel,
  onPressFlash,
}: {
  title: string;
  isFlashEnabled: boolean;
  onPressCancel: (event: GestureResponderEvent) => void;
  onPressFlash: (event: GestureResponderEvent) => void;
}) => {
  const theme = useTheme();
  return (
    <View
      style={{
        backgroundColor: theme.palette.black,
        height: 100,
        ...Platform.select({
          android: {
            paddingTop: theme.getSpacing(4),
          },
        }),
      }}
    >
      <SafeAreaView>
        <View
          style={{
            alignItems: "center",
            width: "100%",
            marginBottom: theme.getSpacing(1),
          }}
        >
          <Text
            style={{
              color: theme.palette.white,
              fontSize: 18,
              lineHeight: 24,
              fontWeight: "500",
            }}
          >
            {title}
          </Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            padding: theme.getSpacing(2),
            top: -52,
          }}
        >
          <View>
            <TouchableOpacity
              onPress={onPressFlash}
              style={{ padding: theme.getSpacing(1) }}
            >
              {isFlashEnabled ? (
                <ZapOffIcon size={16} color={theme.palette.white} />
              ) : (
                <ZapIcon size={16} color={theme.palette.white} />
              )}
            </TouchableOpacity>
          </View>
          <View>
            <TouchableOpacity
              onPress={onPressCancel}
              style={{ padding: theme.getSpacing(1) }}
            >
              <Text style={{ color: theme.palette.white }}>Cancel</Text>
            </TouchableOpacity>
          </View>
        </View>
      </SafeAreaView>
    </View>
  );
};

interface CameraScreenProps {
  cameraType: CameraType;
  flashMode: FlashMode;
  cameraRef: RefObject<ExpoCamera>;
  onTakePicturePress: (event: GestureResponderEvent) => void;
  onToggleFlashPress: (event: GestureResponderEvent) => void;
  title: string;
  onPressCancel: (event: GestureResponderEvent) => void;
  mask?: "card";
}
