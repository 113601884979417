import React, { FunctionComponent, PropsWithChildren } from "react";
import {
  createStackNavigator,
  StackHeaderProps,
} from "@react-navigation/stack";
import { GestureResponderEvent } from "react-native";
import { NativeHeader } from "assets/components/native-header";
import type { StackNavigationProp } from "@react-navigation/stack";
import { getText } from "assets/localization/localization";
import { RefillGuestFindStore } from "./RefillGuestFindStore";

const Stack = createStackNavigator<RefillGuestStackParamList>();

export const RefillGuestNavigation: FunctionComponent<
  PropsWithChildren<RefillGuestNavigationProps>
> = ({}) => {
  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        header: RefillNavigationHeader,
      }}
    >
      <Stack.Group>
        <Stack.Screen
          name="refill-guest-find-store"
          component={RefillGuestFindStore}
        />
      </Stack.Group>
    </Stack.Navigator>
  );
};

interface RefillGuestNavigationProps {}

export type RefillGuestStackParamList = {
  "refill-guest-find-store": undefined;
};

export type RefillStackNavigationProp =
  StackNavigationProp<RefillGuestStackParamList>;

const RefillNavigationHeader: FunctionComponent<
  PropsWithChildren<StackHeaderProps>
> = (props) => {
  const routeName = props.route.name as keyof RefillGuestStackParamList;

  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  return (
    <NativeHeader
      title={getText("medications-fill-your-prescription")}
      actionLabel={getText("cancel")}
      onBack={handleBack}
      showAction={true}
      showBack={true}
    />
  );
};
