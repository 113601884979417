import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path, Circle } from "react-native-svg";
import { IconProps } from "./types";

export const CircleBulletIcon: FunctionComponent<PropsWithChildren<IconProps>> =
  ({ color, size, testID }) => {
    return (
      <Svg
        width={size}
        height={size}
        viewBox="0 0 21 24"
        fill="none"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
        color={color}
        testID={testID}
      >
        <Circle cx={10.5} cy={12} r={5.2} fill={color} />
      </Svg>
    );
  };
