import React, {
  FunctionComponent,
  PropsWithChildren,
  useState,
  useEffect,
} from "react";
import AppLoading from "expo-app-loading";
import {
  useFonts,
  Lato_100Thin,
  Lato_300Light,
  Lato_400Regular,
  Lato_700Bold,
} from "@expo-google-fonts/lato";
import { PlatformType } from "assets/components/types";
import { Platform } from "react-native";
import * as SplashScreen from "expo-splash-screen";

/*
 * Block rendering of app until required resources and data have been loaded
 */
export const AppLoader: FunctionComponent<PropsWithChildren<AppLoaderProps>> =
  ({ children, onBeforeLoad, platform = Platform.OS }) => {
    const [fontsLoaded] = useFonts({
      Lato_100Thin,
      Lato_300Light,
      Lato_400Regular,
      Lato_700Bold,
    });
    const isWeb = platform === "web";

    const [appLoaded, setAppLoaded] = useState(false);
    useEffect(() => {
      (async () => {
        if (!isWeb) {
          await SplashScreen.preventAutoHideAsync();
        }
        // load anything that it is related static contents like fonts
        if (onBeforeLoad) {
          await onBeforeLoad();
        }
        if (!isWeb) {
          await SplashScreen.hideAsync();
        }

        setAppLoaded(true);
      })();
    }, []);

    if (!fontsLoaded || !appLoaded) {
      // https://docs.expo.dev/versions/latest/sdk/app-loading/
      return isWeb ? <AppLoading /> : null;
    }

    return <>{children}</>;
  };

export interface AppLoaderProps {
  onBeforeLoad?: () => Promise<void>;
  platform?: PlatformType;
}
