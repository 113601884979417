import { Form, ScreenContainer } from "assets/layout";
import theme, { makeStyles } from "assets/theme";
import React, {
  FunctionComponent,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { Button } from "assets/components/button";
import { Text } from "assets/components/text/Text";
import { getText } from "assets/localization/localization";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RefillStackParamList } from "./RefillNavigation";
import { RefillTestIDs } from "./RefillTestIDs";
import { RadioButtonGroupField } from "assets/components/radio-button-group";
import { useForm } from "react-hook-form";
import {
  useRefillDetailsStore,
  useRefillMedicationsStore,
} from "./refill-store";
import patientService from "../../api/patient-service";
import { useUserState } from "../../store/user-store";
import { View } from "react-native";

export const RefillDetails: FunctionComponent<
  PropsWithChildren<RefillDetailsProps>
> = ({ navigation }) => {
  const { user } = useUserState();
  const { prescriptionsTogether, easyOpenBottleCaps } = useRefillDetailsStore();
  const { selectedPatientRecord } = useRefillMedicationsStore();
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const form = useForm({
    defaultValues: {
      prescriptionsTogether: prescriptionsTogether,
      easyOpenBottleCaps: easyOpenBottleCaps,
    },
  });

  useEffect(() => {
    if (!user?.patientRecordId || !selectedPatientRecord) return;

    if (selectedPatientRecord.prefers_med_sync !== null) {
      form.setValue(
        "prescriptionsTogether",
        selectedPatientRecord.prefers_med_sync ? "yes" : "no"
      );
    }

    if (selectedPatientRecord.prefers_easy_open_bottle_caps !== null) {
      form.setValue(
        "easyOpenBottleCaps",
        selectedPatientRecord.prefers_easy_open_bottle_caps ? "yes" : "no"
      );
    }
  }, [user?.patientRecordId, selectedPatientRecord]);

  const handleOnPressDone = async () => {
    if (!user || !user.patientRecordId || !selectedPatientRecord) return;

    const pmi = {
      id: user.patientRecordId,
      gender: selectedPatientRecord.gender,
      allergies: selectedPatientRecord.allergies,
      medical_conditions: selectedPatientRecord.medical_conditions,
      prefers_med_sync: form.getValues().prescriptionsTogether === "yes",
      prefers_easy_open_bottle_caps:
        form.getValues().easyOpenBottleCaps === "yes",
    };

    await patientService.updatePatientRecord(pmi.id, pmi);

    setIsLoading(false);
    navigation.navigate("refill-choose-prescription-method");
  };

  return (
    <ScreenContainer>
      <Form methods={form}>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              label={getText("prescription-ready-each-month")}
              rules={{
                required: getText("prescriptions-is-required"),
              }}
              name="prescriptionsTogether"
              values={[
                {
                  text: getText("yes"),
                  value: "yes",
                },
                {
                  text: getText("no"),
                  value: "no",
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              label={getText("easy-open-bottle-caps-confirm")}
              rules={{
                required: getText("field-required"),
              }}
              name="easyOpenBottleCaps"
              values={[
                {
                  text: getText("yes-easy-caps"),
                  value: "yes",
                },
                {
                  text: getText("no-safety-caps"),
                  value: "no",
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <Text style={styles.paragraph}>
              {getText("easy-open-bottle-caps-description")}
            </Text>
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <View style={styles.buttonContainer}>
            <Button
              hierarchy="primary"
              onPress={handleOnPressDone}
              disabled={isLoading}
              testID={RefillTestIDs.refillOrderDetailsSubmit}
              logger={{ id: RefillTestIDs.refillOrderDetailsSubmit }}
            >
              {getText("next")}
            </Button>
          </View>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

export interface RefillDetailsProps
  extends NativeStackScreenProps<RefillStackParamList, "refill-details"> {}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    flex: 1,
    justifyContent: "flex-end",
    width: "100%",
  },
  paragraph: {
    ...theme.fonts.regular,
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[500],
  },
}));
