import { FunctionComponent, useEffect } from "react";
import { Text } from "assets/components/text";
import { TextField } from "assets/components/text-field";
import { useForm } from "assets/form";
import { View } from "react-native";
import theme, { getSpacing } from "assets/theme";
import { Button } from "assets/components/button";
import { useRegisterState } from "./register-store";
import { CreateAccountForm, createPassword } from "./register-actions";
import { useNavigation } from "@react-navigation/native";
import {
  AuthStackNavigationProp,
  CreatePasswordProps,
} from "../../navigation/AuthNavigation";
import { CreatePasswordTestIDs } from "./RegisterTestIDs";
import { PasswordRules } from "../../common/PasswordRules";
import { getText } from "assets/localization/localization";
import { ScreenContainer, Form } from "assets/layout";
import { isPassword } from "@digitalpharmacist/validation-dp";

export const CreatePassword: FunctionComponent<CreatePasswordProps> = ({
  route,
}) => {
  const { email, tempPassword, username } = route.params;
  const methods = useForm<CreateAccountForm>({
    defaultValues: {
      password: "",
      passwordConfirm: "",
    },
  });

  const passwordValue = methods.watch("password");
  const { status, error } = useRegisterState();
  const navigation = useNavigation<AuthStackNavigationProp>();

  const handleSubmit = async () => {
    createPassword(methods.getValues(), navigation);
  };

  const handleGoToLogin = async () => {
    navigation.navigate("intro");
  };

  useEffect(() => {
    methods.setValue("email", email);
    methods.setValue("temp_password", tempPassword);
    methods.setValue("cognito_username", username);
  }, [email, tempPassword, username]);

  return (
    <ScreenContainer>
      <View>
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 30,
            paddingTop: getSpacing(2),
            paddingBottom: getSpacing(1),
            textAlign: "center",
          }}
        >
          {getText("create-new-password")}
        </Text>
        <Text
          style={{
            color: theme.palette.gray[600],
            fontSize: 16,
            paddingBottom: getSpacing(2),
            textAlign: "center",
          }}
        >
          {getText("please-enter-your-new-password")}
        </Text>
      </View>
      <Form methods={methods}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              testID={CreatePasswordTestIDs.passwordInput}
              label={getText("new-password")}
              name="password"
              rules={{
                required: getText("password-is-required"),
                validate: {
                  value: () => {
                    return isPassword(methods.getValues().password)
                      ? true
                      : getText("password-entry-does-not-meet-criteria");
                  },
                },
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              testID={CreatePasswordTestIDs.passwordConfirmInput}
              label={getText("confirm-new-password")}
              name="passwordConfirm"
              rules={{
                required: getText("password-is-required"),
                validate: {
                  value: () => {
                    return methods.getValues().password ===
                      methods.getValues().passwordConfirm
                      ? true
                      : getText("passwords-must-match");
                  },
                },
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
              type="password"
            />
          </Form.Column>
        </Form.Row>
        <Form.Content>
          <PasswordRules passwordValue={passwordValue}></PasswordRules>
        </Form.Content>
        <Form.Actions>
          <View style={{ marginBottom: getSpacing(2) }}>
            <Button
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === "loading"}
              logger={{ id: "create-password-submit-button" }}
            >
              {getText("create-account")}
            </Button>
          </View>
          <View style={{ marginBottom: getSpacing(2) }}>
            <Button
              onPress={handleGoToLogin}
              hierarchy="tertiary"
              logger={{ id: "create-password-back-to-login-button" }}
            >
              {getText("go-to-login")}
            </Button>
          </View>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};
