import { FormFullDataDto } from "@digitalpharmacist/forms-service-client-axios";
import { PatientRecordDto } from "@digitalpharmacist/patient-service-client-axios";
import create from "zustand";
import { ApiError, AsyncStatus } from "../../store/types";

export const useFillInFormStore = create<FillInFormStore>((set) => ({
  records: undefined,
  status: "idle",
}));

interface FillInFormStore {
  records?: PatientRecordDto[];
  patientRecord?: PatientRecordDto;
  form?: FormFullDataDto;
  formHtmlContent?: string;
  status: AsyncStatus;
  error?: ApiError;
}
