import React, { FunctionComponent, PropsWithChildren } from "react";
import {
  createStackNavigator,
  StackHeaderProps,
  StackScreenProps,
} from "@react-navigation/stack";
import { Intro } from "../screens/intro/Intro";
import { Login } from "../screens/login/Login";
import { ResetLink } from "../screens/reset-password/ResetLink";
import { ResetLinkSent } from "../screens/reset-password/ResetLinkSent";
import { ResetSuccessful } from "../screens/reset-password/ResetSuccessful";
import { ResetUnsuccessful } from "../screens/reset-password/ResetUnsuccessful";
import { ResetPassword } from "../screens/reset-password/ResetPassword";
import { GestureResponderEvent, Image, SafeAreaView } from "react-native";
import { GradientView } from "assets/components/gradient-view";
import { IconButton, Text } from "react-native-paper";
import { ChevronLeftIcon } from "assets/icons";
import { useTheme } from "assets/theme";
import { RegisterDetails } from "../screens/register/RegisterDetails";
import { PhoneVerification } from "../screens/verify/PhoneVerification";
import {
  isUserNeedEmailVerification,
  isUserNeedPhoneVerification,
  useUserState,
  isEmailVerified,
  userHasPreferredPharmacy,
  userHasDetails,
  hasSeenFindMedsScreen,
} from "../store/user-store";
import { RegisterEmail } from "../screens/register/RegisterEmail";
import type { StackNavigationProp } from "@react-navigation/stack";
import { RegisterStore } from "../screens/register/RegisterStore";
import { RegisterStoreDetails } from "../screens/register/RegisterStoreDetails";
import { RefillGuestNavigation } from "../screens/refill-guest/RefillGuestNavigation";
import { STATIC_DEV_LUMISTRY, PHARMACY_ID } from "../common/constants";
import { UpdateWeakPassword } from "../screens/reset-password/UpdateWeakPassword";
import { CreatePassword } from "../screens/register/CreatePassword";
import { LoginEnterEmail } from "../screens/login/LoginEnterEmail";
import { RegisterConfirmation } from "../screens/register/RegisterConfirmation";
import { getText } from "assets/localization/localization";
import { NativeHeader } from "assets/components/native-header";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { StorageKeys } from "../../enums/storage-keys";
import {
  FindPrescriptionHeader,
  FindPrescriptionNavigation,
} from "../screens/find-prescription-flow/FindPrescriptionNavigation";
import { TouchableOpacity } from "react-native-gesture-handler";
import { LoginAttemptsExceeded } from "../screens/login/LoginAttemptsExceeded";

const Stack = createStackNavigator<AuthStackParamList>();
const uri = STATIC_DEV_LUMISTRY + PHARMACY_ID + "/images/logo_on_light.png";

export const AuthNavigation: FunctionComponent = () => {
  const isNeedingEmailVerification = useUserState((x) => {
    return isUserNeedEmailVerification(x.user);
  });

  const isNeedingPhoneVerification = useUserState((x) => {
    return isUserNeedPhoneVerification(x.user);
  });

  const isEmailVerificationComplete = useUserState((x) => {
    return isEmailVerified(x.user);
  });

  const userHasToSetPreferredPharmacyLocation = useUserState((x) => {
    return userHasPreferredPharmacy(x.user);
  });

  const userHasRegistrationDetails = useUserState((x) => {
    return userHasDetails(x.user);
  });
  const userHasDetailsSet = useUserState((x) => {
    return userHasDetails(x.user);
  });

  const userHasSeenFindMedsScreen = useUserState((x) => {
    return hasSeenFindMedsScreen(x.user);
  });

  const [hasAuthToken, setAuthToken] = React.useState(false);

  AsyncStorage.getItem(StorageKeys.AccessToken).then((token) => {
    if (token) setAuthToken(true);
    else setAuthToken(false);
  });

  return (
    <Stack.Navigator
      screenOptions={{
        headerShown: true,
        header: AuthNavigationHeader,
        title: getText("app"), // fallback title
      }}
    >
      {!isNeedingEmailVerification && !hasAuthToken && (
        <>
          <Stack.Screen
            name="intro"
            component={Intro}
            options={{ title: getText("intro") }}
          />
          <Stack.Screen
            name="login-enter-email"
            component={LoginEnterEmail}
            options={{ title: getText("login-enter-email") }}
          />
          <Stack.Screen
            name="login"
            component={Login}
            options={{ title: getText("login") }}
          />
          <Stack.Screen
            name="login-attempts-exceeded"
            component={LoginAttemptsExceeded}
            options={{ title: getText("login-attempts-exceeded") }}
          />
          <Stack.Screen
            name="create-password"
            component={CreatePassword}
            options={{ title: getText("create-password") }}
          />
          <Stack.Screen
            name="register-confirmation"
            component={RegisterConfirmation}
            options={{ title: getText("register-confirmation") }}
          />
          <Stack.Screen
            name="register"
            component={RegisterEmail}
            options={{ title: getText("register") }}
          />
          <Stack.Screen
            name="reset-link"
            component={ResetLink}
            options={{ title: getText("reset-link") }}
          />
          <Stack.Screen
            name="reset-link-sent"
            component={ResetLinkSent}
            options={{ title: getText("reset-link-sent") }}
          />
          <Stack.Screen
            name="reset-unsuccessful"
            component={ResetUnsuccessful}
            options={{ title: getText("reset-unsuccessful") }}
          />
          <Stack.Screen
            name="reset-successful"
            component={ResetSuccessful}
            options={{ title: getText("reset-successful") }}
          />
          <Stack.Screen
            name="reset-password"
            component={ResetPassword}
            options={{ title: getText("reset-password") }}
          />
          <Stack.Screen
            name="update-weak-password"
            component={UpdateWeakPassword}
            options={{ title: getText("update-weak-password") }}
          />
        </>
      )}
      {(!userHasDetailsSet || isNeedingPhoneVerification) && (
        <>
          <Stack.Screen
            name="register-details"
            component={RegisterDetails}
            options={{ title: getText("register-details") }}
          />
        </>
      )}
      {isNeedingPhoneVerification && (
        <>
          <Stack.Screen
            name="phone-verification"
            component={PhoneVerification}
            options={{
              title: getText("phone-verification"),
              header: (props) => (
                <AuthNavigationHeader
                  showBack={true}
                  {...props}
                  routeName="register-details"
                />
              ),
            }}
          />
        </>
      )}
      {userHasToSetPreferredPharmacyLocation && (
        <>
          <Stack.Screen
            name="patient-stores"
            component={RegisterStore}
            options={{
              title: getText("patient-stores"),
              cardStyle: { flex: 1 },
              header: AccountSetupNavigationHeader,
              headerShown: true,
            }}
          />
          <Stack.Screen
            name="patient-pharmacy-stores"
            component={RegisterStoreDetails}
            options={{
              title: getText("patient-pharmacy-stores"),
              presentation: "modal",
              headerShown: false,
            }}
          />
        </>
      )}
      {!userHasSeenFindMedsScreen && (
        <Stack.Screen
          name="add-prescription"
          component={FindPrescriptionNavigation}
          options={{
            title: getText("add-prescription"),
            header: FindPrescriptionHeader,
          }}
        />
      )}

      <Stack.Screen
        name="refill-guest"
        component={RefillGuestNavigation}
        options={{ title: getText("refill-guest") }}
      />
    </Stack.Navigator>
  );
};

export type AuthStackParamList = {
  intro: undefined;
  login: undefined;
  "login-enter-email": undefined;
  "create-password": CreatePasswordParamsProps;
  "register-confirmation": undefined;
  "login-attempts-exceeded": undefined;
  register: undefined;
  "refill-guest": undefined;
  "register-details": undefined;
  "phone-verification": undefined;
  "privacy-policy": undefined;
  "terms-of-service": undefined;
  "patient-stores": undefined;
  "patient-pharmacy-stores": undefined;
  "reset-password": ResetPasswordParamsProps;
  "update-weak-password": undefined;
  "reset-link": undefined;
  "reset-link-sent": undefined;
  "reset-successful": undefined;
  "reset-unsuccessful": undefined;
  "add-prescription": undefined;
};

export type AuthStackNavigationProp = StackNavigationProp<AuthStackParamList>;

export interface AuthNavigationProps extends StackHeaderProps {
  showBack?: boolean;
  routeName?: string;
}

export const AuthNavigationHeader: FunctionComponent<
  PropsWithChildren<AuthNavigationProps>
> = (props) => {
  const theme = useTheme();
  const routeName = props.route.name as keyof AuthStackParamList;

  const handleBack = (event: GestureResponderEvent) => {
    props.navigation.goBack();
  };

  if (
    routeName === "intro" ||
    routeName === "login" ||
    routeName === "login-enter-email" ||
    routeName === "register" ||
    routeName === "refill-guest"
  ) {
    return <></>;
  }

  return (
    <>
      <SafeAreaView
        style={{
          position: "absolute",
          top: 48,
          left: 0,
          zIndex: 1,
        }}
      >
        {props.showBack && (
          <TouchableOpacity
            style={{
              flexDirection: "row",
              alignItems: "center",
            }}
            onPress={() =>
              props.navigation.navigate(
                props.routeName as keyof AuthStackParamList
              )
            }
          >
            <IconButton
              icon={ChevronLeftIcon}
              color={theme.colors.brandedPrimary}
              size={24}
            />

            <Text
              style={{
                color: theme.colors.brandedPrimary,
                fontSize: 16,
                fontWeight: "500",
                lineHeight: 24,
              }}
            >
              {getText("back")}
            </Text>
          </TouchableOpacity>
        )}
      </SafeAreaView>

      <GradientView
        style={{
          marginBottom: theme.getSpacing(4),
        }}
        contentStyle={{ justifyContent: "center", alignItems: "center" }}
        fillGradientStops={[
          { stopColor: "#F5F9FD", offset: 0 },
          { stopColor: "#F5F9FD", offset: 100 },
        ]}
        contentHeight={200}
      >
        <Image
          source={{
            uri: uri,
          }}
          style={{ width: 222, height: 100, marginTop: theme.getSpacing(2) }}
          resizeMode={"contain"}
        />
      </GradientView>
    </>
  );
};

export const AccountSetupNavigationHeader: FunctionComponent<StackHeaderProps> =
  (props) => {
    const handleBack = (event: GestureResponderEvent) => {
      props.navigation.goBack();
    };

    return (
      <NativeHeader
        title={getText("account-setup")}
        onBack={handleBack}
        showBack={false}
      />
    );
  };

export interface CreatePasswordProps
  extends StackScreenProps<AuthStackParamList, "create-password"> {}

export interface ResetPasswordProps
  extends StackScreenProps<AuthStackParamList, "reset-password"> {}

export type CreatePasswordParamsProps = {
  email: string;
  tempPassword: string;
  username: string;
};

export type ResetPasswordParamsProps = {
  email: string;
  confirmationCode: string;
};
