import React, { FunctionComponent, PropsWithChildren } from "react";

/**
 * Currently doing nothing, but I could see this used to force the actions to the bottom
 * of the screen. Currently our mockups seems random if the actions should be bottom aligned
 * or just under the last field; perhaps its worth waiting to determine the eventual pattern
 */
export const FormActions: FunctionComponent<
  PropsWithChildren<FormActionsProps>
> = ({ children }) => {
  return <>{children}</>;
};

export interface FormActionsProps {}
