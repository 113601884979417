import {
  AppointmentGroupDto,
  AppointmentTypeDto,
} from "@digitalpharmacist/appointment-service-client-axios";
import { TouchableOpacity } from "@gorhom/bottom-sheet";
import React, { FunctionComponent, ReactElement, useState } from "react";
import { View } from "react-native";
import { Title } from "react-native-paper";
import moment from "moment";
import { Button } from "assets/components/button";
import { Card } from "assets/components/card/Card";
import { Text } from "assets/components/text";
import {
  CalendarIcon,
  ChevronRightIcon,
  CompoundingServicesIcon,
  ConsultationsIcon,
  MedicationTherapyManagementIcon,
  OtherServiceIcon,
  PointOfCareTestingIcon,
  RemotePatientMonitoringIcon,
  VaccinationsImmunizationsIcon,
} from "assets/icons";
import { getText } from "assets/localization/localization";
import { makeStyles, useTheme } from "assets/theme";
import {
  setAppointmentType,
  setShowBookAppointment,
} from "../book-appointment/book-appointment-actions";

export const AppointmentType: FunctionComponent<AppointmentTypeProps> = ({
  type,
  group,
}) => {
  const styles = useStyles();
  const theme = useTheme();
  const [fullDescription, setFullDescription] = useState(false);

  const iconsMap: { [key: string]: ReactElement } = {
    vaccinations_immunizations: (
      <VaccinationsImmunizationsIcon
        size={80}
        color={theme.palette.gray[700]}
        colorSecondary={theme.colors.brandedPrimary}
      />
    ),
    consultations: (
      <ConsultationsIcon
        size={70}
        color={theme.palette.gray[700]}
        colorSecondary={theme.colors.brandedPrimary}
      />
    ),
    compounding_services: (
      <CompoundingServicesIcon
        size={80}
        color={theme.palette.gray[700]}
        colorSecondary={theme.colors.brandedPrimary}
      />
    ),
    point_of_care_testing: (
      <PointOfCareTestingIcon
        size={70}
        color={theme.palette.gray[700]}
        colorSecondary={theme.colors.brandedPrimary}
      />
    ),
    medication_therapy_management: (
      <MedicationTherapyManagementIcon
        size={70}
        color={theme.palette.gray[700]}
        colorSecondary={theme.colors.brandedPrimary}
      />
    ),
    remote_patient_monitoring: (
      <RemotePatientMonitoringIcon
        size={70}
        color={theme.palette.gray[700]}
        colorSecondary={theme.colors.brandedPrimary}
      />
    ),
    other: (
      <OtherServiceIcon
        size={70}
        color={theme.palette.gray[700]}
        colorSecondary={theme.colors.brandedPrimary}
      />
    ),
  };

  const nextSlot = type.nextAvailableSlot
    ? moment(type.nextAvailableSlot).format("ddd MMM D @ h:mm A")
    : null;

  const truncateDescription = (str: string, n: number) =>
    str.length > n ? str.slice(0, n - 1) + "..." : str;

  const toggleFullDescription = () => {
    setFullDescription(!fullDescription);
  };

  const handleAppointmentSelect = () => {
    setAppointmentType(type);
    setShowBookAppointment(true);
  };

  const getIconStyle = (iconType: string) => {
    switch (iconType) {
      case "consultations":
        return {
          top: 0,
          right: 0,
        };
      case "medication_therapy_management":
        return {
          top: theme.getSpacing(1),
          right: -theme.getSpacing(0.5),
        };
      case "remote_patient_monitoring":
      case "other":
        return {
          top: theme.getSpacing(1),
        };
      default:
        return null;
    }
  };

  return (
    <>
      <View style={styles.container}>
        <Card
          title={type.title}
          touchable={false}
          titleComponent={
            <View>
              <Title style={styles.title}>{type.title}</Title>
              {group && (
                <View style={[styles.icon, getIconStyle(group.icon_name)]}>
                  {iconsMap[group.icon_name]}
                </View>
              )}
            </View>
          }
          content={
            <View style={{ minHeight: 90, flex: 1 }}>
              <View
                style={{
                  paddingRight: 100,
                }}
              >
                {type.description && (
                  <>
                    <Text>
                      {fullDescription
                        ? type.description
                        : truncateDescription(type.description, 100)}
                    </Text>
                    {type.description.length > 100 && (
                      <TouchableOpacity onPress={toggleFullDescription}>
                        <Text style={styles.moreText}>
                          {fullDescription ? getText("less") : getText("more")}
                        </Text>
                      </TouchableOpacity>
                    )}
                  </>
                )}
              </View>
              <View style={styles.slotContainer}>
                <View style={{ marginTop: "auto" }}>
                  <Text style={styles.slotTitle}>
                    {getText("next-available")}
                  </Text>
                  <View style={styles.nextDateContainer}>
                    <CalendarIcon size={16} color={theme.palette.gray[500]} />
                    <Text
                      style={[
                        styles.nextDateText,
                        !nextSlot && styles.nextDateNotAvailableText,
                      ]}
                    >
                      {nextSlot ? nextSlot : getText("not-available")}
                    </Text>
                  </View>
                </View>
                {nextSlot && (
                  <View style={styles.buttonContainer}>
                    <Button
                      hierarchy="secondary-gray"
                      size="small"
                      onPress={handleAppointmentSelect}
                      logger={{ id: `find-appointment-time-${type.id}` }}
                      icon={ChevronRightIcon}
                      iconPosition="right"
                    >
                      {getText("find-time")}
                    </Button>
                  </View>
                )}
              </View>
            </View>
          }
        />
      </View>
    </>
  );
};

export interface AppointmentTypeProps {
  type: AppointmentTypeDto;
  group?: AppointmentGroupDto;
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.getSpacing(2),
    position: "relative",
  },
  title: {
    color: theme.colors.brandedPrimary,
    marginBottom: theme.getSpacing(1),
    paddingRight: 80,
  },
  moreText: {
    color: theme.palette.primary[600],
    marginTop: theme.getSpacing(1),
  },
  slotContainer: {
    flex: 1,
    flexDirection: "row",
    marginTop: theme.getSpacing(2),
  },
  slotTitle: {
    ...theme.fonts.medium,
    fontSize: 12,
    marginBottom: theme.getSpacing(1),
  },
  nextDateContainer: {
    flexDirection: "row",
  },
  nextDateText: {
    fontSize: 12,
    color: theme.palette.gray[700],
    marginLeft: theme.getSpacing(1),
  },
  nextDateNotAvailableText: {
    color: theme.palette.gray[500],
  },
  buttonContainer: {
    flex: 1,
    alignItems: "flex-end",
    marginTop: "auto",
  },
  icon: {
    position: "absolute",
    top: -theme.getSpacing(1),
    right: -theme.getSpacing(1),
  },
}));
