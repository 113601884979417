import React, { FunctionComponent, PropsWithChildren } from "react";
import { Alert, AlertProps } from "assets/components/alert";
import { useTheme } from "assets/theme";

export const FormAlert: FunctionComponent<PropsWithChildren<FormAlertProps>> =
  ({ title, intent, visible }) => {
    const theme = useTheme();
    // TODO: it would be great if this were a collapsible that animated into and out of view
    if (!visible) {
      return <></>;
    }

    if (!title || !intent) {
      throw new Error(
        `Title and Intent are required when FormAlert is visible`
      );
    }

    return (
      <Alert
        title={title}
        intent={intent}
        style={{ marginBottom: theme.getSpacing(2) }}
      />
    );
  };

export interface FormAlertProps extends Partial<AlertProps> {
  /**
   * Whether the alert is visible or not
   */
  visible: boolean;
}
