import React, {
  FunctionComponent,
  PropsWithChildren,
  useRef,
  useEffect,
} from "react";
import { GradientView } from "assets/components/gradient-view";
import { View } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { LocationIcon, BellIcon } from "assets/icons";
import { Image } from "react-native";
import { uuid } from "assets/utils/uuid";
import { IconButton } from "assets/components/icon-button";
import { StoreSelector } from "../store-selector";
import { useAppStateStore } from "../../store/app-store";
import { useUserState } from "../../store/user-store";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { StoreSelectorHandler } from "../store-selector/types";
import { STATIC_DEV_LUMISTRY, PHARMACY_ID } from "../../common/constants";
import { NavigationItems } from "./NavigationItems";

export const WebHeader: FunctionComponent<PropsWithChildren<WebHeaderProps>> =
  ({ gradientId = uuid(), navigation, screens }) => {
    const uri = STATIC_DEV_LUMISTRY + PHARMACY_ID + "/images/logo_on_dark.png";
    const theme = useTheme();
    const styles = useStyles();
    const gradientIdRef = useRef(gradientId);
    const storeSelectorRef = useRef<StoreSelectorHandler>(null);

    const { user: user, updateUser } = useUserState();
    const { stores, getStores } = useAppStateStore();

    const isMultiStore = stores && stores.length > 1;

    // first rendering only
    useEffect(() => {
      if (stores.length < 1) getStores();
    }, []);

    const handleOnLocationChanged = (value: PharmacyLocationDto) => {
      updateUser({ preferredPharmacyLocationId: value.id });
    };

    const handleOnStoreIconPress = () => {
      storeSelectorRef.current?.show();
    };

    return (
      <>
        <GradientView
          contentHeight={88}
          fillGradientStops={[
            { stopColor: theme.colors.gradientStart, offset: 0 },
            { stopColor: theme.colors.gradientEnd, offset: 100 },
          ]}
          gradientId={gradientIdRef.current}
        >
          <View style={styles.headerContainer}>
            <View style={styles.leftContent}>
              <Image
                source={{
                  uri: uri,
                }}
                style={{
                  width: 222,
                  height: 100,
                  marginTop: theme.getSpacing(2),
                }}
                // width={222}
                // height={100}
                resizeMode={"contain"}
              />
            </View>
            {navigation && screens && (
              <NavigationItems navigation={navigation} screens={screens} />
            )}
            <View style={styles.rightContent}>
              <IconButton
                icon={BellIcon}
                logger={{ id: "bellIconId" }}
                onPress={() => {}}
                color={theme.palette.white}
              />
              {isMultiStore && (
                <IconButton
                  icon={LocationIcon}
                  logger={{ id: "locationIconId" }}
                  onPress={handleOnStoreIconPress}
                  color={theme.palette.white}
                />
              )}
            </View>
          </View>
        </GradientView>
        {isMultiStore && (
          <StoreSelector
            ref={storeSelectorRef}
            options={stores}
            defaultOption={stores.find(
              (x) => x.id === user?.preferredPharmacyLocationId
            )}
            onChange={handleOnLocationChanged}
            storeInformationShown={false}
          />
        )}
      </>
    );
  };

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: "relative",
    height: "100%",
    alignItems: "flex-end",
    flexDirection: "row",
  },
  leftContent: {
    alignItems: "flex-start",
    paddingLeft: theme.getSpacing(2),
    marginRight: theme.getSpacing(4),
  },
  middleContent: {
    marginBottom: theme.getSpacing(1),
    marginLeft: theme.getSpacing(2),
    alignItems: "stretch",
    flexDirection: "row",
    justifyContent: "flex-end",
    zIndex: 1,
    height: "100%",
  },
  rightContent: {
    position: "absolute",
    flexDirection: "row",
    left: 0,
    right: 0,
    justifyContent: "flex-end",
    margin: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(0.5),
  },
  tabContent: {
    flexDirection: "row",
    marginLeft: theme.getSpacing(4),
  },
  textStyle: {
    color: theme.palette.white,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "500",
    paddingTop: theme.getSpacing(1),
    marginTop: theme.getSpacing(0.5),
  },
}));

export interface WebHeaderProps {
  gradientId?: string;
  navigation?: any;
  screens?: {
    name: string;
    label: string;
    icon: FunctionComponent;
  }[];
}

export const NativeHeaderTestIDs = {
  title: "native-header-title",
  actionButton: "native-header-action-button",
  backButton: "native-header-back-button",
};
