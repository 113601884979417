import RenderHtml from "react-native-render-html";
import { tagStyles, classesStyles } from "../../utils";
import React from "react";

export const MedicationGuideDetails = ({ medication }: any) => {
  return (
    medication &&
    medication.medication_guides && (
      <RenderHtml
        source={{ html: medication.medication_guides[0] }}
        classesStyles={classesStyles}
        tagsStyles={tagStyles}
      />
    )
  );
};
