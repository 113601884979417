import theme from "./theme";
import { BrandedColors } from "./types";
import { ThemePalette } from "./types";

export { default as theme } from "./theme";

// https://callstack.github.io/react-native-paper/theming.html#typescript
declare global {
  namespace ReactNativePaper {
    interface Theme {
      spacing: number;
      /**
       * Calculate spacing based on the theme's spacing and multiplying it by the provided factor.
       * Use for `padding` and `margin` styles.
       *
       * See MUI for inspiration: https://mui.com/system/spacing
       */
      getSpacing: (factor: 0.5 | 1 | 2 | 3 | 4) => number;
      webMaxWidth: number;
      palette: ThemePalette;
    }

    interface ThemeColors extends BrandedColors {}
  }
}

export * from "./utils";
export * from "./hooks";
export * from "./context";
export * from "./types";

export default theme;
