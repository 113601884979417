import theme from "../theme";
// TODO: to be removed once implemented on pharmacy project
/**
 * Calculate spacing based on the theme's spacing and multiplying it by the provided factor.
 * Use for `padding` and `margin` styles.
 *
 * See MUI for inspiration: https://mui.com/system/spacing
 */
export const getSpacing = (factor: 0.5 | 1 | 2 | 3 | 4) => {
  return theme.spacing * factor;
};
