import React, {
  ForwardedRef,
  forwardRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
} from "react";
import { Platform } from "react-native";
import {
  UnableToSubmitHandler,
  UnableToSubmitProps,
} from "./UnableToSubmitProps";
import { UnableToSubmitModalComponent } from "./UnableToSubmitModal";
import { UnableToSubmitBottomSheet } from "./UnableToSubmitBottomsheet";

const UnableToSubmitComponent: ForwardRefRenderFunction<
  UnableToSubmitHandler,
  UnableToSubmitProps
> = (
  { platform = Platform.OS, ...rest }: UnableToSubmitProps,
  ref: ForwardedRef<UnableToSubmitHandler>
) => {
  const nativeRef = React.useRef<UnableToSubmitHandler>(null);
  const webRef = React.useRef<UnableToSubmitHandler>(null);

  const handleShow = () => {
    if (platform === "web") webRef.current?.show();
    else nativeRef.current?.show();
  };

  const handleHide = () => {
    if (platform === "web") webRef.current?.hide();
    else nativeRef.current?.hide();
  };

  useImperativeHandle(ref, () => ({
    show: handleShow,
    hide: handleHide,
  }));

  const handleOnChangePress = () => {
    handleShow();
  };

  return (
    <>
      {platform === "web" ? (
        <UnableToSubmitModalComponent {...rest} ref={webRef} />
      ) : (
        <UnableToSubmitBottomSheet {...rest} ref={nativeRef} />
      )}
    </>
  );
};

export const UnableToSubmit = forwardRef<
  UnableToSubmitHandler,
  UnableToSubmitProps
>(UnableToSubmitComponent);
