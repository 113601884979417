import React, { FunctionComponent } from "react";
import { useTheme } from "assets/theme";
import {
  NativeSyntheticEvent,
  TextInputChangeEventData,
  Platform,
  PlatformOSType,
} from "react-native";
import { NearMeIcon, SearchIcon } from "assets/icons";
import { getText } from "assets/localization/localization";
import { BottomSheetInput } from "assets/components/bottom-sheet";
import { TextInput } from "react-native-paper";

export const StoreSelectorInput: FunctionComponent<{
  onInputChange?: (value: string) => void;
  useBottomSheetInput?: boolean;
}> = ({ onInputChange, useBottomSheetInput = false }) => {
  const theme = useTheme();
  const [inputValue, setInputValue] = React.useState("");

  const handleInputChange = (
    event: NativeSyntheticEvent<TextInputChangeEventData>
  ) => {
    const {
      nativeEvent: { text },
    } = event;
    setInputValue(text);
    onInputChange?.(text);
  };

  return (
    <InputWrapper
      placeholder={getText("store-selector-search-address")}
      autoComplete="off"
      autoCapitalize="none"
      style={{
        height: 44,
        backgroundColor: theme.palette.white,
      }}
      mode="outlined"
      outlineColor={theme.palette.gray[500]}
      placeholderTextColor={theme.palette.gray[500]}
      activeOutlineColor={theme.palette.primary[600]}
      left={
        <TextInput.Icon
          name={SearchIcon}
          color={theme.palette.gray[500]}
          size={22}
          forceTextInputFocus={false}
          style={{ top: theme.getSpacing(0.5) }}
        />
      }
      right={
        <TextInput.Icon
          name={NearMeIcon}
          color={theme.palette.gray[700]}
          size={22}
          forceTextInputFocus={false}
          style={{ top: theme.getSpacing(0.5) }}
        />
      }
      value={inputValue}
      onChange={handleInputChange}
      returnKeyType="done"
      children={undefined}
    />
  );
};

const InputWrapper: FunctionComponent<
  React.ComponentProps<typeof TextInput> & {
    platform?: PlatformOSType;
    useBottomSheetInput?: boolean;
  }
> = ({ platform = Platform.OS, useBottomSheetInput, ...props }) => {
  // TODO: find a better way to write this with generics
  return platform === "web" || !useBottomSheetInput ? (
    <TextInput {...props} />
  ) : (
    <BottomSheetInput {...props} />
  );
};
