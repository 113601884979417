import React, { FunctionComponent, PropsWithChildren } from "react";
import Svg, { Path } from "react-native-svg";
import { IconProps } from "./types";

export const HomeAppointmentsIcon: FunctionComponent<
  PropsWithChildren<IconProps>
> = ({ color, size, testID }) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 53 53" testID={testID}>
      <Path
        fill="#73C5C2"
        d="M53 27c0 14.636-11.864 26.5-26.5 26.5C11.865 53.5 0 41.636 0 27 0 12.365 11.865.5 26.5.5 41.136.5 53 12.365 53 27Z"
      />
      <Path fill="#E45151" d="M40.77 17.827H12.23V13.75h28.54v4.077Z" />
      <Path
        fill="#fff"
        d="M40.77 17.827H12.23v10.192h28.54V17.827ZM12.23 28.02V38.21h28.54V28.02H12.23Z"
      />
      <Path
        fill="#232837"
        d="M16.308 19.61c-.564 0-1.02-.456-1.02-1.019v-1.529a1.019 1.019 0 1 1 2.039 0v1.53c0 .563-.456 1.019-1.02 1.019ZM36.692 19.61c-.563 0-1.02-.456-1.02-1.019v-1.529a1.019 1.019 0 1 1 2.04 0v1.53c0 .563-.457 1.019-1.02 1.019Z"
      />
      <Path fill="#C2C2C4" d="M40.77 38.212H12.23v2.038h28.54v-2.038Z" />
      <Path
        fill="#E76769"
        d="M18.346 24.452h-2.038v-2.038h2.038v2.038ZM21.404 24.452h-2.039v-2.038h2.039v2.038Z"
      />
      <Path
        fill="#A9AAB0"
        d="M24.461 24.452h-2.038v-2.038h2.038v2.038ZM27.52 24.452h-2.04v-2.038h2.04v2.038ZM30.577 24.452h-2.039v-2.038h2.039v2.038Z"
      />
      <Path
        fill="#E76769"
        d="M18.346 27.51h-2.038V25.47h2.038v2.039ZM18.346 30.567h-2.038V28.53h2.038v2.038ZM21.404 27.51h-2.039V25.47h2.039v2.039Z"
      />
      <Path
        fill="#A9AAB0"
        d="M24.461 27.51h-2.038V25.47h2.038v2.039ZM27.52 27.51h-2.04V25.47h2.04v2.039ZM30.577 27.51h-2.039V25.47h2.039v2.039ZM33.635 27.51h-2.039V25.47h2.039v2.039ZM36.692 27.51h-2.038V25.47h2.038v2.039Z"
      />
      <Path fill="#E76769" d="M21.404 30.567h-2.039V28.53h2.039v2.038Z" />
      <Path
        fill="#A9AAB0"
        d="M24.461 30.567h-2.038V28.53h2.038v2.038ZM27.52 30.567h-2.04V28.53h2.04v2.038ZM30.577 30.567h-2.039V28.53h2.039v2.038ZM33.635 30.567h-2.039V28.53h2.039v2.038ZM36.692 30.567h-2.038V28.53h2.038v2.038ZM24.461 33.625h-2.038v-2.038h2.038v2.038ZM27.52 33.625h-2.04v-2.038h2.04v2.038ZM30.577 33.625h-2.039v-2.038h2.039v2.038ZM33.635 33.625h-2.039v-2.038h2.039v2.038ZM36.692 33.625h-2.038v-2.038h2.038v2.038Z"
      />
      <Path
        fill="#5EA0A5"
        d="M40.77 13.75v26.5H12.23l13.228 13.228c.346.013.693.022 1.042.022C41.136 53.5 53 41.636 53 27c0-.35-.009-.696-.023-1.04L40.769 13.75Z"
      />
    </Svg>
  );
};
