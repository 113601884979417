import { AxiosRequestConfig } from "axios";
import { BaseApiConfiguration, BaseApiService } from "assets/core/api";
import { PHARMACY_API_BASE_URL } from "../common/constants";
import {
  BrandingDto,
  DefaultApi as PharmacyServiceClient,
  PharmacyDto,
  PharmacyLocationDto,
  TimeRangeDto,
} from "@digitalpharmacist/pharmacy-service-client-axios";

export interface IPharmacyService {
  findAll(): Promise<PharmacyDto[]>;
  findLocationsByPharmacyId(): Promise<PharmacyLocationDto[]>;
  findPharmacyBranding(pharmacyId: string): Promise<BrandingDto>;
  findLocationHours(locationId: string): Promise<TimeRangeDto[]>;
  findLocationServices(locationId: string): Promise<string[]>;
  findLocationLanguage(locationId: string): Promise<string[]>;
}

export class PharmacyService
  extends BaseApiService
  implements IPharmacyService
{
  private pharmacyServiceClient: PharmacyServiceClient;

  constructor(
    baseUrl: string,
    config: AxiosRequestConfig = {},
    enableAuth = true,
    baseConfig?: BaseApiConfiguration
  ) {
    super(baseUrl, config, enableAuth, baseConfig);
    this.pharmacyServiceClient = new PharmacyServiceClient(
      undefined,
      baseUrl,
      this.axiosInstance
    );
  }

  async findAll(): Promise<PharmacyDto[]> {
    const { data } = await this.pharmacyServiceClient.pharmacyFindAll();
    return data;
  }

  async findOne(pharmacyId: string): Promise<PharmacyDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyFindOne(
      pharmacyId
    );
    return data;
  }

  async findLocationsByPharmacyId(): Promise<PharmacyLocationDto[]> {
    const { data } = await this.pharmacyServiceClient.pharmacyLocationFind();
    return data;
  }

  async findPharmacyBranding(pharmacyId: string): Promise<BrandingDto> {
    const { data } = await this.pharmacyServiceClient.pharmacyBrandingFindOne(
      pharmacyId
    );
    return data;
  }

  async findLocationHours(locationId: string): Promise<TimeRangeDto[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationHoursFindAll(locationId);
    return data;
  }

  async findLocationServices(locationId: string): Promise<string[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationOptionsGetServices(
        locationId
      );
    return data;
  }

  async findLocationLanguage(locationId: string): Promise<string[]> {
    const { data } =
      await this.pharmacyServiceClient.pharmacyLocationOptionsGetLanguages(
        locationId
      );
    return data;
  }
}

export default new PharmacyService(PHARMACY_API_BASE_URL, undefined, false);
