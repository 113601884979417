/* tslint:disable */
/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull frontend'
 *
 * Required dependencies: @amplitude/analytics-react-native@^0.4.0, @react-native-async-storage/async-storage@^1.17.9
 * Tracking Plan Version: 5
 * Build: 1.0.0
 * Runtime: react-native:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/implementation/frontend)
 */

import * as amplitude from '@amplitude/analytics-react-native';

export type ReactNativeClient = amplitude.Types.ReactNativeClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
export type ReactNativeOptions = amplitude.Types.ReactNativeOptions;

export type Environment = 'staging' | 'production' | 'development';

export const ApiKey: Record<Environment, string> = {
  staging: '6941cb51f96485466e285ddefc0c6c53',
  production: 'f3e229fbb85448826abfaea9aa6a88d2',
  development: 'f1b4aa2c6c3e9be57ae9e35a8b5023cf'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: ReactNativeOptions = {
  plan: {
    version: '5',
    branch: 'main',
    source: 'frontend',
    versionId: '2555f11e-6d67-4f18-a062-83100eb64f3f'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'react-native-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: ReactNativeOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: ReactNativeOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: ReactNativeClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface FormsEditProperties {
  form_Edit_Time: string;
  "pharmacy email": string;
  pharmacy_id: string;
}

export interface UserLoginProperties {
  method: string;
  pharmacy_id: string;
  status: string;
}

export class FormsEdit implements BaseEvent {
  event_type = 'formsEdit';

  constructor(
    public event_properties: FormsEditProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MedicationsListView implements BaseEvent {
  event_type = 'MedicationsListView';
}

export class UserLogin implements BaseEvent {
  event_type = 'UserLogin';

  constructor(
    public event_properties: UserLoginProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserLogout implements BaseEvent {
  event_type = 'UserLogout';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: ReactNativeClient;

  get client(): ReactNativeClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already initialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...options.client?.configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();

    return this.amplitude!.identify(amplitudeIdentify, options);
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * Flush pending events.
   */
  flush(): PromiseResult<void> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * formsEdit
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/formsEdit)
   *
   * Owner: Priti Jagger
   *
   * @param properties The event's properties (e.g. form_Edit_Time)
   * @param options Amplitude event options.
   */
  formsEdit(
    properties: FormsEditProperties,
    options?: EventOptions,
  ) {
    return this.track(new FormsEdit(properties), options);
  }

  /**
   * MedicationsListView
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/MedicationsListView)
   *
   * Owner: Justin Bogli
   *
   * @param options Amplitude event options.
   */
  medicationsListView(
    options?: EventOptions,
  ) {
    return this.track(new MedicationsListView(), options);
  }

  /**
   * UserLogin
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/UserLogin)
   *
   * User Login Event occurs when a user attempts to log in
   *
   * Owner: Justin Bogli
   *
   * @param properties The event's properties (e.g. method)
   * @param options Amplitude event options.
   */
  userLogin(
    properties: UserLoginProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserLogin(properties), options);
  }

  /**
   * UserLogout
   *
   * [View in Tracking Plan](https://data.amplitude.com/digital-pharmacist/Lumistry%20Patient/events/main/latest/UserLogout)
   *
   * Owner: Justin Bogli
   *
   * @param options Amplitude event options.
   */
  userLogout(
    options?: EventOptions,
  ) {
    return this.track(new UserLogout(), options);
  }
}

export const ampli = new Ampli();
