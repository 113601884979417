import React, { useRef } from "react";
import { View } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { Text } from "assets/components/text";
import { getText } from "assets/localization/localization";
import { StoreSelectorItemComponent } from "./StoreSelectorItem";
import { StoreSelectorItemHandler, StoreSelectorListProps } from "../types";
import { Distance } from "../../../types";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { useStoresWithDistanceInfo } from "../hooks/useStoresWithDistanceInfo";
import { useLocation } from "../../../hooks/useLocation";

export const StoreSelectorList = ({
  options = [],
  selectedOption,
  defaultOption,
  disabled = false,
  onChange,
  searchCriteria,
  onInfoButtonPress,
}: StoreSelectorListProps) => {
  const styles = useStyles();
  const theme = useTheme();

  const storeSelectorRef = useRef<StoreSelectorItemHandler>(null);

  const { status } = useLocation();

  const { stores } = useStoresWithDistanceInfo(options ?? []);

  const handleStoreItemChange = (item: Distance<PharmacyLocationDto>) => {
    onChange?.(item as PharmacyLocationDto);
  };

  const handleOnInfoButtonPress = (item: Distance<PharmacyLocationDto>) => {
    onInfoButtonPress?.(item as PharmacyLocationDto);
  };

  return (
    <View style={{ flexGrow: 1 }}>
      {defaultOption && (
        <View style={{ marginTop: theme.getSpacing(2) }}>
          <Text style={styles.bottomSheetViewSectionsTitle}>
            {getText("store-selector-your-store")}
          </Text>
          <StoreSelectorItemComponent
            ref={storeSelectorRef}
            item={stores.find((x) => x.id === defaultOption?.id)!}
            selected={
              // finding the selected store from 'stores' list
              // which have the distance information
              stores.find((x) => x.id === selectedOption?.id)?.id ===
              defaultOption?.id
            }
            disabled={disabled}
            onPress={handleStoreItemChange}
            status={status}
            onInfoButtonPress={handleOnInfoButtonPress}
          />
          <View style={styles.bottomSheetViewSectionsSeparator} />
        </View>
      )}

      <View style={{ marginTop: theme.getSpacing(1) }}>
        <Text style={styles.bottomSheetViewSectionsTitle}>
          {getText("store-selector-nearby-store")}
        </Text>
        {stores
          .filter((x) => x.id !== defaultOption?.id)
          .filter(
            (x) =>
              !searchCriteria ||
              Object.values(x)
                .join("#")
                .toLowerCase()
                .includes(searchCriteria.toLowerCase())
          )
          .sort((first, second) => {
            if (!first.distance || !second.distance) return 0;
            return first.distance - second.distance;
          })
          .map((x, index) => (
            <StoreSelectorItemComponent
              ref={storeSelectorRef}
              item={x}
              selected={selectedOption?.id === x.id}
              disabled={disabled}
              onPress={handleStoreItemChange}
              key={index}
              status={status}
              onInfoButtonPress={handleOnInfoButtonPress}
            />
          ))}
      </View>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  selectedAddressTitle: {
    ...theme.fonts.medium,
    fontWeight: "500",
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[900],
  },
  selectedAddressSubTitle: {
    ...theme.fonts.regular,
    fontWeight: "400",
    fontSize: 14,
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  changeButton: {
    ...theme.fonts.medium,
    fontWeight: "500",
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.primary[500],
  },
  bottomSheetViewTitle: {
    alignItems: "center",
    justifyContent: "center",
    flexGrow: 1,
  },
  bottomSheetViewSectionsTitle: {
    ...theme.fonts.medium,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "600",
    color: theme.palette.gray[700],
  },
  bottomSheetViewSectionsSeparator: {
    borderColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
  bottomSheetViewButtonContainer: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  bottomSheetViewCloseButton: { flexGrow: 1, marginRight: theme.getSpacing(1) },
  bottomSheetViewSelectButton: { flexGrow: 1, marginLeft: theme.getSpacing(1) },
}));
