import AppointmentService from "../../../api/appointment-service";
import { useAppointmentTypesState } from "./appointment-types-store";

// TODO: @konradvs replace with location id from app store once it's implemented
export const locationId = "76c4e938-7e7f-48b5-acb7-3a6572b87f37";

export const getAppointmentTypes = async (
  pharmacyId: string,
  locationId: string
) => {
  useAppointmentTypesState.setState({ status: "loading" });

  try {
    const responseTypes = AppointmentService.findAppointmentTypes(
      pharmacyId,
      locationId
    );

    const responseGroups = AppointmentService.findAppointmentGroups(pharmacyId);

    useAppointmentTypesState.setState({
      status: "success",
      appointmentTypes: await responseTypes,
      appointmentGroups: await responseGroups,
    });
  } catch (error) {
    useAppointmentTypesState.setState({
      error: {
        message: "Error loading appointment types",
      },
      status: "error",
    });
  }
};
