import React, {
  FunctionComponent,
  PropsWithChildren,
  useRef,
  useEffect,
} from "react";
import { GradientView } from "assets/components/gradient-view";
import { Platform, View } from "react-native";
import { makeStyles, useTheme } from "assets/theme";
import { LocationIcon, NotificationIcon } from "assets/icons";
import { Text } from "assets/components/text";
import { uuid } from "assets/utils/uuid";
import { IconButton } from "assets/components/icon-button";
import { StoreSelector } from "../store-selector";
import { useAppStateStore } from "../../store/app-store";
import { useUserState } from "../../store/user-store";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { StoreSelectorHandler } from "../store-selector/types";
import { notImplementedAlert } from "assets/utils/alert";
import { getText } from "assets/localization/localization";
import { useIsFocused } from "@react-navigation/native";

export const LandingHeader: FunctionComponent<
  PropsWithChildren<LandingHeaderProps>
> = ({ pharmacyName, greetings, patientName, gradientId = uuid() }) => {
  const theme = useTheme();
  const styles = useStyles();
  const gradientIdRef = useRef(gradientId);
  const storeSelectorRef = useRef<StoreSelectorHandler>(null);

  const { user: user, updateUser } = useUserState();
  const { stores, getStores } = useAppStateStore();

  const isMultiStore = stores.length > 1;

  const isFocused = useIsFocused();
  let defaultGreeting = "";

  const getDefaultGreeting = () => {
    const currentTime = new Date();
    const currentHour = currentTime.getHours();
    const currentMinute = currentTime.getMinutes();

    //This is here so that the greeting is "good evening" until 12:01
    if (currentHour === 0 && currentMinute < 1) {
      return getText("good-evening");
    } else if (currentHour === 0 && currentMinute >= 1) {
      return getText("good-morning");
    } else if (currentHour < 12) {
      return getText("good-morning");
    } else if (currentHour < 18) {
      return getText("good-afternoon");
    } else {
      return getText("good-evening");
    }
  };

  // Set the default greeting when the screen is focused
  if (isFocused) {
    !greetings && (defaultGreeting = getDefaultGreeting());
  }

  // first rendering only
  useEffect(() => {
    if (stores.length < 1) getStores();
  }, []);

  const handleOnLocationChanged = (value: PharmacyLocationDto) => {
    updateUser({ preferredPharmacyLocationId: value.id });
  };

  const handleOnStoreIconPress = () => {
    storeSelectorRef.current?.show();
  };

  return (
    <>
      <GradientView
        contentHeight={Platform.OS === "ios" ? 160 : 140}
        fillGradientStops={[
          { stopColor: theme.colors.gradientStart, offset: 0 },
          { stopColor: theme.colors.gradientEnd, offset: 100 },
        ]}
        gradientId={gradientIdRef.current}
      >
        <View style={styles.headerContainer}>
          <View style={styles.leftContent}>
            <Text
              style={{
                color: theme.palette.white,
                //fontSize: 18,
                fontWeight: "500",
              }}
            >
              {pharmacyName}
            </Text>
            <Text
              style={{
                color: theme.palette.white,
                fontSize: 25,
                fontWeight: "500",
              }}
            >
              {greetings || defaultGreeting}
            </Text>
            <Text style={styles.patientNameText}>{patientName}</Text>
          </View>

          <View style={styles.rightContent}>
            <IconButton
              icon={NotificationIcon}
              logger={{ id: "" }}
              onPress={notImplementedAlert}
              color={theme.palette.white}
            />
            {isMultiStore && (
              <IconButton
                icon={LocationIcon}
                logger={{ id: "" }}
                onPress={handleOnStoreIconPress}
                color={theme.palette.white}
              />
            )}
          </View>
        </View>
      </GradientView>
      {isMultiStore && (
        <StoreSelector
          ref={storeSelectorRef}
          options={stores}
          defaultOption={stores.find(
            (x) => x.id === user?.preferredPharmacyLocationId
          )}
          onChange={handleOnLocationChanged}
          storeInformationShown={false}
        />
      )}
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    position: "relative",
    height: "100%",
    alignItems: "flex-end",
    flexDirection: "row",
    paddingBottom: theme.getSpacing(1),
  },
  leftContent: {
    alignItems: "flex-start",
    paddingLeft: theme.getSpacing(2),
    marginRight: theme.getSpacing(4),
  },
  middleContent: {
    marginBottom: theme.getSpacing(1),
    marginLeft: theme.getSpacing(2),
    alignItems: "stretch",
    flexDirection: "row",
    justifyContent: "flex-end",
    zIndex: 1,
    height: "100%",
  },
  rightContent: {
    position: "absolute",
    flexDirection: "row",
    left: 0,
    right: 0,
    justifyContent: "flex-end",
    margin: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(0.5),
  },
  tabContent: {
    flexDirection: "row",
    marginLeft: theme.getSpacing(4),
  },
  textStyle: {
    color: theme.palette.white,
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "500",
    paddingTop: theme.getSpacing(1),
    marginTop: theme.getSpacing(0.5),
  },
  patientNameText: {
    color: theme.palette.white,
    fontSize: 28,
    fontWeight: "700",
    fontFamily: "Lato_700Bold",
  },
}));

export interface LandingHeaderProps {
  pharmacyName?: string;
  greetings?: string;
  patientName?: string;
  gradientId?: string;
}

export const NativeHeaderTestIDs = {
  title: "native-header-title",
  actionButton: "native-header-action-button",
  backButton: "native-header-back-button",
};
