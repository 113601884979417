import React, { FunctionComponent } from "react";
import { Text } from "assets/components/text";
import { getText } from "assets/localization/localization";
import { makeStyles, useTheme } from "assets/theme";
import { View } from "react-native";

export const AppointmentDetailsBadge: FunctionComponent<AppointmentDetailsBadgeProps> =
  ({ status }) => {
    const styles = useStyles();
    const theme = useTheme();

    const colors = {
      background: {
        upcoming: theme.palette.success[50],
        past: theme.palette.gray[200],
        tomorrow: theme.palette.success[500],
        today: theme.palette.warning[300],
        canceled: theme.palette.error[100],
      },
      text: {
        upcoming: theme.palette.success[700],
        past: theme.palette.gray[700],
        tomorrow: theme.palette.white,
        today: theme.palette.black,
        canceled: theme.palette.error[700],
      },
    };

    return (
      <View
        style={[
          styles.container,
          { backgroundColor: colors.background[status] },
        ]}
      >
        <Text style={[styles.text, { color: colors.text[status] }]}>
          {getText(status)}
        </Text>
      </View>
    );
  };

export interface AppointmentDetailsBadgeProps {
  status: AppointmentStatus;
}

export type AppointmentStatus =
  | "upcoming"
  | "tomorrow"
  | "today"
  | "past"
  | "canceled";

const useStyles = makeStyles((theme) => ({
  container: {
    paddingHorizontal: theme.getSpacing(1),
    marginLeft: theme.getSpacing(2),
    marginTop: theme.getSpacing(0.5),
    borderRadius: theme.roundness,
  },
  text: {
    lineHeight: 26,
  },
}));
