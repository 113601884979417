import { TextField } from "assets/components/text-field";
import { Form } from "assets/layout";
import { getText } from "assets/localization/localization";
import { makeStyles, useTheme } from "assets/theme";
import { View } from "react-native";
import * as validate from "@digitalpharmacist/validation-dp";
import {
  DateTimeFormat,
  formatDate,
  formatDateAsString,
} from "../../../../../common/form-utils";
import { DropdownSelect } from "assets/components/dropdown-select";
import { RadioButtonGroupField } from "assets/components/radio-button-group";
import { Button } from "assets/components/button";
import {
  PatientUnderCareForm,
  updatePatientUnderCareForm,
} from "../../patient-actions";
import { usePatientUnderCareState } from "../../patient-store";
import {
  Gender,
  PatientRecordDto,
  RecordUnderCareDto,
  UpdateAddressDto,
  UpdatePatientRecordDto,
  UpdateRecordUnderCareDto,
} from "@digitalpharmacist/patient-service-client-axios";
import { Text } from "assets/components/text";
import { FunctionComponent, PropsWithChildren } from "react";
import states from "../../../../../types/states";
import patientService from "../../../../../api/patient-service";
import { UseFormReturn } from "react-hook-form";

export const EditPersonalInformation: FunctionComponent<
  PropsWithChildren<{
    patientRecord?: PatientRecordDto;
    changeViewMode: (val: boolean) => void;
    relationshipProps: Array<{ label: string; value: string }>;
    record?: RecordUnderCareDto;
    handleSave: (updatePatientRecord: UpdatePatientRecordDto) => void;
    form: UseFormReturn<PatientUnderCareForm>;
  }>
> = ({
  record,
  patientRecord,
  changeViewMode,
  relationshipProps,
  handleSave,
  form,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const { status, error } = usePatientUnderCareState();
  const formValues = form.getValues();

  const handleSubmit = async () => {
    updatePatientUnderCareForm(form.getValues());
    const updatedFormValues = form.getValues();
    changeViewMode(false);
    if (record?.relationship !== formValues.relationship) {
      const updateRecordUnderCare: UpdateRecordUnderCareDto = {
        relationship: formValues.relationship,
      };
      if (patientRecord && record)
        await patientService.recordUnderCareUpdate(
          patientRecord.id,
          record.id,
          updateRecordUnderCare
        );
    }
    if (
      updatedFormValues.address1 &&
      updatedFormValues.city &&
      updatedFormValues.country &&
      updatedFormValues.state &&
      updatedFormValues.postal_code
    ) {
      const addressDto: UpdateAddressDto = {
        address1: updatedFormValues.address1,
        address2: updatedFormValues.address2,
        city: updatedFormValues.city,
        country: updatedFormValues.country,
        state: updatedFormValues.state,
        postal_code: updatedFormValues.postal_code,
      };
      const updatePatientRecord: UpdatePatientRecordDto = {
        first_name: updatedFormValues.first_name,
        last_name: updatedFormValues.last_name,
        date_of_birth: formatDateAsString(updatedFormValues.date_of_birth),
        gender: updatedFormValues.gender,
        address: addressDto,
      };
      handleSave(updatePatientRecord);
    } else {
      const updatePatientRecord: UpdatePatientRecordDto = {
        first_name: updatedFormValues.first_name,
        last_name: updatedFormValues.last_name,
        date_of_birth: formatDateAsString(updatedFormValues.date_of_birth),
        gender: updatedFormValues.gender,
      };
      handleSave(updatePatientRecord);
    }
  };

  return (
    <View>
      <Form methods={form}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText("first-name")}
              name="first_name"
              value={formValues.first_name}
              rules={{
                required: getText("first-name-is-required"),
                validate: {
                  value: () => {
                    return validate.isName(formValues.first_name)
                      ? true
                      : getText("first-name-is-not-valid");
                  },
                },
              }}
              onSubmit={form.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText("last-name")}
              name="last_name"
              rules={{
                required: getText("last-name-is-required"),
                validate: {
                  value: () => {
                    return validate.isName(formValues.last_name)
                      ? true
                      : getText("last-name-is-not-valid");
                  },
                },
              }}
              onSubmit={form.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText("birth-date")}
              name="date_of_birth"
              rules={{
                required: getText("birth-date-is-required"),
                validate: {
                  value: () => {
                    return validate.isDate(
                      formatDate(
                        formValues.date_of_birth,
                        DateTimeFormat.USDateFormat
                      )
                    )
                      ? true
                      : getText("date-is-not-valid");
                  },
                },
              }}
              onSubmit={form.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
              type="date"
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <DropdownSelect
              options={relationshipProps}
              fieldName="relationship"
              label={getText("relation-to-primary-user") + "*"}
              labelInlined={true}
            ></DropdownSelect>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column style={{ marginTop: theme.getSpacing(1) }}>
            <Text> {getText("gender")}</Text>
            <RadioButtonGroupField
              name="gender"
              testID={"gender-test-id"}
              values={[
                {
                  text: getText("male"),
                  value: Gender.Male,
                },
                {
                  text: getText("female"),
                  value: Gender.Female,
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name={"address1"}
              label={getText("street-address")}
              // rules={{
              //   required: getText("street-address-is-required"),
              // }}
              value={formValues.address1}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              name={"address2"}
              label={getText("street-address-line-2")}
              value={formValues.address2}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row style={styles.row}>
          <Form.Column style={styles.city}>
            <TextField
              name={"city"}
              label={getText("city")}
              // rules={{
              //   required: getText("city-is-required"),
              // }}
              value={formValues.city}
            />
          </Form.Column>
          <Form.Column style={styles.state}>
            {/* TODO this component is going to be changed to cover the view with labels inside*/}
            <DropdownSelect
              options={states[formValues.country == "CA" ? "CA" : "US"]}
              fieldName="state"
              label={getText("state")}
              labelInlined={true}
            ></DropdownSelect>
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column style={styles.zip}>
            <TextField
              name="postal_code"
              label={getText("zip-code")}
              // rules={{
              //   required: getText("zip-code-is-required"),
              // }}
              value={formValues.postal_code}
            />
          </Form.Column>
          <Form.Column>
            {/* TODO use the right component here */}
            <DropdownSelect
              fieldName="country"
              label={getText("country")}
              labelInlined={true}
              options={[
                {
                  label: "US",
                  value: "US",
                },
                {
                  label: "CA",
                  value: "CA",
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <Button
            onPress={form.handleSubmit(handleSubmit)}
            hierarchy="primary"
            loading={status === "loading"}
            logger={{ id: "personal-info-save-button" }}
          >
            {getText("save")}
          </Button>
        </Form.Actions>
      </Form>
    </View>
  );
};

const useStyles = makeStyles((theme) => ({
  city: {
    flexGrow: 3,
  },
  zip: {
    flexGrow: 2,
  },
  state: {
    flexGrow: 1,
    display: "flex",
    alignItems: "stretch",
  },
  row: {
    alignContent: "stretch",
  },
}));
