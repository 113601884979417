import React, { FunctionComponent, PropsWithChildren } from "react";
import { useTheme } from "assets/theme";
import { Text } from "assets/components/text";
import { TextField } from "assets/components/text-field";
import { useForm } from "assets/form";
import { Button } from "assets/components/button";
import { PatientInfoTestIDs } from "./PatientIntakeTestIDs";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { PatientInfoForm, updatePatientIntake } from "./patient-actions";
import { AccountStackParamList } from "../AccountNavigation";
import { usePatientIntakeState } from "./patient-store";
import { getText } from "assets/localization/localization";
import { Form, ScreenContainer } from "assets/layout";
import { RadioButtonGroupField } from "assets/components/radio-button-group";
import { View } from "react-native";

export const PatientInfo: FunctionComponent<
  PropsWithChildren<PatientInfoProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const methods = useForm<PatientInfoForm>({
    defaultValues: {
      dateOfBirth: "",
      firstName: "",
      lastName: "",
      gender: "",
    },
  });

  const { status, error } = usePatientIntakeState();

  const handleSubmit = async () => {
    updatePatientIntake(methods.getValues());
  };

  return (
    <ScreenContainer>
      <View
        style={{
          borderBottomColor: theme.palette.gray[300],
          borderBottomWidth: 1,
          marginBottom: theme.getSpacing(4),
          marginTop: theme.getSpacing(2),
        }}
      >
        <Text
          style={{
            color: theme.palette.gray[900],
            fontSize: 14,
            paddingTop: theme.getSpacing(2),
            paddingBottom: theme.getSpacing(2),
          }}
        >
          {getText("patient-info-step")}
        </Text>
      </View>
      <Form methods={methods}>
        <Form.Alert title={error?.message} intent="error" visible={!!error} />
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText("first-name")}
              name="firstName"
              rules={{
                required: getText("first-name-is-required"),
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
              testID={PatientInfoTestIDs.firstNameInput}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText("last-name")}
              name="lastName"
              rules={{
                required: getText("last-name-is-required"),
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
              testID={PatientInfoTestIDs.lastNameInput}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <TextField
              label={getText("birth-date")}
              name="dateOfBirth"
              rules={{
                required: getText("birth-date-is-required"),
              }}
              onSubmit={methods.handleSubmit(handleSubmit)}
              disabled={status === "loading"}
              type="date"
              testID={PatientInfoTestIDs.dateOfBirthInput}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row>
          <Form.Column>
            <RadioButtonGroupField
              label={getText("gender")}
              rules={{
                required: getText("gender-is-required"),
              }}
              name="gender"
              values={[
                {
                  text: getText("male"),
                  value: "male",
                },
                {
                  text: getText("female"),
                  value: "female",
                },
              ]}
            />
          </Form.Column>
        </Form.Row>
        <Form.Actions>
          <Button
            testID={PatientInfoTestIDs.registerButton}
            onPress={handleSubmit}
            hierarchy="primary"
            loading={status === "loading"}
            logger={{ id: "patient-info-submit-button" }}
          >
            {getText("continue")}
          </Button>
        </Form.Actions>
      </Form>
    </ScreenContainer>
  );
};

interface PatientInfoProps
  extends NativeStackScreenProps<AccountStackParamList, "patient-info"> {}
