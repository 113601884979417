import React, {
  ForwardedRef,
  ForwardRefRenderFunction,
  useImperativeHandle,
  useState,
} from "react";
import { View } from "react-native";
import { Modal } from "assets/components/modal";
import { Text } from "assets/components/text";
import { getText } from "assets/localization/localization";
import { makeStyles } from "assets/theme";
import {
  CloseConfirmationHandler,
  CloseConfirmationProps,
} from "./CloseConfirmation";

export const CloseConfirmationModal: ForwardRefRenderFunction<
  CloseConfirmationHandler,
  CloseConfirmationProps
> = ({ onConfirm }, ref: ForwardedRef<CloseConfirmationHandler>) => {
  const styles = useStyles();
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleDismissModal = () => {
    setShowModal(false);
  };

  const handleConfirm = () => {
    handleDismissModal();
    onConfirm();
  };

  useImperativeHandle(ref, () => ({
    show: handleShowModal,
    hide: handleDismissModal,
  }));

  return (
    <Modal
      size="sm"
      show={showModal}
      cancelButtonProps={{
        onPress: handleDismissModal,
        logger: { id: "cancel-button-modal-close-confirmation" },
      }}
      okButtonProps={{
        onPress: handleConfirm,
        logger: { id: "ok-button-modal-close-confirmation" },
        hierarchy: "primary",
        text: getText("confirm"),
      }}
    >
      <View>
        <Text style={styles.text}>
          {getText("close-confirmation-question")}
        </Text>
        <Text style={styles.text}>{getText("close-confirmation-text")}</Text>
      </View>
    </Modal>
  );
};

const useStyles = makeStyles((theme) => ({
  text: {
    marginBottom: theme.getSpacing(2),
  },
}));
