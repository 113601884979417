import { FunctionComponent, PropsWithChildren } from "react";
import { makeStyles } from "assets/theme";
import { View } from "react-native";
import { ScreenContainer } from "assets/layout";
import { Text } from "assets/components/text";

export const Reminders: FunctionComponent = () => {
  const styles = useStyles();

  return (
    <ScreenContainer>
      <View>
        <Text style={styles.title}>Reminders Page (coming soon)</Text>
      </View>
    </ScreenContainer>
  );
};
const useStyles = makeStyles((theme) => ({
  title: {
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: 16,
    lineHeight: 24,
    color: theme.palette.gray[900],
  },
}));
