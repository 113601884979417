import { BookingDto } from "@digitalpharmacist/appointment-service-client-axios";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import React, { FunctionComponent, useEffect, useRef, useState } from "react";
import { Platform, View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { BottomSheet } from "assets/components/bottom-sheet";
import { Button } from "assets/components/button";
import { IconButton } from "assets/components/icon-button";
import { Modal } from "assets/components/modal";
import { Text } from "assets/components/text";
import { CloseIcon } from "assets/icons";
import { getText } from "assets/localization/localization";
import { makeStyles, useTheme } from "assets/theme";
import { useAppStateStore } from "../../store/app-store";
import { useUserState } from "../../store/user-store";
import {
  cancelBooking,
  setCancelAppointmentStatus,
  setShowCancelAppointment,
} from "./appointment-details-actions";
import { useAppointmentDetailsState } from "./appointment-details-store";
import { CancelAppointmentResult } from "./CancelAppointmentResult";
import { AppointmentsScreenRouteProp } from "../../navigation/RootNavigation";

export const CancelAppointment: FunctionComponent<CancelAppointmentProps> = ({
  booking,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  const confirmationSheetRef = useRef<BottomSheetModal>(null);
  const resultSheetRef = useRef<BottomSheetModal>(null);
  const [confirmationModalIsOpen, setConfirmationModalIsOpen] = useState(false);
  const [resultModalIsOpen, setResultModalIsOpen] = useState(false);
  const { showCancelAppointment, cancelAppointmentStatus } =
    useAppointmentDetailsState();
  const [showResult, setShowResult] = useState(false);
  const { user } = useUserState();
  const { pharmacyId } = useAppStateStore();
  const navigation = useNavigation<AppointmentsScreenRouteProp>();

  useEffect(() => {
    if (showCancelAppointment) {
      Platform.OS === "web"
        ? setConfirmationModalIsOpen(true)
        : confirmationSheetRef.current?.present();
    } else {
      Platform.OS === "web"
        ? setConfirmationModalIsOpen(false)
        : confirmationSheetRef.current?.dismiss();
    }
  }, [showCancelAppointment]);

  useEffect(() => {
    if (showResult) {
      Platform.OS === "web"
        ? setResultModalIsOpen(true)
        : resultSheetRef.current?.present();
    } else {
      Platform.OS === "web"
        ? setResultModalIsOpen(false)
        : resultSheetRef.current?.dismiss();
    }
  }, [showResult]);

  useEffect(() => {
    if (
      cancelAppointmentStatus === "success" ||
      cancelAppointmentStatus === "error"
    ) {
      setShowResult(true);
    }
  }, [cancelAppointmentStatus]);

  const handleConfirmationDismiss = () => {
    setShowCancelAppointment(false);
  };

  const handleResultDismiss = () => {
    setCancelAppointmentStatus("idle");
    setShowCancelAppointment(false);
    setShowResult(false);
    navigation.navigate("appointments");
  };

  const handleCancelAppointment = () => {
    cancelBooking(pharmacyId, booking.location_id, booking.id);
  };

  return (
    <>
      {Platform.OS === "web" ? (
        <>
          <Modal
            show={confirmationModalIsOpen}
            title={getText("cancel-appointment")}
            okButtonProps={{
              onPress: handleCancelAppointment,
              logger: { id: "cancel-appointment-ok-button-modal" },
              text: getText("cancel-appointment"),
              loading: cancelAppointmentStatus === "loading",
            }}
            cancelButtonProps={{
              onPress: handleConfirmationDismiss,
              logger: { id: "cancel-appointment-cancel-button-modal" },
              text: getText("keep"),
            }}
            dismissButtonProps={{
              onPress: handleConfirmationDismiss,
              logger: { id: "cancel-appointment-dismiss-button-modal" },
            }}
            isScrollable={true}
          >
            <Text>{getText("cancel-appointment-confirmation")}</Text>
          </Modal>
          <Modal
            show={resultModalIsOpen}
            title={getText("cancel-appointment")}
            okButtonProps={{
              onPress: handleResultDismiss,
              logger: { id: "cancel-appointment-result-ok-button-modal" },
              text: getText("done"),
            }}
            dismissButtonProps={{
              onPress: handleResultDismiss,
              logger: { id: "cancel-appointment-result-dismiss-button-modal" },
            }}
            isScrollable={true}
            height={800}
          >
            <CancelAppointmentResult booking={booking} />
          </Modal>
        </>
      ) : (
        <>
          <BottomSheet
            bottomSheetRef={confirmationSheetRef}
            height={"30%"}
            onDismiss={handleConfirmationDismiss}
            hideHandle={true}
            contentContainerStyle={{ flex: 1 }}
            headerContent={
              <View
                style={{
                  position: "relative",
                  marginBottom: theme.getSpacing(1),
                }}
              >
                <View style={styles.sheetTitleContainer}>
                  <Text style={styles.sheetTitle}>
                    {getText("cancel-appointment")}
                  </Text>
                </View>
                <View style={styles.sheetIconContainer}>
                  <IconButton
                    icon={CloseIcon}
                    onPress={handleConfirmationDismiss}
                    size={24}
                    color={theme.palette.gray[500]}
                    logger={{ id: "cancel-appointment-bottom-sheet-close" }}
                  />
                </View>
              </View>
            }
          >
            <Text>{getText("cancel-appointment-confirmation")}</Text>
            <View style={styles.sheetButtons}>
              <Button
                hierarchy="secondary-gray"
                size="medium"
                onPress={handleConfirmationDismiss}
                logger={{ id: `cancel-appointment-keep` }}
                style={styles.sheetButton}
              >
                {getText("keep")}
              </Button>
              <Button
                hierarchy="destructive"
                size="medium"
                onPress={handleCancelAppointment}
                logger={{ id: `cancel-appointment-cancel` }}
                style={styles.sheetButton}
                loading={cancelAppointmentStatus === "loading"}
              >
                {getText("cancel-appointment")}
              </Button>
            </View>
          </BottomSheet>
          <BottomSheet
            bottomSheetRef={resultSheetRef}
            height={"100%"}
            onDismiss={handleResultDismiss}
            hideHandle={true}
            contentContainerStyle={{ flex: 1 }}
            headerContent={
              <View
                style={{
                  position: "relative",
                  marginBottom: theme.getSpacing(1),
                }}
              >
                <View style={styles.sheetTitleContainer}>
                  <Text style={styles.sheetTitle}>
                    {getText("cancel-appointment")}
                  </Text>
                </View>
                <View style={styles.sheetIconContainer}>
                  <IconButton
                    icon={CloseIcon}
                    onPress={handleResultDismiss}
                    size={24}
                    color={theme.palette.gray[500]}
                    logger={{
                      id: "cancel-appointment-result-bottom-sheet-close",
                    }}
                  />
                </View>
              </View>
            }
          >
            <CancelAppointmentResult booking={booking} />
            <View style={styles.sheetButtons}>
              <Button
                hierarchy="primary"
                size="medium"
                onPress={handleResultDismiss}
                logger={{ id: `cancel-appointment-done` }}
                style={{ width: "100%" }}
              >
                {getText("done")}
              </Button>
            </View>
          </BottomSheet>
        </>
      )}
    </>
  );
};

export interface CancelAppointmentProps {
  booking: BookingDto;
}

const useStyles = makeStyles((theme) => ({
  sheetIconContainer: {
    position: "absolute",
    right: -12,
    top: -5,
    zIndex: 1,
  },
  sheetTitleContainer: {
    paddingHorizontal: theme.getSpacing(3),
    paddingVertical: theme.getSpacing(1),
  },
  sheetTitle: {
    ...theme.fonts.medium,
    textAlign: "center",
    fontWeight: "600",
    fontSize: 18,
  },
  sheetButtons: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "auto",
    marginBottom: theme.getSpacing(3),
  },
  sheetButton: {
    width: "48%",
  },
}));
