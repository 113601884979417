import React, { FunctionComponent, PropsWithChildren } from "react";
import { Text } from "assets/components/text";
import { TextField } from "assets/components/text-field";
import { useForm } from "assets/form";
import { makeStyles, useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { RegisterFormTestIDs, RegisterTestIDs } from "./RegisterTestIDs";
import { useRegisterState } from "./register-store";
import { register } from "./register-actions";
import { RegisterEmailForm } from "./register-actions";
import { useNavigation } from "@react-navigation/native";
import { AuthStackNavigationProp } from "../../navigation/AuthNavigation";
import { PasswordRules } from "../../common/PasswordRules";
import { getText } from "assets/localization/localization";
import { Form, ScreenContainer } from "assets/layout";
import { GoogleSignInButton } from "../../components/google-sign-in-button/GoogleSignInButton";
import { AppleSignInButton } from "../../components/apple-sign-in-button/AppleSignInButton";
import { View, Image, StyleSheet } from "react-native";
import { GradientView } from "assets/components/gradient-view";
import isEmail from "validator/lib/isEmail";
import { isPassword } from "@digitalpharmacist/validation-dp";
import {
  PHARMACY_ID,
  STATIC_DEV_LUMISTRY,
} from "../../../../../apps/mobile/modules/common/constants";

export const RegisterEmail: FunctionComponent<
  PropsWithChildren<RegisterEmailProps>
> = () => {
  const theme = useTheme();
  const styles = useStyles();
  const methods = useForm<RegisterEmailForm>({
    defaultValues: {
      email: "",
      password: "",
      passwordConfirm: "",
    },
  });

  const uri = STATIC_DEV_LUMISTRY + PHARMACY_ID + "/images/logo_on_dark.png";

  const passwordValue = methods.watch("password");
  const { status, error } = useRegisterState();
  const navigation = useNavigation<AuthStackNavigationProp>();

  const handleSubmit = async () => {
    register(methods.getValues(), navigation);
  };
  const handleBackToLogin = () => {
    navigation.navigate("intro");
  };

  return (
    <>
      <GradientView
        contentHeight={200}
        fillGradientStops={[
          { stopColor: theme.colors.gradientStart, offset: 0 },
          { stopColor: theme.colors.gradientEnd, offset: 100 },
        ]}
      >
        <View
          style={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <Image
            source={{
              uri: uri,
            }}
            style={styles.logo}
          />
        </View>
      </GradientView>
      <ScreenContainer
        style={{
          borderTopLeftRadius: 16,
          borderTopRightRadius: 16,
          marginTop: -16,
        }}
      >
        <Text
          style={{
            ...theme.fonts.regular,
            color: theme.colors.brandedText,
            fontSize: 20,
            paddingTop: theme.getSpacing(2),
            paddingBottom: theme.getSpacing(2),
            marginTop: theme.getSpacing(1),
            textAlign: "center",
            lineHeight: 30,
            fontWeight: "400",
          }}
        >
          {getText("create-your-account")}
        </Text>
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row style={{ paddingTop: theme.getSpacing(3) }}>
            <Form.Column>
              <TextField
                label={getText("email-address")}
                name="email"
                rules={{
                  required: getText("email-is-required"),
                  validate: {
                    value: () => {
                      return isEmail(methods.getValues().email)
                        ? true
                        : getText("email-is-not-valid");
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === "loading"}
                testID={RegisterFormTestIDs.emailInput}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                label={getText("password")}
                name="password"
                rules={{
                  required: getText("password-is-required"),
                  validate: {
                    value: () => {
                      return isPassword(methods.getValues().password)
                        ? true
                        : getText("password-entry-does-not-meet-criteria");
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === "loading"}
                testID={RegisterFormTestIDs.passwordInput}
                type="password"
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                label={getText("confirm-password")}
                name="passwordConfirm"
                rules={{
                  required: getText("password-is-required"),
                  validate: {
                    value: () => {
                      return methods.getValues().password ===
                        methods.getValues().passwordConfirm
                        ? true
                        : getText("password-entries-do-not-match");
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === "loading"}
                testID={RegisterFormTestIDs.passwordConfirmInput}
                type="password"
              />
            </Form.Column>
          </Form.Row>
          <Form.Content
            style={{ display: methods.getValues().password ? "flex" : "none" }}
          >
            <PasswordRules passwordValue={passwordValue}></PasswordRules>
          </Form.Content>
          <Form.Actions>
            <Button
              testID={RegisterTestIDs.registerButton}
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === "loading"}
              logger={{ id: "register-email-submit-button" }}
              style={{
                marginBottom: theme.getSpacing(1),
                marginTop: theme.getSpacing(3),
              }}
            >
              {getText("create-account")}
            </Button>
            <View
              style={{
                borderBottomColor: theme.palette.gray[300],
                borderBottomWidth: 1,
                marginBottom: theme.getSpacing(3),
              }}
            >
              <Button
                hierarchy="tertiary-gray"
                onPress={handleBackToLogin}
                logger={{ id: "back-to-login-button" }}
                style={{ marginBottom: theme.getSpacing(1) }}
              >
                {getText("back-to-login")}
              </Button>
            </View>
            <AppleSignInButton />
            <GoogleSignInButton style={{ marginTop: theme.getSpacing(2) }} />
          </Form.Actions>
        </Form>
      </ScreenContainer>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 300,
    height: 150,
    resizeMode: "contain",
  },
}));

interface RegisterEmailProps {}
