import {
  CreateRecordUnderCareRequestDto,
  PatientRecordDto,
  RecordUnderCareDto,
  RecordUnderCareRequestDto,
} from "@digitalpharmacist/patient-service-client-axios";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import create from "zustand";
import { ApiError, AsyncStatus } from "../../../store/types";

export const usePatientIntakeState = create<PatientIntakeState>((set) => ({
  status: "idle",
  data: undefined,
  updateData: (data: PatientIntakeState) => {
    set({ ...data });
  },
}));

export const usePatientUnderCareState = create<PatientUnderCareState>(
  (set) => ({
    status: "idle",
    data: undefined,
    patientRecord: undefined,
    caregiverRequests: [],
    recordUnderCareRequests: [],
    caregivers: [],
    recordsUnderCare: [],
    updateData: (data: PatientUnderCareState) => {
      set({ ...data });
    },
    updatePatientRecord: (patientRecord: PatientUnderCareState) => {
      set({ ...patientRecord });
    },
  })
);

interface PatientIntakeState {
  data: any;
  selectedStore?: PharmacyLocationDto;
  updateData: (data: PatientIntakeState) => void;
  status: AsyncStatus;
  error?: ApiError;
}

interface PatientUnderCareState {
  data: CreateRecordUnderCareRequestDto | undefined;
  patientRecord: PatientRecordDto | undefined;
  updatePatientRecord: (patientRecord: PatientUnderCareState) => void;
  updateData: (data: PatientUnderCareState) => void;
  status: AsyncStatus;
  error?: ApiError;
  caregiverRequests: RecordUnderCareRequestDto[];
  recordUnderCareRequests: RecordUnderCareRequestDto[];
  caregivers: RecordUnderCareDto[];
  recordsUnderCare: RecordUnderCareDto[];
}

export enum PatientUnderCareEditDetailsEnum {
  PersonalInfo = "personal",
  MedicalInfo = "medical",
  IdAndInsuranceInfo = "id",
}
