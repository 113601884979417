export const ResetPasswordTestIDs = {
  emailInput: "register-email-input",
  passwordInput: "register-password-input",
  passwordConfirmInput: "register-password-confirm-input",
};

export const UpdateWeakPasswordTestIDs = {
  passwordInput: "password-input",
  passwordConfirmInput: "password-confirm-input",
};
