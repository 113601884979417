import React, {
  FunctionComponent,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react";
import { View } from "react-native";
import shallow from "zustand/shallow";
import {
  useRoute,
  useFocusEffect,
  useIsFocused,
  useNavigation,
} from "@react-navigation/native";
import { StackNavigationProp } from "@react-navigation/stack";
import { Text } from "assets/components/text";
import { ScreenContainer } from "assets/layout";
import { makeStyles, useTheme } from "assets/theme";
import { useAppointmentTypesState } from "./appointment-types/appointment-types-store";
import { AppointmentTypesList } from "./appointment-types/AppointmentTypesList";
import { LoadingIndicator } from "assets/components/loading-indicator";
import { useAppointmentsState } from "./appointments-store";
import { Appointment } from "./Appointment";
import { Pagination } from "../../components/pagination/Pagination";
import {
  getAppointments,
  setAppointmentsLocation,
  setOffsetUpcoming,
} from "./appointments-actions";
import { useUserState } from "../../store/user-store";
import { useAppStateStore } from "../../store/app-store";
import { formatDateTimeApi } from "../../common/datetime-utils";
import moment from "moment";
import {
  AppointmentTypeDto,
  SortOrder,
} from "@digitalpharmacist/appointment-service-client-axios";
import { getText } from "assets/localization/localization";
import { useLocation } from "../../hooks/useLocation";
import { useStoresWithDistanceInfo } from "../../components/store-selector/hooks/useStoresWithDistanceInfo";
import AvailableLocationItem from "./available-locations-list/AvailableLocationsList";
import { Button } from "../../../../../packages/assets/components/button";
import { PharmacyLocationDto } from "@digitalpharmacist/pharmacy-service-client-axios";
import { Distance } from "../../types";
import {
  setAppointmentType,
  setLocation,
  setShowBookAppointment,
} from "./book-appointment/book-appointment-actions";
import { BookAppointment } from "./book-appointment/BookAppointment";

interface UpcomingAppointmentsRouteParams {
  appointment_type_id?: string;
  location_id?: string;
}

type UpcomingAppointmentsRouteParamList = {
  Upcoming: UpcomingAppointmentsRouteParams;
};

export const UpcomingAppointments: FunctionComponent<UpcomingAppointmentsProps> =
  () => {
    const isFocused = useIsFocused();
    const route = useRoute(),
      navigation =
        useNavigation<
          StackNavigationProp<UpcomingAppointmentsRouteParamList, "Upcoming">
        >(),
      routeParams =
        route.params && (route.params as UpcomingAppointmentsRouteParams),
      appointmentTypeRouteParam = routeParams?.appointment_type_id,
      locationIdRouteParam = routeParams?.location_id;
    const theme = useTheme();
    const styles = useStyles();
    const [storeRange, setStoreRange] = useState(3);

    const { typesStatus, appointmentTypes, appointmentGroups } =
      useAppointmentTypesState(
        (state) => ({
          typesStatus: state.status,
          appointmentTypes: state.appointmentTypes,
          appointmentGroups: state.appointmentGroups,
        }),
        shallow
      );
    const userId = useUserState((state) => state.user?.id);
    const { status } = useLocation();

    const {
      upcomingAppointments,
      appointmentsStatus,
      offsetUpcoming,
      limit,
      location,
      appointmentsLocationId,
    } = useAppointmentsState(
      (state) => ({
        upcomingAppointments: state.upcomingAppointments,
        appointmentsStatus: state.status,
        offsetUpcoming: state.offsetUpcoming,
        limit: state.limit,
        location: state.location,
        appointmentsLocationId: state.appointmentsLocationId,
      }),
      shallow
    );

    const pharmacyId = useAppStateStore((state) => state.pharmacyId);
    const availableStores = useAppStateStore((state) => state.stores);

    const filteredStores = useMemo(
      () => availableStores.filter((store) => store.id !== location?.id),
      [location?.id, availableStores.length]
    );

    const { stores } = useStoresWithDistanceInfo(filteredStores);

    const handleNext = () => {
      setOffsetUpcoming(offsetUpcoming + limit);
      getAppointmentsData();
    };

    const handlePrev = () => {
      setOffsetUpcoming(offsetUpcoming - limit);
      getAppointmentsData();
    };

    const getAppointmentsData = () => {
      if (location?.id) {
        void getAppointments(
          pharmacyId,
          location.id,
          SortOrder.Asc,
          "upcoming",
          {
            patientId: userId,
            minStartDate: formatDateTimeApi(moment()),
          }
        );
      }
    };

    const handleOnPress = (item: Distance<PharmacyLocationDto>) =>
      setAppointmentsLocation(item.id as string);

    useFocusEffect(
      useCallback(() => {
        if (appointmentTypes.length && appointmentTypeRouteParam) {
          const foundAppointmentTypeBasedOnRouteParam = appointmentTypes.find(
            (type) => type.id === appointmentTypeRouteParam
          );
          if (foundAppointmentTypeBasedOnRouteParam) {
            setAppointmentType(foundAppointmentTypeBasedOnRouteParam);

            // find the location based on the route parameter
            if (locationIdRouteParam && availableStores.length) {
              const foundLocationBasedOnRouteParam = availableStores.find(
                (store) => store.id === locationIdRouteParam
              );
              if (foundLocationBasedOnRouteParam) {
                setLocation(foundLocationBasedOnRouteParam);
              }
            }

            setShowBookAppointment(true);
          }
        }

        return () => {
          navigation.setParams({
            appointment_type_id: undefined,
            location_id: undefined,
          });
        };
      }, [appointmentTypes, locationIdRouteParam, appointmentTypeRouteParam])
    );

    // Resetting the count of displayed locations once the preferred location gets updated
    useEffect(() => {
      setStoreRange(3);
    }, [appointmentsLocationId]);

    const getSortedAppointmentTypes = (types: AppointmentTypeDto[]) => {
      return types.sort((a, b) =>
        a.title.localeCompare(b.title, undefined, { sensitivity: "base" })
      );
    };

    return (
      <ScreenContainer>
        <View
          style={{
            marginTop: theme.getSpacing(4),
          }}
        >
          {appointmentsStatus === "loading" || !upcomingAppointments ? (
            <LoadingIndicator />
          ) : (
            <>
              {upcomingAppointments.total === 0 ? (
                <View style={styles.emptyContainer}>
                  <Text style={styles.emptyTitle}>
                    {getText("no-appointments")}
                  </Text>
                </View>
              ) : (
                upcomingAppointments.results.map((appointment) => (
                  <Appointment
                    appointment={appointment}
                    type="upcoming"
                    key={appointment.id}
                  />
                ))
              )}
            </>
          )}
          {upcomingAppointments && upcomingAppointments.total > limit ? (
            <Pagination
              currentPage={(offsetUpcoming + limit) / limit}
              totalPages={Math.ceil(upcomingAppointments.total / limit)}
              onPrevPress={handlePrev}
              onNextPress={handleNext}
            />
          ) : null}

          <Text style={styles.title}>{getText("make-appointment")}</Text>

          <Text style={styles.locationName}>{location?.name}</Text>
          {typesStatus === "loading" ? (
            <LoadingIndicator />
          ) : !appointmentTypes.length ? (
            <>
              <Text style={styles.emptyTitle}>{getText("no-services")}</Text>
            </>
          ) : (
            <AppointmentTypesList
              types={getSortedAppointmentTypes(appointmentTypes)}
              groups={appointmentGroups}
            />
          )}

          {typesStatus !== "loading" && filteredStores.length > 0 && (
            <>
              <Text style={styles.title}>{getText("dont-see-service")}</Text>
              <Text style={styles.subtitle}>
                {getText("find-service-at-location")}
              </Text>

              {stores
                .sort((first, second) => {
                  if (!first.distance || !second.distance) return 0;
                  return first.distance - second.distance;
                })
                .slice(0, storeRange)
                .map((store) => (
                  <AvailableLocationItem
                    item={store}
                    onPress={handleOnPress}
                    key={store.id}
                    status={status}
                  />
                ))}
              {storeRange < stores.length && (
                <Button
                  style={styles.buttonBorderColor}
                  labelStyle={styles.buttonLabel}
                  onPress={() => {
                    if (stores.length > storeRange + 3) {
                      setStoreRange((prev) => prev + 3);
                    } else {
                      setStoreRange(stores.length);
                    }
                  }}
                  hierarchy="secondary-gray"
                  logger={{ id: "select-all-box-unselect-global" }}
                >
                  {getText("get-more-stores")}
                </Button>
              )}
            </>
          )}
          {isFocused && (
            <BookAppointment onDismiss={() => setShowBookAppointment(false)} />
          )}
        </View>
      </ScreenContainer>
    );
  };

interface UpcomingAppointmentsProps {}

const useStyles = makeStyles((theme) => ({
  title: {
    ...theme.fonts.medium,
    fontSize: 20,
    lineHeight: 22,
    marginTop: theme.getSpacing(3),
    marginBottom: theme.getSpacing(1),
  },
  emptyContainer: {
    marginBottom: theme.getSpacing(2),
  },
  emptyTitle: {
    lineHeight: 20,
    color: theme.palette.gray[700],
  },
  buttonLabel: {
    color: theme.palette.primary[600],
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
    marginLeft: 0,
    letterSpacing: 0,
  },
  buttonBorderColor: {
    borderColor: "transparent",
    backgroundColor: "transparent",
    marginLeft: theme.getSpacing(1),
    padding: theme.getSpacing(2),
  },
  subtitle: {
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(2),
    fontSize: 14,
    lineHeight: 16,
  },
  locationName: {
    color: theme.palette.gray[700],
    marginBottom: theme.getSpacing(2),
    fontSize: 16,
    lineHeight: 18,
  },
}));
