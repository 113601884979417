import React, { FunctionComponent, PropsWithChildren } from "react";
import { Svg, Path, Circle, Rect } from "react-native-svg";
import { IconProps } from "./types";

export const MedicationsIcon: FunctionComponent<PropsWithChildren<IconProps>> =
  ({ color, size, testID }) => {
    return (
      <Svg width={size} height={size} fill="none">
        <Path fill="#fff" fillOpacity={0.01} d="M0 0h24v24H0z" />
        <Path
          d="M20 2v4M22 4h-4"
          stroke="#EAF3F2"
          strokeWidth={1.5}
          strokeLinecap="round"
        />
        <Path
          d="M3 10a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM12 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM11 19a1 1 0 1 1-2 0 1 1 0 0 1 2 0ZM15 7.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
          fill="#EAF3F2"
        />
        <Circle cx={4.5} cy={4.5} r={2.5} stroke="#EAF3F2" strokeWidth={1.5} />
        <Rect
          x={5.069}
          y={21.349}
          width={4.5}
          height={9.5}
          rx={2.25}
          transform="rotate(-156.393 5.07 21.349)"
          stroke="#EAF3F2"
          strokeWidth={1.5}
        />
        <Circle cx={18} cy={16} r={5} stroke="#EAF3F2" strokeWidth={1.5} />
        <Path
          d="m21.769 17.14-7.236-1.976"
          stroke="#EAF3F2"
          strokeWidth={1.5}
          strokeLinecap="round"
        />
      </Svg>
    );
  };
