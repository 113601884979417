import React, { FunctionComponent, PropsWithChildren, useState } from "react";
import { Text } from "assets/components/text";
import { TextField } from "assets/components/text-field";
import { useForm } from "assets/form";
import { makeStyles, useTheme } from "assets/theme";
import { Button } from "assets/components/button";
import { getText } from "assets/localization/localization";
import { Form, ScreenContainer } from "assets/layout";
import { Platform, View } from "react-native";
import * as validate from "@digitalpharmacist/validation-dp";
import { usePatientUnderCareState } from "./patient-store";
import { formatDate, formatDateAsString } from "../../../common/form-utils";
import { DropdownSelect } from "assets/components/dropdown-select";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  CreatePatientRecordCaregiverDto,
  CreatePatientRecordDto,
  PatientUnderCareRelationship,
} from "@digitalpharmacist/patient-service-client-axios";
import { useUserState } from "../../../store/user-store";
import {
  PatientUnderCareForm,
  updatePatientUnderCareForm,
} from "./patient-actions";
import { PatientUnderCareStackParamList } from "./PatientUnderCareNavigation";
import { GuardianConfirmBottomSheetComponent } from "./components/caregiver-minor/LegalGuardianConfirmBottomSheet";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import patientService from "../../../api/patient-service";
import { GuardianConfirmModalComponent } from "./components/caregiver-minor/LegalGuardianConfirmModal";

export const AddPatientUnderCareInfo: FunctionComponent<
  PropsWithChildren<AddPatientUnderCareInfoProps>
> = ({ navigation }) => {
  const theme = useTheme();
  const styles = useStyles();
  const { user } = useUserState();
  const guardianBottomSheetRef = React.useRef<BottomSheetModal>(null);
  const [showModal, setShowModal] = useState(false);

  const methods = useForm<PatientUnderCareForm>({
    defaultValues: {
      first_name: "",
      last_name: "",
      date_of_birth: "",
      relationship: PatientUnderCareRelationship.Child,
      email: "",
      guardianship_approved: false,
    },
  });

  const relationshipProps: Array<{ label: string; value: string }> = [
    {
      label: getText("child"),
      value: PatientUnderCareRelationship.Child,
    },
    {
      label: getText("spouse"),
      value: PatientUnderCareRelationship.Spouse,
    },
    {
      label: getText("parent"),
      value: PatientUnderCareRelationship.Parent,
    },
    {
      label: getText("grandparent"),
      value: PatientUnderCareRelationship.Grandparent,
    },
    {
      label: getText("grandchild"),
      value: PatientUnderCareRelationship.Grandchild,
    },
    {
      label: getText("partner"),
      value: PatientUnderCareRelationship.Partner,
    },
    {
      label: getText("pet"),
      value: PatientUnderCareRelationship.Pet,
    },
    {
      label: getText("other"),
      value: PatientUnderCareRelationship.Other,
    },
  ];

  const { status, error } = usePatientUnderCareState();

  const handleSubmit = () => {
    updatePatientUnderCareForm(methods.getValues());
    if (
      !(methods.getValues().relationship === PatientUnderCareRelationship.Pet)
    ) {
      //Checking the relationship -> if it not a pet then check if it is an adult
      if (validate.isLegalAge(formatDate(methods.getValues().date_of_birth))) {
        navigation.navigate("add-patient-under-care-email");
      } else {
        handleShowModal(true);
      }
    } else {
      handleShowModal(true);
    }
    methods.reset();
  };

  const handleConfirmOnPress = async () => {
    if (!user) {
      throw new Error("user id missing");
    }
    const createPatientRecordCaregiverDto: CreatePatientRecordCaregiverDto = {
      user_id: user.id,
      guardianship_confirmed: methods.getValues().guardianship_approved,
      relationship: methods.getValues().relationship,
    };

    const formattedDOB = formatDateAsString(methods.getValues().date_of_birth);
    if (!methods.getValues().guardianship_approved) {
      alert("Confirmation is required before you can proceed"); //TODO need an error state to show for this use case
    } else {
      if (user.preferredPharmacyLocationId && user.phoneNumber) {
        const createPatientRecord: CreatePatientRecordDto = {
          pharmacy_id: user.preferredPharmacyLocationId,
          first_name: methods.getValues().first_name,
          last_name: methods.getValues().last_name,
          date_of_birth: formattedDOB,
          phone: user.phoneNumber,
          caregiver: createPatientRecordCaregiverDto,
        };
        const data = await patientService.patientRecordCreate(
          createPatientRecord
        );
        methods.setValue("patient_record_id", data.id);
        updatePatientUnderCareForm(methods.getValues());
        usePatientUnderCareState.setState((state) => {
          return { patientRecord: { ...state, ...data } };
        });
        guardianBottomSheetRef.current?.dismiss();
        navigation.navigate("add-minor-or-pet-patient");
      }
      handleShowModal(false);
    }
  };

  const handleShowModal = (show: boolean) => {
    if (Platform.OS === "web") {
      setShowModal(show);
    } else {
      if (!show) {
        guardianBottomSheetRef.current?.dismiss();
      } else {
        guardianBottomSheetRef.current?.present();
      }
    }
  };

  return (
    <ScreenContainer>
      <View style={styles.headerTitle}>
        <Text style={styles.headerTitleText}>
          {getText("enter-patient-information")}
        </Text>
      </View>
      <View
        style={{
          marginTop: theme.getSpacing(2),
        }}
      >
        <Form methods={methods}>
          <Form.Alert title={error?.message} intent="error" visible={!!error} />
          <Form.Row>
            <Form.Column>
              <TextField
                label={getText("first-name")}
                name="first_name"
                rules={{
                  required: getText("first-name-is-required"),
                  validate: {
                    value: () => {
                      return validate.isName(methods.getValues().first_name)
                        ? true
                        : getText("first-name-is-not-valid");
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === "loading"}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                label={getText("last-name")}
                name="last_name"
                rules={{
                  required: getText("last-name-is-required"),
                  validate: {
                    value: () => {
                      return validate.isName(methods.getValues().last_name)
                        ? true
                        : getText("last-name-is-not-valid");
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === "loading"}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <TextField
                label={getText("birth-date")}
                name="date_of_birth"
                rules={{
                  required: getText("birth-date-is-required"),
                  validate: {
                    value: () => {
                      return validate.isDate(
                        formatDate(methods.getValues().date_of_birth)
                      )
                        ? true
                        : getText("date-is-not-valid");
                    },
                  },
                }}
                onSubmit={methods.handleSubmit(handleSubmit)}
                disabled={status === "loading"}
                type="date"
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column>
              <DropdownSelect
                options={relationshipProps}
                fieldName="relationship"
                label={getText("relationship-to-me")}
                labelInlined={true}
              ></DropdownSelect>
            </Form.Column>
          </Form.Row>
          <Form.Actions>
            <Button
              onPress={methods.handleSubmit(handleSubmit)}
              hierarchy="primary"
              loading={status === "loading"}
              logger={{ id: "register-details-submit-button" }}
            >
              {getText("add-person")}
            </Button>
          </Form.Actions>
        </Form>
      </View>
      {Platform.OS === "web" ? (
        <GuardianConfirmModalComponent
          showModal={showModal}
          dismissBottomSheet={handleShowModal}
          legalGuardianAgreement={handleConfirmOnPress}
          formValues={methods}
        ></GuardianConfirmModalComponent>
      ) : (
        <GuardianConfirmBottomSheetComponent
          guardianBottomSheetRef={guardianBottomSheetRef}
          dismissBottomSheet={() => handleShowModal(false)}
          legalGuardianAgreement={handleConfirmOnPress}
          formValues={methods}
        ></GuardianConfirmBottomSheetComponent>
      )}
    </ScreenContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  headerTitleText: {
    marginBottom: theme.getSpacing(1),
    fontSize: 16,
    lineHeight: 24,
    fontWeight: "600",
  },
  headerTitle: {
    marginTop: theme.getSpacing(2),
    marginBottom: theme.getSpacing(1),
    borderBottomColor: theme.palette.gray[300],
    borderBottomWidth: 1,
  },
}));

type AddPatientUnderCareInfoProps = NativeStackScreenProps<
  PatientUnderCareStackParamList,
  "add-patient-under-care-info"
>;
