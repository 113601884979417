import { RecordUnderCareDto } from "@digitalpharmacist/patient-service-client-axios";
import { CloseIcon } from "assets/icons";
import { getText } from "assets/localization/localization";
import { FunctionComponent, PropsWithChildren } from "react";
import { View } from "react-native";
import { useTheme } from "react-native-paper";
import { BottomSheet } from "assets/components/bottom-sheet";
import { Text } from "assets/components/text";
import { BottomSheetModal } from "@gorhom/bottom-sheet";
import { Button } from "assets/components/button";
import { IconButton } from "assets/components/icon-button";
import { makeStyles } from "assets/theme";

export const RevokeAccessMobileComponent: FunctionComponent<
  PropsWithChildren<{
    revokeAccessBottomSheetRef: React.RefObject<BottomSheetModal>;
    dismissBottomSheet: (val: boolean) => void;
    handleRevokeOnPress: (recordId: string, caregiverId: string) => void;
    record: RecordUnderCareDto;
  }>
> = ({
  revokeAccessBottomSheetRef,
  dismissBottomSheet,
  handleRevokeOnPress,
  record,
}) => {
  const theme = useTheme();
  const styles = useStyles();
  return (
    <BottomSheet
      bottomSheetRef={revokeAccessBottomSheetRef}
      height={"30%"}
      footerContent={<View></View>}
      onDismiss={() => dismissBottomSheet(false)}
      children={
        <View style={styles.mainContainer}>
          <Text style={styles.modalText}>
            {getText("revoke-access-for-authorized-caregiver", {
              firstName: record.caregiver_first_name,
              lastName: record.caregiver_last_name,
            })}
          </Text>
          <View style={styles.buttonContainer}>
            <Button
              hierarchy="secondary-gray"
              logger={{
                id: "cancel-bottom-sheet-button",
              }}
              style={{ marginEnd: theme.getSpacing(2), width: "50%" }}
              onPress={() => dismissBottomSheet(false)}
            >
              {getText("cancel")}
            </Button>
            <Button
              hierarchy="destructive-secondary"
              logger={{
                id: "revoke-bottom-sheet-button",
              }}
              style={{ width: "50%" }}
              onPress={() =>
                handleRevokeOnPress(record.id, record.caregiver_id)
              }
            >
              {getText("revoke")}
            </Button>
          </View>
        </View>
      }
      headerContent={
        <View style={styles.footerContent}>
          <View style={styles.headerIconStyle}>
            <IconButton
              icon={CloseIcon}
              onPress={() => dismissBottomSheet(false)}
              size={20}
              color={theme.palette.gray[500]}
              logger={{
                id: "revoke-access-close-button",
              }}
            />
          </View>
          <View style={styles.headerText}>
            <Text style={styles.modalHeader}>
              {getText("revoke-access") + "?"}
            </Text>
          </View>
        </View>
      }
    ></BottomSheet>
  );
};

const useStyles = makeStyles((theme) => ({
  mainContainer: { alignSelf: "center", flexDirection: "column" },
  buttonContainer: {
    flexDirection: "row",
    alignSelf: "center",
    marginBottom: theme.getSpacing(1),
    marginTop: theme.getSpacing(2),
    flex: 1,
    marginStart: theme.getSpacing(1),
    marginEnd: theme.getSpacing(1),
  },
  headerIconStyle: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  footerContent: { justifyContent: "center" },
  headerText: { alignSelf: "center", marginTop: theme.getSpacing(1) },
  modalText: {
    lineHeight: 24,
    color: theme.palette.gray[700],
    fontSize: 16,
    fontWeight: "400",
    padding: theme.getSpacing(1),
    alignSelf: "center",
  },
  modalHeader: {
    lineHeight: 28,
    fontWeight: "600",
    fontSize: 18,
    color: theme.palette.gray[900],
  },
}));
