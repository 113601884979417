import { ScreenContainer } from "assets/layout";
import theme, { makeStyles } from "assets/theme";
import React, { FunctionComponent, PropsWithChildren } from "react";
import { Button } from "assets/components/button";
import { getText } from "assets/localization/localization";
import type { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RefillStackParamList } from "./RefillNavigation";
import { RefillTestIDs } from "./RefillTestIDs";
import { useRefillReviewMethodStore } from "./refill-store";
import { TextInput } from "assets/components/text-field/TextInput";
import { View } from "react-native";
import { Text } from "../../../../../packages/assets/components/text";
import { Divider } from "react-native-paper";

export const RefillNotes: FunctionComponent<
  PropsWithChildren<RefillDetailsProps>
> = ({ navigation }) => {
  const styles = useStyles();
  const { noteValue, methodValue, updateReviewMethodData } =
    useRefillReviewMethodStore();

  const handleOnPressDone = () => {
    navigation.navigate("refill-review");
  };

  return (
    <ScreenContainer>
      <View style={styles.container}>
        <Text style={styles.textTitle}>{getText("notes")}</Text>
        <Divider />
      </View>

      <TextInput
        multiline
        numberOfLines={3}
        value={noteValue}
        placeholder={getText("note-for-pharmacy")}
        onChange={(text: any) => {
          if (updateReviewMethodData) {
            updateReviewMethodData({
              methodValue: methodValue,
              noteValue: text,
            });
          }
        }}
      />
      <View style={styles.buttonContainer}>
        <Button
          hierarchy="primary"
          onPress={handleOnPressDone}
          testID={RefillTestIDs.refillNotesButton}
          logger={{ id: RefillTestIDs.refillNotesButton }}
          style={{
            alignItems: "center",
            width: "100%",
            marginTop: theme.getSpacing(1),
          }}
        >
          {getText("save")}
        </Button>
      </View>
    </ScreenContainer>
  );
};

export interface RefillDetailsProps
  extends NativeStackScreenProps<RefillStackParamList, "refill-notes"> {}

const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    flex: 1,
    justifyContent: "flex-end",
    width: "100%",
  },
  textTitle: {
    ...theme.fonts.medium,
    color: theme.palette.gray[900],
    fontWeight: "600",
    fontSize: 16,
    marginVertical: theme.getSpacing(2),
  },
  container: {
    paddingTop: theme.getSpacing(2),
    paddingBottom: theme.getSpacing(2),
  },
}));
