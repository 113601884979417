export const RegisterTestIDs = {
  firstNameInput: "register-first-name-input",
  lastNameInput: "register-last-name-input",
  dateOfBirthInput: "register-dob-name-input",
  phoneNumberMobileInput: "register-phone-number-mobile-input",
  registerButton: "register-login-button",
};

export const RegisterFormTestIDs = {
  emailInput: "register-email-input",
  passwordInput: "register-password-input",
  passwordConfirmInput: "register-password-confirm-input",
};

export const CreatePasswordTestIDs = {
  passwordInput: "create-password-input",
  passwordConfirmInput: "create-password-confirm-input",
};
