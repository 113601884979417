export * from "./RequestHeaders";
export * from "./BaseApiConfiguration";

declare module "axios" {
  export interface AxiosRequestConfig {
    /**
     * Boolean value that tells to the current / single request if should use the authentication flow or not.
     */
    disableAuth?: boolean;
    /**
     * Boolean value that will be handled automatically from the authentication flow if it is enabled.
     */
    _retry?: boolean;
  }
}
