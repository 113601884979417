/* tslint:disable */
/* eslint-disable */
/**
 * user
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Medication,
    MedicationFromJSON,
    MedicationToJSON,
    PatientFeedback,
    PatientFeedbackFromJSON,
    PatientFeedbackToJSON,
    PharmacyLocation,
    PharmacyLocationFromJSON,
    PharmacyLocationToJSON,
    ResetPassword,
    ResetPasswordFromJSON,
    ResetPasswordToJSON,
    SendPasswordResetLink,
    SendPasswordResetLinkFromJSON,
    SendPasswordResetLinkToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserAccessToken,
    UserAccessTokenFromJSON,
    UserAccessTokenToJSON,
    UserRegistration,
    UserRegistrationFromJSON,
    UserRegistrationToJSON,
    UserToken,
    UserTokenFromJSON,
    UserTokenToJSON,
    UserUpdate,
    UserUpdateFromJSON,
    UserUpdateToJSON,
    UserVerification,
    UserVerificationFromJSON,
    UserVerificationToJSON,
} from '../models';

export interface GoogleSignInRequest {
    userAccessToken?: UserAccessToken;
}

export interface LoginUserRequest {
    username: string;
    password: string;
}

export interface RegisterUserRequest {
    userRegistration?: UserRegistration;
}

export interface ResendVerifyUserEmailRequest {
    email: string;
}

export interface ResendVerifyUserPhoneRequest {
    phone: string;
}

export interface ResetPasswordRequest {
    resetPassword?: ResetPassword;
}

export interface SendPasswordResetLinkRequest {
    sendPasswordResetLink?: SendPasswordResetLink;
}

export interface SubmitPatientFeedbackRequest {
    patientFeedback?: PatientFeedback;
}

export interface UpdateUserRequest {
    userUpdate?: UserUpdate;
}

export interface UploadGovernmentIdRequest {
    frontPhoto: Blob;
    userId: string;
}

export interface UploadInsuranceCardRequest {
    frontPhoto: Blob;
    backPhoto: Blob;
    userId: string;
}

export interface UserGetStoresRequest {
    pharmacyId: string;
    longitude?: string;
    latitude?: string;
    search?: string;
}

export interface UserMedicationRequest {
    rx: string;
}

export interface VerifyUserEmailRequest {
    userVerification?: UserVerification;
}

export interface VerifyUserPhoneRequest {
    userVerification?: UserVerification;
}

/**
 * 
 */
export class UserApi extends runtime.BaseAPI {

    /**
     * Validates the google token and logs the user in to the app and returns a user access token
     */
    async googleSignInRaw(requestParameters: GoogleSignInRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserAccessToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/google-sign-in`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserAccessTokenToJSON(requestParameters.userAccessToken),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAccessTokenFromJSON(jsonValue));
    }

    /**
     * Validates the google token and logs the user in to the app and returns a user access token
     */
    async googleSignIn(requestParameters: GoogleSignInRequest = {}, initOverrides?: RequestInit): Promise<UserAccessToken> {
        const response = await this.googleSignInRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Logs user into the system
     */
    async loginUserRaw(requestParameters: LoginUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserToken>> {
        if (requestParameters.username === null || requestParameters.username === undefined) {
            throw new runtime.RequiredError('username','Required parameter requestParameters.username was null or undefined when calling loginUser.');
        }

        if (requestParameters.password === null || requestParameters.password === undefined) {
            throw new runtime.RequiredError('password','Required parameter requestParameters.password was null or undefined when calling loginUser.');
        }

        const queryParameters: any = {};

        if (requestParameters.username !== undefined) {
            queryParameters['username'] = requestParameters.username;
        }

        if (requestParameters.password !== undefined) {
            queryParameters['password'] = requestParameters.password;
        }

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        const response = await this.request({
            path: `/user/login`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserTokenFromJSON(jsonValue));
    }

    /**
     * Logs user into the system
     */
    async loginUser(requestParameters: LoginUserRequest, initOverrides?: RequestInit): Promise<UserToken> {
        const response = await this.loginUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Register a new customer account
     */
    async registerUserRaw(requestParameters: RegisterUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserRegistrationToJSON(requestParameters.userRegistration),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Register a new customer account
     */
    async registerUser(requestParameters: RegisterUserRequest = {}, initOverrides?: RequestInit): Promise<User> {
        const response = await this.registerUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Resend email verification code for a customer\'s account
     */
    async resendVerifyUserEmailRaw(requestParameters: ResendVerifyUserEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling resendVerifyUserEmail.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        const response = await this.request({
            path: `/user/email-verification`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend email verification code for a customer\'s account
     */
    async resendVerifyUserEmail(requestParameters: ResendVerifyUserEmailRequest, initOverrides?: RequestInit): Promise<void> {
        await this.resendVerifyUserEmailRaw(requestParameters, initOverrides);
    }

    /**
     * Resend phone verification code for a customer\'s account
     */
    async resendVerifyUserPhoneRaw(requestParameters: ResendVerifyUserPhoneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.phone === null || requestParameters.phone === undefined) {
            throw new runtime.RequiredError('phone','Required parameter requestParameters.phone was null or undefined when calling resendVerifyUserPhone.');
        }

        const queryParameters: any = {};

        if (requestParameters.phone !== undefined) {
            queryParameters['phone'] = requestParameters.phone;
        }

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        const response = await this.request({
            path: `/user/phone-verification`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Resend phone verification code for a customer\'s account
     */
    async resendVerifyUserPhone(requestParameters: ResendVerifyUserPhoneRequest, initOverrides?: RequestInit): Promise<void> {
        await this.resendVerifyUserPhoneRaw(requestParameters, initOverrides);
    }

    /**
     * Reset customer account password
     */
    async resetPasswordRaw(requestParameters: ResetPasswordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordToJSON(requestParameters.resetPassword),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Reset customer account password
     */
    async resetPassword(requestParameters: ResetPasswordRequest = {}, initOverrides?: RequestInit): Promise<User> {
        const response = await this.resetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Send password reset link to user
     */
    async sendPasswordResetLinkRaw(requestParameters: SendPasswordResetLinkRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/send-password-reset-link`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: SendPasswordResetLinkToJSON(requestParameters.sendPasswordResetLink),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Send password reset link to user
     */
    async sendPasswordResetLink(requestParameters: SendPasswordResetLinkRequest = {}, initOverrides?: RequestInit): Promise<User> {
        const response = await this.sendPasswordResetLinkRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit patient feedback
     */
    async submitPatientFeedbackRaw(requestParameters: SubmitPatientFeedbackRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/patient-feedback`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: PatientFeedbackToJSON(requestParameters.patientFeedback),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Submit patient feedback
     */
    async submitPatientFeedback(requestParameters: SubmitPatientFeedbackRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.submitPatientFeedbackRaw(requestParameters, initOverrides);
    }

    /**
     * Update a customer account
     */
    async updateUserRaw(requestParameters: UpdateUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserUpdateToJSON(requestParameters.userUpdate),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Update a customer account
     */
    async updateUser(requestParameters: UpdateUserRequest = {}, initOverrides?: RequestInit): Promise<User> {
        const response = await this.updateUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload government id photo
     */
    async uploadGovernmentIdRaw(requestParameters: UploadGovernmentIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.frontPhoto === null || requestParameters.frontPhoto === undefined) {
            throw new runtime.RequiredError('frontPhoto','Required parameter requestParameters.frontPhoto was null or undefined when calling uploadGovernmentId.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling uploadGovernmentId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.frontPhoto !== undefined) {
            formParams.append('frontPhoto', requestParameters.frontPhoto as any);
        }

        if (requestParameters.userId !== undefined) {
            formParams.append('userId', requestParameters.userId as any);
        }

        const response = await this.request({
            path: `/user/government-id`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload government id photo
     */
    async uploadGovernmentId(requestParameters: UploadGovernmentIdRequest, initOverrides?: RequestInit): Promise<void> {
        await this.uploadGovernmentIdRaw(requestParameters, initOverrides);
    }

    /**
     * Upload insurance card photos
     */
    async uploadInsuranceCardRaw(requestParameters: UploadInsuranceCardRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.frontPhoto === null || requestParameters.frontPhoto === undefined) {
            throw new runtime.RequiredError('frontPhoto','Required parameter requestParameters.frontPhoto was null or undefined when calling uploadInsuranceCard.');
        }

        if (requestParameters.backPhoto === null || requestParameters.backPhoto === undefined) {
            throw new runtime.RequiredError('backPhoto','Required parameter requestParameters.backPhoto was null or undefined when calling uploadInsuranceCard.');
        }

        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling uploadInsuranceCard.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.frontPhoto !== undefined) {
            formParams.append('frontPhoto', requestParameters.frontPhoto as any);
        }

        if (requestParameters.backPhoto !== undefined) {
            formParams.append('backPhoto', requestParameters.backPhoto as any);
        }

        if (requestParameters.userId !== undefined) {
            formParams.append('userId', requestParameters.userId as any);
        }

        const response = await this.request({
            path: `/user/insurance-card`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload insurance card photos
     */
    async uploadInsuranceCard(requestParameters: UploadInsuranceCardRequest, initOverrides?: RequestInit): Promise<void> {
        await this.uploadInsuranceCardRaw(requestParameters, initOverrides);
    }

    /**
     * Get current user details
     */
    async userRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Get current user details
     */
    async user(initOverrides?: RequestInit): Promise<User> {
        const response = await this.userRaw(initOverrides);
        return await response.value();
    }

    /**
     * Customer accepts ToS and Privacy Policy
     */
    async userAgreementsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        const response = await this.request({
            path: `/user/agreements`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Customer accepts ToS and Privacy Policy
     */
    async userAgreements(initOverrides?: RequestInit): Promise<User> {
        const response = await this.userAgreementsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Customer gets pharmacy locations
     */
    async userGetStoresRaw(requestParameters: UserGetStoresRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<PharmacyLocation>>> {
        if (requestParameters.pharmacyId === null || requestParameters.pharmacyId === undefined) {
            throw new runtime.RequiredError('pharmacyId','Required parameter requestParameters.pharmacyId was null or undefined when calling userGetStores.');
        }

        const queryParameters: any = {};

        if (requestParameters.longitude !== undefined) {
            queryParameters['longitude'] = requestParameters.longitude;
        }

        if (requestParameters.latitude !== undefined) {
            queryParameters['latitude'] = requestParameters.latitude;
        }

        if (requestParameters.pharmacyId !== undefined) {
            queryParameters['pharmacyId'] = requestParameters.pharmacyId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        const response = await this.request({
            path: `/user/stores`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(PharmacyLocationFromJSON));
    }

    /**
     * Customer gets pharmacy locations
     */
    async userGetStores(requestParameters: UserGetStoresRequest, initOverrides?: RequestInit): Promise<Array<PharmacyLocation>> {
        const response = await this.userGetStoresRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * The customer gets a details prescription for a medication
     */
    async userMedicationRaw(requestParameters: UserMedicationRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Medication>> {
        if (requestParameters.rx === null || requestParameters.rx === undefined) {
            throw new runtime.RequiredError('rx','Required parameter requestParameters.rx was null or undefined when calling userMedication.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/medications/{rx}`.replace(`{${"rx"}}`, encodeURIComponent(String(requestParameters.rx))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MedicationFromJSON(jsonValue));
    }

    /**
     * The customer gets a details prescription for a medication
     */
    async userMedication(requestParameters: UserMedicationRequest, initOverrides?: RequestInit): Promise<Medication> {
        const response = await this.userMedicationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Customer gets prescriptions for all patients
     */
    async userMedicationsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Medication>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("bearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/user/medications/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(MedicationFromJSON));
    }

    /**
     * Customer gets prescriptions for all patients
     */
    async userMedications(initOverrides?: RequestInit): Promise<Array<Medication>> {
        const response = await this.userMedicationsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Verify a customer\'s email
     */
    async verifyUserEmailRaw(requestParameters: VerifyUserEmailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/email-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserVerificationToJSON(requestParameters.userVerification),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Verify a customer\'s email
     */
    async verifyUserEmail(requestParameters: VerifyUserEmailRequest = {}, initOverrides?: RequestInit): Promise<User> {
        const response = await this.verifyUserEmailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Verify a customer\'s phone
     */
    async verifyUserPhoneRaw(requestParameters: VerifyUserPhoneRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {
          // for localtunnel to skip message
          "Bypass-Tunnel-Reminder": "true",
        };

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/user/phone-verification`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserVerificationToJSON(requestParameters.userVerification),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     * Verify a customer\'s phone
     */
    async verifyUserPhone(requestParameters: VerifyUserPhoneRequest = {}, initOverrides?: RequestInit): Promise<User> {
        const response = await this.verifyUserPhoneRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
