import React, { FunctionComponent, PropsWithChildren } from "react";
import {
  createStackNavigator,
  StackHeaderProps,
} from "@react-navigation/stack";
import type { StackNavigationProp } from "@react-navigation/stack";
import { Medications } from "../screens/medications/Medications";
import { AccountNavigation } from "../screens/account/AccountNavigation";
import { Messages } from "../screens/messages/Messages";
import { getText } from "assets/localization/localization";
import { Icon } from "assets/components/icon";
import {
  AccountIcon,
  CalendarIcon,
  HomeIcon,
  MedicationsIcon,
  MessageSquareIcon,
} from "assets/icons";
import theme from "assets/theme";
import { WebHeader } from "../components/landing-header/WebHeader";
import { Dashboard } from "../screens/dashboard/Dashboard";
import { AppointmentsNavigation } from "../screens/appointments/AppointmentsNavigation";
import { WebFooter } from "../components/web-footer/WebFooter";
import { AppointmentDetails } from "../screens/appointment/AppointmentDetails";
import { FillInForm } from "../screens/fill-in-form/FillInForm";

const Stack = createStackNavigator<WebTabsStackParamList>();

export const WebTabsNavigation: FunctionComponent<RefillNavigationProps> =
  ({}) => {
    return (
      <>
        <Stack.Navigator
          screenOptions={{
            headerShown: true,
            header: WebTabsHeader,
            title: getText("app"), // fallback title
          }}
        >
          <Stack.Group>
            <Stack.Screen
              name="home"
              component={Dashboard}
              options={{ title: getText("home") }}
            />
            <Stack.Screen
              name="account"
              component={AccountNavigation}
              options={{ title: getText("account") }}
            />
            <Stack.Screen
              name="medications"
              component={Medications}
              options={{ title: getText("medications") }}
            />
            <Stack.Screen
              name="messages"
              component={Messages}
              options={{ title: getText("messages") }}
            />
            <Stack.Screen
              name="appointments"
              component={AppointmentsNavigation}
              options={{ title: getText("appointments") }}
            />
            <Stack.Screen name="appointment" component={AppointmentDetails} />
            <Stack.Screen name="forms" component={FillInForm} />
          </Stack.Group>
        </Stack.Navigator>
        <WebFooter
          phone="(877) 959-7550"
          email="help@digitalpharmacist.com"
          fax="(877) 456-2345"
        />
        {/*TODO get these values from pharmacy service */}
      </>
    );
  };

interface RefillNavigationProps {}

export type WebTabsStackParamList = {
  home: undefined;
  account: undefined;
  medications: undefined;
  messages: undefined;
  appointments: undefined;
  appointment: undefined;
  forms: {
    form_id: string;
    location_id: string;
  };
};

export type WebTabsStackNavigationProp =
  StackNavigationProp<WebTabsStackParamList>;

const WebTabsHeader: FunctionComponent<PropsWithChildren<StackHeaderProps>> = (
  props
) => {
  return <WebHeader navigation={props.navigation} screens={screens} />;
};

export const screens = [
  {
    name: "home",
    label: getText("dashboard"),
    icon: () => <Icon icon={HomeIcon} color={theme.palette.white} />,
  },
  {
    name: "medications",
    label: getText("medications"),
    icon: () => <Icon icon={MedicationsIcon} color={theme.palette.white} />,
  },
  {
    name: "messages",
    label: getText("messages"),
    icon: () => <Icon icon={MessageSquareIcon} color={theme.palette.white} />,
  },
  {
    name: "appointments",
    label: getText("appointments"),
    icon: () => <Icon icon={CalendarIcon} color={theme.palette.white} />,
  },
  {
    name: "account",
    label: getText("account"),
    icon: () => <Icon icon={AccountIcon} color={theme.palette.white} />,
  },
];
