/* tslint:disable */
/* eslint-disable */
/**
 * user
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PharmacyLocation
 */
export interface PharmacyLocation {
    /**
     * Unique identifier for the given user.
     * @type {string}
     * @memberof PharmacyLocation
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyLocation
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyLocation
     */
    address1: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyLocation
     */
    address2: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyLocation
     */
    city: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyLocation
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyLocation
     */
    postalCode: string;
    /**
     * 
     * @type {string}
     * @memberof PharmacyLocation
     */
    phone: string;
    /**
     * 
     * @type {number}
     * @memberof PharmacyLocation
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof PharmacyLocation
     */
    longitude: number;
}

export function PharmacyLocationFromJSON(json: any): PharmacyLocation {
    return PharmacyLocationFromJSONTyped(json, false);
}

export function PharmacyLocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): PharmacyLocation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'address1': json['address1'],
        'address2': json['address2'],
        'city': json['city'],
        'state': json['state'],
        'postalCode': json['postal_code'],
        'phone': json['phone'],
        'latitude': json['latitude'],
        'longitude': json['longitude'],
    };
}

export function PharmacyLocationToJSON(value?: PharmacyLocation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'address1': value.address1,
        'address2': value.address2,
        'city': value.city,
        'state': value.state,
        'postal_code': value.postalCode,
        'phone': value.phone,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}

